var regionsNames = {}, regions = { Europe: ['Western','Denmark','France','Germany','Netherlands','Norway','Poland','Russia','Sweden','Ukraine','UK','Italy','Spain','Portugal','Albania','Andorra','Armenia','Austria','Belarus','Belgium','Bosnia and Herzegovina','Bulgaria','Croatia','Cyprus','Czech Republic','Estonia','Finland','Georgia','Greenland','Greece','Hungary','Iceland','Ireland','Kosovo','Latvia','Liechtenstein','Lithuania','Luxembourg','Macedonia','Malta','Moldova','Monaco','Montenegro','Romania','San Marino','Serbia','Slovakia','Slovenia','Switzerland'],
AmericaN:
['USA','Canada','Australia','New Zealand'],
Latin:['Argentina','Bolivia','Brazil','Chile','Colombia','Ecuador','Guyana','Paraguay','Peru','Suriname','Uruguay','Venezuela','Cuba','El Salvador','Guatemala','Nicaragua','Panama','Honduras','Dominican Republic','Costa Rica'], // do not use the following countries: 'Dominica','Haiti','Trinidad and Tobago','Jamaica','Saint Kitts and Nevis','Saint Lucia','Saint Vincent and the Grenadines','Belize','Barbados','The Bahamas','Antigua and Barbuda','Grenada'],
Arab: ['Afghanistan','Algeria','Azerbaijan','Bahrain','Egypt','Iran','Iraq','Israel','Jordan','Kuwait','Lebanon','Libya','Morocco','Oman','Pakistan','Qatar','Saudi Arabia','Somalia','Syria','Tunisia','Turkey','United Arab Emirates','Yemen','Turkmenistan','Tajikistan','Uzbekistan'],
Africa: 
['African', 'Angola','Benin','Botswana','Burkina Faso','Burundi','Cameroon','Cape Verde','Central African Republic','Chad','Comoros','Republic of the Congo','Democratic Republic of the Congo','Cote d\'Ivoire','Djibouti','Equatorial Guinea','Eritrea','Ethiopia','Gabon','The Gambia','Ghana','Guinea','Guinea-Bissau','Kenya','Lesotho','Liberia','Madagascar','Malawi','Mali','Mauritania','Mauritius','Mozambique','Namibia','Niger','Nigeria','Rwanda','Sao Tome and Principe','Senegal','Seychelles','Sierra Leone','South Africa','South Sudan','Sudan','Swaziland','Tanzania','Togo','Uganda','Zambia','Zimbabwe'],
India: 
['India','Bangladesh','Bhutan','Myanmar','Sri Lanka','Nepal','Maldives'],
AsiaSE: 
['Brunei','Cambodia','Indonesia','Laos','Malaysia','Vietnam','Philippines','Thailand','East Timor','Fiji','Kiribati','Marshall Islands','Federated States of Micronesia','Nauru','Palau','Papua New Guinea','Samoa','Solomon Islands','Tonga','Tuvalu','Vanuatu'],
China: 
['Asian', 'China','Japan','North Korea','South Korea','Singapore','Mongolia','Taiwan','Kazakhstan','Kyrgyzstan']
};

var races = {}, c;
for(var i=0; i<regions['Europe'].length; i++) {
	races[regions['Europe'][i]]= 'W';
}
for(var i=0; i<regions['AmericaN'].length; i++) {
	races[regions['AmericaN'][i]]= 'W';
}
for(var i=0; i<regions['Latin'].length; i++) {
	c = regions['Latin'][i];
	races[c]= 'L';
	firstnameF[c] = firstnameF['Spain'];
	firstnameM[c] = firstnameM['Spain'];
	surnames[c] = surnames['Spain'];
}
for(var i=0; i<regions['Arab'].length; i++) {
	c = regions['Arab'][i];
	races[c] = 'A';
	firstnameF[c] = firstnameF['Arab'];
	firstnameM[c] = firstnameM['Arab'];
	surnames[c] = surnames['Arab'];
}
for(var i=0; i<regions['Africa'].length; i++) {
	c = regions['Africa'][i];
	races[c] = 'R';
	firstnameF[c] = firstnameF['Africa'];
	firstnameM[c] = firstnameM['Africa'];
	surnames[c] = surnames['Africa'];
}
for(var i=0; i<regions['India'].length; i++) {
	races[regions['India'][i]]= 'I';
}
for(var i=0; i<regions['AsiaSE'].length; i++) {
	races[regions['AsiaSE'][i]]= 'S';
}
for(var i=0; i<regions['China'].length; i++) {
	races[regions['China'][i]]= 'C';
}

firstnameF['Canada'] = firstnameF['USA'];
firstnameM['Canada'] = firstnameM['USA'];
surnames['Canada'] = surnames['USA'];

for(var r in regions) {
	if(!regionsNames[r]) regionsNames[r] = [];
	for(var i=0; i<regions[r].length; i++) {
		var c= regions[r][i];
		if(firstnameF[c] && firstnameM[c] && surnames[c]) regionsNames[r].push(c);
	}
}
for(var r in regionsNames) {
	regionsNames[r].sort();
}
