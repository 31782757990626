
function showCompletedBelts(){
	$('ul.belt-list li.hide').removeClass('hide');
}

function showProfile(){
	missionParams = undefined;
  $('.user-belt-holder').addClass('animate');
	userBelt = getBelt();
	nextBelt = userBelt<11? userBelt + 1 : 11;
	$('.beltname').text(beltNames[lang][userBelt]);
	$('.belt-list').html('');
	if(!starMap['superm'][12]) starMap['superm'].push([0,0]);
	if(!starMap['meeting'][12]) starMap['meeting'].push([0,0]);
	if(!starMap['face'][12]) starMap['face'].push([0,0]);
	if(!starMap['library'][12]) starMap['library'].push([0,0]);
	if(!starMap['mindmap'][12]) starMap['mindmap'].push([0,0]);
	if(!starMap['bankv'][12]) starMap['bankv'].push([0,0]);
	for(var i=1; i<=11; i++)
		$('.belt-list').append(TemplateEngine($('#tpl-belt-details').html(),{belt: i}));
  $('.game-menu .button').removeClass('active');
  $('.game-menu .button:nth-child(2)').addClass('active');
	showPanel('profile');
	translate('profile');
}

function toggleBelt(button){
	$(button).parent().children().addClass('close');
	$(button).removeClass('close');
}
