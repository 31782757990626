var BRAINSTORM_PAGESIZE = isMobile? 5 : 10;

function initTestLibrary(){
	currentMode = 'test';
	$('input').val('');
    
	showPanel('paneltest'+currentGame);
	index = -1;
	randomLibrary.sort(function(){ return Math.random() - 0.5; });
	nextLibraryTest();
	showLives(3);
}

function initGameLibrary(){
	currentMode = 'learn';
	pageSize = BRAINSTORM_PAGESIZE;
  // We have already set gameLevel when start newGame.
  // DO NOT overwrite it here.
	///// gameLevel = getGameLevel(currentGame);
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1];
	totalPages = Math.ceil(totalItems/pageSize);
	star2 = levelSettings[6];
	star3 = levelSettings[7];

	randomLibrary = [];
	for(var i=0; i<totalItems; i++) {
		randomLibrary.push({id: i, text: randomItemU(libraryItemsEU, libraryItems['easy'])[lang]});
	}
	if(levelSettings[4]) {
		for(var i=0; i<totalItems/2; i++) {
			randomLibrary[i].text = randomItemU(libraryItemsHU, libraryItems['hard'])[lang];
		}
	}
	if(levelSettings[5]) {
		for(var i=0; i<totalItems; i++) {
			randomLibrary[i].color = parseInt(Math.random()*levelSettings[5])+1;
		}
	}
  
  if(missionParams) {
    itemTime = 1000000000;
    $('.pagination').addClass('hide'); 
  } else { $('.pagination').removeClass('hide'); }

	$('.brain-grid').html('');
	$('#panellibrary .pagination').hide();
	itemTime = levelSettings[2];
	page = -1;
	learnRemainTime = 0;
	penaltyTime =0;
	pageTime = 0;
	pageTimes = [];
	hideButton();
	setTimeout(function(){
		nextLibraryPage();
	}, 2000);
	setTimeout(updateClockLearn, 5000);
}

function verifyLibrary(){
	if((!wordQues && $('#library-input-order').text() == (randomLibrary[index].id+1)) || (wordQues && ($('.test-puzzle .body .active')[0].innerHTML + $('.test-puzzle .body .active')[1].innerHTML== randomLibrary[index].text || $('.test-puzzle .body .active')[1].innerHTML + $('.test-puzzle .body .active')[0].innerHTML == randomLibrary[index].text))) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		if(levelSettings[5]) {
			setTimeout(function() {
				nextLibraryTestColor();
				$('.tick').removeClass('animate');
			}, 500);
		} else {
			randomLibrary[index].step = 'done';
			setTimeout(function() {
				nextLibraryTest();
				$('.tick').removeClass('animate');
			}, 500);
		}
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function(){
			$('.test-puzzle .body .active').removeClass('active');
			$('#library-input-order').text('');
			$('.tick-red').removeClass('animate');
		}, 500);
		checkGameOver();
	}
}

function verifyColor(c) {
	if(c == randomLibrary[index].color) {
		randomLibrary[index].step = 'done';
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function() {
			nextLibraryTest();
			$('.tick').removeClass('animate');
		}, 500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function(){
			$('.tick-red').removeClass('animate');
		}, 500);
		checkGameOver();
	}
}

function keypressLibraryTest(e) {
	if(e.keyCode == 13) {
		verifyLibrary();
	}
}

function nextLibraryPage() {
	page += 1;
	if(page >= totalPages) {
		startTesting();
	} else {
		learnRemainTime += pageTime>0?pageTime:0;
		//pageTimes.push(pageTime >= 0 ? pageTime : 0);
		pageTimes.push(pageTime >= 0 ? pageTime0 - pageTime : pageTime0);
		$('.brain-frame .brain-grid').html('');
		for(var i= page*pageSize; i<totalItems && i<page*pageSize+pageSize; i++){
			$('.brain-frame .brain-grid').append(
				'<li>'+
				  '<div class="item color-'+(levelSettings[5]?randomLibrary[i].color:'')+'">'+
					'<div class="text hide item-'+(i % pageSize)+'"><span>'+(i+1)+'.</span> '+randomLibrary[i].text+'</div>'+
				  '</div>'+
				'</li>');
		}
		$('.brain-frame .brain-grid').css('opacity',1);
		showPages();
		arrangeGrid();
	}
}

function skipLibraryWord(step){
	if(index<randomLibrary.length) randomLibrary[index].step = step;
	checkGameOver();
	if(lives) {
	  nextLibraryTest();
	}
}

function numLibrary(button){
	var s = $(button).text();
	if(s == 'x'){
		$("#library-input-order").text('');
	}else{
		$("#library-input-order").text($("#library-input-order").text()+s);
	}
}

function arrangeGrid(){
	var rows = 3, cols = isMobile? 2 : 6;
	var cellWidth = $('.brain-grid').width()/cols;
	var cellHeight = $('.brain-grid').height()/rows;
	$('.brain-grid .item').css('width',cellWidth-50);
	$('.brain-grid .item').css('height',  cellHeight-50);

	var randOrder = [];

	for(var i=1; i<=pageSize; i++)  {
		randOrder.push(i);
	}
	for(var i=pageSize+1; i<=rows * cols; i++) {
		randOrder.push(0);
	}
	randOrder.sort(function(){ return Math.random()-0.5; });

	for(var cell=0; cell<rows * cols; cell++) {
		moveTagGrid(randOrder[cell], cell, cellWidth, cellHeight, parseInt(cell / cols), cell % cols);
	}
	playSoundAction('music-library-action');
}

function nextLibraryTestColor() {
	$('.library-test-sequence, .library-test-word').addClass('hide');
	$('.test-color').removeClass('hide');
	$('.test-color .brain-grid').html('');
	var options= [];
	for(var i=1; i<=levelSettings[5]; i++) options.push(i);
	options.sort(function(){ return Math.random()-0.5; });
	for(var i=0; i<options.length; i++){
		$('.test-color .brain-grid').append(
			'<li onclick="verifyColor('+options[i]+')">'+
			  '<div class="item color-'+options[i]+'">'+
				'<div class="text">'+randomLibrary[index].text+'</div>'+
			  '</div>'+
			'</li>');
	}
}

function nextLibraryTest() {
	index += 1;
	for(; index<totalItems; index++){
		if(randomLibrary[index].step != 'done') break;
	}
	if(index >= totalItems) {
		if(randomLibrary.filter(function(item){ return item.step != 'done'; }).length == 0) {
			showResult(totalItems, totalItems);
		} else {
			index = -1;
			nextLibraryTest();
		}
		return;
	}
	$('.test-order').text(''+(index+1)+'/'+totalItems);
	if(!randomLibrary[index].step) {
		showWordSplits();
	} else {
		nextLibraryTestColor();
	}
}

function showWordSplits(){
	wordQues = Math.random() > 0.5;
	$('#library-input-order').text(wordQues? randomLibrary[index].id+1: '');
	$('#library-input-word').val(wordQues? '': randomLibrary[index].text);
	$('.library-sequence').html(getMSG('game_brainstorming_test_01') + ' '+ (randomLibrary[index].id+1)+ '?');
	$('.library-word').html(getMSG('game_brainstorming_test_02')+' <b>&lsquo;'+ randomLibrary[index].text+'&rsquo;</b>'+'?');
	if(wordQues) {
		$('.test-puzzle .body').html('');
		$('.library-test-sequence, .test-color').addClass('hide');
		$('.library-test-word').removeClass('hide');
		var option = randomLibrary[index].text, options= [option.substr(0,option.length/2), option.substr(option.length/2)], part;
		for(var i=1; i<=libraryItems['easy'].length; i++) {
			option = libraryItems['easy'][(index+i)%libraryItems['easy'].length][lang];
			part = option.substr(0,option.length/2);
			pushUnique(options, part);
			part = option.substr(option.length/2);
			pushUnique(options, part);
			if(options.length >= 12) break;
		}
		options.sort(function(){ return Math.random()-0.5; });
		for(var i=0; i<options.length; i++){
			$('.test-puzzle .body').append('<div class="box" onclick="$(this).toggleClass(\'active\');if($(\'.test-puzzle .body .active\').length >= 2) verifyLibrary();">'+options[i]+'</div>');
		}
	} else {
		$('.library-test-sequence').removeClass('hide');
		$('.library-test-word, .test-color').addClass('hide');
	}
}

function moveTagGrid(child, cell, cellWidth, cellHeight, row, col){
	if(child){
		var id = child % pageSize || pageSize;
		$('.brain-grid li:nth-child('+id+')').css({
			'top':row * cellHeight+ 20 + (Math.random()-0.5)*40,
			'left':col * cellWidth+ 20 + (Math.random()-0.5)*40,
			'margin':0,
			'-webkit-transform': 'rotate('+(Math.random()-0.5)*10+'deg) translate3d(0,0,0)',
			'transform': 'rotate('+(Math.random()-0.5)*10+'deg) translate3d(0,0,0)'
		});
		setTimeout(function(){
			$('.brain-frame .item-'+(child-1)).removeClass('hide');
			if($('.brain-frame .item').length == child % pageSize || child % pageSize == 0) {
				$('#panellibrary .button').removeClass('inactive');
			}
		}, 100 + id * 1500);
	}
}
