window.dictionary = [
  {
    "id": "game_timetravel_dropdown_06",
    "description": "Time Travel dd L2b",
    "en": "<h1>Do you remember when the events happened?</h1>",
    "no": "<h1>Husker du når hendelsene skjedde?</h1>"
  },
  {
    "id": "game_socialstar_intro_05",
    "description": "SocialStar main intro",
    "en": "You’re at a party after an all-day event, where you’ve met many new people. Have you got it in you to remember all the names, titles and hobbies of the people you’ve met throughout the day?",
    "no": "Du er på fest etter en heldagsmesse hvor du har møtt mange mennesker. Klarer du å huske navnene, titlene og hobbiene til alle du har møtt?"
  },
  {
    "id": "game_socialstar_intro_09",
    "description": "SocialStar intro - level 4",
    "en": "Great, to really connect with people it’s important to know what they’re passionate about. Can you remember their hobbies as well?",
    "no": "Bra, for å virkelig skape gode relasjoner med mennesker er det viktig å vite hva de brenner for. Klarer du å huske hobbiene deres også?"
  },
  {
    "id": "game_socialstar_intro_07",
    "description": "SocialStar intro - level 2",
    "en": "Now it’s time to remember both the first names and surnames of the people you met at the event. Have you got it in you - or will you have to go through the social embarrassment of forgetting someone’s name?",
    "no": "Nå gjelder det å huske både fornavnene og etternavnene til menneskene du møter på messen. Har du det som skal til - eller må du gå gjennom den flaue situasjonen av å glemme noens navn mens folk husker ditt navn? "
  },
  {
    "id": "game_socialstar_intro_08",
    "description": "SocialStar intro - level 3",
    "en": "All right, now let’s see if you can remember the titles of the people you met as well. You wouldn’t want to offend the CEO by acting like he’s the middle manager, would you?",
    "no": "Ok, la oss nå se om du kan få med deg titlene til de du møter også. Du ville vel ikke fornærme direktøren i selskapet ved å oppføre deg som om han er en mellomlederen? "
  },
  {
    "id": "game_socialstar_dropdown_01",
    "description": "SocialStar dd L1a",
    "en": "<h1>Memorize the first names of the people you meet</h1>",
    "no": "<h1>Memoriser fornavnene til menneskene du møter</h1>"
  },
  {
    "id": "game_socialstar_intro_06",
    "description": "SocialStar intro - level 1",
    "en": "The first people you met at the event only gave you their first names, so remembering them should be a walk in the park. Will you rise to become the social star or face the embarrassment of forgetting their names?",
    "no": "De første menneskene du møtte på messen ga deg bare fornavnet sitt, så å huske navnene deres burde være rene barneleken. Har du det som skal til for å bli navnemester eller må du lide deg gjennom flauheten av å glemme navnene deres?"
  },
  {
    "id": "game_socialstar_dropdown_05",
    "description": "SocialStar dd L1b",
    "en": "<h1>Do you remember the first names of the people you met?</h1>",
    "no": "<h1>Husker du fornavnene til menneskene du møtte?</h1>"
  },
  {
    "id": "game_timetravel_dropdown_01",
    "description": "Time Travel dd L1a",
    "en": "<h1>Memorize what happened in which year</h1>",
    "no": "<h1>Memoriser hva som skjedde i hvilket år</h1>"
  },
  {
    "id": "game_wheel_dropdown_04",
    "description": "Menu dd",
    "en": "Achievements",
    "no": "Pokaler"
  },
  {
    "id": "global_system_01",
    "description": "All game intro - learn area",
    "en": "Memolife system",
    "no": "Memolife system"
  },
  {
    "id": "game_wheel_dropdown_06",
    "description": "Menu dd",
    "en": "BELTS",
    "no": "BELTER"
  },
  {
    "id": "system_memolife_tutorial_02",
    "description": "MemolifeSys tutorial",
    "en": "To remember long numbers all you have to do is create visual stories out of the Person - Action - Object memory codes you have learnt. ",
    "no": "Alt du trenger å gjøre for å huske lengre numre er å visualisere historier med huskekodene for Person - Handling - Objekt som du har lært."
  },
  {
    "id": "system_memolife_tutorial_03",
    "description": "MemolifeSys tutorial",
    "en": "Here's how you visualize to remember:",
    "no": "Slik visualiserer du for å huske:"
  },
  {
    "id": "system_memolife_tutorial_04",
    "description": "MemolifeSys tutorial",
    "en": "Albert Einstein (Person - 00) is doing a golf swing (Action - 09) with a candle (Object - 01) on the moon (location).",
    "no": "Albert Einstein (Person - 00) gjør en golfswing (Handling - 09) med et stearinlys (Objekt - 01) på månen (Lagringssted)."
  },
  {
    "id": "system_memolife_tutorial_05",
    "description": "MemolifeSys tutorial",
    "en": "Got it, let me try ",
    "no": "Skjønner, la meg prøve"
  },
  {
    "id": "st6",
    "description": "---- MemolifeSys tutorial",
    "en": "What is happening",
    "no": "Hva skjer"
  },
  {
    "id": "game_shopping_intro_09",
    "description": "Shopping intro4",
    "en": "Supermarkets sometimesy price their products differently inside the store than in the cash registers. Let’s see if you remember the prices of all the products and can avoid paying too much!",
    "no": "Det hender supermarkedene priser produktene forskjellig i butikken og i kassen. Klarer du å huske prisene for produktene og unngå å betale for mye?"
  },
  {
    "id": "game_shopping_intro_11",
    "description": "Shopping techniques",
    "en": "<p><b>What if you could easily remember large shopping lists, to-do lists, project lists and all the quantities for the list points? Too good to be true, you might think ... but with a little practice this will be as natural as cycling.",
    "no": "<p><b>Hva hvis du enkelt kunne huske store handlelister, to-do lister, prosjektlister og til og med alle detaljene for listepunktene? For godt til å være sant, tenker du kanskje ... men med litt øving vil dette være like naturlig som å sykle."
  },
  {
    "id": "game_shopping_intro_08",
    "description": "Shopping intro3",
    "en": "All right, now let’s see if you can get the right quantities of your shopping products as well. Have you got what it takes to remember it all?",
    "no": "Ok, la oss nå se om du kan få med deg de riktige mengdene og antallet av produktene du skal handle. Har du det som skal til å ta med deg nok? "
  },
  {
    "id": "game_shopping_dropdown_01",
    "description": "Shopping dd L1a",
    "en": "<h1>Memorize the products in the right sequence</h1>",
    "no": "<h1>Memoriser produktene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_shopping_dropdown_05",
    "description": "Shopping dd L1b",
    "en": "<h1>Do you remember all the products in the right sequence?</h1>",
    "no": "<h1>Husker du alle produktene i riktig rekkefølge?</h1>"
  },
  {
    "id": "game_shopping_dropdown_02",
    "description": "Shopping dd L2a",
    "en": "<h1>Memorize more products in the right sequence</h1>",
    "no": "<h1>Memoriser produktene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_shopping_dropdown_06",
    "description": "Shopping dd L2b",
    "en": "<h1>Do you remember all the products in the right sequence?</h1>",
    "no": "<h1>Husker du alle produktene i riktig rekkefølge?</h1>"
  },
  {
    "id": "game_shopping_dropdown_03",
    "description": "Shopping dd L3a",
    "en": "<h1>Memorize the products & quantities in the right sequence</h1>",
    "no": "<h1>Memoriser produktene & mengdene i riktig rekkefølge</h1>"
  },
  {
    "id": "system_roman_intro_01",
    "description": "RomanSys- Coming soon",
    "en": "The Roman Room System",
    "no": "Det Romerske Rom-systemet"
  },
  {
    "id": "system_roman_intro_02",
    "description": "RomanSys- Coming soon",
    "en": "<p>Imagine you’re living in an enormous Roman villa in the glory days of the Roman Empire. Together with all your furnitures you also have a lot of memories in the different rooms, making your home a true memory palace.</p><p>Using the Roman Room system, all you need to do is visualize the things you need to remember and mentally place them in different rooms. That way, you can organize the rooms into different topics. Later, you can simply look inside a topic room for what you need to recall, just like you would look for a computer file in a folder. Pretty cool, don’t you think?</p><p> Let’s look at some tips and examples in the article right away ...</p>",
    "no": "<p>Se for deg at du lever i en enorm romersk villa fra Romertiden. Sammen alle de majestiske rommene og møblene har du også en rekke minner i rommene, som virkelig gjør villaen din til et hukommelsespalass.</p><p>Når du bruker det Romerske Romsystemet er alt du trenger å gjøre å visualisere det du vil huske og plassere det mentalt på de forskjellige stedene i rommene. På den måten kan du organisere rommene i temaer. Senere, kan du rett og slett ta en titt i rommene for det du trenger å hente frem, akkurat som du skulle kikke i en mappe i en computer. Ganske kult egentlig, synes du ikke?</p><p> La oss ta en titt på noen tips og eksempler i artikkelen med en gang ...</p>"
  },
  {
    "id": "system_rhyme_dropdown_02",
    "description": "Rhyme  part B -dropdown",
    "en": "<h1>Can you match the memory codes to the correct numbers?</h1>",
    "no": "<h1>Klarer du å koble huskekodene til de riktige tallene?</h1>"
  },
  {
    "id": "global_system_04",
    "description": "All game intro - learn area",
    "en": "Rhyme System",
    "no": "Rim-systemet"
  },
  {
    "id": "system_name_20",
    "description": "NameSys- pratice",
    "en": "What is your memory code for this name?",
    "no": "Hva er huskekoden for dette navnet?"
  },
  {
    "id": "game_socialstar_dropdown_02",
    "description": "SocialStar dd L2a",
    "en": "<h1>Memorize the first names & surnames of the people you meet</h1>",
    "no": "<h1>Memoriser fornavnene & etternavnene til menneskene du møter</h1>"
  },
  {
    "id": "game_socialstar_test_01",
    "description": "SocialStar lv 1 - test questions",
    "en": "<h1><b>What's the first name of this person?</b></h1><p>Combine the letters</p>",
    "no": "<h1><b>Hva er fornavnet til denne personen?</b></h1><p>Kombiner bokstavene</p>"
  },
  {
    "id": "game_socialstar_dropdown_06",
    "description": "SocialStar dd L2b",
    "en": "<h1>Do you remember the first names & surnames of the people you met?</h1>",
    "no": "<h1>Husker du fornavnene & etternavnene til menneskene du møtte?</h1>"
  },
  {
    "id": "game_socialstar_dropdown_03",
    "description": "SocialStar dd L3a",
    "en": "<h1>Memorize the names & titles of the people you meet</h1>",
    "no": "<h1>Memoriser navnene & titlene til menneskene du møter</h1>"
  },
  {
    "id": "game_socialstar_dropdown_07",
    "description": "SocialStar dd L3b",
    "en": "<h1>Do you remember the names & titles of the people you met?</h1>",
    "no": "<h1>Husker du navnene & titlene til menneskene du møtte?</h1>"
  },
  {
    "id": "game_socialstar_dropdown_04",
    "description": "SocialStar dd L4a",
    "en": "<h1>Memorize the names, titles & hobbies of the people you meet</h1>",
    "no": "<h1>Memoriser navnene, titlene & hobbiene til menneskene du møter</h1>"
  },
  {
    "id": "game_socialstar_test_02",
    "description": "SocialStar lv 2",
    "en": "<h1><b>What's the surname of this person?</b></h1><p>Combine the letters</p>",
    "no": "<h1><b>Hva er etternavnet til denne personen?</b></h1><p>Kombiner bokstavene</p>"
  },
  {
    "id": "game_socialstar_test_03",
    "description": "SocialStar lv3",
    "en": "<h1><b>Do you remember the work title?</b></h1>",
    "no": "<h1><b>Husker du jobbtittelen?</b></h1>"
  },
  {
    "id": "l-S1",
    "description": "--- Shopping",
    "en": "Shopping List : Level",
    "no": "Handlelisten: Nivå"
  },
  {
    "id": "game_shopping_intro_12",
    "description": "Shopping",
    "en": "Ace the Shopping List game with these memory systems:",
    "no": "Bli bedre i Handlelisten med disse huskesystemene:"
  },
  {
    "id": "game_shopping_intro_01",
    "description": "Shopping",
    "en": "Shopping List",
    "no": "Handlelisten"
  },
  {
    "id": "game_shoppingflash_intro_02",
    "description": "Shopping flash couse intro -  main text",
    "en": "You’re doing your grocery shopping in the mega-supermarket. You’ve got big list of items you want to buy, but you've forgotten the list at home. Have you got what it takes to remember the right products in the right sequence?",
    "no": "Du er på handletur i et mega-supermarked. Handlelisten din er stor, men uheldigvis har du glemt den hjemme. Har du allikevel det som skal til for å huske alle produktene i riktig rekkefølge?"
  },

  {
    "id": "game_socialstar_test_04",
    "description": "SocialStar lv4",
    "en": "<h1><b>Do you remember the hobby?</b></h1>",
    "no": "<h1><b>Husker du hobbyen?</b></h1>"
  },
  {
    "id": "l-N1",
    "description": "--- SocialStar",
    "en": "Social Star : Level",
    "no": "Navnemester: Nivå"
  },
  {
    "id": "game_shopping_test_01",
    "description": "Shopping - test phrase  level3",
    "en": "How much does it cost?",
    "no": "Hvor mye koster det?"
  },
  {
    "id": "game_shopping_intro_06",
    "description": "Shopping intro1",
    "en": "You’re off to shop at the local store and your family calls you on the way. Before you know it, your mental shopping list is already double digits. Have you got it in you to buy everything you need?",
    "no": "Du er på vei til den lokale butikken når et familiemedlem ringer opp og ber deg ta med en haug av nye ting. Før du vet ordet av det er den mentale handlelisten din blitt ganske stor. Har du det som skal til for å huske alt du trenger?"
  },
  {
    "id": "game_shopping_dropdown_07",
    "description": "Shopping dd L3b",
    "en": "<h1>Do you remember the products & quantities in the right sequence?</h1>",
    "no": "<h1>Husker du produktene & mengdene i riktig rekkefølge?</h1>"
  },
  {
    "id": "game_shopping_intro_05",
    "description": "Shopping main",
    "en": "You’re doing your grocery shopping in the mega-supermarket. You’ve got big list of items you want to buy, but you've forgotten the list at home. Have you got what it takes to remember the right products in the right sequence?",
    "no": "Du er på handletur i et mega-supermarked. Handlelisten din er stor, men uheldigvis har du glemt den hjemme. Har du allikevel det som skal til for å huske alle produktene i riktig rekkefølge?"
  },
  {
    "id": "game_meeting_memory_02",
    "description": "meeting slide 1 intro - L2",
    "en": "<div>Get to know your participants for the </div> <div> “[game]” sales meeting</div>",
    "no": "<div>Bli kjent med deltagerne i  </div> <div> “[game]” salgsmøte</div>"
  },
  {
    "id": "game_meeting_dropdown_05",
    "description": "Meeting dd L1b",
    "en": "<h1>Do you remember who said what in the right sequence?</h1>",
    "no": "<h1>Husker du hvem som sa hva i riktig rekkefølge?</h1>"
  },
  {
    "id": "system_memolife_tutorial_21",
    "description": "Knowledge",
    "en": "Here’s how you remember <b>15 18 31</b>",
    "no": "Slik husker du <b>15 18 31</b>"
  },
  {
    "id": "system_journey_01",
    "description": "JourneySys - memory phrase",
    "en": "Journey Systems",
    "no": "Reisesystemer"
  },
  {
    "id": "system_journey_08",
    "description": "JourneySys - Practice",
    "en": "Do you remember this journey step?",
    "no": "Husker du reisepunktet?"
  },
  {
    "id": "system_journey_09",
    "description": "JourneySys",
    "en": "Here’s your journey step - did you get it right?",
    "no": "Her er reisepunktet ditt - husket du riktig?"
  },
  {
    "id": "system_journey_05",
    "description": "JourneySys",
    "en": "Add journey step",
    "no": "Legg til reisepunkt"
  },
  {
    "id": "Sj-6",
    "description": "JourneySys",
    "en": "Here’s your journey step - did you get it right?",
    "no": "Her er reisepunktet ditt - husket du riktig?"
  },
  {
    "id": "Sj-5",
    "description": "JourneySys",
    "en": "Did you remember this journey step?",
    "no": "Husket du dette reisepunktet?"
  },
  {
    "id": "system_journey_03",
    "description": "JourneySys",
    "en": "Upload journey step image",
    "no": "Last opp et reisepunktsbilde"
  },
  {
    "id": "system_journey_04",
    "description": "JourneySys",
    "en": "Type your journey step name",
    "no": "Skriv navnet på reisepunktet her"
  },
  {
    "id": "Sj-2",
    "description": "--- JourneySys",
    "en": "Type your journey name here",
    "no": "Skriv navnet på reisen din her"
  },
  {
    "id": "system_memolife_tutorial_16",
    "description": "MemolifeSys",
    "en": "Buddha (Person 67) gives a hug (Action 75) to a yin-yang symbol (Object 72) on a keyboard (Location).",
    "no": "Buddha (Person 67) gir en klem (Handling 75) til et yin-yang symbol (Objekt 72) på et keyboard (Lagringssted)."
  },
  {
    "id": "system_memolife_01",
    "description": "MemolifeSys - Pop up",
    "en": "Learn the number shapes and life areas of the Memolife system",
    "no": "Lær Memolife-systemets tallfigurer og livsområder"
  },
  {
    "id": "system_memolife_15",
    "description": "MemolifeSys",
    "en": "CAREER",
    "no": "KARRIERE"
  },
  {
    "id": "system_memolife_13",
    "description": "MemolifeSys",
    "en": "SOUL",
    "no": "SJEL"
  },
  {
    "id": "system_memolife_12",
    "description": "MemolifeSys",
    "en": "52-66",
    "no": "52-66"
  },
  {
    "id": "system_memolife_11",
    "description": "MemolifeSys",
    "en": "BODY",
    "no": "KROPP"
  },
  {
    "id": "system_memolife_10",
    "description": "MemolifeSys",
    "en": "33-51",
    "no": "33-51"
  },
  {
    "id": "system_memolife_09",
    "description": "MemolifeSys",
    "en": "MIND",
    "no": "SINN"
  },
  {
    "id": "system_memolife_08",
    "description": "MemolifeSys",
    "en": "10-32",
    "no": "10-32"
  },
  {
    "id": "system_memolife_07",
    "description": "MemolifeSys",
    "en": "KNOWLEDGE",
    "no": "KUNNSKAP"
  },
  {
    "id": "system_memolife_06",
    "description": "MemolifeSys",
    "en": "00-09",
    "no": "00-09"
  },
  {
    "id": "system_memolife_04",
    "description": "MemolifeSys - pop up",
    "en": "Double digits",
    "no": "Dobbelsifre"
  },
  {
    "id": "system_memolife_double_explanation",
    "description": "MemolifeSys - pop up",
    "en": "Learn how to use the memory codes & life areas from 00-99 to improve your brain for a better everyday life.",
    "no": "Lær hvordan du kan bruke huskekodene & livsområdene fra 00-99 til å trene hjernen til en bedre hverdag."
  },
  {
    "id": "st7",
    "description": "----- MemolifeSys tutorial",
    "en": "Where it’s happening",
    "no": "Hvor skjer det"
  },
  {
    "id": "system_memolife_tutorial_23",
    "description": "MemolifeSys tutorial - Test",
    "en": "Memorize the 3 lines and all 18 digits in sequence",
    "no": "Memoriser de 3 linjene og alle 18 sifrene i rekkefølge"
  },
  {
    "id": "system_memolife_tutorial_24",
    "description": "MemolifeSys tutorial",
    "en": "Combine the Person, Action and Object memory codes into a mental movie scene on the location for each line. ",
    "no": "Kombiner huskekodene for Person, Handling og Objekt til en mental filmscene i Lagringsstedet per linje."
  },
  {
    "id": "system_memolife_single_04",
    "description": "Memolife singleSys",
    "en": "The shape of  %%systemCode[0]%% looks like a %%systemCode[1]%%",
    "no": "Formen til  %%systemCode[0]%% ligner på %%systemCode[1]%%"
  },
  {
    "id": "system_memolife_single_04_a",
    "description": "Memolife singleSys",
    "en": "The shape of",
    "no": "Formen til"
  },
    {
    "id": "system_memolife_single_04_b",
    "description": "Memolife singleSys",
    "en": "looks like a",
    "no": "ligner på"
  },
  {
    "id": "system_numbershape_intro_02",
    "description": "MemolifeSys single - move to memolife",
    "en": "<p>What you see is what you get! Remember those childhood nights using your hands and a flash light to make shadow figures in the dark? </p><p>Now, imagine doing do the same with the numbers 0 to 9.  What do you see? With a little imagination you might see that number 1 looks like a pen and that number 2 looks like a swan. These are the memory pegs you’ll use to link the things you want to remember up with. The best part is that you’ll impress people by remembering your lists, in any sequence you like.</p><p> Let’s look at some tips and examples in the article right away ...</p>",
    "no": "<p>What you see is what you get! Remember those childhood nights using your hands and a flash light to make shadow figures in the dark? </p><p>Now, imagine doing do the same with the numbers 0 to 9.  What do you see? With a little imagination you might see that number 1 looks like a pen and that number 2 looks like a swan. These are the memory pegs you’ll use to link the things you want to remember up with. The best part is that you’ll impress people by remembering your lists, in any sequence you like.</p><p> Let’s look at some tips and examples in the article right away ...</p>"
  },
  {
    "id": "system_memolife_03",
    "description": "MemolifeSys - pop up",
    "en": "Single digits",
    "no": "Singelsifre"
  },
  {
    "id": "system_memolife_single_explanation",
    "description": "MemolifeSys - pop up",
    "en": "Learn how to use the number codes from 0-9 to remember in a fast and fun manner.",
    "no": "Lær hvordan du kan bruke tallkodene for 0-9 for å huske på en hurtig og morsom måte."
  },
  {
    "id": "system_memolife_tutorial_14",
    "description": "MemolifeSys",
    "en": "Neo (Person 53) listens (Action 56) to a tea cup (Object 62) on a skyscraper (Location).",
    "no": "Neo (Person 53) hører (Handling 56) på en tekopp (Objekt 62) på en skyskraper (Lagringssted)."
  },
  {
    "id": "system_memolife_18",
    "description": "MemolifeSys",
    "en": "89-99",
    "no": "89-99"
  },
  {
    "id": "system_memolife_tutorial_12",
    "description": "MemolifeSys",
    "en": "Gandalf (Person 37) celebrates raising his arms (Action 40) in front of an Area 51 sign (Object 51) on a volcano (Location).",
    "no": "Gandalf (Person 37) feirer ved å reise armene sine (Handling 40) foran et 'Area 51'-skilt (Objekt 51) på en vulkan (Lagringssted)."
  },
  {
    "id": "system_memolife_17",
    "description": "MemolifeSys",
    "en": "LEISURE",
    "no": "FRITID"
  },
  {
    "id": "system_name_09",
    "description": "NameSys - CTA",
    "en": "Test all 100",
    "no": "Test alle 100"
  },
  {
    "id": "system_memolife_tutorial_09",
    "description": "MemolifeSys",
    "en": "<span>What :</span> Combine P + A + O into a funny story",
    "no": "<span>Hva :</span> Kombiner P + H + O til en morsom historie"
  },
  {
    "id": "game_wheel_dropdown_01",
    "description": "menu dropdown",
    "en": "Menu",
    "no": "Meny"
  },
  {
    "id": "hh10",
    "description": "--- header links",
    "en": "BENEFITS",
    "no": "FORDELER"
  },
  {
    "id": "global_01",
    "description": "Global item",
    "en": "Please switch your device to portrait mode to play MemoGenius",
    "no": "Vri skjermen din til portrett-modus for å spille MemoGeni."
  },
  {
    "id": "game_result_gameover_01",
    "description": "GameOver title",
    "en": "Game Over",
    "no": "Game Over"
  },
  {
    "id": "game_result_gameover_03",
    "description": "GameOver text",
    "en": "<p>To improve your memory and do better in this game, have a look at the memory techniques. You’ll be surprised at what your brain can do!</p><p><a onclick='showIntroLearn(1)'>Learn the techniques</a></p>",
    "no": "<p>For å forbedre hukommelsen og gjøre det bedre i dette spillet bør du ta en titt på husketeknikkene. Du vil bli overrasket over hva hjernen din får til!</p><p><span class='button-green gameover-btn-learn' onclick='showIntroLearn(1)'>Lær teknikkene her</span></p>"
  },
  {
    'id': "game_result_gameover_mission",
    "description": "Mission GameOver title",
    "en": "Your Result",
    "no": "Ditt Resultat"
  },
  {
    'id': "you_have",
    'description': "",
    'en': "You got",
    'no': "Du fikk"
  },
  {
    'id': "correct",
    'description': "",
    'en': "correct",
    'no': "riktige"
  },
  {
    'id': "gameover_result_bad",
    'description': "bad result",
    'en': "Oops, don’t worry. Before you know it you’ll be a real memory genius!",
    'no': "Oops, ikke bekymre deg. Du kommer til å bli et memogeni før du vet ordet av det!"
  },
  {
    'id': "gameover_result_medium",
    'description': "medium result",
    'en': "A pretty good start. Before you know it you’ll be a real memory genius!",
    'no': "En bra start. Før du vet ordet av det kommer du til å bli et ordentlig memogeni."
  },
  {
    'id': "gameover_result_good",
    'description': "good result",
    'en': "Very good. Before you know it you’ll be a real memory genius.",
    'no': "Veldig bra. Før du vet ordet av det kommer du til å bli et memogeni."
  },
  {
    'id': "gameover_result_great",
    'description': "great result",
    'en': "Impressive. You might be using some memory techniques already?",
    'no': "Imponerende. Du bruker kanskje noen husketeknikker allerede?"
  },
  {
    'id': "gameover_result_awesome",
    'description': "awesome result",
    'en': "Awesome. Surely you must be familiar with memory techniques - soon you can learn more and have fun with more memory games.",
    'no': "Glimrende. Du bruker helt sikkert husketeknikker fra før av - snart kan du lære mer og spille flere huskespill."
  },
  {
    "id": "game_wheel_header_03",
    "description": "header links",
    "en": "BELTS",
    "no": "BELTER"
  },
  {
    "id": "game_wheel_header_04",
    "description": "header links",
    "en": "MISSIONS",
    "no": "OPPDRAG"
  },
  {
    "id": "game_result_gameover_02",
    "description": "GameOver sub",
    "en": "You failed your mission - better luck next time!",
    "no": "Oppdraget mislyktes - bedre lykke neste gang!"
  },
  {
    "id": "game_curriculum_intro_12",
    "description": "Game intro -  learn slide footer",
    "en": "Ace the Curriculum Blitz game with these memory systems:",
    "no": "Bli bedre i Lynpensum med disse huskesystemene"
  },
  {
    "id": "game_curriculum_intro_11",
    "description": "Game intro - learn slide",
    "en": "<p><b>What if you could learn your curriculum in weeks rather than months, so that you could spend more time on applying your knowledge to real life situations instead?",
    "no": "<p><b>Hva hvis du kunne lære deg pensum på noen uker istedenfor måneder, slik at du kunne anvende kunnskapen din i praktiske prosjekter istedenfor? Hva hvis det i tillegg var morsomt?"
  },
  {
    "id": "game_curriculum_intro_09",
    "description": "Curriculum intro4",
    "en": "We're upping the ante with multiple words on the same memomap branches. Have you got what it takes to remember a small book chapter?",
    "no": "Nå dukker det plutselig opp flere ord på samme memomap-grener. Har du det som skal til for å huske et lite kapittel i en bok?"
  },
  {
    "id": "game_curriculum_intro_07",
    "description": "Curriculum intro2",
    "en": "You’re looking at a memomap summary of several curriculum pages. Do you remember enough to place the right words to the right MemoMaps branches?",
    "no": "Du kikker på et memomap-sammendrag av noen pensumsider. Husker du nok for å plassere de riktige ordene på de riktige memomaps-grenene?"
  },
  {
    "id": "game_curriculum_intro_08",
    "description": "Curriculum intro3",
    "en": "All right, time to throw diagrams and icons into the mix as well. Have you got it in you to remember all the words and graphics on the right MemoMaps branches?",
    "no": "Ok, nå er det på tide å legge til diagammer og ikoner i memomappet. Har du det som skal til for å huske alle ordene og grafikken fra de riktige memomap-grenene?"
  },
  {
    "id": "game_curriculum_intro_06",
    "description": "Curriculum intro1",
    "en": "Here’s a memomap summary of some paragraphs in a book chapter. Can you remember enough to place the right words on the right MemoMaps branches?",
    "no": "Her er et memomap-sammendrag av noen avsnitt i et kapittel. Klarer du å huske nok for å plassere ordene på de riktige memomaps-grenene?"
  },
  {
    "id": "game_wheel_dropdown_09",
    "description": "What's next menu - belt",
    "en": "Achieve the <label></label> belt",
    "no": "Oppnå det <label></label> belte"
  },
  {
    "id": "game_result_stageclear_08",
    "description": "",
    "en": "PLAY NEXT LEVEL",
    "no": "SPILL NESTE NIVÅ"
  },
  {
    "id": "system_result_01",
    "description": "All System result - journey,name,single,duoble",
    "en": "Great job",
    "no": "Bra gjort "
  },
  {
    "id": "system_result_02",
    "description": "All System result",
    "en": "Amazing memory",
    "no": "Fantastisk hukommelse"
  },
  {
    "id": "system_result_03",
    "description": "All System result",
    "en": "What happened?",
    "no": "Hva skjedde?"
  },
  {
    "id": "system_result_04",
    "description": "All System result",
    "en": "You got [x]/[y] correct",
    "no": "Du fikk [x]/[y] riktig"
  },
  {
    "id": "system_result_05",
    "description": "All System result",
    "en": "Continue",
    "no": "Fortsett"
  },
  {
    "id": "system_result_06",
    "description": "All System result",
    "en": "Try again",
    "no": "Prøv igjen"
  },
  {
    "id": "system_memolife_tutorial_01",
    "description": "Memolife DuobleSys - Tutorials",
    "en": "Great job, [user]",
    "no": "Bra gjort, [user]"
  },
  {
    "id": "system_memolife_single_05",
    "description": "MemolifeSys -  single practice",
    "en": "Now visualize the products and the wheel together",
    "no": "Visualiser nå produktene og hjulet sammen"
  },
  {
    "id": "system_memolife_single_05_01",
    "description": "MemolifeSys -  single practice",
    "en": "Perhaps the WHEEL could be made of BANANAS and CARROTS?",
    "no": "Kanskje HJULET kan være laget av BANANER og GULRØTTER?"
  },
  {
    "id": "ti6",
    "description": "-----",
    "en": "Open safe",
    "no": "Åpne safen"
  },
  {
    "id": "game_bankvault_dropdown_01",
    "description": "BankVault dd",
    "en": "<h1>Memorize the numbers in the right sequence</h1>",
    "no": "<h1>Memoriser sifrene i riktig rekkefølge</h1>"
  },
  {
    "id": "system_alphabet_dropdown_02",
    "description": "Alphabet  part B - dropdown",
    "en": "<h1>Can you match the memory codes to the correct letters?</h1>",
    "no": "<h1>Kan du forene huskekodene med de riktige bokstavene?</h1>"
  },
  {
    "id": "system_alphabet_dropdown_01",
    "description": "Alphabet  part A - dropdown",
    "en": "<h1>Remember the memory codes by observing which letter they start with</h1>",
    "no": "<h1>Husk kodene ved å se hvilken bokstav de begynner med</h1>"
  },
  {
    "id": "game_bankvault_dropdown_02",
    "description": "BankVault dd",
    "en": "<h1>Here are two wheels - memorize the number sequence</h1>",
    "no": "<h1>Her kommer to hjul - memoriser sifrene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_bankvault_dropdown_03",
    "description": "BankVault dd",
    "en": "<h1>Here’s a number display in the middle - memorize the number sequence</h1>",
    "no": "<h1>Her kommer en skjerm i midten - memoriser sifrene i riktig rekkefølge</h1>"
  },
  {
    "id": "l-V1",
    "description": "--- BankVault",
    "en": "Bank Vault : Level",
    "no": "Bankhvelvet : Nivå"
  },
  {
    "id": "game_bankvault_dropdown_04",
    "description": "BankVault dd",
    "en": "<h1>Here are more display digits - memorize the number sequence</h1>",
    "no": "<h1>Her kommer flere sifre i midten - memoriser sifrene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_bankvault_dropdown_05",
    "description": "BankVault dd",
    "en": "<h1>Enter the numbers in the correct sequencence - without triggering the alarm</h1>",
    "no": "<h1>Tast inn sifrene i riktig rekkefølge - uten å utløse alarmen</h1>"
  },
  {
    "id": "game_bankvault_intro_01",
    "description": "BankVault",
    "en": "Bank Vault",
    "no": "Bankhvelvet"
  },
  {
    "id": "system_alphabet_intro_01", // Used more places
    "description": "AlphabetSys",
    "en": "The Alphabet System",
    "no": "Alfabet-systemet"
  },
  {
    "id": "game_result_stageclear_01",
    "description": "Stage clear",
    "en": "You cleared level",
    "no": "Du fullførte nivå"
  },
  {
    "id": "global_intro_09",
    "description": "All game/system intro - learn",
    "en": "Article - coming soon",
    "no": "Artikkel kommer snart"
  },
  {
    'id': "read_the_article",
    'description': "Read the article on game intro screen",
    'en': "Read the article",
    'no': "Les artikkelen"
  },
  {
    'id': "read_the_article-single",
    'description': "Read the article on game intro screen",
    'en': "Start: single",
    'no': "Start: singel"
  },
  {
    'id': "read_the_article-double",
    'description': "Read the article on game intro screen",
    'en': "Continue: double",
    'no': "Fortsett: dobbel"
  },
  {
    "id": "global_05",
    "description": "game - test footer link - bankvault",
    "en": "Don’t remember? Take another <a>test here!</a>",
    "no": "Husker du ikke? Ta en ny <a>test her!</a>"
  },
  {
    "id": "s-1",
    "description": "---",
    "en": "Techniques",
    "no": "Teknikker"
  },
  {
    "id": "system_memolife_05",
    "description": "MemolifeSys - choose category",
    "en": "BRAIN",
    "no": "HJERNE"
  },
  {
    "id": "game_result_gameover_05",
    "description": "GameOver button 2",
    "en": "Play other games",
    "no": "Spill andre spill"
  },
  {
    'id': "other_categories",
    'description': "Other categories",
    'en': "Other categories 00..99",
    'no': "Andre kategorier 00..99"
  },
  {
    'id': 'learn_again',
    'en': "Learn again",
    'no': "Lær på nytt"
  },
  {
    "id": "global_system_03",
    "description": "All game intro - learn area",
    "en": "Journey system",
    "no": "Reise-systemet"
  },
  {
    "id": "l-C1",
    "description": "--- Curriculum",
    "en": "Curriculum Blitz : Level",
    "no": "Lynpensum : Nivå"
  },
  {
    "id": "belt_profile_02",
    "description": "Completed belts button",
    "en": "View completed belts",
    "no": "Se fullførte belter"
  },
  {
    "id": "game_curriculum_intro_10",
    "description": "Game intro - CTA learn slide",
    "en": "Play the game",
    "no": "Spill spillet"
  },
  {
    "id": "game_brainstorming_intro_09",
    "description": "Brainstorm intro4",
    "en": "You’re soon at the highest level! Now, you have to memorize more abstract words with even more color categories. Are you up for the challenge?",
    "no": "Du er snart på toppnivået! Nå gjelder det å memorisere abstrakte ord med enda flere fargekategorier. Har du det som skal til?"
  },
  {
    "id": "game_brainstorming_intro_08",
    "description": "Brainstorm intro3",
    "en": "Great, now it's time to both memorize and organize your ideas from the brainstorming session. Have you got what it takes to remember all the words with the correct color categories?",
    "no": "Bra, nå er det på tide å memorisere og organisere ideene fra brainstormingsøkten. Har du det som skal til for å huske alle ordene med riktig fargekategori?"
  },
  {
    "id": "game_brainstorming_intro_07",
    "description": "Brainstorm intro2",
    "en": "All right, here’s a longer brainstorming session with more complicated words. Are you up for the challenge?",
    "no": "Ok, dette er en lenger brainstormingsøkt med mer kompliserte ord. Har du det som skal til for å få med deg alt?"
  },
  {
    "id": "game_brainstorming_intro_06",
    "description": "Brainstorm intro1",
    "en": "Your brainstorming session produces a lot of ideas. Can you remember them all?",
    "no": "Du får mange ideer ut av en brainstorming-sesjon. Klarer du å huske alle sammen?"
  },
  {
    "id": "game_brainstorming_test_03",
    "description": "Brain storm - test",
    "en": "What's the correct color?",
    "no": "Hva er den riktige fargen?"
  },
  {
    "id": "l-B1",
    "description": "--- Brainstorm ??",
    "en": "Brainstorming : Level",
    "no": "Brainstorming : Nivå"
  },
  {
    "id": "game_brainstorming_intro_05",
    "description": "Brainstorm main intro",
    "en": "You’re a creative director leading a brainstorming session for a new brand. The best ideas often come overnight, so you need to remember them in the right sequence. Can you nail all the abstract words?",
    "no": "Du er kreativ direktør og gjennomfører en brainstormingsøkt for en ny merkevare. De beste ideene kommer oftest over natten, så du trenger å huske dem i riktig rekkefølge. Får du til å huske alle de abstrakte ordene?"
  },
  {
    "id": "game_brainstorming_intro_11",
    "description": "Brainstorm techniques teaser",
    "en": "<p><b>Imagine to be able to remember all ideas, concepts and abstract words from a brainstorming session, as well as all the categories you organized them into! Have you got what it takes?",
    "no": "<p><b>Forestill deg å kunne huske alle ideer, konsepter og abstrakte ord fra en brainstormingsøkt, så vel som kategoriene du har organisert dem i! Har du det som skal til?"
  },
  {
    "id": "game_brainstorming_dropdown_05",
    "description": "Brainstorm dd",
    "en": "<h1>Do you remember the brainstorming ideas in the right sequence?</h1>",
    "no": "<h1>Husker du brainstorming-ideene i riktig rekkefølge?</h1>"
  },
  {
    "id": "BP-10",
    "description": "--- Boarding",
    "en": "Test your memory    ",
    "no": "Test hukommelsen din"
  },
  {
    "id": "BP-9",
    "description": "--- Boarding",
    "en": "<p>All right, you'll soon be able to impress yourself by doubling your memory result. All it takes is a couple of minutes.</p><p>But, before learning your first magic memory powers, let's test where your memory is at today.</p>",
    "no": "<p>Du kommer snart til å imponere deg selv med å doble hukommelsen din. Alt som skal til er noen få minutter.</p><p>Men før du setter i gang, la oss først teste hvordan hukommelsen din ligger an nå.</p>"
  },
  {
    "id": "game_bankvault_intro_12",
    "description": "BankVault techniques",
    "en": "Ace the Bank Vault game with these memory systems:",
    "no": "Bli bedre i Bankhvelvet med disse huskesystemene:"
  },
  {
    "id": "belt_profile_04",
    "description": "Belt tab title",
    "en": "Badges (coming soon)",
    "no": "Pokaler (kommer snart)"
  },
  {
    "id": "game_bankvault_intro_11",
    "description": "BankVault - learn slide",
    "en": "<p><b>What if you could remember pin-codes, birthdays, phone numbers, formulas or any historic dates for as long as you like?",
    "no": "<p><b>Hva hvis du kunne huske pin-koder, bursdager, telefonnumre, formler, avtaler eller utallige historiske hendelser så lenge du vil?"
  },
  {
    "id": "belt_list_02",
    "description": "Belt",
    "en": "belt",
    "no": " beltet trenger du: "
  },
  {
    "id": "belt_list_03",
    "description": "Belt",
    "en": "Your time to beat:",
    "no": "Din beste tid:"
  },
  {
    "id": "belt_list_04",
    "description": "Belt",
    "en": "seconds",
    "no": "sekunder"
  },
  {
    "id": "game_brainstorming_dropdown_04",
    "description": "Brainstorm dd",
    "en": "<h1>Memorize more ideas & their categories in the right sequence</h1>",
    "no": "<h1>Memoriser flere ideer & kategoriene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_brainstorming_dropdown_03",
    "description": "Brainstorm dd",
    "en": "<h1>Memorize the ideas & their categories in the right sequence</h1>",
    "no": "<h1>Memoriser ideene & kategoriene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_brainstorming_dropdown_02",
    "description": "Brainstorm dd",
    "en": "<h1>Memorize more complex ideas in the right sequence</h1>",
    "no": "<h1>Memoriser mer komplekse ideer i riktig rekkefølge</h1>"
  },
  {
    "id": "game_curriculum_intro_04",
    "description": "Curriculum",
    "en": "mindmaps",
    "no": "mindmaps"
  },
  {
    "id": "game_timetravel_dropdown_05",
    "description": "Time Travel dd L1b",
    "en": "<h1>Do you remember when the events happened?</h1>",
    "no": "<h1>Husker du når hendelsene skjedde?</h1>"
  },
  {
    "id": "game_timetravel_dropdown_02",
    "description": "Time Travel dd L2a",
    "en": "<h1>Memorize the month & year of the events</h1>",
    "no": "<h1>Memoriser måneden & året til hendelsen</h1>"
  },
  {
    "id": "game_bankvault_intro_08",
    "description": "BankVault intro - level 3",
    "en": "Great, now let’s see if you can master another two digits in the center of the safe as well! Have you got what it takes or will you trigger the alarm?",
    "no": "Bra, la oss nå i tillegg se om du klarer å mestre to nye sifre i midten av safen. Klarer du å heve nivået ditt, eller kommer du til å utløse alarmen?"
  },
  {
    "id": "i-12",
    "description": "---",
    "en": "PLAY NEXT LEVEL",
    "no": "SPILL NESTE NIVÅ"
  },
  {
    "id": "gr1",
    "description": "---",
    "en": "Game Ranking",
    "no": "Spillranking"
  },
  {
    "id": "game_wheel_dropdown_08",
    "description": "",
    "en": "What's next?",
    "no": "Hva vil du nå?"
  },
  {
    "id": "system_memolife_14",
    "description": "MemolifeSys",
    "en": "67-78",
    "no": "67-78"
  },
  {
    "id": "global_intro_05",
    "description": "All game intro footer",
    "en": "Go back",
    "no": "Gå tilbake"
  },
  {
    "id": "game_timetravel_intro_10",
    "description": "Game intro - CTA learn slide",
    "en": "Play the game",
    "no": "Prøv spillet"
  },
  {
    "id": "system_memolife_tutorial_17",
    "description": "Memolife ???",
    "en": "Here’s how you remember <b>88 85 81</b>",
    "no": "Slik husker du <b>88 85 81</b>"
  },
  {
    "id": "game_meeting_intro_05",
    "description": "Meetings intro main",
    "en": "You’re rushing through several meetings in one day. Have you still got what it takes to fully engage with your meeting participants - remembering who said what, in what sequence and the agreed action points?",
    "no": "Du løper fra møte til møte i jobbhverdagen. Har du det som skal til for å virkelige engasjere deg i møtedeltagerne - å huske hvem som sa hva, i hvilken rekkefølge og de avtalte handlingspunktene?"
  },
  {
    "id": "game_meeting_intro_11",
    "description": "Meeting intro - learn slide",
    "en": "<p><b>What if you could summarize all the meeting points, the correct names of the participants and who said what in the meeting – without taking a single note?",
    "no": "<p><b>Hva hvis du kunne oppsummere alle møtepunktene, navnene til møtedeltagerne og hvem som sa hva i møtet - uten å ta et eneste notat?"
  },
  {
    "id": "game_meeting_intro_07",
    "description": "Meeting intro - level 2",
    "en": "You’re in a sales meeting and have to remember who said what and all the needs of your clients to close the deal. Have you got what it takes?",
    "no": "Du er i et salgsmøte og trenger å huske hvem som kommer med salgsbehovene og kommentarene for å lande avtalen. Har du det som skal til?"
  },
  {
    "id": "game_socialstar_intro_12",
    "description": "SocialStar intro - learn slide",
    "en": "Ace the Social Star game with these memory systems:",
    "no": "Bli bedre i Navnemester med disse huskesystemene:"
  },
  {
    "id": "game_curriculum_intro_05",
    "description": "Curriculum intro main",
    "en": "You’re late with your test preparations. Combining memory techniques and mindmapping, you’ve got one week to master the curriculum with MemoMaps. Have you got what it takes to ace your exams and place the right content on the right branches?",
    "no": "Du er sent ute med eksamensforberedelsene og skippertaket er et faktum. Ved å kombinere memo-teknikker og mindmapping har du en uke til å mestre pensum med MemoMaps. Har du det som skal til for å ta eksamen med glans?"
  },
  {
    "id": "game_socialstar_intro_11",
    "description": "SocialStar intro - learn slide",
    "en": "<p><b>Ah, those people I just met … what were their names again? Forgetting people’s names is one of the most common memory problems we face. Luckily it’s both easy and fun to get the hang of. Pretty soon you'll remember all the names of the people you meet.",
    "no": "<p><b>Ååå, de folkene jeg akkurat møtte ... hva var det de het igjen? Det å glemme folks navn er et av de mest vanlige problemene vi har i hverdagen. Heldigvis er det både enkelt og hurtig å gjøre noe med. Ganske snart vil du huske navnene til alle du møter."
  },
  {
    "id": "game_timetravel_intro_04",
    "description": "",
    "en": "events",
    "no": "hendelser"
  },
  {
    "id": "game_timetravel_intro_03",
    "description": "",
    "en": "birthdays",
    "no": "bursdager"
  },
  {
    "id": "game_timetravel_intro_02",
    "description": "",
    "en": "historic dates",
    "no": "historiske datoer"
  },
  {
    "id": "game_wheel_dropdown_07",
    "description": "",
    "en": "Back to home",
    "no": "Hjem"
  },
  {
    "id": "game_shoppingflash_intro_01",
    "description": "Shopping flash course intro - title",
    "en": "Flashcourse",
    "no": "Lynkurs"
  },
  {
    "id": "global_04",
    "description": "Game bouncing text / Game test phrase header",
    "en": "Memory phase",
    "no": "Huskefase"
  },
  {
    "id": "game_result_stageclear_07",
    "description": "",
    "en": "Total time",
    "no": "Totaltid"
  },
  {
    "id": "game_result_stageclear_06",
    "description": "",
    "en": "Penalty time",
    "no": "Straffetid"
  },
  {
    "id": "game_result_stageclear_05",
    "description": "",
    "en": "Memory time",
    "no": "Husketid"
  },
  {
    "id": "global_03",
    "description": "Game test button",
    "en": "TEST",
    "no": "TEST"
  },
  {
    "id": "global_02",
    "description": "Game test button",
    "en": "NEXT",
    "no": "NESTE"
  },
  {
    "id": "game_shopping_memory_01",
    "description": "Shopping - Memory phase header",
    "en": "Your list",
    "no": "Din liste"
  },
  {
    "id": "game_shopping_memory_help_button",
    "en": "help",
    "no": "hjelp"
  },
  {
    "id": "supermarket_help_dialog_instruction",
    "en": "Use the number shapes as memory containers to visualize your shopping products.",
    "no": "Bruk tallfigurene som lagringssted for å memorisere produktene fra handlelisten."
  },
  {
    "id": "supermarket_help_dialog_close_label",
    "en": "close",
    "no": "lukk"
  },
  {
    "id": "system_memolife_19",
    "description": "Memolife - CTA -button text",
    "en": "Next",
    "no": "Neste"
  },
  {
    "id": "next",
    "description": "Next button",
    "en": "Next",
    "no": "Neste"
  },
  {
    "id": "back",
    "description": "Back button",
    "en": "Back",
    "no": "Tilbake"
  },
  {
    "id": "done",
    "description": "Done button",
    "en": "Done",
    "no": "Ferdig"
  },
  {
    "id": "system_name_08",
    "description": "NameSys - CTA",
    "en": "Start practice",
    "no": "Start øving"
  },
  {
    "id": "system_name_07",
    "description": "NameSys - CTA",
    "en": "Show next 10",
    "no": "Vis neste 10"
  },
  {
    "id": "system_name_06",
    "description": "NameSys - CTA",
    "en": "Previous",
    "no": "Forrige"
  },
  {
    "id": "st19",
    "description": "----",
    "en": "Location",
    "no": "Lagringssted"
  },
  {
    "id": "system_memolife_tutorial_10",
    "description": "",
    "en": "<span>Where :</span> Place your visualisation in a location",
    "no": "<span>Hvor :</span> Plasser visualiseringene i et lagringssted"
  },
  {
    "id": "system_name_26",
    "description": "NameSys",
    "en": "Show next",
    "no": "Vis neste"
  },
  {
    "id": "system_journey_14",
    "description": "",
    "en": "Show answer",
    "no": "Vis svaret"
  },
  {
    "id": "system_journey_add_new",
    "description": "",
    "en": "Add a new journey",
    "no": "Legg til ny reise"
  },
  {
    "id": "system_journey_learn_how",
    "description": "",
    "en": "Learn how",
    "no": "Lær hvordan"
  },
  {
    "id": "system_journey_title",
    "description": "",
    "en": "A world of memory journeys",
    "no": "Et univers av hukommelsesreiser"
  },
  {
    "id": "system_journey_explain",
    "description": "",
    "en": "The beauty of Journey Systems is how we easily can use the surroundings we care about to create new memories.",
    "no": "Det lekre med reisesystemer er at vi enkelt kan bruke alle de flotte stedene i livet vårt som lagringssteder for det vi vil huske."
  },
  {
    "id": "system_journey_create_first_step",
    "description": "",
    "en": "Next step",
    "no": "Neste steg"
  },
  {
    "id": "system_journey_upload_file",
    "description": "",
    "en": "Click here and add your next step on your journey",
    "no": "Klikk her og legg til neste steg på reisen"
  },
  {
    "id": "system_journey_create_x_step",
    "description": "",
    "en": "Click here and add your next step on your journey",
    "no": "Klikk her og legg til neste steg på reisen"
  },
  {
    "id": "system_journey_create_x_step_line2",
    "description": "",
    "en": "Keep in mind that the journey steps need to be places that naturally connect with each other in a sequence.",
    "no": "Husk at hvert reisesteg må koble seg opp til det neste, slik at du kan se for deg en naturlig reise med flere steg."
  },
  {
    'id': "system_journey_image_placeholder",
    'description': "",
    'en': "What do I look like?",
    'no': "Hvordan ser jeg ut?"
  },
  {
    'id': "system_journey_learn_header",
    'description': "Learn journey header",
    'en': "Start on your first journey",
    'no': "Start din første reise i et hjem"
  },
  {
    'id': "system_journey_learn_content",
    'description': "Learn journey header",
    'en': "<p>First, of all congratulation on your journey to become a master of memory and on your first mission. Click this icon to see your notification and assist you from anywhere in the app, what is left to do to complete your mission.</p>",
    'no': "<p>Se for deg hvordan du beveger deg gjennom et hjem fra du står opp til du går ut av døren. Vips, så har du en reise med 10 huskesteder.</p>"+
          "<p>Bla deg gjennom reisepunktene før du øver deg!</p>"
  },
  {
    "id": "system_journey_new_journey_name",
    "description": "",
    "en": "Choose a name for the whole journey",
    "no": "Velg reisenavn"
  },
  {
    "id": "system_journey_look_like",
    "description": "",
    "en": "What do I look like?",
    "no": "Hvordan ser jeg ut?"
  },
  {
    "id": "system_journey_new_photo_name",
    "description": "",
    "en": "Give me a name",
    "no": "Gi meg et navn"
  },
  {
    "id": "game_meeting_memory_08",
    "description": "Meeting slide 2- CTA",
    "en": "Let's get started",
    "no": "La oss begynne"
  },
  {
    "id": "game_shopping_test_02",
    "description": "",
    "en": "How much do you need?",
    "no": "Hvor mye trenger du?"
  },
  {
    "id": "global_intro_03",
    "description": "All game intro footer",
    "en": "Select level",
    "no": "Velg nivå"
  },
  {
    "id": "system_memolife_02",
    "description": "Memolife - Pop up CTA text",
    "en": "Get started!",
    "no": "Kom i gang!"
  },
  {
    "id": "game_wheel_text_01",
    "description": "Wheel center text",
    "en": "Play a Game",
    "no": "Spill her"
  },
  {
    "id": "system_wheel_text_01",
    "description": "System wheel center text",
    "en": "Learn a System",
    "no": "Lær et system"
  },
  {
    "id": "global_system_06",
    "description": "All game intro - learn area",
    "en": "Link system",
    "no": "Lenke-systemet"
  },
  {
    "id": "game_brainstorming_test_01",
    "description": "Brain storm - test",
    "en": "What is the word for number",
    "no": "Hva er ordet for nummer"
  },
  {
    "id": "game_brainstorming_test_02",
    "description": "Brain storm - test",
    "en": "Which number matches with the word?",
    "no": "Hvilket nummer tilhører ordet?"
  },
  {
    "id": "global_06",
    "description": "game - test footer link",
    "en": "Don’t remember? <a>You can skip</a>,  but you’ll lose a life.",
    "no": "Husker du ikke? <a>Du kan hoppe over</a>, men vil miste et liv."
  },
  {
    "id": "TT-16",
    "description": "--- MemolifeSys",
    "en": "Let me see if I remember",
    "no": "La meg se om jeg husker"
  },
  {
    "id": "TT-15",
    "description": "--- MemolifeSys",
    "en": "seconds",
    "no": "sekunder"
  },
  {
    "id": "SC-7",
    "description": "--",
    "en": "BEST SCORE",
    "no": "BESTE SCORE"
  },
  {
    "id": "SC-6",
    "description": "--",
    "en": "RANK",
    "no": "RANKING"
  },
  {
    "id": "SC-5",
    "description": "--",
    "en": "YOUR TIME",
    "no": "DIN TID"
  },
  {
    "id": "game_result_stageclear_04",
    "description": "",
    "en": "PERFECT",
    "no": "PERFEKT"
  },
  {
    "id": "game_result_stageclear_03",
    "description": "",
    "en": "Impressive!",
    "no": "Imponerende!"
  },
  {
    "id": "game_result_stageclear_02",
    "description": "",
    "en": "Well done!",
    "no": "Bra gjort!"
  },
  {
    "id": "system_memolife_single_02",
    "description": "Memolife singleSys part B - dropdown",
    "en": "<h1>Can you match the memory codes to the correct numbers?</h1>",
    "no": "<h1>Kan du matche huskekodene med de riktige tallene?</h1>"
  },
  {
    "id": "game_vocabguru_dropdown_07",
    "description": "VocabGuru dd",
    "en": "<h1>Do you remember the meaning of the words?</h1>",
    "no": "<h1>Husker du hva ordene betyr?</h1>"
  },
  {
    "id": "i-vg9",
    "description": "--- Vocab Guru level text",
    "en": "Vocab Guru: Level",
    "no": "Gloseguru: Nivå"
  },
  {
    "id": "TT-1",
    "description": "--- MemolifeSys",
    "en": "Great job,",
    "no": "Bra gjort,"
  },
  {
    "id": "game_vocabguru_intro_01",
    "description": "Vocab Guru game",
    "en": "Vocab Guru",
    "no": "Gloseguru"
  },
  {
    "id": "game_timetravel_intro_05",
    "description": "TimeTravel intro main",
    "en": "You’ve stumbled upon a time machine which throws you into multiple time travels. It turns out that our history is quite different from what you previously thought. Have you got what it takes to remember the new historic events and when they happened?",
    "no": "Du har snublet borti en tidsmaskin som kaster deg inn i forskjellige tidsreiser. Det viser seg kjappt at historien er ganske annerledes enn det du har trodd til nå. Har du det som skal til for å huske de nye historiske hendelsene og når de skjedde?"
  },
  {
    "id": "s-6",
    "description": "---",
    "en": "Read the full articles",
    "no": "Les artiklene"
  },
  {
    "id": "s-5",
    "description": "---",
    "en": "View all",
    "no": "Se alt"
  },
  {
    "id": "s-4",
    "description": "---",
    "en": "Score",
    "no": "Score"
  },
  {
    "id": "global_intro_10",
    "description": "All system intro - CTA button text",
    "en": "Learn & practise",
    "no": "Lær & Praktiser"
  },
  {
    "id": "s-2",
    "description": "---",
    "en": "Related game",
    "no": "Relatert spill"
  },
  {
    "id": "game_socialstar_intro_10",
    "description": "Game intro - CTA learn slide",
    "en": "Play the game",
    "no": "Spill her"
  },
  {
    "id": "game_socialstar_intro_04",
    "description": "intro tag 3",
    "en": "hobbies",
    "no": "hobbier"
  },
  {
    "id": "game_socialstar_intro_03",
    "description": "intro tag 2",
    "en": "titles",
    "no": "titler"
  },
  {
    "id": "game_vocabguru_intro_12",
    "description": "VocabGuru techniques",
    "en": "Ace the Vocab Guru game by combining these systems:",
    "no": "Bli bedre i Gloseguru med disse huskesystemene:"
  },
  {
    "id": "game_vocabguru_intro_05",
    "description": "VocabGuru main",
    "en": "You find yourself lost in a new world with strange languages. Your survival depends on how fast you can learn enough vocabularies to make yourself understood. Have you got it in you to rapidly learn vast amounts of words?",
    "no": "Du har havnet i en ny verden med rare språk. Din eneste mulighet for å overleve er å hurtig lære deg nok gloser for å gjøre deg forstått. Har du det som skal til for å lære deg mange nye gloser i et lynrakst tempo?"
  },
  {
    "id": "game_vocabguru_intro_11",
    "description": "VocabGuru techniques",
    "en": "<p><b>What if you could easily learn 100 new vocabularies in any new language per day - and have fun doing it? Or, how about mastering new facts and definitions in the same tempo?",
    "no": "<p><b>Hva hvis du enkelt kunne lære 100 nye gloser i hvilket som helst språk på en dag - og ha det gøy mens du holder på? Eller, hva med å kunne lære nye fremmedord og begreper i samme tempo?"
  },
  {
    "id": "game_socialstar_intro_02",
    "description": "intro tag 1",
    "en": "names",
    "no": "navn"
  },
  {
    "id": "system_name_03",
    "description": "NameSys - tab name",
    "en": "Male name",
    "no": "Mannlig navn"
  },
  {
    "id": "game_brainstorming_intro_10",
    "description": "",
    "en": "Play the game",
    "no": "Spill her"
  },
  {
    "id": "game_brainstorming_intro_04",
    "description": "tag 3",
    "en": "abstract words",
    "no": "abstrakte ord"
  },
  {
    "id": "game_brainstorming_intro_03",
    "description": "tag 2",
    "en": "ideas",
    "no": "ideer"
  },
  {
    "id": "game_brainstorming_intro_02",
    "description": "tag 1",
    "en": "concepts",
    "no": "konsepter"
  },
  {
    "id": "game_shopping_intro_10",
    "description": "Game intro - CTA learn slide",
    "en": "Play the game",
    "no": "Spill her"
  },
  {
    "id": "game_shopping_intro_04",
    "description": "",
    "en": "project lists",
    "no": "prosjektliste"
  },
  {
    "id": "game_shopping_intro_03",
    "description": "",
    "en": "to-do lists",
    "no": "to-do lister"
  },
  {
    "id": "game_shopping_intro_02",
    "description": "",
    "en": "shopping lists",
    "no": "handlelister"
  },
  {
    "id": "game_meeting_intro_10",
    "description": "Game intro - CTA learn slide",
    "en": "Play the game",
    "no": "Spill her"
  },
  {
    "id": "game_vocabguru_intro_07",
    "description": "VocabGuru intro2",
    "en": "Yup, Elves, and your language challenge is an ancient Elvish dialect. You’ll need it to defend yourself against spells and dark magic. Have you got it in you to save your soul?",
    "no": "Ja, her snakker vi om alver! Og språket du må lære deg for å beskytte deg mot trolldom og svart magi er en gammel alvedialekt. Har du det som skal til for å redde sjelen din?"
  },
  {
    "id": "game_vocabguru_intro_06",
    "description": "VocabGuru intro1",
    "en": "You’re in the world of Clockwork Orange and you need to master its ‘Nadsat’ language to survive. Have you got what it takes to learn the meaning of the words in time?",
    "no": "Du befinner deg i den farlige 'Clockwork Orange'-verdenen og du må mestre dens ‘Nadsat’ språk for å overleve.  Har du det som skal til for å lære betydningen av ordene tidsnok?"
  },
  {
    "id": "game_vocabguru_dropdown_02",
    "description": "VocabGuru dd",
    "en": "<h1>Memorize the meaning of the Star Trek ‘Klingon’ words</h1>",
    "no": "<h1>Memoriser betydningen av ‘Klingon’-ordene fra Star Trek</h1>"
  },
  {
    "id": "game_vocabguru_intro_09",
    "description": "VocabGuru intro4",
    "en": "Your fourth world is the blue planet of Pandora and your language challenge is ‘Navi’. Your only possibility for survival is to learn enough of the language before the war parties reach you. How badly do you want to survive?",
    "no": "Den fjerde verdenen er den blå planeten Pandora ot språkutfordringen din er ‘Navi’. Din eneste mulighet for å overleve er å lære nok av språket før de innfødte krigerne når deg. Hvor sulten er du på å leve?"
  },
  {
    "id": "game_vocabguru_dropdown_05",
    "description": "VocabGuru dd",
    "en": "<h1>Do you remember the meaning of the words?</h1>",
    "no": "<h1>Husker du hva ordene betyr?</h1>"
  },
  {
    "id": "game_vocabguru_dropdown_08",
    "description": "VocabGuru dd",
    "en": "<h1>Do you remember the meaning of the words?</h1>",
    "no": "<h1>Husker du hva ordene betyr?</h1>"
  },
  {
    "id": "game_vocabguru_dropdown_06",
    "description": "VocabGuru dd",
    "en": "<h1>Do you remember the meaning of the words?</h1>",
    "no": "<h1>Husker du hva ordene betyr?</h1>"
  },
  {
    "id": "game_vocabguru_intro_08",
    "description": "VocabGuru intro 3",
    "en": "Your second world is the Star Trek dimension and your language challenge is Klingon. It’s not as hard as it sounds, but you better be ready, or galactic pulverization will be your fate. Are you up for the challenge?",    "no": "Din andre verden er Star Trek og språket er Klingon. Det er ikke så vanskelig som det høres ut som, men du bør være klar, hvis ikke blir galaktisk pulverisering din skjebne. Er du klar for utfordringen?"
  },
  {
    "id": "game_vocabguru_dropdown_03",
    "description": "VocabGuru dd",
    "en": "<h1>Memorize the meaning of the Elvish ‘Quenya’ words</h1>",
    "no": "<h1>Memoriser begydningen av alveordene i ‘Quenya’ fra Ringenes Herre</h1>"
  },
  {
    "id": "game_vocabguru_dropdown_01",
    "description": "VocabGuru dd",
    "en": "<h1>Memorize the meaning of the Clockwork Orange ‘Nadsat’ words</h1>",
    "no": "<h1>Memoriser betydningen av ‘Nadsat’-ordene fra Clockwork Orange</h1>"
  },
  {
    "id": "game_vocabguru_dropdown_04",
    "description": "VocabGuru dd",
    "en": "<h1>Memorize the meaning of the Avatar ‘Navi’ words</h1>",
    "no": "<h1>Memoriser betydningen av ‘Navi’- ordene fra Avatar</h1>"
  },
  {
    "id": "game_bankvault_intro_07",
    "description": "BankVault intro - level 2",
    "en": "Here’s a second wheel with numbers you need to remember. Are you up for the challenge, or will you trigger the alarm?",
    "no": "Her kommer to kodehjul med sifre du må huske. Har du det som skal til, eller kommer du til å utløse alarmen?"
  },
  {
    "id": "game_bankvault_intro_06",
    "description": "BankVault intro - level 1",
    "en": "The safe wheel is spinning and briefly stops for a moment … before it continues to spin. Can you remember the pin codes and avoid triggering the alarm?",
    "no": "Kodehjulet spinner og stopper for et lite øyeblikk ... før det spinner videre. Husker du alle tallkodene uten å utløse alarmen?"
  },
  {
    "id": "game_bankvault_dropdown_06",
    "description": "BankVault dd",
    "en": "<h1>Enter the digits in the correct sequencence - without triggering the alarm</h1>",
    "no": "<h1>Tast inn sifrene i riktig rekkefølge - uten å utløse alarmen</h1>"
  },
  {
    "id": "game_bankvault_dropdown_07",
    "description": "BankVault dd",
    "en": "<h1>Enter the numbers in the correct sequencence - without triggering the alarm</h1>",
    "no": "<h1>Tast inn sifrene i riktig rekkefølge - uten å utløse alarmen</h1>"
  },
  {
    "id": "game_vocabguru_intro_10",
    "description": "Game intro - CTA learn slide",
    "en": "Play the game",
    "no": "Spill her"
  },
  {
    "id": "game_vocabguru_intro_04",
    "description": "VocabGuru benefits",
    "en": "facts",
    "no": "fakta"
  },
  {
    "id": "system_memolife_tutorial_08",
    "description": "MemolifeSys",
    "en": "Object",
    "no": "Objekt"
  },
  {
    "id": "system_memolife_tutorial_07",
    "description": "MemolifeSys",
    "en": "Action",
    "no": "Handling"
  },
  {
    "id": "system_memolife_tutorial_06",
    "description": "Memolife tutorial",
    "en": "Person",
    "no": "Person"
  },
  {
    "id": "system_memolife_tutorial_15",
    "description": "Memolife Soul",
    "en": "Here’s how you remember <b>67 75 72</b>",
    "no": "Slik husker du <b>67 75 72</b>"
  },
  {
    "id": "game_vocabguru_intro_02",
    "description": "VocabGuru benefits",
    "en": "native words",
    "no": "fremmedord"
  },
  {
    "id": "game_vocabguru_intro_03",
    "description": "VocabGuru benefits",
    "en": "foreign words",
    "no": "gloser"
  },
  {
    "id": "system_memolife_16",
    "description": "MemolifeSys",
    "en": "79-88",
    "no": "79-88"
  },
  {
    "id": "system_memolife_result_02",
    "description": "MemolifeSys result - CTA button text",
    "en": "Play other games",
    "no": "Spill andre spill"
  },
  {
    "id": "global_intro_06",
    "description": "All game intro footer",
    "en": "Best time:",
    "no": "Beste tid:"
  },
  {
    "id": "system_memolife_single_03",
    "description": "Memolife singleSys",
    "en": "What does the number look like?",
    "no": "Hva ligner nummeret på?"
  },
  {
    "id": "system_memolife_rhyme_03",
    "description": "Memolife rhymeSys",
    "en": "What does the number rhyme with?",
    "no": "Hva rimer tallet på?"
  },
  {
    "id": "system_memolife_alphabet_03",
    "description": "Memolife alphabetSys",
    "en": "What animal starts with this letter?",
    "no": "Hvilket dyr starter med denne bokstaven?"
  },
  {
    "id": "system_memolife_single_09",
    "description": "",
    "en": "Visualize the things you want to buy with the memory code into a memory fantasy",
    "no": "Visualiser tingene du ville kjøpe med huskekoden i en huskefantasi"
  },
  {
    "id": "system_memolife_single_10",
    "description": "",
    "en": "When you're in the store and want to remember the products, simply visualize the wheel (number 0) and you'll automatically see a wheel made of bananas and carrots.",
    "no": "Når du er i butikken og vil huske produktene trenger du kun å se for deg hjulet (tallet 0), så vil du automatisk se for deg et hjul laget av banener og gulrøtter."
  },
  {
    "id": "system_memolife_single_11",
    "description": "",
    "en": "Your two first shopping list items are carrots and bananas",
    "no": "De to første tingene på handlelisten er gulrøtter og bananer"
  },
  {
    "id": "system_memolife_single_12",
    "description": "",
    "en": "Carrots",
    "no": "Gulrøtter"
  },
  {
    "id": "system_memolife_single_13",
    "description": "",
    "en": "Bananas",
    "no": "Bananer"
  },
  {
    "id": "system_memolife_single_17",
    "description": "",
    "en": "Wheel",
    "no": "Hjul"
  },
  {
    "id": "system_memolife_single_18",
    "description": "",
    "en": "Example: memorize two products with the number shape for 0",
    "no": "Eksempel: memoriser to produkter med tallfiguren for 0"
  },
  {
    "id": "system_memolife_single_14",
    "description": "",
    "en": "The number 0 looks like a wheel, right? The wheel is the number shape you'll visualize with what you want to remember for number 0.",
    "no": "Tallet 0 ligner på et hjul, ikke sant? Hjulet er tallfiguren du visualiserer sammen med det du vil huske for tallet 0."
  },
  {
    "id": "system_memolife_single_15",
    "description": "",
    "en": "Visualize and remember with number shapes",
    "no": "Visualiser og husk med tallfigurer"
  },
  {
    "id": "system_memolife_single_16",
    "description": "",
    "en": "View example",
    "no": "Se eksempel"
  },
  {
    'id': "singlesys_tour_step3_header",
    'description': "Let's deep dive",
    'en': "Example: remember the shopping list",
    'no': "Eksempel: husk handlelisten"
  },
  {
    'id': "singlesys_tour_step3_content",
    'description': "Singlesys tour step 3 content",
    'en': "Use the number shapes for 0-9 as mental locations where you visualize the products you want to buy. Let's have a look at how you visually memorize two producs on the number shape for 0.",
    'no': "Bruk tallfigurene for 0-9 som mentale lagringssteder hvor du visualiserer produktene du vil handle. La oss se på hvordan du mentalt lagrer to produkter på tallfiguren for 0."
  },
  {
    "id": "system_memolife_locked",
    "description": "",
    "en": "Locked",
    "no": "Låst"
  },
  {
    "id": "system_memolife_start",
    "description": "",
    "en": "Start",
    "no": "Start"
  },
  {
    "id": "system_memolife_duoble_02",
    "description": "Memolife doubleSys - dd B",
    "en": "<h1>Can you match the Objects - People - Actions to the correct numbers?</h1>",
    "no": "<h1>Klarer du å matche Objektene - Menneskene - Handlingene til de riktige numrene?</h1>"
  },
  {
    "id": "system_memolife_duoble_01",
    "description": "Memolife doubleSys - dd A",
    "en": "<h1>Remember the memory codes by observing which objects the numbers look like</h1>",
    "no": "<h1>Husk huskekodene ved å se hva numrene ligner på</h1>"
  },
  {
    "id": "game_meeting_memory_07",
    "description": "Meeting slide 2",
    "en": "<p>Interesting remark, <span>Amy</span>, the robot comes with a good sense of humor.</p>",
    "no": "<p>Interessant kommentar, <span>Amy</span>, ja, alle robotene kommer faktisk med en god sans for humor.</p>"
  },
  {
    "id": "game_meeting_intro_06",
    "description": "Meeting intro - level 1",
    "en": "You suddenly find yourself in an 'speed meeting' and the time to memorize what everyone says is limited. Have you got what it takes to remember the feedback you need?",
    "no": "Du havner plutselig i et 'lynmøte' og tiden for å huske hva alle sier er knapp. Har du det som skal til for å få med deg alle tilbakemeldingene du trenger?"
  },
  {
    "id": "game_brainstorming_dropdown_01",
    "description": "Brainstorm dd",
    "en": "<h1>Memorize the ideas in the right sequence</h1>",
    "no": "<h1>Memoriser ideene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_brainstorming_dropdown_08",
    "description": "Brainstorm dd",
    "en": "<h1>Do you remember all the ideas and their categories in the right sequence?</h1>",
    "no": "<h1>Husker du alle ideene & kategoriene i riktig rekkefølge?</h1>"
  },
  {
    "id": "game_brainstorming_dropdown_07",
    "description": "Brainstorm dd",
    "en": "<h1>Do you remember all the ideas and their categories in the right sequence?</h1>",
    "no": "<h1>Husker du alle ideene & kategoriene i riktig rekkefølge?</h1>"
  },
  {
    "id": "system_memolife_tutorial_13",
    "description": "Body",
    "en": "Here’s how you remember <b>53 52 62</b>",
    "no": "Slik husker du <b>53 52 62</b>"
  },
  {
    "id": "game_brainstorming_dropdown_06",
    "description": "Brainstorm dd",
    "en": "<h1>Do you remember the brainstorming ideas in the right sequence?</h1>",
    "no": "<h1>Husker du brainstorming-ideene i riktig rekkefølge?</h1>"
  },
  {
    "id": "game_brainstorming_intro_12",
    "description": "Brainstorm inftro footer - learn slide",
    "en": "Ace the Brainstorming game with these memory systems:",
    "no": "Bli bedre i Brainstorming med disse huskesystemene:"
  },
  {
    "id": "game_brainstorming_intro_01",
    "description": "Brainstorm",
    "en": "Brainstorming",
    "no": "Brainstorming"
  },
  {
    "id": "global_board_03",
    "description": "Boarding",
    "en": "<b class='color-green'>Free trial :</b> explore the first levels in the games for free!",
    "no": "<b class='color-green'>Gratisprøve :</b> utforsk de første spillnivåene gratis."
  },
  {
    "id": "BP-7",
    "description": "--- Boarding",
    "en": "Double Your Memory with the Flash Course",
    "no": "Doble hukommelsen med Lynkurset"
  },
  {
    "id": "global_board_06",
    "description": "Boarding",
    "en": "Get started",
    "no": "Kom i gang"
  },
  {
    "id": "belt_profile_03",
    "description": "Belt tab title",
    "en": "Belts",
    "no": "Belter"
  },
  {
    "id": "global_board_05",
    "description": "Boarding",
    "en": "<p>MemoGenius lets you learn the memory techniques used by world memory champions and apply them in your everyday life, whether you're a student, a professional or a senior.</p>",
    "no": "<p>MemoGeni lar deg lære husketeknikker som brukes av verdensmestere i hukommelse og anvende dem i hverdagen din, om du er student, i jobb eller pensjonist.</p>"
  },
  {
    "id": "global_board_04",
    "description": "Boarding",
    "en": "Welcome to MemoGenius <br/>and a world of super-fast memory",
    "no": "Velkommen til MemoGeni <br/>og en verden av super-hurtig hukommelse"
  },
  {
    "id": "global_board_02",
    "description": "Boarding",
    "en": "<p>A poor memory is something we all experience from time to time. Luckily, anyone can become a memory genius with the right brain improvement techniques.</p><p>The best part is that it's alot of fun, because the part of your brain responsible for emotions is responsible for memory as well</p>",
    "no": "<p>En dårlig hukommelse er noe vi alle opplever iblandt. Heldigvis kan alle bli et huskegeni med de riktige hjernetreningsteknikkene.</p><p>Best av alt er at det hele er morsomt, fordi hjernedelen som er ansvarlig for følelser også er ansvarllig for hukommelse.</p>"
  },
  {
    "id": "global_board_01",
    "description": "Boarding Pop up",
    "en": "Memogenius",
    "no": "MemoGeni"
  },
  {
    "id": "BP-8",
    "description": "--- Boarding",
    "en": "x2 memory graphic",
    "no": "x2 memory graphic"
  },
  {
    "id": "belt_list_01",
    "description": "Belt level headers",
    "en": "Clear these game levels to achieve the ",
    "no": "For å få det "
  },
  {
    "id": "game_bankvault_intro_09",
    "description": "BankVault intro - level 4",
    "en": "You’re almost there! Now, you have to memorize 6 digits at the time. Are you up for the challenge or will you trigger the alarm and get caught?",
    "no": "Nesten fremme! Nå må du memorisere 6 sifre om gangen. Har du det som skal til eller utløser du alarmen og blir tatt?"
  },
  {
    "id": "game_bankvault_intro_05",
    "description": "BankVault main intro",
    "en": "You’re a luxury thief who’s about to break into the bank vault. Can you keep your cool and remember all the high speed number codes without triggering the alarm?",
    "no": "Du er en luksustyv som er iferd med å bryte seg inn i et bankhvelv med diamanter. Er du hurtig nok i hodet og avslappet nok i magen til å memorisere alle sifferkodene i høy hastighet, eller kommer du til å utløse alarmen?"
  },
  {
    "id": "system_name_21",
    "description": "NameSys- pratice",
    "en": "Here’s your memory code for <b>[x]</b> - did you get it right?",
    "no": "Her er huskekoden for <b>[x]</b> - husket du riktig?"
  },
  {
    "id": "system_name_22",
    "description": "NameSys- pratice",
    "en": "Press no if you can't remember the name",
    "no": "Press 'nei' dersom du ikke husker navnet"
  },
  {
    "id": "game_meeting_intro_04",
    "description": "intro tag 3",
    "en": "deadlines",
    "no": "deadlines"
  },
  {
    "id": "sp4",
    "description": "--- NameSys",
    "en": "Press stop to reveal the answer",
    "no": "Press 'stopp' for å vise svaret"
  },
  {
    "id": "game_shopping_dropdown_04",
    "description": "Shopping dd L4a",
    "en": "<h1>Memorize the products, quantities & prices in the right sequence</h1>",
    "no": "<h1>Memoriser produktene, mengdene & prisene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_shopping_intro_07",
    "description": "Shopping intro2",
    "en": "It’s time for your weekly shopping trip and there are many products you need to remember to buy. Are you up for the challenge?",
    "no": "Det er på tide med din ukentlige storhandletur og det er mange produkter du må huske på å ta med deg. Klarer du å få med deg alt uten å skrive det ned?"
  },
  {
    "id": "game_socialstar_intro_01",
    "description": "SocialStar intro view",
    "en": "Social Star",
    "no": "Navnemester"
  },
  {
    "id": "game_shopping_dropdown_08",
    "description": "Shopping dd L4b",
    "en": "<h1>Do you remember the products, quantities & prices in the right sequence?</h1>",
    "no": "<h1>Husker du produktene, mengdene & prisene i riktig rekkefølge?</h1>"
  },
  {
    "id": "system_memolife_tutorial_26",
    "description": "MemolifeSys tutorial Test - CTA button text",
    "en": "Let me see if I remember",
    "no": "La meg se om jeg husker"
  },
  {
    "id": "system_name_23",
    "description": "NameSys- pratice",
    "en": "Press yes if you remember it correctly",
    "no": "Press 'ja' dersom du husker riktig"
  },
  {
    "id": "asys",
    "description": "--- Msys alphabet",
    "en": "Alphabet system",
    "no": "Alfabet-systemet"
  },
  {
    "id": "belt_profile_01",
    "description": "Belt user ranking",
    "en": "Rank :",
    "no": "Ranking"
  },
  {
    "id": "system_memolife_tutorial_11",
    "description": "Mind",
    "en": "Here’s how you remember <b>37 40 51</b>",
    "no": "Slik husker du <b>37 40 51</b>"
  },
  {
    "id": "game_wheel_dropdown_05",
    "description": "Menu dd",
    "en": "Sound on/off",
    "no": "Lyd på/av"
  },
  {
    "id": "game_wheel_dropdown_03",
    "description": "Menu dd",
    "en": "Ranking",
    "no": "Ranking"
  },
  {
    "id": "game_wheel_dropdown_02",
    "description": "Menu dd",
    "en": "Hide",
    "no": "Gjem"
  },
  {
    "id": "system_memolife_tutorial_27",
    "description": "MemolifeSys tutorial - Test - input keyboard",
    "en": "Do you remember all the 18 digits?",
    "no": "Husker du alle 18 sifrene?"
  },
  {
    "id": "hh6",
    "description": "--- Menu dd",
    "en": "BENEFITS",
    "no": "FORDELER"
  },
  {
    "id": "l-1",
    "description": "NEXT LEVELS",
    "en": "PLAY NEXT LEVEL",
    "no": "SPILL NESTE NIVÅ"
  },
  {
    "id": "system_rhyme_dropdown_01",
    "description": "Rhyme  part A - dropdown",
    "en": "<h1>Remember the memory codes by listening to what the numbers sound like </h1>",
    "no": "<h1>Husk huskekodene ved å lytte til hva numrene rimer på </h1>"
  },
  {
    "id": "system_name_24",
    "description": "NameSys- pratice",
    "en": "Yes",
    "no": "Ja"
  },
  {
    "id": "system_name_25",
    "description": "NameSys- pratice",
    "en": "No",
    "no": "Nei"
  },
  {
    "id": "system_name_05",
    "description": "NameSys - tab name",
    "en": "Surname",
    "no": "Etternavn"
  },
  {
    "id": "system_name_04",
    "description": "NameSys - tab name",
    "en": "Female name",
    "no": "Kvinnelig navn"
  },
  {
    "id": "system_name_01",
    "description": "NameSys - tab name",
    "en": "Top names",
    "no": "Mannlig navn"
  },
  {
    "id": "global_system_07",
    "description": "NameSys",
    "en": "Name System",
    "no": "Navn-systemet"
  },
  {
    "id": "system_memolife_single_01",
    "description": "Memolife singleSys part- dropdown",
    "en": "<h1>Remember the memory codes by observing what the numbers look like </h1>",
    "no": "<h1>Husk huskekodene ved å observere hva numrene ligner på </h1>"
  },
  {
    "id": "system_name_02",
    "description": "NameSys - tab name",
    "en": "Flag",
    "no": "Flagg"
  },
  {
    "id": "namesys",
    "description": "--- NameSys",
    "en": "Name system",
    "no": "Navn-systemet"
  },
  {
    "id": "system_memolife_tutorial_28",
    "description": "MemolifeSys tutorial - Test - input footer link",
    "en": "Don’t remember? Take another test here!",
    "no": "Husker du ikke? Ta en ny test her!"
  },
  {
    "id": "system_memolife_tutorial_25",
    "description": "MemolifeSys - Test",
    "en": "Example locations",
    "no": "Eksempler for lagringssteder"
  },
  {
    "id": "TT-14",
    "description": "--- MemolifeSys",
    "en": "Combine the Person, Action and Object memory codes into a mental movie scene on the location for each line.",
    "no": "Kombiner huskekodene for Person, Handling og Objekt til en mental filmscene på lagringsstedet per linje. "
  },
  {
    "id": "TT-13",
    "description": "--- MemolifeSys",
    "en": "Memorize the 3 lines and all 18 digits in sequence",
    "no": "Memoriser de 3 linjene og alle 18 sifre i rekkefølge"
  },
  {
    "id": "TT-12",
    "description": "--- MemolifeSys",
    "en": "(location)",
    "no": "(lagringssted)"
  },
  {
    "id": "TT-11",
    "description": "--- MemolifeSys",
    "en": "Moon",
    "no": "Måne"
  },
  {
    "id": "TT-10",
    "description": "--- MemolifeSys",
    "en": "Got it, let me try",
    "no": "Skjønner, la meg prøve"
  },
  {
    "id": "TT-9",
    "description": "--- MemolifeSys",
    "en": "Albert Einstein (Person - 00) is doing a golf swing (Action - 09) with a candle (Object - 01) on the moon (location).",
    "no": "Albert Einstein (Person - 00) tar en golfswing (Handling - 09) med et stearinlys (Objekt - 01) på månen (lagringssted)."
  },
  {
    "id": "TT-8",
    "description": "--- MemolifeSys",
    "en": "Object",
    "no": "Objekt"
  },
  {
    "id": "TT-7",
    "description": "--- MemolifeSys",
    "en": "Action",
    "no": "Handling"
  },
  {
    "id": "system_numbershape_intro_01",
    "description": "MemolifeSys single - Move to memolife",
    "en": "The Number-Shape System",
    "no": "The Number-Shape System"
  },
  {
    "id": "TT-6",
    "description": "--- MemolifeSys",
    "en": "Person",
    "no": "Person"
  },
  {
    "id": "TT-5",
    "description": "--- MemolifeSys",
    "en": "Where it’s happening",
    "no": "Hvor skjer det"
  },
  {
    "id": "TT-4",
    "description": "--- MemolifeSys",
    "en": "What is happening",
    "no": "Hva skjer"
  },
  {
    "id": "TT-3",
    "description": "--- MemolifeSys",
    "en": "Here’s how you remember 00 09 01",
    "no": "Slik husker du 00 09 01"
  },
  {
    "id": "TT-2",
    "description": "--- MemolifeSys",
    "en": "To remember long numbers all you have to do is create visual stories out of the Person - Action - Object memory codes you have learnt.",
    "no": "Alt du trenger å gjøre for å huske lengre numre er å visualisere historier med huskekodene for Person - Handling - Objekt som du har lært."
  },
  {
    "id": "system_memolife_tutorial_18",
    "description": "MemolifeSys",
    "en": "Steve Jobs (Person 88) clicks a mouse (Action 85) attached to a pearl necklace (Object 81) inside a shower (Location).",
    "no": "Steve Jobs (Person 88) klikker på en mus (Action 85) med perlekjede (Objekt 81) i en dusj (Lagringssted)."
  },
  {
    "id": "system_memolife_tutorial_20",
    "description": "MemolifeSys",
    "en": "Angelina Jolie (Person 95) opens a wine bottle (Action 89) with a knife (Object 91) on a surfboard (Location).",
    "no": "Angelina Jolie (Person 95) åpner en vinflaske (Handling 89) med en kniv (Objekt 91) på et surfebrett (Lagringssted)."
  },
  {
    "id": "system_memolife_tutorial_22",
    "description": "MemolifeSys",
    "en": "Jennifer Lopez (Person 15) throws a sword (Action 18) against a column (Object 31) inside an aquarium (Location).",
    "no": "Jennifer Lopez (Person 15) sverdfekter (Action 18) mot en søyle (Objekt 31) i et akvarium (Lagringssted)."
  },
  {
    "id": "system_memolife_intro_01",
    "description": "MemolifeSys intro",
    "en": "The Memolife System",
    "no": "Memolife-systemet"
  },
  {
    "id": "global_system_02",
    "description": "All game intro - learn area",
    "en": "Memolife system",
    "no": "Memolife-systemet"
  },
  {
    "id": "game_timetravel_intro_06",
    "description": "TimeTravel intro1",
    "en": "Your first time travel is a rush! Can you remember the different years and the events as you’re thrown through time?",
    "no": "Din første tidsreise er et ordentlig kick! Klarer du å huske årstallene for de historiske hendelsene idet du kastes gjennom tiden?"
  },
  {
    "id": "game_timetravel_dropdown_03",
    "description": "TimeTravel dd L3a",
    "en": "<h1>Memorize the day, month and year of the events </h1>",
    "no": "<h1>Memoriser dagen, måneden og året for hendelsene </h1>"
  },
  {
    "id": "game_timetravel_intro_01",
    "description": "TimeTravel",
    "en": "Time Travel",
    "no": "Tidsmaskin"
  },
  {
    "id": "game_timetravel_test_01",
    "description": "TimeTravel - test phrase",
    "en": "When did this happen?",
    "no": "Når skjedde dette?"
  },
  {
    'id': 'tt1',
    "en": "When did this happen?",
    "no": "Når skjedde dette?"
  },
  {
    'id': 'tt2',
    "en": "When did this happen?",
    "no": "Når skjedde dette?"
  },
  {
    "id": "game_vocabguru_test_01",
    "description": "Vocab game - test",
    "en": "What is the correct translation?",
    "no": "Hva er den riktige oversettelsen?"
  },
  {
    "id": "game_timetravel_intro_07",
    "description": "TimeTravel intro2",
    "en": "Your time machine has become finetuned! You now have to remember the month and year of the event you travel to. Are you up for the challenge?",
    "no": "Tidsmaskinen din har blitt fininnstilt! Du må nå huske måneden og året da de historiske hendelsene skjedde. Er du klar for utfordringene?"
  },
  {
    "id": "game_timetravel_dropdown_08",
    "description": "Time Travel dd L4b",
    "en": "<h1>Do you remember when the events happened?</h1>",
    "no": "<h1>Husker du når hendelsene skjedde?</h1>"
  },
  {
    "id": "game_timetravel_dropdown_04",
    "description": "Time Travel dd L4a",
    "en": "<h1>Memorize the time periods of the events</h1>",
    "no": "<h1>Memoriser tidsperiodene for hendelsene</h1>"
  },
  {
    "id": "game_timetravel_dropdown_07",
    "description": "Time Travel dd L3b",
    "en": "<h1>Do you remember when the events happened?</h1>",
    "no": "<h1>Husker du når hendelsene skjedde?</h1>"
  },
  {
    "id": "game_timetravel_intro_09",
    "description": "TimeTravel intro4",
   "en": "Your time travels now happen over longer periods of time. Have you got what it takes to remember the start and end years of the different historic events?",    
   "no": "Tidsreisene skjer nå over lengere tidsperioder. Klarer du å huske begynnelses- og sluttåret for de forskjellige historiske hendelsene?"  
  },
  {
    "id": "game_timetravel_intro_08",
    "description": "TimeTravel intro3",
    "en": "Your time travels are becoming even more finetuned. You now have to remember the date, month and year of both the beginning and end of the different historic events. Think you can handle it?",
    "no": "Tidsmaskinen din har blitt enda mer fininnstilt! Du må nå huske dagen, måneden og året da de historiske hendelsene skjedde. Er du klar for utfordringene?"
  },
  {
    "id": "i-tt9",
    "description": "--- TimeTravel level text",
    "en": "Time Travel: Level",
    "no": "Tidsmaskin: Nivå"
  },
  {
    "id": "game_timetravel_intro_12",
    "description": "TimeTravel Techniques",
    "en": "Ace the Time Travel game with these systems:",
    "no": "Bli bedre i Tidsmaskinen med disse huskesystemene:"
  },
  {
    "id": "game_meeting_intro_03",
    "description": "intro tag 2",
    "en": "opinions",
    "no": "meninger"
  },
  {
    "id": "game_bankvault_dropdown_08",
    "description": "BankVault dd",
    "en": "<h1>Enter the numbers in the correct sequencence - without triggering the alarm</h1>",
    "no": "<h1>Tast inn numrene i riktig rekkefølge - uten å utløse alarmen</h1>"
  },
  {
    "id": "game_socialstar_dropdown_08",
    "description": "SocialStar dd L4b",
    "en": "<h1>Do you remember the names, titles & hobbies of the people you met?</h1>",
    "no": "<h1>Husker du navnene, titlene og hobbiene til menneskene du møtte?</h1>"
  },
  {
    "id": "system_memolife_tutorial_19",
    "description": "Leisure",
    "en": "Here’s how you remember <b>95 89 91</b>",
    "no": "Slik husker du  <b>95 89 91</b>"
  },
  {
    "id": "game_curriculum_dropdown_02",
    "description": "Curriculum dd L1a",
    "en": "<h1>Memorize the words on the memomap branches</h1>",
    "no": "<h1>Memoriser ordene på grenene til memomappet</h1>"
  },
  {
    "id": "l-C5",
    "description": "--- Curriculum intro ???",
    "en": "Now it’s time to include several words on the same branch as well. Have you got what it takes to remember a whole book chapter?",
    "no": "Nå er det på tide å inkludere flere ord på samme gren. Har du det som skal til for å huske et helt kapittel?"
  },
  {
    "id": "game_curriculum_dropdown_05",
    "description": "Curriculum dd L1b",
    "en": "<h1>Do you know which words belong to the right branches?</h1>",
    "no": "<h1>Husker du hvilke ord som tilhører grenene til memomappet?</h1>"
  },
  {
    "id": "game_curriculum_dropdown_01",
    "description": "Curriculum dd L2a",
    "en": "<h1>Memorize the words on the memomap branches</h1>",
    "no": "<h1>Memoriser ordene på grenene til memomappet</h1>"
  },
  {
    "id": "game_curriculum_dropdown_06",
    "description": "Curriculum dd L2b",
    "en": "<h1>Do you know which words belong to the right branches?</h1>",
    "no": "<h1>Husker du hvilke ord som tilhører grenene til memomappet?</h1>"
  },
  {
    "id": "game_curriculum_dropdown_03",
    "description": "Curriculum dd L3a",
    "en": "<h1>Memorize the images & words on the memomap branches</h1>",
    "no": "<h1>Memoriser ordene & bildene på grenene til memomappet</h1>"
  },
  {
    "id": "game_curriculum_dropdown_07",
    "description": "Curriculum dd L3b",
    "en": "<h1>Do you know which words & images belong to the right branches?</h1>",
    "no": "<h1>Husker du hvilke ord & bilder som tilhører grenene til memomappet?</h1>"
  },
  {
    "id": "game_curriculum_dropdown_04",
    "description": "Curriculum dd L4a",
    "en": "<h1>Memorize more words & images on the memomap branches</h1>",
    "no": "<h1>Memoriser flere ord & bilder på grenene til memomappet</h1>"
  },
  {
    "id": "game_curriculum_dropdown_08",
    "description": "Curriculum dd L4b",
    "en": "<h1>Do you know which words & images belong to the right branches?</h1>",
    "no": "<h1>Husker du hvilke ord & bilder som tilhører grenene til memomappet?</h1>"
  },
  {
    "id": "game_curriculum_intro_03",
    "description": "Curriculum",
    "en": "articles or texts",
    "no": "artikler eller tekst"
  },
  {
    "id": "game_meeting_dropdown_02",
    "description": "Meeting dd L2a",
    "en": "<h1>Memorize the sales needs & comments in the right sequence</h1>",
    "no": "<h1>Memoriser hvem som sa salgsbehovene & kommentarene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_curriculum_intro_02",
    "description": "Curriculum",
    "en": "book chapters",
    "no": "bokkapitler"
  },
  {
    "id": "game_curriculum_intro_01",
    "description": "Curriculum",
    "en": "Curriculum Blitz",
    "no": "Lynpensum"
  },
  {
    "id": "game_meeting_intro_02",
    "description": "intro tag 1",
    "en": "tasks",
    "no": "oppgaver"
  },
  {
    "id": "game_bankvault_intro_10",
    "description": "BankVault - learn slide CTA text",
    "en": "Play the game",
    "no": "Spill her"
  },
  {
    "id": "game_bankvault_intro_04",
    "description": "intro tag 3",
    "en": "dates",
    "no": "datoer"
  },
  {
    "id": "game_bankvault_intro_03",
    "description": "intro tag 2",
    "en": "pin-codes",
    "no": "pin-koder"
  },
  {
    "id": "game_bankvault_intro_02",
    "description": "intro tag 1",
    "en": "numbers",
    "no": "tall"
  },
  {
    "id": "global_intro_08",
    "description": "All game/system intro - learn",
    "en": "min read",
    "no": "minutter å lese"
  },
  {
    "id": "i-9",
    "description": "---",
    "en": "Ranking",
    "no": "Ranking"
  },
  {
    "id": "global_intro_02",
    "description": "All game intro banner area",
    "en": "Learn how here",
    "no": "Lær teknikkene her"
  },
  {
    "id": "i-3",
    "description": "---",
    "en": "Learn how to play",
    "no": "Lær å spille"
  },
  {
    "id": "game_meeting_intro_08",
    "description": "Meeting intro - level 3",
    "en": "You’re about to hold an exciting presentation to an enthusiastic audience. In order to avoid \"death by slides\" you decide to adapt your presentation to what the audience really wants to know by listening to all their questions right away. Have you got what it takes to hold a dynamic presentation and give the answers in the right sequence to the right people?",
    "no": "Du skal snart holde et spennende foredrag til et entusiastisk publikum. For å unngå å \"drepe deltagerne med slides\" bestemmer du deg for å tilpasse presentasjonen til hva publikum virkelig vil vite - ved å lytte til alle spørsmålene deres med en gang. Har du det som skal til for å holde et dynamisk foredrag med svarene i riktig rekkefølge til de riktige menneskene?"
  },
  {
    "id": "game_meeting_intro_09",
    "description": "Meeting intro - level 4",
    "en": "You’re a manager and need to remember who takes the responsibility for the different tasks and their deadlines in the project meeting. Have you got what it takes to summarize the project plan at the end of the meeting without taking notes?",
    "no": "Du er bedriftsleder og trenger å huske hvem som tar ansvar for de forskjellige oppgavene og tidsfristene i planleggingsmøtet. Har du det som skal til for å oppsummere prosjektplanen muntlig på slutten av møtet uten å ta notater?"
  },
  {
    "id": "game_meeting_memory_01",
    "description": "meeting slide 1 intro - L1",
    "en": "<div>Get to know your participants for the </div> <div> “[game]” speed meeting</div>",
    "no": "<div>Bli kjent med deltagerne i  </div> <div> “[game]” lynmøte</div>"
  },
  {
    "id": "game_meeting_dropdown_06",
    "description": "Meeting dd L2b",
    "en": "<h1>Repeat the sales needs & comments in the right sequence?</h1>",
    "no": "<h1>Husker du salgsbehovene & kommentarene i riktig riktig rekkefølge?</h1>"
  },
  {
    "id": "game_meeting_dropdown_03",
    "description": "Meeting dd L3a",
    "en": "<h1>Memorize who asked which questions</h1>",
    "no": "<h1>Memoriser hvem som spurte hvilke spørsmål</h1>"
  },
  {
    "id": "game_meeting_memory_06",
    "description": "Meeting slide 2",
    "en": "Focus mainly on the content in the white speech bubbles",
    "no": "Fokuser først og fremst på innholdet i de hvite boblene"
  },
  {
    "id": "game_meeting_dropdown_07",
    "description": "Meeting dd L3b",
    "en": "<h1>Give the answers - in the right sequence - to the right people</h1>",
    "no": "<h1>Gi svarene - i riktig rekkefølge - til de riktige personene</h1>"
  },
  {
    "id": "game_meeting_dropdown_04",
    "description": "Meeting dd L4a",
    "en": "<h1>Memorize the tasks, people & deadlines in the right sequence</h1>",
    "no": "<h1>Memoriser oppgavene, menneskene & tidsfristene i riktig rekkefølge</h1>"
  },
  {
    "id": "game_meeting_dropdown_08",
    "description": "Meeting dd L4b",
    "en": "<h1>Repeat the tasks, the project managers & their deadlines in the right sequence </h1>",
    "no": "<h1>Gjenta oppgavene, prosjektlederne & tidsfristene i riktig rekkefølge</h1>"
  },
  {
    "id": "l-M5",
    "description": "--- Meeting intro ???",
    "en": "You’re moving up in the world! You now have to remember who mentioned the company needs and the comments they gave each other as well. Are you up for the challenge?",
    "no": "Du beveger deg opp i verden! Du må nå huske hvem som nevnte firmabehovene og kommentarene de ga hverandre. Er du klar for utfordringen?"
  },
  {
    "id": "game_meeting_memory_05",
    "description": "Meeting slide 1- CTA",
    "en": "Start your meeting",
    "no": "Start møtet"
  },
  {
    "id": "l-M1",
    "description": "--- Meeting",
    "en": "Meeting Bliss : Level",
    "no": "Møtelykke: Nivå"
  },
  {
    "id": "game_meeting_intro_12",
    "description": "Meeting intro - learn slide",
    "en": "Ace the Meeting Bliss game with these memory systems:",
    "no": "Bli bedre i Møtelykke med disse huskesystemene:"
  },
  {
    "id": "game_meeting_memory_04",
    "description": "meeting slide 1 intro - L4",
    "en": "<div>Get to know your participants for the </div> <div> “[game]” project meeting</div>",
    "no": "<div>Bli kjent med deltagerne i  </div> <div> “[game]” prosjektmøte</div>"
  },
  {
    "id": "game_result_gameover_04",
    "description": "GameOver button 1",
    "en": "Restart",
    "no": "Start på nytt"
  },
  {
    "id": "game_wheel_header_02",
    "description": "header links",
    "en": "SYSTEMS",
    "no": "SYSTEMER"
  },
  {
    "id": "game_wheel_header_01",
    "description": "header links",
    "en": "GAMES",
    "no": "SPILL"
  },
  {
    "id": "global_intro_01",
    "description": "All game intro header",
    "en": "Home",
    "no": "Hjem"
  },
  {
    "id": "game_meeting_intro_01",
    "description": "Meeting",
    "en": "Meeting Bliss",
    "no": "Møtelykke"
  },
  {
    "id": "game_meeting_dropdown_01",
    "description": "Meeting dd L1a",
    "en": "<h1>Memorize who said what in the right sequence</h1>",
    "no": "<h1>Memoriser hvem som sa hva i riktig rekkefølge</h1>"
  },
  {
    "id": "global_levelup_02",
    "description": "Level up pop up",
    "en": "You unlocked level",
    "no": "Du låste opp nivå"
  },
  {
    "id": "global_intro_04",
    "description": "All game intro footer",
    "en": "Level",
    "no": "Nivå"
  },
  {
    "id": "game_timetravel_intro_11",
    "description": "TimeTravel techniques",
    "en": "<p><b>What if you could remember any historic date, birthday or future event at the blink of an eye - and have fun doing it?",
    "no": "<p><b>Hva hvis du kunne huske hvilken som helst historisk dato, bursdag eller fremtidig avtale på brøkdelen av et sekund - og ha det gøy mens det skjer?"
  },
  {
    "id": "game_meeting_memory_03",
    "description": "meeting slide 1 intro - L3",
    "en": "<div>Get to know your participants for the </div> <div> “[game]” dynamic presentation</div>",
    "no": "<div>Bli kjent med deltagerne i </div> <div> “[game]” dynamisk presentasjonsmøte</div>"
  },
  {
    "id": "global_intro_07",
    "description": "All game intro footer",
    "en": "seconds",
    "no": "sekunder"
  },
  {
    "id": "game_result_gameover_06",
    "description": "Continue",
    "en": "Continue",
    "no": "Fortsett"
  },
  {
    "id": "global_07",
    "description": "all game - test header",
    "en": "Level",
    "no": "Nivå"
  },
  {
    "id": "game_bankvault_test_01",
    "description": "Bankvault - test CTA",
    "en": "Open safe",
    "no": "Åpne safen"
  },
  {
    "id": "global_08",
    "description": "Game input test - CTA",
    "en": "Answer",
    "no": "Svar"
  },
  {
    "id": "game_vocabguru_memory_01",
    "description": "Vocab guru- memory pharse",
    "en": "English",
    "no": "Norsk"
  },
  {
    "id": "global_levelup_01",
    "description": "Level up pop up",
    "en": "Level unlocked",
    "no": "Nytt nivå"
  },
  {
    "id": "game_timetravel_memory_01",
    "description": "time travel - memory phrase",
    "en": "year",
    "no": "år"
  },
  {
    "id": "global_belt_01",
    "description": "Belt pop up - header text",
    "en": "Congratz",
    "no": "Gratulerer"
  },
  {
    "id": "global_belt_02",
    "description": "Belt pop up - header text",
    "en": "What an achievement",
    "no": "For en prestasjon"
  },
  {
    "id": "global_belt_03",
    "description": "Belt pop up - content text",
    "en": "A small paragraph to give context, to the memory champion a litter of story with a twist.",
    "no": null
  },
  {
    "id": "system_memolife_result_01",
    "description": "MemolifeSys - result",
    "en": "Congratulations, you have completed the",
    "no": "Gratulerer, du fullførte"
  },
  {
    "id": "system_memolife_result_03",
    "description": "MemolifeSys result - CTA button text",
    "en": "Back to systems",
    "no": "Tilbake til systemene"
  },
  {
    "id": "system_memolife_result_04",
    "description": "MemolifeSys result - CTA button text",
    "en": "Play the next level using the memory system",
    "no": "Spill neste nivå ved å bruke huskesystemene"
  },
  {
    "id": "system_memolife_duoble_03",
    "description": "Memolife duobleSys",
    //"en": "Now, link the OBJECT with the <u>Brain Improvement Area</u>, PERSON and ACTION",
    //"no": "Knytt OBJEKTET med <u>livsområdet</u>, PERSONEN og HANDLINGEN."
    "en": "is life skill",
    "no": "er livsområdet"
  },
  {
    "id": "system_memolife_duoble_04",
    "description": "Memolife duobleSys ",
    "en": "Object",
    "no": "Objekt"
  },
  {
    "id": "system_memolife_duoble_05",
    "description": "Memolife duobleSys ",
    "en": "Person",
    "no": "Person"
  },
  {
    "id": "system_memolife_duoble_06",
    "description": "Memolife duobleSys ",
    "en": "Action",
    "no": "Handling"
  },
  {
    'id': 'dblexample_slide1_descr_header',
    'en': "Get the Memolife system in 2 steps",
    'no': "Forstå Memolife-systemet i 2 steg"
  },
  {
    'id': "dblexample_slide1_descr",
    'en': "1) Link the shape (<b>Object</b>) of a double digit number to a <b>Life Area</b><br>2) Link this life area to a <b>Person</b> and an <b>Action</b>.",
    'no': "1) Koble sammen tallformen (<b>Objektet</b>) med et <b>Livsområde</b>.<br>2) Koble deretter Livsområdet til en <b>Person</b> og en <b>Handling</b>"
  },
  {
    'id': "dblexample_slide2_descr_header",
    'en': "1) From Object to Life Area",
    'no': "1) Fra Objekt til Livsområde"
  },
  {
    'id': "dblexample_slide2_descr",
    'en': "Every number will animate into a shape and give you the Object. Every object is then linked to a logical Life Area, such as brain halves (object) and brain improvement (life area).",
    'no': "Hver tallform gir deg Objektet. Hvert objekt blir så lenket til et logisk Livsområde, slik som Hjernehalvdeler (objekt) og hjernetrening (livsområde)"
  },
  {
    'id': "dblexample_slide3_descr_header",
    'en': "2) From Life Area to Person & Action",
    'no': "2) Fra Livsområde til Person og Handling"
  },
  {
    'id': "dblexample_slide3_descr",
    'en': 'Once you know the Life Area it’s easy to make the connection to the matching Person and Action, in this case “Albert Einstein” (person) and “Pointing fingers to head” (action).',
    'no': 'Når du vet Livsområdet er det enkelt å lage en kobling til riktig Person og Handling. I dette tilfellet “Albert Einstein” (person) og "Peke fingre til hodet" (handling).'
  },
  {
    'id': "dblexample2_slide1_header",
    'en': "Memorize this 6-digit number ...",
    'no': "Husk dette 6-sifrete tallet ..."
  },
  {
    'id': "dblexample2_slide1_descr",
    'en': "<p> ... by visualizing a memory fantasy with:</p><p> - the <b>Person</b> from the first number (00)<br>- the <b>Action</b> from the second number (12)<br>- the <b>Object</b> from the thrid number (49)</p>",
    'no': "<p> ... ved å visualisere en huskefantasi med:</p><p> - <b>Personen</b> fra det første tallet (00)<br>- <b>Handlingen</b> fra det andre tallet (12)<br>- <b>Objektet</b> fra det tredje tallet (49)</p>"
  },
  {
    'id': "dblexample2_slide2_header",
    'en': "Start with the person - Albert Einstein (00) - for the two first digits.",
    'no': "Start med personen - Albert Einstein (00) - for de to første sifrene."
  },
  {
    'id': "dblexample2_slide3_header",
    'en': "Continue with the action - rowing (14) - for the two next digits.",
    'no': "Fortsett med handlingen - å ro (14) - for de to neste sifrene."
  },
  {
    'id': "dblexample2_slide4_header",
    'en': "Continue with the object - a frying pan (89) - for the two last digits.",
    'no': "Fortsett med objektet - en stekepanne (89) - for de to siste sifrene."
  },
  {
    'id': "dblexample2_slide5_header",
    'en': "Combine the person-action-object into a memory fantasy",
    'no': "Kombiner personen-handlingen-objektet til en huskefantasi"
  },
  {
    'id': "dblexample2_slide5_descr",
    'en': "Albert Einstein (00 Person) rows (14 Action) on a frying pan (89 Object).<br>Tip: the funnier you make your visualizations, the easier you'll remember.",
    'no': "Albert Einstein (00 Person) ror (14 Handling) på en stekepanne (89 Objekt).<br>Tips: Jo morsommere du gjør visualiseringen, desto enklere vil du huske."
  },
  {
    'id': "doublesys_start_tutorial",
    'en': "Start tutorial",
    'no': "Start tutorial"
  },
  {
    'id': 'lets_see',
    'en': "OK, let's see",
    'no': "OK, la oss ta en titt"
  },
  {
    'id': 'lets_play',
    'en': "Let's play",
    'no': "Start spillet"
  },
  {
    'id': 'continue',
    'en': "Continue",
    'no': "Fortsett"
  },
  {
    'id': 'life_area',
    'en': "Life Area",
    'no': "Livsområde"
  },
  {
    'id': "view_example",
    'en': "Here's an example",
    'no': "Her er et eksempel"
  },

  {
    "id": "system_journey_02",
    "description": "System journey - CTA button text",
    "en": "Start practice",
    "no":  "Start å øve"
  },
  {
    "id": "system_journey_10",
    "description": "journeySys - pratice",
    "en": "Press no if you can't remember the name",
    "no": "Press 'nei' dersom du ikke husker navnet"
  },
  {
    "id": "system_name_10",
    "description": "NameSys - categories",
    "en": "Africa (Sub-Sahara)",
    "no": "Afrika (Sub-Sahara)"
  },
  {
    "id": "system_journey_11",
    "description": "journeySys - pratice",
    "en": "Press yes if you remember it correctly",
    "no": "Press 'ja' dersom du husker riktig"
  },
  {
    "id": "system_journey_12",
    "description": "journeySys - pratice",
    "en": "Yes",
    "no": "Ja"
  },
  {
    "id": "system_journey_13",
    "description": "journeySys - pratice",
    "en": "No",
    "no": "Nei"
  },
  {
    "id": "system_name_11",
    "description": "NameSys - categories",
    "en": "East Asia",
    "no": "Øst-Asia"
  },
  {
    "id": "system_name_12",
    "description": "NameSys - categories",
    "en": "Europe",
    "no": "Europa"
  },
  {
    "id": "system_name_13",
    "description": "NameSys - categories",
    "en": "Middle-East & Greater Arabia",
    "no": "Midtøsten & 'Arabia'"
  },
  {
    "id": "system_name_14",
    "description": "NameSys - categories",
    "en": "North America",
    "no": "Nord-Amerika"
  },
  {
    "id": "system_name_15",
    "description": "NameSys - categories",
    "en": "South America",
    "no": "Sør-Amerika"
  },
  {
    "id": "system_name_16",
    "description": "NameSys - categories",
    "en": "South Asia",
    "no": "Sør-Asia"
  },
  {
    "id": "system_name_17",
    "description": "NameSys - categories",
    "en": "South-East Asia",
    "no": "Sør-Øst Asia"
  },
  {
    "id": "system_name_27",
    "description": "NameSys- pratice",
    "en": "You don't have any memory code for this name",
    "no": "Du har ingen huskekode for dette navnet"
  },
  {
    "id": "missions",
    "description": "Missions",
    "en": "Missions",
    "no": "Oppdrag"
  },
  {
    'id': "you_just_completed_the",
    'description': "mission completed badge",
    'en': "You just completed the",
    'no': "Du er nå ferdig med alle øvelsesstegene i "
  },
  {
    'id': "mission",
    'description': "mission",
    'en': "mission",
    'no': "Prøv spillene for å få poeng og nytt belte."
  },
  {
    'id': "nice_work",
    'description': "nice_work_mission_badge",
    'en': "Nice work!",
    'no': "Bra innsats!"
  },
  {
    'id': "close",
    'description': "Close",
    'en': "Close",
    'no': "Lukk"
  },
  { // used more places
    'id': "clone_system_title",
    'description': "",
    'en': "Clone System",
    'no': "Klone-systemet"
  },
  { // used more places
    'id': "similarity_system_title",
    'description': "",
    'en': "Similarity System",
    'no': "Likhets-systemet"
  },
  { // used more places
    'id': "symbolization_system_title",
    'description': "",
    'en': "Symbolization System",
    'no': "Symbol-systemet"
  },
  { // used more places
    'id': "time_system_title",
    'description': "",
    'en': "Time System",
    'no': "Tids-systemet"
  },
  { // used more places
    'id': "highlight_system_title",
    'description': "",
    'en': "Highlight System",
    'no': "Markør-systemet"
  },
  {
    'id': "your_quest",
    'description': "Your quest",
    'en': "Become A True MemoGenius",
    'no': "Bli et ekte memogeni"
  },
  {
    'id': "mission_overview_complete_these",
    'description': "Complete these missions...",
    'en': "Complete these missions and master the memory systems which will help you become a black belt in MemoGenius and a memory genius in real life.",
    'no': "Fullfør disse oppdragene for å mestre huskesystemene som vil hjelpe deg med å få svart belte i MemoGeni og til å bli et huskegeni i hverdagen."
  },
  {
    'id': "mission_start",
    'description': "start mission",
    'en': "Start mission",
    'no': "Start oppdraget"
  },
  {
    'id': "sidebar_view_mission_page",
    'description': "start mission",
    'en': "View mission page",
    'no': "Se oppdragene"
  },
  {
    'id': "mission_banner_back",
    'description': "",
    'en': "Back to mission",
    'no': "Tilbake til oppdragene"
  },
  {
    'id': "mission_sidetext",
    'description': "",
    'en': "<b>What you will learn</b><ul><li>Super-fast memory technique</li><li>How to rememember lists</li><li>How to remember numbers</li><li>How to visualize and daydream</li></ul>",
    'no': "<b>Det du vil lære</b><ul><li>Super-hurtig husketeknikk</li><li>Hvordan huske lister</li><li>Hvordan huske tall</li><li>Hvordan visualisere og huske</li></ul>"
  },
  {
    'id': 'you_have',
    'description': "",
    'en': "You have",
    'no': "Du har"
  },
  {
    'id': 'correct',
    'description': "",
    'en': "correct answers",
    'no': "riktige"
  },
  {
    'id': 'missions',
    'description': "",
    'en': "missions",
    'no': "oppdrag"
  },
  {
    'id': 'out_of',
    'description': "",
    'en': "out of",
    'no': "av"
  },
  {
    'id': 'completed',
    'description': "completed",
    'en': "completed",
    'no': "er fullført"
  },
  {
    'id': "next_missions",
    'description': "",
    'en': "Next missions",
    'no': "Neste oppdrag"
  },
  {
    'id': "profile-game-play",
    'description': "",
    'en': "Click to play",
    'no': "Klikk for å spille"
  },
  {
    'id': 'namesys-first',
    'description': '',
    'en': "<b>Remember names easily with memory codes</b><p>Usually names have no meaning to us and our brain struggles to remember them. With memory codes for name this easily becomes a thing of the past. Here we’ve got the 100 most popular names per country or region.</p>",
    'no': "<b>Husk navn enkelt med huskekoder</b><p>Navn har som regel ingen betydning for oss og hjernen sliter derfor med å huske dem. Med huskekoder for navn blir dette heldigvis en saga blott. Her har vi samlet de 100 mest populære navnene per land eller region</p>"
  },
  {
    'id': 'namesys-sidebartitle',
    'description': "",
    'en': "Names from",
    'no': "Top 100 Navn fra"
  },
  {
    'id': 'memorize-below',
    'description': "",
    'en': "Memorize the 2 items",
    'no': "Husk disse 2 produktene"
  },
  {
    'id': 'namesys-male-names',
    'en': "Male names",
    'no': "Navn på menn"
  },
  {
    'id': 'next-female',
    'en': "Next: female",
    'no': "Neste: damer"
  },
  {
    'id': 'prev-male',
    'en': "Prev: Males",
    'no': "Forrige: Menn"
  },

  {
    'id': 'namesys-family-names',
    'en': "Male names",
    'no': "Navn på menn"
  },
  {
    'id': 'namesys-female-names',
    'en': "Female names",
    'no': "Kvinnenavn"
  },
  {
    'id': 'namesys-mission-memorize',
    'en': "Memorize 10 male and 10 female names to complete this mission",
    'no': "Husk 10 navn for hvert kjønn for å gjøre ferdig dette oppdraget"
  },
  {
    'id': 'namesys-learn1',
    'description': "",
    'en': "Learn how",
    'no': "Lær hvordan"
  },
  {
    'id': 'namesys-learn2',
    'description': "",
    'en': "<b>Learning names with memory codes</b><p>Doesn’t Christian remind you of a cross (a christian)? After finding the memory code for the name all you have to do is visualize it in a dominating facial feature.</p>",
    'no': "<b>Husk navn enkelt med huskekoder</b><p>Minner ikke Kristian deg om et kors (kristen)? Etter du har funnet en huskekode for navnet er alt du trenger å gjøre å visualisere det i et dominerende ansiktstrekk.</p>"
  },
  {
    'id': 'names',
    'en': "Names",
    'no': "Navn"
  },
  {
    'id': 'name',
    'en': "Name",
    'no': "Navn"
  },
  {
    'id': 'namesys-placehoder',
    'description': "",
    'en': "Add your memory code for the name",
    'no': "Legg til en huskekode for navnet"
  },  
  {
    'id': "memorycodes",
    'en': "Memory codes",
    'no': "Huskekoder"
  },
  {
    'id': 'memory-code',
    'en': "Memory code",
    'no': "Huskekode"
  },
  {
    'id': 'namesys-learn4',
    'en': 'Christine',
    'no': "Kristian"
  },
  {
    'id': 'namesys-learn4-code',
    'en': 'Cross',
    'no': "Kors"
  },
  {
    'id': 'namesys-learn5',
    'en': "<b>Visualize and remember</b><p>Place the memory code in a dominant facial feature. Perhaps it can be used to comb his hair backwards or as support for his sleepy eyes?</p>",
    'no': "<b>Visualiser og husk</b><p>Plasser huskekoden et eller annet sted i ansiktet som skiller seg ut. Kanskje korset kan brukes til å gre håret til Kristian bakover eller at det sitter fast i en pose under øyet?</p>"
  },
  {
    'id': 'namesys-getstart',
    'en': "Get started",
    'no': "Kom i gang"
  },
  {
    'id': 'namesys-practice-names',
    'en': "Practice names from ",
    'no': "Praktiser navn fra "
  },
  {
    'id': 'sidebar-nomission',
    'en': "You don't have any active missions. Go the mission page to select your next journey.",
    'no': "Du har ingen aktive oppdrag. Gå til oppdragene for å lære husketeknikkene steg for steg."
  },
  {
    'id': 'sidebar-belt-title',
    'en': "Claim your next belt!",
    'no': "Få ditt neste belte!"
  },
  {
    'id': 'sidebar-belt-subtitle',
    'en': "You need [0] more game levels to get the [1].",
    'no': "Du trenger [0] flere spillnivåer for å få [1]."
  },
  {
    'id': 'sidebar-viewbelt',
    'en': "View your belts",
    'no': "Se beltene dine"
  },
  {
    'id': 'doublesystutorial-intro',
    'en': "To remember long numbers all you have to do is create visual stories out of Person - Action - Object that we just learnt.",
    'no': "For å huske lange tall trengere du kun å lage visuelle historierer av Personene - Handlingene - Objektene som du nettopp har lært."
  },
  {
    'id': 'doublesystutorial-letstest',
    'en': "Let's do a test",
    'no': "La oss ta en test"
  },
  {
    'id': 'doublesystutorial-memorizenumber',
    'en': "Here's how you memorize the number",
    'no': "Slik memoriserer nummeret"
  },
  {
    'id': 'doublesystutorial-combinePOA',
    'en': "Combine Person-Action-Object into a funny story",
    'no': "Kombiner Person-Handling-Objekt til en morsom historie"
  },
  {
    'id': 'doublesystutorial-visualize',
    'en': "Visualize Albert Einstein doing a golf swing with a candle!",
    'no': "Visualiser at Albert Einstein gjør en golf-swing med et stearinlys."
  },
  {
    'id': 'doublesystutorial-person',
    'en': "<p>Person</p><b>Einstein</b>",
    'no': "<p>Person</p><b>Einstein</b>"
  },
  {
    'id': 'doublesystutorial-action',
    'en': "<p>Action</p><b>Golf swing</b>",
    'no': "<p>Handling</p><b>Golf swing</b>"
  },
  {
    'id': 'doublesystutorial-object',
    'en': "<p>Object</p><b>Candle</b>",
    'no': "<p>Objekt</p><b>Stearinlys</b>"
  },
  {
    'id': 'doublesystutorial-addlocation',
    'en': "Add a location to the story",
    'no': "Legg til et lagringssted til historien"
  },
  {
    'id': 'doublesystutorial-visualizelocation',
    'en': "Now, visualize Albert Ainstein doing a golf swing with a candle - on the moon! Afterwards, when you want to remember this 6-digit number, simply visualize what's going on on the moon and your mental movie scene will symbolize the digits.",
    'no': "Nå, visualiser at Albert Ainstein gjør en golf-swing med et stearinlys - på månen! Når du etterpå ønsker å huske dette 6-sifrete nummeret trenger du kun å se for deg hva som skjer på månen, så vil din mentale filmscene symbolisere sifrene."
  },
  {
    'id': 'doublesystutorial-location1',
    'en': "Location",
    'no': "Sted"
  },
  {
    'id': 'doublesystutorial-suggestlocation',
    'en': "Example locations",
    'no': "Eksempler for lagringssteder"
  },
  {
    'id': 'doublesystutorial-showsolution',
    'en': "Show answer",
    'no': "Vis svaret"
  },
  {
    'id': 'doublesystutorial-test',
    'en': "Test if you remember",
    'no': "Test om du husker"
  },
  {
    'id': 'doublesystutorial-starttest',
    'en': "Start test",
    'no': "Start testen"
  },
  {
    'id': 'doublesystutorial-othernumber',
    'en': "Continue with another number",
    'no': "Fortsett med et annet tall"
  },
  {
    'id': 'doublesystutorial-poa',
    'en': "P A O",
    'no': "P H O"
  },
  {
    'id': 'locationname-1',
    'en': "Moon",
    'no': "Månen"
  },
  {
    'id': 'memory-improvement',
    'en': "Great, you improved your memory by [x]%",
    'no': "Bra, du forbedret hukommelsen din med [x]%"
  },
  {
    'id': 'nextbelt-levels',
    'en': "For next belt you need:",
    'no': "For neste belte må du:"
  },
  {
    'id': 'belt-callout-header',
    'en': "Becoming a Black Belt",
    'no': "Få svart belte"
  },
  {
    'id': 'belt-callout-content',
    'en': "You did amazing job with your first mission. Now let's go and apply those techniques you've learned to complete your level and get closer to a black belt.",
    'no': "Du gjorde en fantastisk jobb med ditt første oppdrag. Nå kan du bruke de samme teknikkene du har lært for å gjøre ferdig nivået slik at du kommer nærmere ditt svarte belte."
  },
  {
    'id': 'choose-country',
    'en': "By choosing your country you changing the Social star game, <br>by viewing more names of your country.",
    'no': "Ved å velge ditt land endrer du Social Star Game, <br> ved å vise flere navn i ditt land."
  },
  {
    'id': 'choose-country-title',
    'en': "Choos your country",
    'no': "Velg land"
  },
  {
    'id': 'tip-combine-systems',
    'en': "Tip: Combine these systems to master the game",
    'no': "Tips: Kombiner disse systeme for å mestre spillet"
  },

  // onboarding-popup
  {
    'id': 'onboarding_msg1',
    'en': 'Get Full Access to MemoGenius',
    'no': 'Få full tilgang til MemoGeni'
  },
  {
    'id': 'onboarding_msg2',
    'en': 'Improve your memory with proven techniques<br> &ndash; while exercising, commuting or taking a break.',
    'no': 'Få bedre hukommelse med ekte teknikker<br> &ndash; mens du trener, pendler eller tar en pause.'
  },
  {
    'id': 'onboarding_memogenius_games',
    'en': '8 games',
    'no': '8 spill'
  },
  {
    'id': 'onboarding_memogenius_systems',
    'en': '12 systems',
    'no': '12 systemer'
  },
  {
    'id': 'onboarding_memogenius_missions',
    'en': '50 missions',
    'no': '50 oppdrag'
  },
  {
    'id': 'onboarding_msg3',
    'en': 'Unlock you memory power and<br> <span class="bold">get access to all brain improvement apps!</span>',
    'no': 'Bli et et huskegeni og få tilgang til alle Memolifes<br> <span class="bold">apps for hjernetrening og akselerert læring!</span>'
  },
  {
    'id': 'onboarding_button_label',
    'en': 'Become a memory genius now',
    'no': 'Bli et huskegeni nå'
  }
];

window.systemNames = {
  'en': {
    'doublesys':   'Memolife Double system',
    'singlesys':   'Memolife Single system',
    'namesys':     'Name system',
    'journeysys':  'Journey system',
    'rhymesys':    'Rhyme system',
    'alphabetsys': 'Alphabet system'
  },
  'no':{
    'doublesys': 'Memolife Dobbel-systemet',
     'singlesys': 'Memolife Singel-systemet',
     'namesys': 'Navne-systemet',
     'journeysys': 'Reise-systemet',
     'rhymesys': 'Rim-systemet',
     'alphabetsys': 'Alfabet-systemet'
   }
};
  
window.vocabLanguages = {
  'en': {
    'Klingon': 'Klingon',
    'Nadsat': 'Nadsat',
    'Elvish': 'Elvish',
    'Na’vi': 'Na’vi'
  },
  'no': {
    'Klingon': 'Klingon',
    'Nadsat': 'Nadsat',
    'Elvish': 'Alvisk',
    'Na’vi': 'Na’vi'
  }
};
