// defines the score ranges for a level...
var levelMap = {
    'x': [],
    'superm0': [
        {'score': 0, 'items': 10, 'item_time': 3, 'item_test_time': 4.5, 'quantity': 0, 'price': 0, 'star_time_per_item': 4, 'three_star_time_per_item': 6},
        {'score': 0, 'items': 20, 'item_time': 1000000000, 'item_test_time': 1000000000, 'quantity': 0, 'price': 0, 'star_time_per_item': 4, 'three_star_time_per_item': 6}
    ],
    'vocab': [
      // #score(0), #Items(1), #itemTime(2), #itemTestTime(3), #next(4), #2-star time per item, #3-star
      [0,3,12,12,0,9,6],
      [0,5,12,12,0,9,6],
      [0,10,12,12,1,9,6],
      [0,15,12,12,1,9,6],
      [0,20,12,12,1,9,6],
      [0,20,12,12,1,9,6],
      [0,25,12,12,1,9,6],
      [0,30,12,12,1,9,6],
      [0,35,12,12,1,9,6],
      [0,40,12,12,1,9,6],
      [0,45,12,12,1,9,6],
      [0,50,12,12,1,9,6]
    ],
    'time': [
      // #score(0), #Items(1), #itemTime(2), #itemTestTime(3), #next(4), #2-star time per item, #3-star
      [0,4,36,36,0,27,18],
      [0,6,36,36,0,27,18],
      [0,8,48,48,1,36,24],
      [0,12,48,48,1,36,24],
      [0,16,48,48,1,36,24],
      [0,20,60,60,1,45,30],
      [0,24,60,60,1,45,30],
      [0,28,60,60,1,45,30],
      [0,28,60,60,1,45,30],
      [0,32,60,60,1,45,30],
      [0,36,60,60,1,45,30],
      [0,40,60,60,1,45,30]
    ],
    'superm': [
      // #score(0), #Items(1), #itemTime(2), #itemTestTime(3), #quantity(4), #price(5), #2-star time per item, #3-star
      {'score': 0, 'items': 10, 'item_time': 10, 'item_test_time': 10, 'quantity': 0, 'price': 0, 'star_time_per_item': 8, 'three_star_time_per_item': 5},
      {'score': 0, 'items': 15, 'item_time': 10, 'item_test_time': 10, 'quantity': 0, 'price': 0, 'star_time_per_item': 8, 'three_star_time_per_item': 5},
      {'score': 0, 'items': 20, 'item_time': 10, 'item_test_time': 10, 'quantity': 0, 'price': 0, 'star_time_per_item': 8, 'three_star_time_per_item': 5},
      {'score': 0, 'items': 25, 'item_time': 10, 'item_test_time': 10, 'quantity': 0, 'price': 0, 'star_time_per_item': 8, 'three_star_time_per_item': 5},
      {'score': 0, 'items': 30, 'item_time': 10, 'item_test_time': 10, 'quantity': 0, 'price': 0, 'star_time_per_item': 8, 'three_star_time_per_item': 5},
      {'score': 0, 'items': 20, 'item_time': 20, 'item_test_time': 20, 'quantity': 1, 'price': 0, 'star_time_per_item': 16, 'three_star_time_per_item': 10},
      {'score': 0, 'items': 25, 'item_time': 20, 'item_test_time': 20, 'quantity': 1, 'price': 0, 'star_time_per_item': 16, 'three_star_time_per_item': 10},
      {'score': 0, 'items': 30, 'item_time': 20, 'item_test_time': 20, 'quantity': 1, 'price': 0, 'star_time_per_item': 16, 'three_star_time_per_item': 10},
      {'score': 0, 'items': 25, 'item_time': 30, 'item_test_time': 30, 'quantity': 1, 'price': 1, 'star_time_per_item': 24, 'three_star_time_per_item': 15},
      {'score': 0, 'items': 30, 'item_time': 30, 'item_test_time': 30, 'quantity': 1, 'price': 1, 'star_time_per_item': 24, 'three_star_time_per_item': 15},
      {'score': 0, 'items': 35, 'item_time': 30, 'item_test_time': 30, 'quantity': 1, 'price': 1, 'star_time_per_item': 24, 'three_star_time_per_item': 15},
      {'score': 0, 'items': 40, 'item_time': 30, 'item_test_time': 30, 'quantity': 1, 'price': 1, 'star_time_per_item': 24, 'three_star_time_per_item': 15}
    ],
    'bankv': [
      // #score, #digits(1), #itemTime, #itemTestTime, #secondRing, #innerDigits, #pauseBefore ring2, #pauseBefore digits1-2, #pauseBefore digits3-4, #2-star, #3-star
       [0,5,2,5,0,0,1,1,0,3.5,2.5],    // 1.1
       [0,8,2,5,0,0,1,1,0,3.5,2.5],    // 1.2
       [0,16,10,5,1,0,1,1,0,3.5,2.5],   // 2.1
       [0,26,10,5,1,0,0,0,0,3.5,2.5],   // 2.2
       [0,36,10,5,1,0,0,0,0,3.5,2.5],   // 2.3
       [0,48,20,5,1,2,0,1,1,3.5,2.5],   // 3.1
       [0,60,20,5,1,2,0,0,0,3.5,2.5],   // 3.2
       [0,72,20,5,1,2,0,0,0,3.5,2.5],   // 3.3
       [0,84,30,5,1,4,0,1,1,3.5,2.5],   // 4.1
       [0,96,30,5,1,4,0,0,0,3.5,2.5],   // 4.2
       [0,108,30,5,1,4,0,0,0,3.5,2.5],  // 4.3
       [0,120,30,5,1,4,0,0,0,3.5,2.5]   // 4.4
    ],
    // #score, #Items, #itemTime, #itemTestTime, #difficult, #color
    'library': [
      [0,3,12,12,0,0,9,6],
      [0,5,12,12,0,0,9,6],
      [0,10,12,12,1,0,9,6],
      [0,15,12,12,1,0,9,6],
      [0,20,12,12,1,0,9,6],
      [0,20,24,24,1,3,18,12],
      [0,25,24,24,1,3,18,12],
      [0,30,24,24,1,3,18,12],
      [0,35,24,24,1,6,18,12],
      [0,40,24,24,1,6,18,12],
      [0,45,24,24,1,6,18,12],
      [0,50,24,24,1,6,18,12]    
    ],
    //#score, #totalNeeds, #itemTime, #itemTestTime, #totalComments, #deadline, #people
    'meeting': [
        [0, 3,15,15, 0, 0, 3, 12, 9],
        [0, 4,15,15, 0, 0, 3, 12, 9],
       [0, 5,15,15, 2, 0, 4, 12, 9],
       [0, 7,15,15, 3, 0, 4, 12, 9],
       [0, 9,15,15, 4, 0, 4, 12, 9],
       [0, 10,15,15, 5, 0, 5, 12, 9],
       [0, 12,15,15, 6, 0, 5, 12, 9],
       [0, 14,15,15, 7, 0, 5, 12, 9],
       [0, 14,15,15, 0, 1, 6, 12, 9],
       [0, 16,15,15, 0, 1, 6, 12, 9],
       [0, 18,15,15, 0, 1, 6, 12, 9],
       [0, 20,15,15, 0, 1, 6, 12, 9]
    ],
    // #score, #Items, #itemTime, #itemTestTime, #surname, #profession, #hobbies
    'face': [
      [0,4,12,12,0,0,0,9,6],
      [0,6,12,12,0,0,0,9,6],
      [0,8,24,24,1,0,0,18,12],
      [0,12,24,24,1,0,0,18,12],
      [0,16,24,24,1,0,0,18,12],
      [0,20,36,36,1,1,0,27,18],
      [0,24,36,36,1,1,0,27,18],
      [0,28,36,36,1,1,0,27,18],
      [0,28,48,48,1,1,1,36,24],
      [0,32,48,48,1,1,1,36,24],
      [0,36,48,48,1,1,1,36,24],
      [0,40,48,48,1,1,1,36,24]    
    ],
    // #score, #Items, #itemTime, #itemTestTime, #maxLevel, #images, #doubleWords, #2-star, #3-star, #animateTime, #timeNewWord, #mainBranches
    'mindmap': [
       [0,5,12,10,3,0,0,9,6,2,3,0],
       [0,7,12,10,3,0,0,9,6,2,3,0],
       [0,10,12,10,3,0,0,9,6,2,3,0],
       [0,15,12,10,3,0,0,9,6,2,3,5],
       [0,20,12,10,4,0,0,9,6,2,3,6],
       [0,20,12,10,4,5,0,9,6,2,3,6],
       [0,25,12,10,4,6,0,9,6,2,3,7],
       [0,30,12,10,4,7,0,9,6,2,3,7],
       [0,35,12,10,4,8,10,9,6,2,3,8],
       [0,40,12,10,4,9,13,9,6,2,3,8],
       [0,45,12,10,4,10,16,9,6,2,3,9],
       [0,50,12,10,4,11,20,9,6,2,3,10]       
    ],
  };
var levelHeaders = {
  'superm': ['', '#Items', '#itemTime', '#itemTestTime', '#quantity', '#price', '#2-star remaining time', '#3-star remaining time'],
  'bankv': ['', '#digits', '#itemTime', '#itemTestTime', '#innerRing', '#innerScreenDigits', '#pauseBefore ring2', '#pauseBefore digits1-2', '#pauseBefore digits3-4', '#2-star remaining time', '#3-star remaining time'],
  'library': ['', '#Items', '#itemTime', '#itemTestTime', '#difficult', '#color', '#2-star remaining time', '#3-star remaining time'],
  'meeting': ['', '#totalNeeds', '#itemTime', '#itemTestTime', '#totalComments', '#deadline', '#people', '#2-star remaining time', '#3-star remaining time'],
  'face': ['', '#Items', '#itemTime', '#itemTestTime', '#surname', '#profession', '#hobbies', '#2-star remaining time', '#3-star remaining time'],
  'mindmap': ['', '#Items', '#itemTime', '#itemTestTime', '#maxLevel', '#images', '#doubleWords', '#2-star remaining time', '#3-star remaining time', '#animateTime', '#timeNewWord','#mainBranches'],
  'time': ['', '#Items', '#itemTime', '#itemTestTime', '#next', '#2-star remaining time', '#3-star remaining time'],
  'vocab': ['', '#Items', '#itemTime', '#itemTestTime', '#next', '#2-star remaining time', '#3-star remaining time']
}
var lifearea= {
  'superm0': 'test3',
  'superm': 'test3',
  'bankv': 'test2',
  'library': 'test',
  'meeting': 'test',
  'face': 'test',
  'mindmap': 'test',
  'time': 'test',
  'vocab': 'test'
}

var gameNames = ['bankv', 'superm', 'face', 'library', 'mindmap', 'meeting', 'vocab', 'time'];
var gameFullnames = {
  'en': {'bankv' : 'Bank Vault', 'superm' : 'Shopping cart', 'face' : 'Social Star', 'library' : 'Brainstorming', 'mindmap' : 'Curriculum', 'meeting' : 'Meeting Bliss', 'vocab' : 'Vocab Guru', 'time' : 'Time Travel'},
  'no': {'bankv' : 'Bankhvelvet', 'superm' : 'Handlelisten', 'face' : 'Navnemester', 'library' : 'Brainstorming', 'mindmap' : 'Lynpensum', 'meeting' : 'Møtelykke', 'vocab' : 'Gloseguru', 'time' : 'Tidsreise'}
};
var beltNames={
  'en': ['trainee', 'white','yellow','orange','light green','dark green','light blue','dark blue','light brown','dark brown','red','black'],
  'no': ['nybegynner', 'hvitt', 'gult', 'oransje', 'lysegrønt', 'mørkegrønt', 'lyseblått', 'mørkeblått', 'lysebrunt', 'mørkebrunt', 'rødt', 'svart']
};
var beltNamesColor={
  'en': ['trainee', 'white','yellow','orange','light green','dark green','light blue','dark blue','light brown','dark brown','red','black'],
  'no': ['nybegynner', 'hvite', 'gule', 'oransje', 'lysegrønne', 'mørkegrønne', 'lyseblå', 'mørkeblå', 'lysebrune', 'mørkebrune', 'røde', 'svarte']
};

var beltMap = {
//vault shopping  names brainstorm  curriculum  meeting vocab time
0:[0,0,0,0,0,0,0,0],
1:[1,1,1,0,0,0,0,0],
2:[3,3,3,3,0,0,3,0],
3:[4,4,4,4,4,0,4,4],
4:[5,5,5,5,5,5,5,5],
5:[6,6,6,6,6,6,6,6],
6:[7,7,7,7,7,7,7,7],
7:[8,8,8,8,8,8,8,8],
8:[9,9,9,9,9,9,9,9],
9:[10,10,10,10,10,10,10,10],
10:[11,11,11,11,11,11,11,11],
11:[12,12,12,12,12,12,12,12]
}


