function verifyDoubleSys(e, ui) {
	var target = $(e.target),
		  dragItem = ui.draggable;

	itemId = dragItem.data('id');

	if(itemId == target.data('id')) {
		playSoundAction('music-correct');
		target.removeClass('wrong').addClass('correct');
		target.find('.vertical').text(target.data('text'));
		target.find('img').attr('src', '/images/'+target.data('id')+'.png');
		$('.option-'+itemId).remove();
		showPageItems($('#doublesys .shopping-items'), DOUBLESYS_PAGESIZE);
		if($('.system-test-container .shopping-items li').length == 0){
			showNextSysDoublePractice();
		}
	} else {
		playSoundAction('music-wrong');
		target.addClass('wrong');
		dragItem.addClass('wrong');
		dragItem.find('span').text(5);
		dragEnd(dragItem);
	}
}

function showDoublesysExample() {
	"use strict";
  // example guide for New User


	$('[class*=dbldata-]').each(function() {
		var item = $(this);
		var clazz = item.attr('class');
		var d = clazz.split("-");
		var code = d[1];
		var element = d[2];

		/* Iterate over doublesys data and populate the screens */
		for (var i in window.doublesys_data) {
			if (window.doublesys_data.hasOwnProperty(i)) {
				for (var j=0;j<window.doublesys_data[i].length; j++) {
					if (window.doublesys_data[i][j].number === code) {
						var txt = window.doublesys_data[i][j][lang][element];
						$("."+clazz).html(txt);
						break;
					}
				}
			}
		}
	});

	$('.doublesys-example.example1 .slide1').show();
	$('.doublesys-example.example2 .slide1').show();
  showPanel('doublesysexample');
}


function showDoubleSysCategories(){
  
	$('#doublesyscategories').html(TemplateEngine($('#tpl-doublesys-categories').html(),{}));
	showPanel('doublesyscategories');
  



  $("#pieChart").drawPieChart([
    { title: "BRAIN",     value: 1, color: "#d1703b" },
    { title: "KNOWLEDGE", value: 1, color: "#6899f0" },
    { title: "MIND",      value: 1, color: "#efc151" },
    { title: "BODY",      value: 1, color: "#f5d34e" },
    { title: "SOUL",      value: 1, color: "#f7ea59" },
    { title: "CARRER",    value: 1, color: "#fbef76" },
    { title: "LEISURE",   value: 1, color: "#fdfa9e" }
  ]);

  $("#pieChart2").drawPieChart([
    { title: "code1", value: 1, color: "#93c60b" }
  ]);
}

function getSystem(codeIndex){
  if (codeIndex <= 9)       return 'brain';
  else if (codeIndex <= 32) return 'knowledge';
  else if (codeIndex <= 51) return 'mind';
  else if (codeIndex <= 66) return 'body';
  else if (codeIndex <= 78) return 'soul';
  else if (codeIndex <= 88) return 'career';
  else if (codeIndex <= 99) return 'leisure';
}

function showSysDoublePractice(params, index){
	if (window.gamestate.data.firsttime_doublesys === undefined) {
		window.gamestate_utils.set({'firsttime_doublesys': true});
		showDoublesysExample();
		window.backup_params = params;
		return;
	}

	params = params || window.backup_params; // In case we are coming back from the doublesys example tutorial
	missionParams = params;
  if(params) {
    missionSystem = [];
    for(var i = params.min; i <= params.max; i++) {
      missionSystem.push({
        system: getSystem(i),
        systemCodeIndex: i
      });
    }

    if(params.system2) {
      for(var i=0; i<=params.randomItems ; i++) {
        var codeIndex = parseInt(Math.random()*(params.max2-params.min2)+1+params.min2);

        missionSystem.push({
          system: getSystem(codeIndex),
          systemCodeIndex: codeIndex
        });
      }
    }
  } else { 
    systemIndex = index || 0;
    system = systems[systemIndex];
  }

	showPracticeNotice('a');
	$('.doublesys-test, .doublesys-practice .system-learn').empty()
	showPanel('doublesys');
	$('#doublesys').removeClass('cat-1 cat-2 cat-3 cat-4 cat-5 cat-6 cat-7').addClass('cat-'+(systemIndex+1));
	systemCodeIndex = -1;
	setTimeout(showNextSysDoublePractice, 3000);
}

function showNextSysDoublePractice(){
	systemCodeIndex += 1;
	if(!missionParams && systemCodeIndex >= doublesys_data[system].length) {
		systemCodeIndex = -1;
		showDoublesysTutorial();
		gameEnd = true;
		return;
	}

	var life_area = null;
  if(missionParams) {
    life_area = doublesys_data[missionSystem[systemCodeIndex].system][systemCodeIndex];
  } else {
    life_area = doublesys_data[system][systemCodeIndex];
  }
	if(systemCodeIndex % 4 == 0)
		$('.doublesys-practice').html('<div class="system-learn">'+TemplateEngine($('#tpl-doublesys-practice').html()+'</div>', {life_area: life_area}));
	else
		$('.doublesys-practice .system-learn').html(TemplateEngine($('#tpl-doublesys-practice').html(), {life_area: life_area}));

	$('#doublesys .doublesys-test').hide();
	$('#doublesys .doublesys-practice').show();

	setTimeout(function(){
		$('.doublesys-practice .box, .doublesys-practice .top h1 ,.doublesys-practice .top p').addClass('preview');
	},1000);
  translate();
}

function showNextSysDoublePracticeOrTest(){
	if((systemCodeIndex % 4 == 3) || systemCodeIndex == (doublesys_data[system].length-1)) {
		$('#doublesys .doublesys-test .drop-places, #doublesys .doublesys-test  .dock-items').empty();
		$('#doublesys .doublesys-practice').hide();

		showPracticeNotice('b');
		gameEnd = false;
		gameTime = 0;
		setTimeout(function(){
			showNextSysDoubleTest();
			$('#doublesys .doublesys-test').show();
			updateClockUp();
		}, 3000);
	} else {
		showNextSysDoublePractice();
	}
}

function showNextSysDoubleTest(){
	var life_area, options = [];

	$('.doublesys-test').html(TemplateEngine($('#tpl-doublesys-test').html(),{}));

	for(var i=Math.floor(systemCodeIndex / 4) * 4; i< Math.floor(systemCodeIndex / 4) * 4 + 4 && i < doublesys_data[system].length; i++) {
		life_area = window.doublesys_data[system][i];

		$('#doublesys .drop-places').append(
				'<li class="">'+
				  '<div class="system-box">'+
					'<div class="close"></div>'+
					'<div class="header">'+ life_area['number'] + '</div>'+
					'<div class="body">'+
					  '<div class="item" data-id="object'+life_area['number']+'" data-text="'+life_area[lang]['object_code']+'">'+
						'<div class="img-holder pull-left">'+
						  '<span class="number">'+life_area['number']+'</span>'+
						  '<img>'+
						'</div>'+
						'<div class="text">'+
						  '<div class="vertical">'+
						  '<span class="msg-system_memolife_duoble_04">Object</span>'+
						  '</div>'+
						'</div>'+
					  '</div>'+
					  '<div class="item" data-id="person'+life_area['number']+'" data-text="'+life_area[lang]['person_code']+'">'+
						'<div class="img-holder pull-left">'+
						  '<span class="number">'+life_area['number']+'</span>'+
						  '<img>'+
						'</div>'+
						'<div class="text">'+
						  '<div class="vertical">'+
						  '<span class="msg-system_memolife_duoble_05">Person</span>'+
						  '</div>'+
						'</div>'+
					  '</div>'+
					  '<div class="item" data-id="action'+ life_area['number'] +'" data-text="'+life_area[lang]['action_code']+'">'+
						'<div class="img-holder pull-left">'+
						  '<span class="number">'+life_area['number']+'</span>'+
						  '<img>'+
						'</div>'+
						'<div class="text">'+
						  '<div class="vertical">'+
						  '<span class="msg-system_memolife_duoble_06">Action</span>'+
						  '</div>'+
						'</div>'+
					  '</div>'+
					'</div>'+
				  '</div>'+
				'</li>'
		);
		options.push('person'+life_area['number']);
		options.push('object'+life_area['number']);
		options.push('action'+life_area['number']);
	}

	options.sort(function(){ return Math.random() - 0.5; });

	for(var i=0; i<options.length; i++) {
		$('#doublesys .shopping-items').append(
			'<li class="drag hide option-'+options[i]+'" data-id="'+options[i]+'"><span></span><img src="/images/'+options[i]+'.png"></li>'
		);
	}

	showPageItems($('#doublesys .shopping-items'), DOUBLESYS_PAGESIZE);

	$('#doublesys .drop-places .item').droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
	$('#doublesys .shopping-items .drag').draggable({ cancel: ".wrong" });

	var _itemW = $('li.drag').outerWidth()+10;
	$('#doublesys .shopping-items').css('text-align', 'left').css('width', _itemW*options.length);
  
  translate('doublesys');
}

function showDoublesysTutorial(){
	hideAllPanels();
	$('#doublesystutorial').html(TemplateEngine($('#tpl-doublesys-tutorial').html(),{}));
	translate('doublesystutorial');
	$('#doublesystutorial .catx').hide();
	$('#doublesystutorial .catx.cat-'+system).show();
  
	var codes = doublesys_data[systems[systemIndex]];
	var options = [], selected=[], digits = '';
	for(var i=0; i<codes.length; i++) { options.push(i); }
	for(var i=0; i<9; i++) {
		var rand = parseInt(Math.random()*options.length);
		selected.push(options[rand]);
	}

	var digits_6 = ""+codes[selected[0]].number + " " + codes[selected[1]].number + " " + codes[selected[2]].number + " ";
	var digits_12 = ""+codes[selected[3]].number + " " + codes[selected[4]].number + " " + codes[selected[5]].number + " ";


	$('.msg-st1').html(getMSG('st1').replace('[user]', user.first + ' ' + user.last));
	$('.digits-6').text(digits_6);
	$('.digits-12').text(digits_12);
	$('.digits-61').text(codes[selected[0]].number);
  $('.digits-62').text(codes[selected[1]].number);
  $('.digits-63').text(codes[selected[2]].number);
	$('.digits-6p').text(codes[selected[0]][lang].person_code);
  $('.digits-6a').text(codes[selected[1]][lang].action_code);
  $('.digits-6o').text(codes[selected[2]][lang].object_code);
  $('.digits-6pimg').attr('src', '/images/person' + codes[selected[0]].number + '.png');
  $('.digits-6aimg').attr('src', '/images/action' + codes[selected[1]].number+ '.png');
  $('.digits-6oimg').attr('src', '/images/object' + codes[selected[2]].number+ '.png');
	$('.digits-121').text(codes[selected[3]].number);
  $('.digits-122').text(codes[selected[4]].number);
  $('.digits-123').text(codes[selected[5]].number);
	$('.digits-12p').text(codes[selected[3]][lang].person_code);
  $('.digits-12a').text(codes[selected[4]][lang].action_code);
  $('.digits-12o').text(codes[selected[5]][lang].object_code);
  $('.digits-12pimg').attr('src', '/images/person' + codes[selected[3]].number + '.png');
  $('.digits-12aimg').attr('src', '/images/action' + codes[selected[4]].number+ '.png');
  $('.digits-12oimg').attr('src', '/images/object' + codes[selected[5]].number+ '.png');
	$('#doublesystutorial .location-1').attr('src', '/images/location1-'+system+'.jpg');
	$('#doublesystutorial .location-2').attr('src', '/images/location2-'+system+'.jpg');
	$('#doublesystutorial .locationname-1').text(doublesysLocation[lang][system][0]);
	$('#doublesystutorial .locationname-2').text(doublesysLocation[lang][system][1]);

	showPanel('doublesystutorial');
}

function showDoublesysTutorialTest(){
	gameEnd = false;
	gameTime = 0;
	updateClockUp();
	hideAllPanels();
	$('.doublesys-tutorial-test').removeClass('hide');
	$('.doublesys-tutorial-test .opaque-test').addClass('show');
  $('.doublesys-tutorial-test .opaque-test-result').removeClass('show');
  $('.doublesys-input').empty();
}

function startDoublesysTest(){
	gameEnd = true;
	$('.num-answer').text('');
	$('.doublesys-tutorial-test .opaque-test').addClass('show');
  $('.doublesys-tutorial-test .opaque-test-result').removeClass('show');
  $('.doublesys-input').empty();
}

function testAnotherDoublesysNumber() {
	"use strict";
	showDoublesysTutorial();
	$('.tutorial-double > div').hide();
	$('.tutorial-double .slide3').show();
}

function verifyDoublesysTutorialTest(){
	var sol =$('.digits-6').text()+$('.digits-12').text()+$('.digits-18').text(),
			ans = $('.doublesys-input').text(),
			correct = 0;
	sol = sol.replace(/\s/g, "");

	for(var i=0; i<sol.length; i++) {
		if(sol[i] === ans[i]) {
			correct += 1;
		}
	}

	$('.doublesys-tutorial-test h1').hide();
	gameTime = 0;

	if(correct === sol.length) {
		$('.doublesys-tutorial-test .msg-system_result_01').show();		// show success test
		$('.doublesys-tutorial-test .msg-learn_again').hide();				// hide repeat level
		$('.doublesys-tutorial-test .msg-system_result_05').show();		// show play next level
	} else {
		$('.doublesys-tutorial-test .msg-system_result_03').show();		// show failure text
		$('.doublesys-tutorial-test .msg-learn_again').show();				// show repeat level
		$('.doublesys-tutorial-test .msg-system_result_05').hide();  	// hide play next level
	}
	var _msg = $('.doublesystutorial-result').text();
	$('.doublesystutorial-result').text(_msg.replace('[x]',correct).replace('[y]', sol.length));
	$('.doublesys-tutorial-test .opaque-test-result').addClass('show');
}
