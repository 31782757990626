var currentJourney = {'object': null}
var currentStep = null;
var firstStepCompleted = {val: false}

var mobileState = {
	intro: true,
	journeys: false,
	steps: false
}

function isFirstStepCompleted() {
	var journey = currentJourney.object;
	if (journey) {
		return (journey.items.length > 0 && journey.items[0].img !== '' && journey.items[0] !== '')
	}
	return false;
}

function listJourneys() {
	currentJourney.object = null;
	firstStepCompleted.val = isFirstStepCompleted();
}

function createJourneyStep(){
	currentJourney['object'].items.push({'img': "", 'text': ""})
	firstStepCompleted.val = isFirstStepCompleted();
	window.gamestate_utils.set({'journeys': window.gamestate.data.journeys});
}

function deleteJourneyStep(e, v) {
	currentJourney.object.items.splice([v.index], 1);
	firstStepCompleted.val = isFirstStepCompleted();
	window.gamestate_utils.set({'journeys': window.gamestate.data.journeys});
}

function deleteJourney(e, v) {
	window.gamestate.data.journeys.splice([v.index], 1);
	window.gamestate_utils.set({'journeys': window.gamestate.data.journeys});
	currentJourney.object = null;
	firstStepCompleted.val = false;
}

function changeImage(e, v) {
	console.log("change image");
	currentStep = v.i;
	console.log(currentStep);
	$('#input-image').click();
}

function uploadImage() {
	console.log("Uploading");
	if (currentJourney['object']) {
		var fd = new FormData()
		fd.append('file', $('#input-image')[0].files[0]);
		$.ajax({
			url: "https://mboy.herokuapp.com/upload",
			type: "POST",
			data: fd,
			processData: false,
			contentType: false,
			success: function(data) {
				console.log("setting image", data);
				currentStep.img = data.filename.slice(1);
				if (currentJourney.object.img === "") {
					currentJourney.object.img = data.filename.slice(1);
				}
				window.gamestate_utils.set({'journeys': window.gamestate.data.journeys});
			}
		})
	}
}

function showJourney(e, v) {
	console.log("showJourney");
	currentJourney.object = v.j;
	firstStepCompleted.val = isFirstStepCompleted();
}

function addJourney(e, v) {
	console.log("Adding new journey");
	var j = {
		'name': getMSG("system_journey_new_journey_name"),
		'img':  '',
		'items': [{'img': "", 'text': getMSG("system_journey_new_photo_name")}]
	};
	window.gamestate.data.journeys.push(j);
	window.gamestate_utils.set({'journeys': window.gamestate.data.journeys});
	currentJourney.object = j;
	mobileState.intro = false;
	mobileState.journeys = false;
	mobileState.steps = true;
}

function journeyChanged(e, v) {
	window.gamestate_utils.save();
	firstStepCompleted.val = isFirstStepCompleted();
}

function mobileStart(e, v) {
	mobileState.intro = false;
	mobileState.journeys = true;
	mobileState.steps = false;
	currentJourney.object = null;
	firstStepCompleted.val = isFirstStepCompleted();
}

function mobileIntroScreen(e, v) {
	mobileState.intro = true;
	mobileState.journeys = false;
	mobileState.steps = false;
	currentJourney.object = null;
	firstStepCompleted.val = isFirstStepCompleted();
}

function mobileShowSteps(e, v) {
	mobileState.intro = false;
	mobileState.journeys = false;
	mobileState.steps = true;	
	currentJourney.object = v.j;
	firstStepCompleted.val = isFirstStepCompleted();
  $('#journeysys input').attr('readonly', null);
}

function showSysJourneyPractice() {
	currentJourney = {'object': null}
	currentStep = null;
	firstStepCompleted = {val: false}


	showPanel('journeysys');
	  /* TODO: perhaps use rivets components instead of reloading the DOM */
	  $('#journeysys').html($('#journeysys-template').html());

	rivets.bind($('#journeysys'), {
		default_journeys: window.journeys[lang],
		journeys: window.gamestate.data.journeys,
		currentJourney: currentJourney,
		firstStepCompleted: firstStepCompleted,
		mobileState: mobileState,
		actions: {
			'listJourneys': listJourneys,
			'showJourney': showJourney,
			'createJourneyStep': createJourneyStep,
			'deleteJourneyStep': deleteJourneyStep,
			'deleteJourney': deleteJourney,
			'uploadImage': uploadImage,
			'changeImage': changeImage,
			'addJourney': addJourney,
			'journeyChanged': journeyChanged,
			'mobileStart': mobileStart,
			'mobileIntroScreen': mobileIntroScreen,
			'mobileShowSteps': mobileShowSteps
		}
	});
}



function showTechniqueJourneyTest() {
	$('#journeytest .opaque').removeClass('show');
	showPanel('journeytest');
	systemJourneyStep = 0;
	showNextSysJourneyTest();
}

function showNextSysJourneyTest(){
	var journey = currentJourney.object;
	var journeystep = journey.items[systemJourneyStep];
	if(systemJourneyStep >= journey.items.length || (systemJourneyStep >= journey.items.length-1 && journeystep.text=='')) {
		/* Show result screen */
		var correct = journey.items.filter(function(item){ return item.correct; }).length;
		$('.spr-text').hide();

		if(correct >= journey.items.length) {
			$('.spr-text.msg-spr2').show();
		} else if(correct >= 0.7 * journey.items.length) {
			$('.spr-text.msg-spr1').show();
		} else {
			$('.spr-text.msg-spr3').show();
		}
		
		$('.msg-system_result_04').html(getMSG('system_result_04').replace('[x]',correct).replace('[y]',journey.items.length));
    $('#journeytest .opaque').addClass('show');
    if (missionParams !== undefined) {
      finished_mission_step(current_mission_step_name());
    }
	} else {
		$('.journey-test-step').text(systemJourneyStep + 1);
		$('.journey-test-text').text(journeystep.text);
		$('.journey-length').text(journey.items.length);
		
		if(journeystep.img != '') {
			$('.journey-test-img').attr('src', 'https://uploads.memolife.com/'+journeystep.img);
		} else {
			$('.journey-test-img').attr('src', '');
		}
		
		$('.journey-test .question').removeClass('hide');
		$('.journey-test .answer').addClass('hide');
		$('.journey-test .btn-answer').show();
		$('.journey-test .no, .journey-test .yes, .journey-test .btn-next').hide();
		gameTime = 0;
		gameEnd = false;
		updateClockUp();
		systemJourneyStep += 1;
	}
	showHintYesNo();
}

function showHintYesNo(){
	if(!hintYesNoPractice) {
		hintYesNoPractice = true;
		$('.msg-sp3,.msg-sp4,.msg-sp5').show();
	} else {
		$('.msg-sp3,.msg-sp4,.msg-sp5').hide();
	}
}

function showTechniqueJourneyAnswer(){
	gameEnd = true;
	var journey = currentJourney.object;
	$('.journey-test .no, .journey-test .yes').show();
	$('.journey-test .btn-answer').hide();
	$('.journey-test .question').addClass('hide');
	$('.journey-test .answer').removeClass('hide');
	showHintYesNo();
}

function showNextSysJourneyYes(){
	var journey = currentJourney.object;
	$('.tick').addClass('animate').show();
	playSoundAction('music-correct');
	journey.items[systemJourneyStep-1].correct = true;
	setTimeout(function(){
		$('.tick').removeClass('animate');
		showNextSysJourneyTest();
	}, 500);
}

function showNextSysJourneyNo(){
	var journey = currentJourney.object;
	journey.items[systemJourneyStep-1].correct = false;
	setTimeout(function(){
		showNextSysJourneyTest();
	}, 500);
}

function showLearnification(){
  currentJourney.object = window.gamestate.data.journeys[0];
  $('.learn-journeysys-1').addClass('show');
  
  $('.learn-journeysys-1 .journey-callout').css({'top':$('.system-journey .box').offset().top,'left':($('.system-journey .box').offset().left+$('.system-journey .box').width())});
}

function hideLearnification(){
  $('.learnification').removeClass('show');
}

function systemContinue() {
	if (missionParams !== undefined) {
		loadMission();
	} else {
		showPanel('mainpanel');
	}
}
