var MIND_DROP_ITEMS = isMobile ? 4 : 14;
var mapZoomScale = 100;
var mindAnswers = [];
var mindPendingAnswers = [];

function initTestMindmap(){
	currentMode = 'test';
	index = 0;
	mindAnswers = [];
	mindPendingAnswers = [];
	for(var i=0; i<nodes.length; i++) {
		mindPendingAnswers.push({pos: MIND_DROP_ITEMS, id: mindPendingAnswers.length, title: nodes[i].answer});
		if(nodes[i].answer1) {
			mindPendingAnswers.push({pos: MIND_DROP_ITEMS, id: mindPendingAnswers.length, title: nodes[i].answer1});
		}
		if(nodes[i].img) {
			mindPendingAnswers.push({pos: MIND_DROP_ITEMS, id: mindPendingAnswers.length, title: nodes[i].img, img: nodes[i].img});
			//mapModel.setIcon('drag and drop', 'http://memogym.s3.amazonaws.com/img/nodeImages/empty.png', 4, 4, 'left', nodes[i].id);
		}
		//mapModel.updateTitle(nodes[i].id,'?',false);
	}
	$('span[data-mapjs-role=title]').text('?');
	$('div[data-mapjs-role=node]').css('background-image','none').css('background','#fff');

	$('.mapjs-node').droppable({drop: drop, hoverClass: 'over', greedy: false, tolerance: 'pointer'});
	$('.map-inner-container').droppable({drop: drop, greedy: true, tolerance: 'pointer', over: dragMove});

	$('.mapjs-node').on('tap', drop);

	$('.mapjs-node').css('background-image','none').removeClass('map-pale-white map-pale-blue map-pale-darkblue map-pale-green map-pale-darkgreen map-pale-purple map-pale-darkpurple map-pale-red map-pale-yellow map-pale-orange map-gradient-white map-gradient-blue map-gradient-darkblue map-gradient-green map-gradient-darkgreen map-gradient-purple map-gradient-darkpurple map-gradient-red map-gradient-yellow map-gradient-orange');
	mindPendingAnswers.sort(function(){ return Math.random()-0.5; });
	$('#panelmindmap .game-button, #panelmindmap .pagination').hide();

	if(isMobile) $('#panelmindmap .map-panel-mobile, #panelmindmap .game-header').fadeIn();
	else $('#panelmindmap .map-panel, #panelmindmap .game-header').fadeIn();

	showLives(3);
	$('.map-panel .drop-area, .map-panel-mobile .drop-area').html('');
	if(isMobile) $('.map-inner-container').css('top','80px');
}

function initGameMindmap(){

  // We have already set gameLevel when start newGame.
  // DO NOT overwrite it here.
	///// gameLevel = getGameLevel(currentGame);
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1]-levelSettings[6];
	totalPages = 1;
	page = 0;
	pageSize = totalItems;
	itemTime = levelSettings[2];
	pageTime = 0;
	pageTimes = [];
	currentMode = 'learn';
	star2 = levelSettings[7];
	star3 = levelSettings[8];
	learnRemainTime = 0;
	penaltyTime =0;

	$('.map-inner-container').html('').css('top','0');
	$('#panelmindmap .map-panel, #panelmindmap .map-panel-mobile, #panelmindmap .game-header').hide();
	$('#panelmindmap .game-button, #panelmindmap .pagination').show();



	setTimeout(function(){
	  $('.bk-list').addClass('zoom');
	  setTimeout(function(){
		$('.bk-book').removeClass('bk-bookdefault').addClass('bk-viewinside big-zoom');
		setTimeout(function(){
		  $('.map-container').addClass('show');
			var container = jQuery('#map-inner-container'),
			idea = MAPJS.content(random_tree(totalItems, levelSettings[4])),
			imageInsertController = new MAPJS.ImageInsertController("http://localhost:4999?u="),
			mapModel = new MAPJS.MapModel(MAPJS.DOMRender.layoutCalculator, []);
			container.domMapWidget(console, mapModel, false, imageInsertController);
			mapModel.setIdea(idea);
			window.mapModel = mapModel;
			startLearnMap();
			showPages();
			setTimeout(updateClockLearn, 1500);
		},4000);
	  },1000);
	},500);

}

function verifyMindmap(e, ui) {
	var target, dragItem;
	if(e.type == 'drop') {
		target = $(e.target);
		dragItem = ui.draggable;
		itemId = dragItem.data('id');
		//dragItem.css('transition', 'all 400ms');
		//setTimeout(function(){ dragItem.css('transition', 'left 400ms'); },100);
	} else {
		target = $(e.currentTarget);
		dragItem = $('#panel'+currentGame+' .item.active');
		itemId = dragItem.data('id');
	}
	var nodeId = Math.abs(target.attr('id').split('_')[1]), _id = Math.abs(nodeId)-1;
	if(itemId == null || target==null || target.data('mapjs-role') != 'node') {
		dragEnd(dragItem);
		return;
	} else if(itemId == nodes[_id].answer || itemId == nodes[_id].answer1) {
		playSoundAction('music-correct');
		if(target.text() == '?' || target.text() == '')
			mapModel.updateTitle(nodeId, itemId +' ');
		else
			mapModel.updateTitle(nodeId, nodes[_id].answer+ ' , '+nodes[_id].answer1+ ' ');
		target.addClass(nodes[_id].color);
		dragItem.remove();
	} else if(itemId == nodes[_id].img) {
		playSoundAction('music-correct');
		mapModel.setIcon('drag and drop', 'http://memogym.s3.amazonaws.com/img/nodeImages/'+itemId, 32, 32, 'left', nodeId);
		dragItem.remove();
	} else {
		playSoundAction('music-wrong');
		target.addClass('wrong');
		dragItem.addClass('wrong').removeClass('moving');
		dragItem.find('span').text(5);
		dragEnd(dragItem);
		setTimeout(function(){ target.removeClass('wrong'); }, 500);
		checkGameOver();
		return;
	}

	for(var i=0; i<mindAnswers.length; i++)
		if(mindAnswers[i].title == itemId) {
			mindAnswers.splice(i,1);
			break;
		}

	if(!mindAnswers.length && !mindPendingAnswers.length) {
		showResult(nodes.length, nodes.length);
	}
}

function mapZoom(scale){
	if(scale == mapZoomScale) return;
	else if(scale > mapZoomScale) {
		mapZoomScale = scale;
		mapModel.scale($('body'), 10/6);
	} else {
		mapZoomScale = scale;
		mapModel.scale($('body'), 0.6);
	}
}

function moveAnswers(){
	for(var i=0; i< mindAnswers.length; i++){
		if(!$('.mind-answer-'+mindAnswers[i].id).hasClass('moving')) {
			if(isMobile)
				$('.mind-answer-'+mindAnswers[i].id).css('top', (4-Math.floor(mindAnswers[i].pos/2))*$('.mind-answer-'+mindAnswers[i].id).outerHeight());
			else
				$('.mind-answer-'+mindAnswers[i].id).css('bottom', (mindAnswers[i].pos)*$('.mind-answer-'+mindAnswers[i].id).outerHeight());
		}
		mindAnswers[i].pos = mindAnswers[i].pos > i ? mindAnswers[i].pos - 1 : mindAnswers[i].pos;
	}
}

function nextAnswer(){
	if(mindAnswers.length >= (isMobile ? 14: MIND_DROP_ITEMS)) {
		gameTime = 0;
		checkGameOver();
		return;
	}
	if(mindPendingAnswers.length) {
		playSoundAction('music-superm-action');
		var item = mindPendingAnswers.pop(), itemClass=Math.random()<0.5? 'left':'right';
		mindAnswers.push(item);
		var eventHandler = isMobile? 'ontouchend' : 'onclick';
		var dropArea = $('#panelmindmap .drop-area'+(isMobile?(Math.random()<0.5?'-left':'-right'):''))
		dropArea.append('<div '+eventHandler+'="activate(this)"  class="item mind-answer-'+item.id+'"  style="bottom:476px;" data-id="'+item.title+'"><span></span>'+(item.img? '<img style="max-width: 20px;" src="/images/'+item.img+'">' : '<div>'+item.title+'</div>')+'</div>');
		$('.item.active').removeClass('active');
		$('.mind-answer-'+item.id).draggable({ cancel: ".wrong" }).addClass('active').addClass(itemClass);
	}
}
