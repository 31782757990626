var Router = {
  routes: [
    {'url': /^\/mission\/([\w]+)\/?$/, 'fn': 'loadMission', 'args': ["$1"]},
    {'url': /^\/mission\/([\w]+)\/([0-9]+)\/?$/, 'fn': 'startupMissions', 'args': ["$1", "$2"]},
    {'url': /^\/missions-overview/, 'fn': 'showPanel', 'args': ["missions-overview-panel"]},
    {'url': /^\/journeys/, 'fn': 'showSysPractice', 'args': ['journeysys']},
    {'url': /^\/rhymesys/, 'fn': 'showSysPractice', 'args': ['rhymesys']},
    {'url': /^\/alphabetsys/, 'fn': 'showSysPractice', 'args': ['alphabetsys']},
    {'url': /^\/namesys/, 'fn': 'showSysPractice', 'args': ['namesys']}
  ],

  route: function() {
    var url = window.location.pathname;
    this.routes.forEach(function(elem) {
      if (elem.url.test(url)) {
        var apply_args = [];

        elem.args.forEach(function(a) {
          apply_args.push(url.replace(elem.url, a));
        });

        var fn = eval(elem.fn);

        console.log("routing", window.location.pathname, "->", ""+elem.fn+"("+apply_args+")");

        return fn.apply(null, apply_args);
      }
    })
  }
}
