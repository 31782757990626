var games = {
      'superm':  'Super Market',
      'bankv':   'Bank Vault',
      'library': 'Library'
    },

    dimensions = {
	    'iphone':  [620, 360],
	    'ipad':    [1024, 640],
	    'browser': [-1, -1]
    },

    isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent),

    DOUBLESYS_PAGESIZE = isMobile ? 4 : 6,
    SUPERM_PAGESIZE = 5,

    dragItemId = null,

    dragOriginPos = null,

    homeState = 'game',

    lastItemU = null,

    //firsttime = false,

    hintYesNoPractice = false,

    star2 = 0,

    star3 = 0,

    score = 0,

    diamonds = 0,

    lives = 3,

    paused = false,

    level = {
      'superm':  0,
      'superm0': 0,
      'bankv':   0,
      'library': 0,
      'meeting': 0,
      'face':    0,
      'mindmap': 0,
      'vocab':   0,
      'time':    0
    },

    gameLevel = 0,

    masterLevel = 0,

    master2 = 2,

    master3 = 5,

    master4 = 8,

    levelSettings = [],

    gamePaused = false,

    gamePractice = null,

    currentGame = 'superm',

    currentMode = 'learn',

    backToGame = null,

    userBelt = 0,

    nextBelt = -1,

    randomLibrary = [],

    wordQues = true,

    answerLibrary = [],

    meetingSpeakers = [],

    meetingSpeakersU = [],

    disNeedsU = [],

    meetingMsgs = [],

    testState = '',

    itemId = 0,

    faceSet = [],

    currentLanguage = '',

    timeSet = [],

    currentTimeType= '',

    number = '',

    digit = '',

    ring1 = '0',

    ring2 = '0',

    initSlots = false,

    names = [],

    index = 0,

    page = 0,

    gameTime = 0,

    itemTime = 0,

    pageTime = 0,

    pageTime0 = 0,

    pageTimes = [],

    learnRemainTime = 0,

    penaltyTime = 0,

    pageSize = 0,

    totalItems = 0,

    totalPages = 0,

    gameEnd = false,

    container = null,

    MSG = {},

    langs = {'en' : 0, 'no' : 1}
;
