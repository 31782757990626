"use strict";

var levelName = {
  0:  '1.1',
  1:  '1.2',
  2:  '2.1',
  3:  '2.2',
  4:  '2.3',
  5:  '3.1',
  6:  '3.2',
  7:  '3.3',
  8:  '4.1',
  9:  '4.2',
  10: '4.3',
  11: '4.4'
};

var initTest = {
  'superm': initTestSupermarket,
  'superm0': initTestSupermarket0,
  'supermgym': initTestSupermarket0,
  'bankv': initTestBankvault,
  'library': initTestLibrary,
  'meeting': initTestMeeting,
  'face': initTestFace,
  'vocab': initTestVocab,
  'time': initTestTime,
  'mindmap': initTestMindmap
};

var initGame = {
  'vocab': initGameVocab,
  'time': initGameTime,
  'superm': initGameSupermarket,
  'supermgym': initGameSupermarketGym,
  'superm0': initGameSupermarket0,
  'bankv': initGameBankvault,
  'library': initGameLibrary,
  'meeting': initGameMeeting,
  'face': initGameFace,
  'mindmap': initGameMindmap
};

var verify = {
  'superm': verifySupermarket,
  'superm0': verifySupermarket0,
  'singlesys': verifySinglesys,
  'alphabetsys': verifySinglesys,
  'rhymesys': verifySinglesys,
  'bankv': verifyBankvault,
  'library': verifyLibrary,
  'meeting': verifyMeeting,
  'face': verifyFirstname,
  'mindmap': verifyMindmap,
  'doublesys': verifyDoubleSys
};

var PopupQueue = {
  queue: [],
  current: undefined,

  push: function(addfn, removefn) {
    console.log("Added to queue");
    this.queue.push({'addfn': addfn, 'removefn': removefn});
    if (this.current === undefined) {
      this.pop();
    }
  },

  pop: function() {
    console.log("Popping the queue");
    if (this.current !== undefined) {
      this.current.removefn();
    }
    var next = this.queue.pop();
    if (next === undefined) {
      showResultScreen();
    } else {
      next.addfn();
    }
    this.current = next;
  }
};


rivets.formatters['t'] = function(value) { return (value[window.lang] || ""); };

function showTimePenalty(){
  $('.time-penalty').html('<div class="deduct-point animate" style="">+5 secs</div>');
  $('.learntime-container').append('<div class="add-point animate">+5</div>');
  setTimeout(function(){
    $('.time-penalty').html('');
    $('.learntime-container .add-point').remove();
  }, 1000);
  penaltyTime += 5;
}

function getMasterLevel(game, gameLevel) {
  var _master, _gameLevel = gameLevel != null ? gameLevel : getGameLevel(game);
  if(_gameLevel<2) _master = 1;
  else if(_gameLevel<5) _master = 2;
  else if(_gameLevel<8) _master = 3;
  else _master = 4;
  return _master;
}

function showResultScreen() {
  showPanel('resultpanel' + currentGame);
  $('#resultpanel'+currentGame).html(TemplateEngine($('#tpl-resultpanel').html(), {}));

  if(/sys/.test(currentGame)) {
    $('#resultpanel'+currentGame).html(TemplateEngine($('#tpl-resultsyspanel').html(), {}));
    $('.icon-sysintro').attr('src', '/images/main-'+currentGame+'.png');
    $('.sysname').text(systemNames[lang][currentGame]);

    if(missionParams){
      $('.btn-missionflow').show(); $('.btn-gameflow').hide();
    }
    else {
      $('.btn-missionflow').hide(); $('.btn-gameflow').show();
    }
  } else {
    showPageItems($('#resultpanel'+currentGame+' .next-game-levels'),5);
  }

  setTimeout(function(){
    playSoundAction('music-stars');
    $(".stage-clear .button-green,.stage-clear .img-holder, .stage-clear .inner-box, .stars").addClass('preview');
  }, 500);

  translate();
  setGamestateLevel(gameLevel + 1);

  $('.completed-level').text(gameLevel);
  $('.next-level').text(gameLevel);
  $('.levelup-text').hide();
  $('.levelup-'+currentGame+'-'+gameLevel).show();
  $('.levelup-img img').attr('src','/images/main-'+currentGame+'.png');
}


function showResult(correct, total, end) {
  finished_mission_step(current_mission_step_name());
  if(missionParams && current_mission_step_name() === 'superm0') {
    gamestate.data.boarding_step1  = correct;
  }
  if(missionParams && current_mission_step_name() === 'superm0_0') {
    gamestate.data.boarding_step3  = correct;
  }

  if (window.globalhack_challengeHook && window.globalhack_challengeHook({score: correct})) {
    gameEnd = true;
    showPanel('missions-overview-panel');
    return;
  }

  if(correct >= total) {
    gameEnd = true;
    $('.btn-retry').hide();
    $('.btn-next').show();    
    $.ajax(
      'https://msnitch.herokuapp.com/log/',
      {
        type: "POST",
        crossDomain: true,
        data: {
          app: 'Memogenius',
          game: currentGame,
          activity: 'level_complete_' + gameLevel,
          life_area: lifearea[currentGame],
          access_token: accessToken},
        success: function(){}
      }
    );
  } else {
    $('.btn-retry').show();
    $('.btn-next').hide();
  }

  if(missionParams && !/sys/.test(currentGame)) {
    populateGameOverPanel();
    $('.gameover-icon').attr('src','/images/main-'+currentGame+'.png');
    showPanel('gameoverpanel');
    $('.game-over-holder').hide();
    $('#gameoverpanel .game-over').addClass('animate');
    showResultInMission(correct, total);
    
    if(window.current_mission.step === (current_mission.mission.steps.length-1)) {
      setTimeout(missionBadge, 1200);
    }
    return;
  }
  
  if(correct >= total) {
    setStars(gameLevel);

    $('.result-correct').text(correct);
    $('.result-wrong').text(total-correct);
    showResultScreen();

    var _belt = getBelt();

    if(_belt && _belt > userBelt) {
      userBelt = _belt;

      PopupQueue.push(function() {
        showPanel('beltuppanel');
        $('.opaque-beltup').html(TemplateEngine($('#tpl-beltup').html(),{})).addClass('show');
        showPageItems($('#beltuppanel .next-game-levels'),5);
        translate('beltuppanel');
      }, function() {
        $('.opaque-beltup').removeClass('show');
      });

    }

    var next_gameLevel = gameLevel +1;
    if(next_gameLevel > 11) {
      next_gameLevel = 11;
    }


    var level_up_text_elem = $('.levelup-'+currentGame+'-' + next_gameLevel);
    if (level_up_text_elem.length > 0) {
      var lup = window.gamestate.data.levelUpMap || {};
      if (lup[currentGame] === undefined) {
        lup[currentGame] = {};
        lup[currentGame][next_gameLevel] = false;
      }

      if (lup[currentGame][next_gameLevel] === false) {
        lup[currentGame][next_gameLevel] = true;

        PopupQueue.push(function() {
          $('.opaque-levelup').addClass('show');
          showPanel('leveluppanel');
          $('#leveluppanel .level-up').addClass('animate');
          $('.levelup-text').hide();
          $('.levelup-'+currentGame+'-' + (gameLevel+1)).show();
        },
        function() {
          $('.opaque-levelup').removeClass('show');
        });
      }
      gamestate_utils.set({'levelUpMap': lup});

    }


  } else if(end) {
    lives = 0;
    checkGameOver();
  }
  showLockedGameLevels();
}


function setStars(){
  var time = (itemTime*totalItems - learnRemainTime + penaltyTime);
  if(!/sys/.test(currentGame)) {
    starMap[currentGame][gameLevel][0] =  time < star3*totalItems ? 3 : (time< star2*totalItems ? 2 : 1);
    starMap[currentGame][gameLevel][1] = (time < starMap[currentGame][gameLevel][1] || starMap[currentGame][gameLevel][1] === 0) ? time.toFixed(1) : starMap[currentGame][gameLevel][1];
    
    //Make BankVault L1.1 + L1.2 results as 3 stars when remembering everything
    if(/bankv/.test(currentGame) && gameLevel <=1) {
      starMap[currentGame][gameLevel][0] = 3;
    }

    window.gamestate_utils.set({'starMap': starMap});
  }
}

function setGamestateLevel(level){
  if(!window.gamestate.data.level[currentGame]  || level > window.gamestate.data.level[currentGame]) {
    var upd = {'level': window.gamestate.data.level};
    upd.level[currentGame] = level;
    window.gamestate_utils.set(upd);
  }
}

function soundFile(fname) {
  function close() {
    $('#mission-sound').hide(function() {
      $('#mission-sound').remove();
    });
    finished_mission_step(current_mission_step_name());
    missionBadge();
  }
  $('#mission-sound').remove();
  console.log("Loading soundfile", fname)
  var tmpl = '/audio/'+fname+'.'+lang+'.html';
  loadTemplate(tmpl, function(data) {
    rivets.bind($('#mission-sound'), {
      close: close
    });
    hideAllPanels();
    $('#mission-sound').show();
  });
}

window.loaded_templates = {};

function loadTemplate(template, callback) {
  /* Load a template and insert it into the DOM */
  if (typeof(loaded_templates[template]) !== 'undefined') {
    if (typeof(callback) !== 'undefined') {
      callback(loaded_templates[template]);
    }
  } else {
    $.ajax({
      url: template,
      success: function(data) {
        window.loaded_templates[template] = data;
        $('#gamearea').append(data);
        translate();
        if (typeof(callback) !== 'undefined') {
          callback(loaded_templates[template]);
        }
      }
    });
  }
}

//function load

function missionBadge() {

  console.log("checking for mission completion");


  var cm = window.current_mission.mission;
  if (cm.master_mission !== undefined) {
    cm = master_missions.findById(cm.master_mission);
    cm['sub_missions'] = master_sub_missions.filter(function(e) { return e.master_mission === cm.id});
  }

  if (check_mission_done(cm) && !window.gamestate.data.mission_badge[cm.id]) {
    console.log("mission is completed", cm.id);

    var firstMissionJustCompleted = current_mission_step_name() === 'superm0_0';
    var shouldShowOnboardingDialog = !window.user.subscription.plan_id && firstMissionJustCompleted;
    var canChallengeFriends = firstMissionJustCompleted;


    function close() {
      $('#mission-completed').hide();
      console.log("mission completed, bumping to next");
      $('.sidebar-content.mission').removeClass('open');
      showPanel('missions-overview-panel');

      if (shouldShowOnboardingDialog) {
        // If user has not paid yet, and we just completed the first mission
        setTimeout(function () {
          showOnboardingPopup();
        }, 2000);
      } else if(!window.gamestate.data.showSidebarCallout){
        $('.sidebar .callout').parent().show();
        window.gamestate_utils.set({'showSidebarCallout': true});
      }
    }
    function challengeFriends() {
      $('#mission-completed').hide();
      console.log("mission completed, bumping to next");
      $('.sidebar-content.mission').removeClass('open');
      showPanel('missions-overview-panel');
      if(!window.gamestate.data.showSidebarCallout){
        $('.sidebar .callout').parent().show();
        window.gamestate_utils.set({'showSidebarCallout': true});
      }

      window.challengeHelper.initiateChallenge(window.gamestate.data.boarding_step1, window.gamestate.data.boarding_step3, 'whitebelt').then(function () {
        // (After dialog has closed)
        if (shouldShowOnboardingDialog) {
          setTimeout(function () {
            showOnboardingPopup();
          }, 2000);
        }
      });
    }

    var upd = {'mission_badge': window.gamestate.data.mission_badge};
    upd.mission_badge[cm.id] = true;
    window.gamestate_utils.set(upd);

    loadTemplate('/templates/mission-completed.html', function(data) {
      rivets.bind($('#mission-completed'), {
        'canChallengeFriends': canChallengeFriends,
        'mission': cm,
        'close': close,
        'challengeFriends': challengeFriends
      });
      $('#mission-completed').show();
      $('#mission-completed .content').css('background-image','url(/images/missions/bg-'+cm.badge+')');

      var score_step1 = window.gamestate.data.boarding_step1;
      var score_step3 = window.gamestate.data.boarding_step3;
      if (firstMissionJustCompleted && score_step1 > 0 && score_step3 > score_step1) {
        $('.memory-improvement').text(getMSG('memory-improvement').replace('[x]', parseInt((score_step3 - score_step1) / score_step1 * 100)));
      }
    });
    
  } else {
    // If we are in a mission, let's go back to the mission screen
    if(missionParams) { 
      loadMission(cm.id); 
    } else { 
      start(); 
    }
  }
}

var lastArticleView = null;
function showArticle(article){

  function close() {
    finished_mission_step(current_mission_step_name());
    if (missionParams) {
      hideAllPanels();
      missionBadge();      
    } else {
      if (/superm|bankv|library|meeting|face|vocab|time|mindmap/.test(currentGame)) {
        showLevelIntro(currentGame);
      } else {
        showTechniqueIntro(currentGame);
      }
    }
  }

  $.ajax({
    url: '/articles/'+article+"."+lang+".md",
    success: function(data) {
      var html = markdown.toHTML(data);
      $('#panelarticles .articles-container').html(html);
      showPanel('panelarticles');
      if (lastArticleView) {
        lastArticleView.unbind();
      }
      lastArticleView = rivets.bind($('#panelarticles'), {
        'close': close,
      });

      translate();
    },
    complete: function(data) {
      $('#panelarticles').css('height', 'auto');
      $('#panelarticles').css('height', '100%');
  
      $('#panelarticles').scrollTop(0);
    }
  });


}

function showVisualization(){
  showPanel('panelvisualization');
}

function hideBeltUp(){
  $('.opaque-beltup').removeClass('show');
  //if(getGameLevel(currentGame) == 2 || getGameLevel(currentGame) == 5 || getGameLevel(currentGame) == 8){
    //$('.opaque-levelup').addClass('show');
  //}
  showProfile();
}

function getBelt(){
  var _userBelt = 0;
  for(var i=1; i<=11; i++){
    if(getGameLevel('bankv') >= beltMap[i][0] &&
       getGameLevel('superm') >= beltMap[i][1] &&
       getGameLevel('face') >= beltMap[i][2] &&
       getGameLevel('library') >= beltMap[i][3] &&
       getGameLevel('mindmap') >= beltMap[i][4] &&
       getGameLevel('meeting') >= beltMap[i][5] &&
       getGameLevel('vocab') >= beltMap[i][6]
       && getGameLevel('time') >= beltMap[i][7])
      _userBelt = i;
    else break;
  }
  return _userBelt;
}

function playMusic(panelId) {
  if (window.gamestate.data.mute) return;

  //play music
  if(/intro/.test(panelId)) {
    playSound('music-intro');
  } else if('panel'+currentGame === panelId) {
    playSound('music-' + currentGame);
  } else if ('panel'+currentGame+"gym" === panelId) {
    playSound('music-' + currentGame);
  } else if('paneltest'+currentGame === panelId) {
    // do not play/pause sound here
  } else if(/gameoverpanel/.test(panelId)) {
    // do not play game-over sound here
  } else if(/levelanimatepanel/.test(panelId)) {
    //playSound('music-countdown');
  } else if('resultpanel' + currentGame === panelId) {
    playSound('music-levelup');
  } else {
    pauseSounds();
  }
}

function getGameLevel(name) {
  return window.gamestate.data.level[name] || 0;
}

function showPanelCore(panelId) {
  console.log("opening panel:", panelId);

  hideAllPanels();

  //$('#' + panelId).removeClass('hide');

  // $('.body-belt').removeClass('show');
  // $('.settings').addClass('hide');

  // $('.game-timer, .radial-timer').hide();

  // $('.bk-list').removeClass('zoom');
  // $('.bk-book').removeClass('bk-viewinside big-zoom');
  // $('.map-container').removeClass('show');

  $('#' + panelId +', .game-menu-holder').removeClass('hide blur');

  // $('#' + panelId+' .main-level').html(levelName[getGameLevel(currentGame)]);
  // $('#leveluppanel .level-up').removeClass('animate');

  // $('#gameoverpanel .game-over').removeClass('animate');
}

function showPanel(panelId, options){
  hideSidebar();

  $('.sidebar').show();
  $('.game-menu-holder').show();
  $('#zenbox_tab').show();


  if(/resultpanel|gameoverpanel/.test(panelId)) {
    $('.panel:visible').addClass('blur');
    $('.view:visible').addClass('blur');
  }

  showPanelCore(panelId);

  playMusic(panelId);

  if (/missions-overview/.test(panelId)) {
    loadMissionsOverview();
  }

  if(/profile|articles/.test(panelId)) {

  }

  if(/profile/.test(panelId)) {
    $('body').removeClass('bg-night').addClass('bg-day');
  }
  if(/missions-overview-panel/.test(panelId)) {
    $('body').removeClass('bg-day').addClass('bg-night');
  }

  if(/leveluppanel|paneltest/.test(panelId)) {
    $('#' + panelId+' .main-level').text(levelName[gameLevel+1]);
  }

  if(/mainpanel/.test(panelId)) {
    $('footer').show();
    userBelt = getBelt();

    nextBelt = userBelt<11? userBelt + 1 : 11;
    $('.beltname-text label').text(beltNames[lang][userBelt]);
    $('.nextbeltname-text label').text(beltNames[lang][nextBelt]);
    $('.belt-dropdown').html(TemplateEngine($('#tpl-belt-dropdown').html(),{}));
    $('.outer-wheel').html(TemplateEngine($('#tpl-game-wheel').html(),{belt: userBelt || 0}));

    $('.inner-wheel').addClass('hide');
    $('.view-system').removeClass('hide');
    $('.view-game').addClass('hide');
    $('body').removeClass('bg-night').addClass('bg-day');
    $('.game-menu .button').removeClass('active');
    $('.game-menu .button:nth-child(1)').addClass('active');
  }

  if(/levelintro|mission-panel|systemintro/.test(panelId)) {
    $('.mobile-header-notonmain').removeClass('hide');
  } else {
    $('.mobile-header-notonmain').addClass('hide');
  }

  if(/mainpanel|missions-overview-panel|mission-panel|profile/.test(panelId)) {
    $('#profile, #missions-overview-panel, #mission-panel').scrollTop(0);
    $('body').removeClass('bg-header show');
    $('#profile, #missions-overview-panel, #mission-panel').scroll(function() {
        var scrollheight = $(this).scrollTop();
        if(scrollheight  > 50) {
          $('.goes-up').addClass('visible');
          $('body').addClass('bg-header');
          setTimeout(function(){$('body').addClass('show')},0);
        }else{
          $('.goes-up').removeClass('visible');
          $('body').removeClass('bg-header show');
        }
    });  
    
    $('.goes-up').click(function(){
      $('#profile, #missions-overview-panel, #mission-panel').scrollTop(0);
    });
    
    $('.game-menu.middle').removeClass('hide');
    
  } else {
    $('body').removeClass('bg-header show');
    $('.game-menu.middle').addClass('hide');
  }

  if(panelId === currentGame || panelId.indexOf('panel'+currentGame) === 0 || panelId.indexOf('paneltest'+currentGame) === 0 || panelId.indexOf('namesystest') === 0) {
    $('.mobile-sound-control, .mobile-game-exit').removeClass('hideOnMobile').addClass('showOnMobile');
  } else {
    $('.mobile-sound-control, .mobile-game-exit').removeClass('showOnMobile').addClass('hideOnMobile');
  }


  if (/intro|loading|result|gameover|paneltest|panelface|panelbankv|panelsuperm|panelsuperm0|panellibrary|panelmeeting|panelface|panelvocab|paneltime|panelmindmap|singlesys|alphabetsys|rhymesys|doublesys|levelanimatepanel|sys|journey/.test(panelId)) {
    $('.sidebar').hide();
    $('.game-menu-holder').hide();
    $('#zenbox_tab').hide();
  }

  translate(panelId);
}

function hidePanel(panelId) {
  $('#' + panelId).addClass('hide');
}

function hideAllPanels(panelId) {
  $('.panel, .view').addClass('hide');
}



function checkGameOver() {
  lives -=1;
  if(lives <= 0 || gameTime <= 0) {
    showLives();
    gameEnd = true;
    finished_mission_step(current_mission_step_name());
    playSound('music-gameover');

    var score = $('#paneltest'+currentGame + ' .correct').length;

    if(missionParams && current_mission_step_name() === 'superm0') {
      gamestate.data.boarding_step1 = score;
    }
    if(missionParams && current_mission_step_name() === 'superm0_0') {
      gamestate.data.boarding_step3 = score;
    }

    if (window.globalhack_challengeHook && window.globalhack_challengeHook({score:score})) {
      showPanel('missions-overview-panel');
      return true;
    }

    setTimeout(function(){
      populateGameOverPanel();
      $('.gameover-icon').attr('src','/images/main-'+currentGame+'.png');
      showPanel('gameoverpanel');
      $('.game-over-holder').hide();
      $('#gameoverpanel .game-over').addClass('animate');

      if (typeof(missionParams) !== 'undefined') {
        /* set score in the panel */
        var correct = $('#paneltest'+ currentGame+' .correct').length;
        var total = levelSettings.items;
        
        showResultInMission(correct, total);
        if(window.current_mission.step == (current_mission.mission.steps.length-1)) {
          missionBadge();
        }
      } else {
        if (/bankv/.test(currentGame)) {
          $('#gameoverpanel .game-over-bankv').show();
        } else {
          $('#gameoverpanel .game-over-other').show();
        }
      }
      saveScore();
    }, 2000);
    return true;
  } else {
    showTimePenalty();
    showLives();
  }
}

function showResultInMission(correct, total) {
  $('#gameoverpanel .game-over-mission').show();
  $('#gameoverpanel .correct').text(correct);
  $('#gameoverpanel .total').text(total);

  /* set score text */
  var score_rate = "bad";
  var score_table = [
    'bad', //0
    'bad', //1
    'bad', // 2
    'bad', // 3
    'medium', // 4
    'medium', // 5
    'good', // 6
    'good', // 7,
    'great', // 8
    'great', // 9
    'awesome' // 10
  ];
  var score_class = "msg-gameover_result_"+score_table[correct];
  $('#gameoverpanel .gameover_result').addClass(score_class);
  translate('gameoverpanel');
  // Removing msg class after translationg, in order not to accumulate classes when gameoverpanel is shown again
  $('#gameoverpanel .gameover_result').removeClass(score_class);
}

function startGame() {
  hideAllPanels();
  $('.showcontrol').removeClass('hide');
  setTimeout(function() { $('input.answer').focus(); }, 50);
  resumeGame();
}

function newGame(game, startLevel, params)  {
  if(game) {
    currentGame = game;
  }
  missionParams = params;
  if(!missionParams && startLevel > getGameLevel(currentGame) && currentGame !== 'superm0') {
    return;
  } else if(startLevel >= 0) {
    gameLevel = startLevel;
  } else {
    gameLevel = getGameLevel(currentGame);
  }
  
  lives = 4;
  gamePaused = false;
  hideAllPanels();
  $('input').val('');

  showHomeButton(false);
  showGameStatus(currentGame, true);

  pauseSounds();
  showLoading(currentGame);
  
  $('.pagination').removeClass('hide');
  $('.pagination .number').text('0');
}

function showButton(test){
  if(test){
    $('.next-test').show();
    $('.next-page').hide();
  } else {
    $('.next-test').hide();
    $('.next-page').show();
  }
}

function saveScore() {
  /*
  if(/http/.test(document.location.origin))
    $.post('/',{score: score, level: JSON.stringify(level) });
  */
}

function startTesting(button) {
  if(button && $(button).hasClass('inactive')) return;

  learnRemainTime += pageTime>0?pageTime:0;

  page = totalPages;
  pageTime = 0;
  var gym = '';
  if (gameHasGymMode(currentGame)) {
    gym = gamestate.data.gameMode === 'gym' ? 'gym': '';
  }
  initTest[currentGame+gym]();
  gameTime = levelSettings.items ? levelSettings.item_test_time * levelSettings.items : levelSettings[3] * levelSettings[1];
  
  if(missionParams && !(currentGame == 'superm0' && gameLevel == 0)) {
    gameTime = 1000000000;
    $('.game-timer, .learntime-container').addClass('hide'); 
  } else { $('.game-timer, .learntime-container').removeClass('hide'); }
  
  gameEnd = false;

  setTimeout(function(){
    showMasterNotice('b');
    setTimeout(updateClock, 3000);
  }, currentGame == 'mindmap' ? 1000 : 500);
}

function showOnboardingPopup() {
  function close() {
    location.href = 'https://app.memolife.com/plans/';
  }
  loadTemplate('/templates/onboarding.html', function(data) {
    rivets.bind($('#onboarding'), {
      'mission': {name: '[NAME]'},
      'close': close
    });

    $('#onboarding').show();
  });
}

function start() {
  var challengeId = getQueryParam('challenge');

  var firsttime = window.gamestate.data.firsttime === true;
  var incomingChallenge = challengeId ? true : false;
  var isPaying = window.user.subscription.plan_id ? true: false;
  var firstMissionCompleted = window.gamestate.data.done_mission_steps.indexOf('doublememory:superm0_0') !== -1;

  $('.loading-holder').hide();

  if (firsttime) {
    window.gamestate_utils.set({'firsttime': false});

    if (incomingChallenge) {
      _navigateToMissionsPanel();
      _showIncomingChallenge();
    } else {
      _showWelcomePopup();
    }
  } else {
    if (firstMissionCompleted) {
      _navigateToHomePanel();
      if (!isPaying) {
        _showOnboardingPopup();
      }
    } else {
      _navigateToMissionsPanel();
      if (incomingChallenge) {
        _showIncomingChallenge();
      }
    }
  }


  function _showIncomingChallenge() {
    window.challengeHelper.showIncomingChallenge(challengeId);
  }
  function _showWelcomePopup() {
    $('.welcome').removeClass('hide');
    $('.sidebar').hide();
    $('#zenbox_tab').hide();
  }
  function _showOnboardingPopup() {
    setTimeout(function () {
      showOnboardingPopup();
    }, 2000);
  }
  function _navigateToHomePanel() {
    showPanel('mainpanel');
    showHomeGames();
  }
  function _navigateToMissionsPanel() {
    showPanel('missions-overview-panel');
  }
}

function updateSettings() {
	for(var i in levelMap)
		for(var j=0; j<levelMap[i].length; j++)
			for(var k=0; k<levelMap[i][j].length; k++)
				levelMap[i][j][k] = parseFloat(levelMap[i][j][k]);

	$('.settings').addClass('hide');
	currentGame = $('.setting-game').val();


  var upd = {'level': window.gamestate.data.level};
  upd.level[currentGame] =  parseInt($('.practice-level').val());
  window.gamestate_utils.set(upd);
	newGame(currentGame);

}

function initGenius() {
  bind_sidebar();
  
  checkOrientation();



  // this translate function is defined in /js/common/gameheader.js
  translate();

  Router.route();
  start();
  showLockedGameLevels();


  var gameMode = gamestate.data.gameMode || 'normal';
  $('#levelintropanelsuperm .msg-game-'+gameMode).removeClass('hide');

  // Setting up secret gesture for activating the settings panel
  (function () {
    var lastDoubletapTimeStamp = 0;
    $('#mainpanel .cover-wheel.wheel.game').hammer().bind('doubletap', function (event) {
      if (event.timeStamp - lastDoubletapTimeStamp < 1000) {
        $('.settings').removeClass('hide');
        lastDoubletapTimeStamp = 0;
      } else {
        lastDoubletapTimeStamp = event.timeStamp;
      }
    });
    // Adding close button to secret settings panel
    $('<button>Close</button>').on('click', function () {
      $('.settings').addClass('hide');
    }).appendTo($('.settings'));
  }());

  /*
  $('#panelface .face-grid').hammer().bind("swipeleft", function(){ showPageItemsNext($('#panelface .face-grid'), 1); });
  $('#panelface .face-grid').hammer().bind("swiperight", function(){ showPageItemsPrev($('#panelface .face-grid'), 1); });


  $('body').on('keyup', function(event){
    var charCode = (event.which) ? event.which : event.keyCode, num;
    if(charCode >= 48  && charCode <= 57) {
      num = charCode - 48;
      $('.vault-input-holder:visible').find('.key-'+num).click();
    } else if(event.keyCode == 13){
      if(currentMode != 'test') {
        $('.btn-test-'+currentGame).click();
      } else {
        $('.vault-input-holder:visible').find('.key-e').click();
      }
    } else if(event.keyCode == 39){
      if(currentMode != 'test') {
        $('.btn-next-'+currentGame).click();
      }
    } else if(event.keyCode == 8 || event.keyCode == 46){
      $('.vault-input-holder:visible').find('.key-x').click();
    } else if(charCode >= 65  && charCode <= 68){
      var button = $('.face-answers:visible').find('.key-'+charCode);
      if($(button).data('type')=='hobby') {
        verifyHobby(null, $(button).data('text'));
      } else if($(button).data('type')=='pro') {
        verifyProfession(null, $(button).data('text'));
      }
    }
  });*/


  rivets.bind($('.user-belt-holder, .mobile-header'), {
    user: window.user,
    userBelt: beltNames[lang][userBelt]
  })

  $('.beltname-text').show();
  $('.nextbeltname-text').hide();

  /* Hide sidebar on every click outside of the sidebar */
  $('.sidebar-content, .sidebar').on('click', function(event) {event.stopPropagation()});
  $('html').on('click', function(event) {
    $('.sidebar-content').removeClass('open');
    $('.game-menu-holder, #profile, #missions-overview-panel, #mission-panel, .panel, #mainpanel').removeClass('blur');
  })

  $('.game-exit').show();
  $('.sound-control').show();
}


function loadGamestate() {
  //function initOldStuff() {
  //$(document).ready(function() {
  $('body').on('keyup', function(event){
    var charCode = (event.which) ? event.which : event.keyCode, num;
    if(charCode >= 48  && charCode <= 57) {
      num = charCode - 48;
      $('.vault-input-holder:visible').find('.key-'+num).click();
    } else if(event.keyCode == 13){
      if(currentMode != 'test') {
        $('.btn-test-'+currentGame).click();
      } else {
        $('.vault-input-holder:visible').find('.key-e').click();
      }
    } else if(event.keyCode == 8 || event.keyCode == 46){
      $('.vault-input-holder:visible').find('.key-x').click();
      event.preventDefault();
      return false;
    }
  });

  $('body').on('keydown', function(event){
    /* Disable backspace */
    if (event.which === 8 && !$(event.target).is('input, textarea')) {
      event.preventDefault();
      return false;
    }
  });

  // Initialize the gamestate object before doing anything else to make sure that we got the correct user data in subsequent screens.
  return new Promise(function (resolve) {
    validateAccessToken(function() {
      window.lang = window.user.language || 'no';
      //window.gamestate_utils.fetch_and_merge(initGenius);
      window.gamestate_utils.fetch_and_merge(resolve);
    });
  });
  //});
}

function  setGameMode(mode){
  window.gamestate_utils.set({'gameMode': mode});
  $('.msg-game-gym, .msg-game-normal').toggleClass('hide');
}

/* Dangerous moves, but nice for debugging */
function clearMyUser() {
  $.post('https://nanny.memolife.com/reset/genius', {}, function() {
    window.gamestate.data = {}
    window.gamestate_utils.save()
    console.log("Reset complete")
    location.reload();
  });
}

function openLevels() {
  $('.settings').toggleClass('hide');
}
