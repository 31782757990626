function initDom(){
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent))) {
    $('input').attr('readonly', 'true');
    $('#music-correct').html('');
    $('#music-wrong').html('');
  }
};

initDom();

function sliceClick(ui){
  if(!ui.hasClass('lock')) {
    showLevelIntro(ui.data('game'));
  }else{
    ui.addClass('active');
    setTimeout(function(){
      ui.removeClass('active');
    },1000);
  }
};

$('.card-close').click(function(){
  $('.intro-panel').removeClass('show');
});
