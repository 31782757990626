function toggleBeltDropdown(e){
	$('.dropdown.right').toggleClass('open');
	if($('.dropdown.right').hasClass('open')) {
		$('.beltname-text').hide();
		$('.nextbeltname-text').show();
	} else {
		$('.beltname-text').show();
		$('.nextbeltname-text').hide();
	}
  if(e.stopPropagation) e.stopPropagation();
  return false;
}

function showLives(_lives) {
	if(_lives) lives = _lives;
	$('.main-lives').html('');
	for(var i=0; i< lives; i++) {
		$('.main-lives').append('<span class="live red"></span>');
	}
	for(var i=lives; i<3; i++) {
		$('.main-lives').append('<span class="live"></span>');
	}
	$('.learntime').text((itemTime * totalItems - learnRemainTime + penaltyTime).toFixed(1));
}

function hideHomeMenu(){
      $('.dropdown.left').removeClass('open');
      setTimeout(function(){
        $('.game-home').removeClass('hide');
      },500);

	$('.beltname-text').show();
	$('.nextbeltname-text').hide();
	  $('.dropdown.right').removeClass('open');
}

function activate(el, deactive){
	if($(el).hasClass('active')) {
		if(deactive) $(el).removeClass('active');
	} else {
		$(el).parent().find('.active').removeClass('active');
		$(el).addClass('active');
	}
}

function showHomeMenu(e){
  $('.game-home').addClass('hide');
  $('.dropdown.left').addClass('open');
  if(e.stopPropagation) e.stopPropagation();
  return false;
}

function showGameStatus(game, set){
	if(!set) {
		$(".score, .lives, .diamonds, .level, .progress").addClass('hide');
	} else {
		$('.game-menus').show();
		$(".score, .lives, .diamonds, .level, .progress").removeClass('hide');
		$('.infopanel-holder').removeClass("bg-dark bg-main");

		$('#lives-'+game).text(lives);
	}
}

function hideButton(){
	$('.next-test,.next-page').hide();
}

function showBenefits(el){
	showPanel('articles');
	$('.articles-holder').addClass('animate');
	homeState = 'benefit';
}

function showArticles(){
	return;
	showPanel('panelarticles');
}

function showHomeGames(delay) {
  if (delay === undefined) {
    delay = 500;
  }
  missionParams = undefined;

  $('.cover-wheel.game').removeClass('night').addClass('day');
  $('.outer-wheel').removeClass('hide');

  if (delay) {
    $('.outer-wheel').removeClass('animate');
    setTimeout(function(){
      $('.outer-wheel').addClass('animate');
    }, delay);
  } else {
    $('.outer-wheel').addClass('animate');
  }

	homeState = 'game';
}

function showHomeButton(set){
	if(!set) {
		$('#home').addClass('hide');
	} else {
		$('#home').removeClass('hide');
	}
}

function showHomeSystems(delay) {
  if (delay === undefined) {
    delay = 500;
  }

  $('.game-menu .button').removeClass('active');
  $('.game-menu .button:nth-child(3)').addClass('active');

  missionParams = undefined;
  $('.outer-wheel').removeClass('xanimate').addClass('hide');
  $('.view-system').addClass('hide');
  $('.view-game').removeClass('hide');
  $('.inner-wheel').removeClass('hide');

  if (delay) {
    $('.inner-wheel').removeClass('animate');

    setTimeout(function(){
      $('.inner-wheel').addClass('animate');
    }, delay);
  } else {
    //
  }

  homeState = 'system';

  $('body').removeClass('bg-day').addClass('bg-night');
  $('.cover-wheel.game').removeClass('day').addClass('night');
  
}

function checkOrientation(){
	if(isMobile){
		if(window.innerWidth > window.innerHeight) {
			$('.orientation-note').addClass('show');
		} else {
			$('.orientation-note').removeClass('show');
		}
	}
}

function deselectSlice(){
	$('.slice').removeClass('active inactive');
	$('.cover-wheel').removeClass('active').addClass('hide');
	$('.cover-wheel.game').removeClass('hide');
}

function showLevelIntro(game){
  // Show mission if the current game is superm0
  if(/superm0/.test(game)){
    loadMission();
    return;
  }
  
  // Show technique intro if the game parameter is a system
  if(/sys/.test(game)) {
    showTechniqueIntro(game);
    return;
  }
  
  //Show gym mode intro if the game parameter is a gym
  if(/gym/.test(game)){
    showPanel('levelintropanel'+game);
    console.log('how to call template');
  }
  
	if(!game) return;
	deselectSlice();
	if(/sys/.test(game)) {
		showPanel('mainpanel');
		translate('mainpanel');
	} else {
		currentGame = game;
		currentMode = 'intro';
		gameLevel = getGameLevel(currentGame);
		$('#levelintropanel'+game+' .intro-footer-levels').html(TemplateEngine($('#tpl-intro-footer').html(), {}));
		showPanel('levelintropanel'+game);
		showMainlevels();
		showIntroPlay();
		translate('levelintropanel'+game);
    $('.intro-home').show();
	}
}

function getMSG(id) {
  for(var i in dictionary) {
    if(dictionary[i].id == id) return dictionary[i][lang];
  }
	return '';
}

function translate(panel){
	if (panel) {
        window.dictionary.forEach(function (t) {
		  $('#' + panel + ' .msg-' + t['id']).html(t[lang]);
        });
	} else {
        window.dictionary.forEach(function (t) {
            $('.msg-' + t['id']).html(t[lang]);
        });
	}
}

function populateRankingPanel() {
  $('.top-list').html('');
  $.get('/game/topplayers/', function(results) {
    if(!results) return;
    var exists = false;
    for(var i=0; i<results.length; i++) {
      if(results[i].username == window.user.username) exists = true;
      $('.top-list').append('' +
      '<li>'+
        '<div class="number">'+ (i+1) +'</div>' +
          '<div class="text clearfix">' +
            '<img class="pull-left" src="/images/profile.png">' +
            '<span>'+ (results[i].username == window.user.username ? 'You' : (results[i].first + ' ' + results[i].last)) +'</span>' +
          '</div>' +
        '<div class="points">' + results[i].mathScore + ' '+getMSG(1).toLowerCase()+'</div>' +
      '</li>'
      );
    }
    if(!exists)
      $('.top-list').append('' +
      '<li>'+
        '<div class="number"></div>' +
          '<div class="text clearfix">' +
            '<img class="pull-left" src="/images/profile.png">' +
            '<span>You</span>' +
          '</div>' +
        '<div class="points">' + score + ' ' + getMSG(1).toLowerCase() +'</div>' +
      '</li>'
      );
  });
}

function populateGameOverPanel() {
  if(/bankv/.test(currentGame)) {
    var answer = $('#paneltestbankv .num-answer').text();
    $('.bankv-summary').html('');
    for(var i=0; i<answer.length/12; i++){
      $('.bankv-summary').append('<span class="bankv-summary-'+i+'"></span>')
      for(var j=i*12; j<i*12 + 12 && j<answer.length; j++) {
        $('.bankv-summary-'+i).append('<a class="'+(!number[j] || number[j]!=answer[j]?'wrong':'')+'">'+answer[j]+'</a>');
      }
    }
    if(!answer.length) {
      $('.bankv-summary').parent().parent().hide();
    } else {
      $('.bankv-summary').parent().parent().show();
    }
  }
}

function showMissions(){
  $('.sidebar-content.belt, .sidebar-content.profile').removeClass('open');
  $('.sidebar-content.mission').toggleClass('open');
  if($('.sidebar-content.mission').hasClass('open')){
  	$('.game-menu-holder, #profile, #missions-overview-panel, #mission-panel , .panel, #mainpanel').addClass('blur');
  }else{
    $('.game-menu-holder, #profile, #missions-overview-panel, #mission-panel, .panel, #mainpanel').removeClass('blur');
  }
}

function showNextBelt(){
  $('.sidebar-content.mission, .sidebar-content.profile').removeClass('open');
  $('.sidebar-content.belt').toggleClass('open');
  if($('.sidebar-content.belt').hasClass('open')){
    $('#sidebar-content-belt').html(TemplateEngine($('#sidebar-content-belt-tmpl').html(), {belt: userBelt+1}));
    translate('sidebar-content-belt');
    $('.game-menu-holder, #profile, #missions-overview-panel, #mission-panel,  .panel, #mainpanel').addClass('blur');
  }else{
    $('.game-menu-holder, #profile, #missions-overview-panel, #mission-panel, .panel, #mainpanel').removeClass('blur');
  }
}

function showSidebarProfile(){
  $('.sidebar-content.mission, .sidebar-content.belt').removeClass('open');
  $('.sidebar-content.profile').toggleClass('open');
  if($('.sidebar-content.profile').hasClass('open')){
    $('#sidebar-content-profile').html(TemplateEngine($('#sidebar-content-profile-tmpl').html(), {belt: userBelt+1}));
    
    $('.game-menu-holder, #profile, #missions-overview-panel, #mission-panel,  .panel, #mainpanel').addClass('blur');
  }else{
    $('.game-menu-holder, #profile, #missions-overview-panel, #mission-panel, .panel, #mainpanel').removeClass('blur');
  }
}

function hideSidebar() {
  if ($('.sidebar-content.mission').hasClass('open'))
    showMissions();
  if ($('.sidebar-content.belt').hasClass('open'))
    showNextBelt();
}

function hideMissions(){
  $('.sidebar-content.mission').removeClass('open');
}

/*
function showMissionSteps(self){
  if(self.parent().hasClass('lock')) return;
  self.parent().parent().children().find('.list').removeClass('open');
  self.parent().parent().find('.active').removeClass('active');
  self.toggleClass('open');
  if(self.hasClass('open')) self.parent().addClass('active');
}
*/

function redirectMissionStep(missionId, step) {
  window.location.href = "/mission/" + missionId + "/" + step + "?accessToken=" + accessToken;
}

function goBack(){
  if(/intro/.test($('.panel:visible').attr('id')) || /intro/.test($('.view:visible').attr('id'))) {
    showPanel('mainpanel');
    if(/sys/.test(currentGame)) {
      showHomeSystems(0);
    } else {
      showHomeGames(0);
    }
  } else if(/mission/.test($('.panel:visible').attr('id'))) {
    showPanel('missions-overview-panel')
  } else {
    if(missionParams) {
      loadMission();
    } else {
      showLevelIntro(currentGame);
    }
  }
}
