var vocabSet = [];
var vocabLang = '';

function initTestVocab(){
	currentMode = 'test';
	showPanel('paneltest'+currentGame);
	vocabSet.sort(function(){ return Math.random()-0.5; });
	index = -1;
	showLives(3);
	nextVocabTest();
	$('#paneltestvocab').removeClass('l1 l2 l3 l4').addClass('l'+getMasterLevel(currentGame));
}

function initGameVocab(){
	currentMode = 'learn';
  // We have already set gameLevel when start newGame.
  // DO NOT overwrite it here.
	///// gameLevel = getGameLevel(currentGame);
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1];
	pageSize = totalItems;
	totalPages = 1;
	star2 = levelSettings[5];
	star3 = levelSettings[6];

	index = 0;
	itemTime = levelSettings[2];
	pageTime = 0;
	pageTimes = [];
	gameTime = itemTime * totalItems;
	page = -1;
	learnRemainTime = 0;
	penaltyTime =0;
	hideButton();
	$('.sprint-container').html('');

	if(missionParams) {
    itemTime = 1000000000;
    $('.pagination').addClass('hide'); 
  } else { $('.pagination').removeClass('hide'); }
  
	setTimeout(function(){
		getVocabSet();
		nextVocab();
		updateClockLearn2();
	}, 3000);
	$('#panelvocab').removeClass('l1 l2 l3 l4').addClass('l'+getMasterLevel(currentGame));
}

function verifyVocab(e) {
	if($('.vocab-answers .active')[0].innerHTML.toLowerCase() + $('.vocab-answers .active')[1].innerHTML.toLowerCase() == vocabSet[index][0].toLowerCase() ||
     $('.vocab-answers .active')[1].innerHTML.toLowerCase() + $('.vocab-answers .active')[0].innerHTML.toLowerCase() == vocabSet[index][0].toLowerCase()) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function() {
			vocabSet[index].step = 'done';
			nextVocabTest();
			$('.tick').removeClass('animate');
		}, 500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function() {
			$('.tick-red').removeClass('animate');
			showVocabSplits();
			gameTime -= gameTime> 5 ? 5.0: gameTime;
			checkGameOver();
		}, 500);
	}
}

function skipVocab(step){
	if(index<vocabSet.length) {
		vocabSet[index].step = step;
	}
	checkGameOver();
	if(lives) {
		nextVocabTest();
	}
}

function getVocabSet(){
	vocabSet = [];
	vocabLang = '';
	if(gameLevel < 2) {
		vocabLang = 'Nadsat';
	} else if(gameLevel < 5) {
		vocabLang = 'Elvish';
	} else if(gameLevel < 8) {
		vocabLang = 'Klingon';
	} else {
		vocabLang = 'Na’vi';
	}
	getVocab(vocabLang, totalItems);
  window.gamestate_utils.set({'languagesU': languagesU});

}

function getVocab(language, totalItems){
	currentLanguage = language;
	for(var i=0; i<totalItems; i++){
		vocabSet.push(randomItemU(languagesU[language], languages[language]));
	}
}

function nextVocab(button) {
	if(button && $(button).hasClass('inactive')) {
		return;
	}
	page += 1;
	if(page >= totalItems) {
		startTesting();
	} else {
		learnRemainTime += pageTime>0?pageTime:0;
		pageTimes.push(pageTime >= 0 ? pageTime0 - pageTime : pageTime0);
		$('.sprint-content').addClass('animate');

    var c = $('#tpl-vocab-game').html();

		setTimeout(function() {
			$('.sprint-container').html(TemplateEngine(c, { vocab: vocabSet[page], langTrans: window.vocabLanguages[lang][vocabLang] }));
      
      translate('panelvocab');
			setTimeout(function() {
				$('.sprint-content').addClass('show');
			}, 100);
		}, 1000);

		showPages();
	}
}

function nextVocabTest() {
	index += 1;
	for(; index<totalItems; index++){
		if(vocabSet[index].step != 'done') break;
	}
	if(index >= totalItems) {
		if(vocabSet.filter(function(item){ return item.step !== 'done'; }).length === 0) {
			showResult(totalItems, totalItems);
		} else {
			index = -1;
			nextVocabTest();
		}
		return;
	}
	$('.test-order').text(''+(index+1)+'/'+totalItems);
	$('.vocab-word').html(
	  '<div>'+
		  vocabSet[index][1+langs[lang]]+
	    '</div>'
	);
	showVocabSplits();
}

function showVocabSplits(){
	$('#paneltestvocab .test-card').addClass('hide');
	$('#paneltestvocab .split-test').removeClass('hide');
	$('.vocab-answers').html('');
	var answer = vocabSet[index][0], options= [answer.substr(0,answer.length/2), answer.substr(answer.length/2)];
	for(var i=1; i<= vocabSet.length; i++) {
		answer = vocabSet[(index+i)%vocabSet.length][0];
		pushUnique(options, answer.substr(0,answer.length/2));
		pushUnique(options, answer.substr(answer.length/2));
		if(options.length>=12) break;
	}
	if(options.length<12) {
	  var _index = parseInt(Math.random()*languages[currentLanguage].length);
	  for(var i=1; i<= languages[currentLanguage].length; i++) {
		  answer = languages[currentLanguage][(_index+i)%languages[currentLanguage].length][0];
		  pushUnique(options, answer.substr(0,answer.length/2));
		  pushUnique(options, answer.substr(answer.length/2));
		  if(options.length>=12) {
        break;
      }
	  }
	}
	options.sort(function() { return Math.random()- 0.5; });
	for(var i=0; i<options.length; i++) {
		$('.vocab-answers').append('<div class="box" onclick="$(this).toggleClass(\'active\');if($(\'.vocab-answers .active\').length >= 2) verifyVocab(event);">'+options[i].toLowerCase()+'</div>');
	}
}
