function showPageItems(parent, pageSize){
	var i=0, total = parent.children().length;
	
	if(total <= pageSize) parent.parent().parent().find('.prev, .next').hide();
	else parent.parent().parent().find('.prev, .next').show();

  var items = {};
  for(var i=0; i< total; i++) items[i] = 0;
  for(i=0; i<total; i++) if($(parent.children()[i]).is(':visible')) break;
  for(var j=0; j<pageSize; j++) {
    items[(i+j)%total] = 1;
    $(parent.children()[(i+j)%total]).removeClass('hide');
  }
  for(var i=0; i< total; i++) 
    if(!items[i]) $(parent.children()[i]).addClass('hide');
}
function showPageItemsNext(parent, pageSize){
	var i=0, total = parent.children().length;
  var items = {};
  for(i=0; i< total; i++) items[i] = 0;
	for(i=0; i<total; i++) if($(parent.children()[i]).is(':visible')) break;
	for(var j=0; j<pageSize; j++) {
    items[(i+j+pageSize)%total] = 1;
    $(parent.children()[(i+j+pageSize)%total]).removeClass('hide').addClass('no-delay');
  }
  for(var i=0; i< total; i++) 
    if(!items[i]) $(parent.children()[i]).addClass('hide');
}
function showPageItemsPrev(parent, pageSize){
	var i=0, total = parent.children().length;
  var items = {};
  for(i=0; i< total; i++) items[i] = 0;
	for(i=0; i<total; i++) if($(parent.children()[i]).is(':visible')) break;
	if(i == 0 ) i = total;
	for(var j=0; j<pageSize; j++) {
    items[(i-1-j)] = 1;
    $(parent.children()[i-1-j]).removeClass('hide').addClass('no-delay');
  }
  for(var i=0; i< total; i++) 
    if(!items[i]) $(parent.children()[i]).addClass('hide');
}

function scrollNext(panel){
	var _page = 6;
	if(currentGame == 'meeting') _page = 3;
	if($('#'+panel+' .dock-items .drag.hide').length>0){
		for(var i=0; i<_page; i++) {
			var last = $('#'+panel+' .dock-items .drag.hide').length;
			$($('#'+panel+' .dock-items .drag.hide')[last-1]).removeClass('hide');
		}
	} else {
		var len = $('#'+ panel+' .drag').length;
		for(var i=0; i<len-_page; i++){
			$($('#'+panel+' .dock-items .drag')[i]).addClass('hide');
		}
	}
}

function scrollPrev(panel){
	var _page = 6;
	if(currentGame == 'meeting') _page = 3;
	var len = $('#'+panel+' .drag').length;
	if($('#'+panel+' .dock-items .drag.hide').length + _page < len){
		for(var i=0; i<_page; i++){
			$($('#'+panel+' .dock-items .drag:not(.hide)')[0]).addClass('hide');
		}
	} else {
		$('#'+panel+' .dock-items .drag.hide').removeClass('hide');
	}
}

function scrollUp(panel){
	var _page = 5;
	var len = $('#'+panel+' .drop-li').length;
	if($('#'+panel+' .drop-li.hide').length + _page < len){
		for(var i=0; i<_page; i++){
			$($('#'+panel+' .drop-li:not(.hide)')[0]).addClass('hide');
		}
	} else {
		$('#'+panel+' .drop-li.hide').removeClass('hide');
	}
}

function scrollDown(panel){
	var _page = 5;
	if($('#'+panel+' .drop-li.hide').length>0){
		for(var i=0; i<_page; i++) {
			var last = $('#'+panel+' .drop-li.hide').length;
			$($('#'+panel+' .drop-li.hide')[last-1]).removeClass('hide');
		}
	} else {
		var len = $('#'+ panel+' .drop-li').length;
		for(var i=0; i<len-_page; i++){
			$($('#'+panel+' .drop-li')[i]).addClass('hide');
		}
	}
}

function showPages(){
	$('.pagination').html('');
	for(var p=0; p < totalPages; p++) {
		$('.pagination').append('<span class="'+(p<page?'done':'')+' page page-'+p+'">'+(pageTimes.length && p<page?pageTimes[p+1].toFixed(1):'')+'</span>');
	}
	pageTime = ((totalItems - page * pageSize) > pageSize ? pageSize :  (totalItems - page * pageSize)) * itemTime;
	pageTime0 = pageTime;
	$('.pagination .page-'+page).html('<div class="radial-timer s-animate">'+
              '<div class="number">'+pageTime+'</div>'+
            '</div>').removeClass('page');
	if(/meeting|vocab|bankv|time/.test(currentGame)) {
    pageTime = levelSettings[2];
    if(page >= totalPages * pageSize && /bankv/.test(currentGame))
      pageTime = 0;
      
		$('.pagination .page-0').html('<div class="radial-timer s-animate">'+
				  '<div class="number">'+gameTime+'</div>'+
				'</div>').removeClass('page');
		pageTime0 = gameTime;
	}
	$('.button.next, .button.test').addClass('inactive');
	if(/meeting|vocab|time/.test(currentGame)) {
		$('.button.next, .button.test').removeClass('inactive');
	}
	if(/face/.test(currentGame)) {
		setTimeout(function(){
			$('.button.next, .button.test').removeClass('inactive');
		}, 2000);
	}
	if(/meeting|bankv|time|vocab/.test(currentGame) || (/face/.test(currentGame) && isMobile)){
		if(/bankv/.test(currentGame) && gameLevel < 2) $('.button.next, .button.test').hide();
		else if(page >= (totalItems - 1)) showButton('test');
		else showButton();
	} else if(page >= (totalPages - 1)) {
		showButton('test');
	} else {
		showButton();
	}
}

