// NOTE: 'lang' is not available when loading this file. There 'shoppingItems' of the right language must be 'loaded' later (when needed).
var shoppingItems_orig = shoppingItems;
var shoppingItems = null;
function loadShoppingItems() {
  if (!shoppingItems) {
    shoppingItems = shoppingItems_orig[lang];
    shoppingItems.sort(function () {
      return Math.random() - 0.5;
    }); // Initial randomization
  }
}

function initTestSupermarket(){
  loadShoppingItems();
  currentMode = 'test';
  $('#paneltest'+currentGame+' .drop-places').html('');
  var _hasQuantity = levelSettings.quantity,
    _hasPrice = levelSettings.price,
    _shoppingItems = shoppingItems.slice(0, totalItems);
  for(var i=0; i<totalItems; i++) {
    if(alphabetItems.length) {
      var alphabetCode = systemAlphabetCodes[lang][alphabetItems[i]];
      $('#paneltest'+currentGame+' .drop-places').append('<li onclick="verifySupermarket(event)" class="drop" data-id="'+(i+1)+'" data-text="'+alphabetCode[1]+'"><span class="number">'+(i+1)+'</span></li>');
    } else {
      $('#paneltest'+currentGame+' .drop-places').append('<li onclick="verifySupermarket(event)" class="drop" data-id="'+shoppingItems[i].id+'" data-text="'+shoppingItems[i].name+'"  data-quantity="'+(_hasQuantity ? shoppingItems[i].quantity : '')+'"  data-price="'+(_hasPrice ? shoppingItems[i].price: '')+'"><span class="number">'+(i+1)+'</span></li>');
    }
	}
	_shoppingItems.sort(function(){ return Math.random() - 0.5; });
	addFakeItems(_shoppingItems);
  
  var _alphabetItems= [];
  for(var i=0; i<alphabetItems.length; i++) _alphabetItems.push(i);
  _alphabetItems.sort(function(){ return Math.random() - 0.5; });
  
	$('#paneltest'+currentGame+' .shopping-items').html('');
	for(var i=0; i< (alphabetItems.length ? alphabetItems.length : _shoppingItems.length); i++) {
    if(alphabetItems.length){
      var alphabetCode = systemAlphabetCodes[lang][alphabetItems[_alphabetItems[i]]];
      $('#paneltest'+currentGame+' .shopping-items').append('<li onclick="activate(this)" class="drag hide item-'+alphabetCode[0]+'" data-id="'+(_alphabetItems[i]+1)+'" data-text="'+alphabetCode[1]+'"><img src="/images/alphabetsys'+alphabetCode[0]+'.jpg"><span></span></li>');
    } else {
      $('#paneltest'+currentGame+' .shopping-items').append('<li onclick="activate(this)" class="drag hide item-'+_shoppingItems[i].id+'" data-id="'+_shoppingItems[i].id+'" data-text="'+_shoppingItems[i].name+'"><img src="/images/shopping-'+_shoppingItems[i].id+'.png"><span></span></li>');
    }
	}
	showPageItems($('#paneltest'+currentGame+' .shopping-items'), SUPERM_PAGESIZE);
	$('.price-test, .quantity-test').removeClass('show');
	var _itemW = $('li.drag').outerWidth()+10;
	$('#paneltest'+currentGame+' .shopping-items').css('text-align', 'left').css('width', _itemW*(alphabetItems.length ? alphabetItems.length : _shoppingItems.length));

	$('#paneltest'+currentGame+' .shopping-items').css('height','100%');
	$('#paneltest'+currentGame+' .shopping-items .drag').draggable({ cancel: ".wrong", revert: 'invalid'}).css('transition', 'all 0');
	$('#paneltest'+currentGame+' .drop').droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
	$('#paneltest'+currentGame+' .drop-places').droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});

  if(missionParams) { 
    itemTime = 1000000000;
    $('.game-timer').addClass('hide'); 
  } else { $('.game-timer').removeClass('hide'); }
  
	showPanel('paneltest'+currentGame);
	showNextPrev();
	showLives(3);
}

function addFakeItems(_shoppingItems){
	_shoppingItems.splice(parseInt(Math.random()*5), 0, shoppingItems[totalItems]);
	for(var i=1; i<0.2 * totalItems && totalItems + i < shoppingItems.length; i++){
		_shoppingItems.splice(6+parseInt(Math.random()*(totalItems-6)), 0, shoppingItems[totalItems + i]);
	}
}

function initTestSupermarket0(){
  loadShoppingItems();
	currentMode = 'test';
	$('.drop-places').html('');
	$('#paneltest'+currentGame+' .shopping-items').html('');
	var _shoppingItems = shoppingItems.slice(0, totalItems);
  var starting0 = gameLevel == 1 ? 0 : 1;
	for(var i=0; i<totalItems; i+=2) {
		$('#paneltest'+currentGame+' .drop-places').append('<div class="double-drop"><li onclick="verifySupermarket(event)" class="drop" data-id="'+shoppingItems[i].id+'" data-id2="'+shoppingItems[i+1].id+'" data-text="'+shoppingItems[i].name+'"><span class="number">'+(parseInt(i/2)+starting0)+'</span></li>  <li onclick="verifySupermarket(event)" class="drop" data-id="'+shoppingItems[i+1].id+'" data-id2="'+shoppingItems[i].id+'" data-text="'+shoppingItems[i+1].name+'"><span class="number">'+(parseInt(i/2)+starting0)+'</span></li></div>');
	}
	_shoppingItems.sort(function(){ return Math.random() - 0.5; });
	for(var i=0; i< _shoppingItems.length; i++) {
		$('#paneltest'+currentGame+' .shopping-items').append('<li onclick="activate(this)" class="drag hide item-'+_shoppingItems[i].id+'" data-id="'+_shoppingItems[i].id+'" data-text="'+_shoppingItems[i].name+'"><img src="/images/shopping-'+_shoppingItems[i].id+'.png"><span></span></li>');
	}
	showPageItems($('#paneltest'+currentGame+' .shopping-items'), SUPERM_PAGESIZE);
	$('#paneltest'+currentGame+' .shopping-items').css('height','100%');
	$('#paneltest'+currentGame+' .shopping-items .drag').draggable({ cancel: ".wrong", revert: 'invalid'}).css('transition', 'all 0');
	$('#paneltest'+currentGame+' .drop').droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
	$('#paneltest'+currentGame+' .drop-places').droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
  if(itemTime >= 1000000000) { $('.game-timer').addClass('hide'); }
  else { $('.game-timer').removeClass('hide'); }
	showPanel('paneltest'+currentGame);
	showNextPrev();
	showLives(3);
}

function initGameSupermarketGym(){
  loadShoppingItems();
	currentMode = 'learn';
	pageSize = 2;
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && (missionParams.totalItems || systemAlphabetCodes[lang].length))? (missionParams.totalItems || systemAlphabetCodes[lang].length) : levelSettings.items;
	totalPages = Math.ceil(totalItems/pageSize);
	itemTime = 4;//(missionParams && missionParams.item_time)? missionParams.item_time : levelSettings.item_time;
	star2 = levelSettings.star_time_per_item;
	star3 = levelSettings.three_star_time_per_item;

  window.alphabetItems=[];
  shoppingItems.sort(function(){ return Math.random() - 0.5; });
	$('#panelsuperm .header, #panelsuperm .pagination').hide();

	page = -1;
	learnRemainTime = 0;
	penaltyTime =0;
	pageTime = 0;
	pageTimes =[];
	hideButton();
	nextSupermarketPage();
}

function initGameSupermarket(){
  loadShoppingItems();
	currentMode = 'learn';
	pageSize = 5;
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && (missionParams.totalItems || systemAlphabetCodes[lang].length))? (missionParams.totalItems || systemAlphabetCodes[lang].length) : levelSettings.items;
	totalPages = Math.ceil(totalItems/pageSize);
	itemTime = (missionParams && missionParams.item_time)? missionParams.item_time : levelSettings.item_time;
	star2 = levelSettings.star_time_per_item;
	star3 = levelSettings.three_star_time_per_item;

  window.alphabetItems=[];
  if(missionParams && missionParams.source == 'alphabet'){
    for(var i=0; i<systemAlphabetCodes[lang].length; i++) alphabetItems.push(i);
  } else {
    shoppingItems.sort(function(){ return Math.random() - 0.5; });
  }
	$('#panelsuperm .header, #panelsuperm .pagination').hide();

  $('.shopping-list').html('');
  for(var p = 0; p<totalPages; p++) {
    $('.shopping-list').append('<div class="market-content hide market-page market-page-'+p+'"></div>');
    for(var i = p * pageSize; i<totalItems && i<(p+1)*pageSize; i++) {
      if(alphabetItems.length) {
        var alphabetCode = systemAlphabetCodes[lang][alphabetItems[i]];
        $('.market-page-'+p).append('<div class="item item-'+i+'"><span class="no">'+(i+1)+ '</span> <div class="img-holder"> <img src="/images/alphabetsys'+alphabetCode[0]+'.jpg"> </div> <p><b class="name">' +alphabetCode[1]+'</b></div>');        
      } else {
        shoppingItems[i].quantity = parseInt(Math.random() * 9) + 1;
        shoppingItems[i].price = parseInt(Math.random() * 9) + 1;
        $('.market-page-'+p).append('<div class="item item-'+i+'"><span class="no">'+(i + 1)+ '</span> <div class="img-holder"> <img src="/images/shopping-'+shoppingItems[i].id+'.png"> </div> <p><b class="name">' +shoppingItems[i].name +'</b>'+ (levelSettings.quantity ? ' <span class="quantity">' + shoppingItems[i].quantity + '</span> <span class="unit">' +shoppingItems[i].unit + '</span></p> ' : '') + (levelSettings.price ? ' <span class="number">$' + shoppingItems[i].price+ '</span>' : '') +'</div>');
      }
    }
  }
	page = -1;
	learnRemainTime = 0;
	penaltyTime =0;
	pageTime = 0;
	pageTimes =[];
	hideButton();
  
  if(missionParams) {
    itemTime = 1000000000;
    $('.pagination').addClass('hide'); 
  } else { $('.pagination').removeClass('hide'); }
  
	setTimeout(function() {
		nextSupermarketPage();
		setTimeout(function(){ $('#panelsuperm .header').show(); }, 200)
		setTimeout(updateClockLearn, 1000);
	}, 3000);
}

function swapItem(i, j){
  var temp = shoppingItems[i];
  shoppingItems[i] = shoppingItems[j];
  shoppingItems[j] = temp;
}

function moveItem(id, index){
  for(var i=0; i<shoppingItems.length; i++){
    if(shoppingItems[i].id == id) {
      swapItem(i, index);
      return;
    }
  }
}

function initGameSupermarket0(){
  loadShoppingItems();
	currentMode = 'learn';
	pageSize = 10;
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && missionParams.totalItems)? missionParams.totalItems : levelSettings.items;
	totalPages = Math.ceil(totalItems/pageSize);
	itemTime = (missionParams && missionParams.item_time)? missionParams.item_time : levelSettings.item_time;
	star2 = levelSettings.star_time_per_item;
	star3 = levelSettings.three_star_time_per_item;

  // In order to avoid getting the same items in successive games, we do some shuffling of shoppingItems
  var ourItems = shoppingItems.splice(-totalItems);
	ourItems.sort(function () {return Math.random() - 0.5;});
  shoppingItems = ourItems.concat(shoppingItems);

  if(gameLevel==1) {
    moveItem(42,0);
    moveItem(17,1);
  }

	$('#panelsuperm0 .header').hide();

  var starting0 = gameLevel == 1 ? 0 : 1;
	$('.shopping-list').html('');
	for(var p = 0; p<totalPages; p++) {
		$('.shopping-list').append('<div class="market-content hide market-page market-page-'+p+'"></div>');
		for(var i = p * pageSize; i<totalItems && i<(p+1)*pageSize; i+= 2) {
			$('.market-page-'+p).append('<div class="item double-item item-'+i+'"><span class="no">'+(parseInt(i/2) + starting0)+ '</span> <div class="item-holder"><div class="img-holder"> <img src="/images/shopping-'+shoppingItems[i].id+'.png"> </div> <p><b class="name">' +shoppingItems[i].name +'</b></p></div>  <div class="item-holder"><div class="img-holder"> <img src="/images/shopping-'+shoppingItems[i+1].id+'.png"> </div> <p><b class="name">' +shoppingItems[i+1].name +'</b></p></div></div>');
		}
	}
	page = -1;
	learnRemainTime = 0;
	penaltyTime =0;
	pageTime = -1;
	pageTimes = [];
	hideButton();
  
  if(itemTime >= 1000000000) { $('.pagination').addClass('hide'); }
  else { $('.pagination').removeClass('hide'); }
  
	setTimeout(function() {
		nextSupermarketPage();
		setTimeout(function(){ $('#panelsuperm0 .header').show(); }, 200)
		setTimeout(updateClockLearn, 1000);
	}, 3000);
}

function verifySupermarket(e, ui){
	var target, dragItem;
	if(e.type == 'drop') {
		target = $(e.target);
		dragItem = ui.draggable;
		itemId = dragItem.data('id');
	} else {
		target = $(e.currentTarget);
		dragItem = $('#paneltest'+currentGame+' .dock-items .drag.active');
		itemId = dragItem.data('id');
	}
	if(itemId == null || target==null || target.data('id') == null) {
		dragEnd(dragItem);
	} else if(itemId == target.data('id')){
    if(alphabetItems.length) {
      target.html('<img src="/images/alphabetsys'+systemAlphabetCodes[lang][alphabetItems[itemId-1]][0]+'.jpg">');
    } else {
      target.html('<img src="/images/shopping-'+itemId+'.png">');
    }
		target.removeClass('wrong').addClass('correct');
    var setEmpty = 1;
    if(isMobile) {
      setTimeout(function(){
        target.remove();
        setEmpty = 0;
      },1400);
    }
		dragItem.remove();
		showPageItems($('#paneltest'+currentGame+' .shopping-items'), SUPERM_PAGESIZE);
		playSoundAction('music-correct');
		if(levelSettings.quantity) {
			$('.quantity-test .num-correct').text(target.data('quantity'));
			$('.price-test .num-correct').text(target.data('price'));
			$('.quantity-test').addClass('show');
		} else if($('#paneltest'+currentGame+' .shopping-drag .correct').length == totalItems || (isMobile && $('#paneltest'+currentGame+' .shopping-drag .drop-places').children().length == setEmpty)){
			showResult(totalItems, totalItems);
		}
		showNextPrev();
	} else if(!target.hasClass('correct')) {
		playSoundAction('music-wrong');
		target.addClass('wrong');
		dragItem.addClass('wrong');
		dragItem.find('span').text(5);
		dragEnd(dragItem);
		checkGameOver();
	}
}

function verifySupermarketQuantity() {
	if($('.quantity-test .num-answer').text() == $('.quantity-test .num-correct').text()) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function(){
			$('.quantity-test').removeClass('show');
			$('.tick').removeClass('animate');
			if(levelSettings.price) {
				$('.price-test').addClass('show');
			} else {
				showResult($('.shopping-drag .correct').length, $('.shopping-drag .drop').length);
			}
		}, 500);
		showNextPrev();
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function(){
			$('.tick-red').removeClass('animate');
		}, 500);
		checkGameOver();
	}
	$('.num-answer').html('');
}

function verifySupermarketPrice() {
	if($('.price-test .num-answer').text() == $('.price-test .num-correct').text()) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function(){
			$('.tick').removeClass('animate');
			$('.price-test').removeClass('show');
			showResult($('.shopping-drag .correct').length, $('.shopping-drag .drop').length);
		}, 500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function(){
			$('.tick-red').removeClass('animate');
		}, 500);
		checkGameOver();
	}
	showNextPrev();
	$('.num-answer').html('');
}

function verifySupermarket0(e, ui){
	var target, dragItem;
	if(e.type == 'drop') {
		target = $(e.target);
		dragItem = ui.draggable;
		itemId = dragItem.data('id');
	} else {
		target = $(e.currentTarget);
		dragItem = $('#paneltest'+currentGame+' .dock-items .drag.active');
		itemId = dragItem.data('id');
	}
	if(itemId == null || target==null || target.data('id') == null) {
		dragEnd(dragItem);
	} else if(itemId == target.data('id') || itemId == target.data('id2')){
		target.html('<img src="/images/shopping-'+itemId+'.png">');
		target.removeClass('wrong').addClass('correct');
		dragItem.remove();
		showPageItems($('#paneltest'+currentGame+' .shopping-items'), SUPERM_PAGESIZE);
		playSoundAction('music-correct');
		if($('#paneltest'+currentGame+' .shopping-drag .correct').length == totalItems){
			if(getGameLevel('superm0') < 1 || missionParams) {
				showResult(totalItems, totalItems);
			} else {
        // The signup panel has been removed?
				//showPanel('panelsignup');
        showResult(totalItems, totalItems);
			}
		}
	} else if(!target.hasClass('correct')) {
		playSoundAction('music-wrong');
		target.addClass('wrong');
		dragItem.addClass('wrong');
		dragItem.find('span').text(5);
		dragEnd(dragItem);
		checkGameOver();
	}
}

function nextSupermarketPage(button) {
	if($(button).hasClass('inactive')) return;
	page += 1;
	if(page >= totalPages) {
		startTesting();
	} else {
  
    if(!missionParams && gamestate.data.gameMode === 'gym'){
      showPages();
      //populate panelsupermgym
      $('#panelsupermgym').html(TemplateEngine($('#tpl-supermgym').html(), {}));
			$('#panelsupermgym input.knob').val((100 * page+1) / totalPages).knob();
      $('#panelsupermgym .preview1, #panelsupermgym .preview2').addClass('preview');
      translate('panelsupermgym');
    } else {
      learnRemainTime += pageTime >= 0 ? pageTime : 0;
      pageTimes.push(pageTime >= 0 ? pageTime0 - pageTime : pageTime0);
      $('.market-page').addClass('hide');
      showPages();
      $('.market-page-'+page).removeClass('hide');
      var i = page*pageSize;
      previewShoppingItem(i);
      $('#panelsuperm .next-page, #panelsuperm .next-test, #panelsuperm0 .next-page, #panelsuperm0 .next-test').addClass('inactive');
      setTimeout(function(){
        $('#panelsuperm .next-page, #panelsuperm .next-test, #panelsuperm0 .next-page, #panelsuperm0 .next-test').removeClass('inactive');
      },6000);
    }
  }
}

function previewShoppingItem(i) {
	setTimeout(function(){
		$('.item-'+i).addClass('preview');
		if(i < (page + 1)*pageSize-1) {
			i++;
			previewShoppingItem(i);
		}
	}, 500);
}

function showNextPrev(){
	if($('#paneltestsuperm .drag').length<=6)
		$('#paneltestsuperm .next, #paneltestsuperm .prev').hide();
	else
		$('#paneltestsuperm .next, #paneltestsuperm .prev').show();
}
