var currentSound;

function playSound(id) {
  console.log("playSound", id);
  currentSound = id;
  pauseSounds();

  var elem = document.getElementById(id);
  if (elem !== null) {
    if (elem.readyState < 4) {
      elem.load();      
    }
    elem.currentTime = 0;
    if (!window.gamestate.data.mute) {
      elem.play();
    }
  }
}

function playSoundAction(id, time) {
  if (window.gamestate.data.mute) return;

  var elem = document.getElementById(id);
  if (elem !== null) {
    if (elem.readyState < 4) {
      elem.load();      
    }
    elem.play();    
  }

  if (time) setTimeout(function(){ pauseSound(id) }, time);
}

function pauseSound(id) {
  try {
    document.getElementById(id).pause();
  } catch(err) { }
}

function pauseSounds() {
  for(var i = 0; i < $('audio').length; i++) {
    pauseSound($('audio')[i].pause());
  }
}

function toggleVolume(e) {
  if ($(e).hasClass('on')) {
    window.gamestate_utils.set({'mute': true});
    $(e).removeClass('on').addClass('off');
    $('.sound-control, .d-sounds').removeClass('on').addClass('off');
    pauseSounds();
  } else {
    window.gamestate_utils.set({'mute': false});
    $(e).removeClass('off').addClass('on');
    $('.sound-control, .d-sounds').removeClass('off').addClass('on');
    playSound(currentSound);
  }
}
