window.doublesys_data = {
  brain: [{
    number: "00",
    en: {
      life_area: "Functional Brain Training",
      object_code: "Brain halves",
      object_explanation: "The brain halves symbolize the Life Area <u>Functional Brain Training</u>.",
      animation_description: "0 as left brain + 0 as right brain",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Albert Einstein",
      person_explanation: "Yup, not much doubt that Albert Einstein was smart enough to be used as the person for <u>Functional Brain Training</u>.",
      action_code: "Pointing fingers to the head",
      action_explanation: "Pointing the index fingers to each side of the forehead is a good action for Albert Einstein as well as for <u>Functional Brain Training</u>."
    },
    no: {
      life_area: "Funksjonell hjernetrening",
      object_code: "Hjernehalvdeler",
      object_explanation: "Hjernehalvdelene symboliserer Livsområdet <u>Funksjonell hjernetrening</u>.",
      animation_description: "0 som venstre-hjerne + 0 som høyre-hjerne",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Albert Einstein",
      person_explanation: "Neppe noen tvil om at Albert Einstein var smart nok til å bli brukt som personen for <u>Funksjonell hjernetrening</u> i Memolife systemet.",
      action_code: "Å peke pekefingrene til tinningen",
      action_explanation: "Å peke pekefingrene til tinningen er en god handling for Albert Einstein og for  <u>Funksjonell hjernetrening</u>."
    }
  }, {
    number: "01",
    en: {
      life_area: "Focus & Concentration",
      object_code: "Candle",
      object_explanation: "The candle symbolizes the Life Area <u>Focus & Concentration</u> (focus on the light).",
      animation_description: "0 as oval picture frame + 1 as candle light",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Sherlock Holmes",
      person_explanation: "Sherlock Holmes - the world's most famous detective played by Robert Downey Jr. - has incredible <u>Focus & Concentration</u> abilities.",
      action_code: "Focusing through magnifying glass",
      action_explanation: "Sherlock Holmes can easily be visualized focusing through a magnifying glass to improve his <u>Focus & Concentration</u> skills."
    },
    no: {
      life_area: "Fokus & Konsentrasjon",
      object_code: "Stearinlys",
      object_explanation: "Stearinlyset symboliserer Livsområdet <u>Fokus & Konsentrasjon</u> (fokuser på lyset).",
      animation_description: "0 som oval billedramme + 1 som stearinlys ",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Sherlock Holmes",
      person_explanation: "Sherlock Holmes - verdens mest berømte detektiv personifisert av Robert Downey Jr. - har sinnsyke ferdigheter i <u>Fokus & Konsentrasjon<u/>.",
      action_code: "Å fokusere gjennom forstørrelsesglass",
      action_explanation: "Sherlock Holmes var knallskarp - fort gjort å se ham for seg bruke et forstørrelsesglass for å få bedre <u>Fokus & Konsentrasjon</u>."
    }
  }, {
    number: "02",
    en: {
      life_area: "Memory",
      object_code: "Oxygen tank",
      object_explanation: "The oxygen tank symbolizes the Life Area <u>Memory</u>  (oxygen is needed to remember).",
      animation_description: "0 as oxygen tank + 2 as switch",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Dumbo the elephant",
      person_explanation: "Elephants are known for their amazing <u>Memory</u>, although Dumbo the Disney elephant was more known for having huge ears.",
      action_code: "Sticking a post-it note",
      action_explanation: "What better way to remember which action to use for our <u>Memory</u> elephant Dumbo than sticking a post-it note somewhere?"
    },
    no: {
      life_area: "Hukommelse",
      object_code: "Oksygentank",
      object_explanation: "Oksygentanken symboliserer Livsområdet <u>Hukommelse</u> (oksygen behøves for å huske).",
      animation_description: "0 som oksygentank + 2 som ventil",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Elefanten Dumbo",
      person_explanation: "Elefanter er kjent for deres utrolige <u>Hukommelse</u>, selv om Dumbo fremfor alt var kjent for å ha store ører (og å kunne fly).",
      action_code: "Å plassere en post-it",
      action_explanation: "Dumbos ører fulle av gule lapper? Elefanten Dumbo har ihvertfall <u>Hukommelse</u> som en ... elefant."
    }
  }, {
    number: "03",
    en: {
      life_area: "Mental Calculation",
      object_code: "Old phone",
      object_explanation: "The old phone with the numbers symbolizes the Life Area <u>Mental Calculation</u>.",
      animation_description: "old handphone with 0 as number selection + 3 as the handset",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Cleopatra",
      person_explanation: "The Egyptians were the first known advanced mathematicians, so why not use Cleopatra to represent <u>Mental Calculation</u>?",
      action_code: "Finger-counting",
      action_explanation: "Counting with one's fingers is how we used to do <u>Mental Calculation</u> when we were kids. Cleopatra as a teacher, yup, could be worse."
    },
    no: {
      life_area: "Hoderegning",
      object_code: "Gammel Telefon",
      object_explanation: "Den gamle telefonen symboliserer Livsområdet <u>Hoderegning</u>.",
      animation_description: "gammel telefon med 0 som tallskive + 3 som telefonrøret",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Cleopatra",
      person_explanation: "Egypterne var de første avanserte matematikerne, so hvorfor ikke bruke Cleopatra for å representere <u>Hoderegning</u>?",
      action_code: "Å telle med fingrene",
      action_explanation: "Å telle med fingrene var jo noe vi gjorde for <u>Hoderegning</u> da vi var små. Med Cleopatra som lærer - kunne vært verre!"
    }
  }, {
    number: "04",
    en: {
      life_area: "Logical Intelligence",
      object_code: "Badminton feather",
      object_explanation: "The badminton feather symbolilzes the Life Area <u>Logical Intelligence</u> (it's logical that the head falls down first).",
      animation_description: "0 as badminton feather tip + 4 as badminton feather body",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Bill Gates",
      person_explanation: "All right, it's safe to say that Bill Gates is quite up there when it comes to <u>Logical Intelligence</u>.",
      action_code: "Finger-typing",
      action_explanation: "Ok, finger-typing is not the sexiest action, but hey, it's <u>Logical Intelligence</u> and Bill Gates we're talking about. As long as it works, it works."
    },
    no: {
      life_area: "Logisk Intelligens",
      object_code: "Badmintonfjær",
      object_explanation: "Badmintonfjæren symboliserer Livsområdet <u>Logisk Intelligens</u> (det er logisk at tuppen faller ned først).",
      animation_description: "0 som badmintonfjær-tupp + 4 som badmintonfjær-kropp ",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Bill Gates",
      person_explanation: "Ok, at Bill Gates har <u>Logisk Intelligens</u> er neppe noen overraskelse.",
      action_code: "Å taste med fingrene",
      action_explanation: "Ok, å taste med fingrene er kanskje ikke den mest sexy handlingen, men hei - det er <u>Logisk Intelligens</u> og Bill Gates vi snakker om."
    }
  }, {
    number: "05",
    en: {
      life_area: "Verbal Intelligence & Flexibility",
      object_code: "Mouth",
      object_explanation: "The mouth symbolizes the Life Area Verbal <u>Intelligence & Flexibility</u>.",
      animation_description: "0 as mouth + 5 as twisted tongue",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Barack Obama",
      person_explanation: "The American president is known for his brilliant speeches showing off his <u>Verbal Intelligence & Flexibility</u>.",
      action_code: "Tongue-twisting",
      action_explanation: "Tongue-twisting is a funny action which is easily associated with <u>Verbal Intelligence & Flexibility</u> as well as Obama."
    },
    no: {
      life_area: "Verbal Intelligens & Fleksibilitet",
      object_code: "Munn",
      object_explanation: "Munnen symboliserer Livsområdet <u>Verbal Intelligens & Fleksibilitet</u>.",
      animation_description: "0 som munn + 5 som vridd tunge",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Barack Obama",
      person_explanation: "Den amerikanske presidenten er kjent for å briljere med <u>Verbal Intelligens & Fleksibilitet</u> i sine taler.",
      action_code: "Å vri tungen",
      action_explanation: "Å vri tungen er en morsom handling som enkelt lar seg knytte opp med <u>Verbal Intelligens & Fleksibilitet</u>, så vel som med Obama."
    }
  }, {
    number: "06",
    en: {
      life_area: "Visual-Spatial Intelligence",
      object_code: "Cone & cylinder",
      object_explanation: "The cylinder and cone symbolize the Life Area <u>Visual-Spatial Intelligence</u>. ",
      animation_description: "0 as cylinder + 6 as cone",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Salvador Dali",
      person_explanation: "Known for paintings with breathtaking visual effects, Salvador Dali almost defines <u>Visual-Spatial Intelligence</u>.",
      action_code: "Painting",
      action_explanation: "Painting and <u>Visual-Spatial Intelligence</u> is a no-brainer. Add Salvador Dali into the mix and you've got fireworks in the making."
    },
    no: {
      life_area: "Visuell-Romlig Intelligens",
      object_code: "Sylinder & Kjegle",
      object_explanation: "Sylinderen og kjeglen symboliserer Livsområdet <u>Visuell-Romlig Intellligens</u>.",
      animation_description: "0 som sylinder + 6 som kjegle",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Salvador Dali",
      person_explanation: "Kjent for malerier med imponerende visuelle effekter, personifiserer Salvador Dali <u>Visuell-Romlig Intelligens</u>.",
      action_code: "Å male",
      action_explanation: "Å male knyttet opp mot <u>Visuell-Romlig Intelligens</u> er som fot i hose. Sleng inn Salvador Dali i bildet og kunstverket er komplett."
    }
  }, {
    number: "07",
    en: {
      life_area: "Mindmapping & Note-Taking",
      object_code: "Tree branch",
      object_explanation: "The tree branch symbolizes the Life Area <u>Mindmapping & Note-taking</u>.",
      animation_description: "0 as leaf + 7 as branch",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Paul the octopus oracle",
      person_explanation: "The octopus famous for predicting the outcome of world cup matches also has a shape which nicely represents <u>Mindmapping & Note-taking</u>.",
      action_code: "Mindmapping",
      action_explanation: "In your inner cartoon world the octopus Paul is <u>Mindmapping & Note-taking</u> his thoughts at bottom of the sea. "
    },
    no: {
      life_area: "Mindmapping & Notatteknikk",
      object_code: "Tregren",
      object_explanation: "Tregrenen symboliserer Livsområdet </u>Mindmapping & Notatteknikk</u>.",
      animation_description: "0 som blad + 7 som gren",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Blekkspruten Paul",
      person_explanation: "Blekkspruten Paul, kjent for å spå riktig resultat av fotballkamper, har også en fasong som ligner <u>Mindmapping & Notatteknikker</u>.",
      action_code: "Å mindmappe",
      action_explanation: "I din indre hukommelses-tegnefilmverden holder blekkspruten Paul på med <u>Mindmapping & Notatteknikk</u> på havets bunn."
    }
  }, {
    number: "08",
    en: {
      life_area: "Speed Reading",
      object_code: "Race track",
      object_explanation: "The race track symbolizes the Life Area <u>Speed Reading</u>.",
      animation_description: "0 as audience tribune + 8 as race track",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "John F. Kennedy",
      person_explanation: "The American president was an average reader, but later mastered <u>Speed Reading</u> of 1200 words per minute.",
      action_code: "Flipping book pages",
      action_explanation: "All right, unfortunately JFK is long gone, but his ghost may still be <u>Speed Reading</u> and flipping book pages at the speed of light."
    },
    no: {
      life_area: "Hurtiglesing",
      object_code: "Racerbane",
      object_explanation: "Racerbanen symboliserer Livsområdet <u>Hurtiglesing</u>.",
      animation_description: "0 som publikumstribune + 8 som racerbane",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "John F. Kennedy",
      person_explanation: "Den amerikanske presidenten var en gjennomsnittlig leser, men endte opp med å nå en rå hastighet på 1200 ord i minuttet med <u>Hurtiglesing</u>.",
      action_code: "Å bla boksider",
      action_explanation: "All right, dessverre er JFK ikke med oss lenger, men spøkelset hans holder fremdeles på å bla seg gjennom boksider med <u>Hurtiglesing</u> i lynets hastighet."
    }
  }, {
    number: "09",
    en: {
      life_area: "Accelerated Learning & Learning Styles",
      object_code: "Golf club",
      object_explanation: "The golf club symbolizes the Life Area <u>Accelerated Learning & Learning Stypes</u> (a golf swing is fast & different golf clubs represent different learning styles).",
      animation_description: "0 as golf ball + 9 as golf club",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Tiger Woods",
      person_explanation: "The world's best golfer used <u>Accelerated Learning & Learning Styles</u> principles to skyrocket himself into golf stardom.",
      action_code: "Golf swinging",
      action_explanation: "Golf swinging is what \"Tiger\" does best, having used <u>Accelerated Learning & Learning Styles</u> to experiment with infinite ways of hitting that hole in one."
    },
    no: {
      life_area: "Akselerert Læring & Læringstyper",
      object_code: "Golfkølle",
      object_explanation: "Golfkøllen symboliserer Livsområdet <u>Akselerert Læring & Læringstyper</u>.",
      animation_description: "0 som golfball + 9 som golfkølle",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Tiger Woods",
      person_explanation: "Verdens beste golfer brukte prinsipper innen <u>Akselerert Læring & Læringstyper</u> for å skyte seg opp i golfhimmelen.",
      action_code: "Å slå golfswing",
      action_explanation: "Å slå golfswng gjør \"Tiger\" i søvne mens han bruker <u>Akselerert Læring & Læringstyper</u> for å eksperimentere med uendelige måter å slå \"hole in one\" på."
    }
  }],
  knowledge: [{
    number: "10",
    en: {
      life_area: "Native Language Improvement",
      object_code: "Knife and plate",
      object_explanation: "The knife and plate symbolize the Life Area <u>Native Language Improvement</u> (cutting complex words into bitesize learning experiences).",
      animation_description: "1 as knife + 0 as plate",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "J.K. Rowling",
      person_explanation: "The Harry Potter author uses a continuous <u>Native Language Improvement</u> to tell her stories. Now, she even writes crime books under a pseudonym.",
      action_code: "Using magic wand",
      action_explanation: "What better action for J. K. Rowling than using a magic wand for <u>Native Language Improvement</u>?"
    },
    no: {
      life_area: "Morsmålsforbedring",
      object_code: "Kniv Og Tallerken",
      object_explanation: "Kniv og tallerken symboliserer Livsområdet <u>Morsmålsforbedring</u> (å kutte komplekse ord til små læringsopplevelser).",
      animation_description: "1 som kniv + 0 som tallerken",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "J.K. Rowling",
      person_explanation: "Harry Potter-forfatteren bruker kontinuerlig <u>Morsmålsforbedring</u> - nå skriver hun til og med krim under et pseudonym.",
      action_code: "Å bruke tryllestav",
      action_explanation: "Finnes det noen bedre handling for J. K. Rowling enn å bruke en tryllestav for <u>Morsmålsforbedring</u>? Neppe."
    }
  }, {
    number: "11",
    en: {
      life_area: "Accelerated Language Learning",
      object_code: "Chop sticks",
      object_explanation: "The chop sticks symbolize the Life Area <u>Accelerated Language Learning<u> (picking up small language pieces at super-fast speed).",
      animation_description: "1 + 1 as chop sticks",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Lucy Liu",
      person_explanation: "The actress speaks 6 languages, making her our chosen <u>Accelerated Language Learning</u> ambassador for the Memolife system.",
      action_code: "Waving a world flag banner",
      action_explanation: "Waving a world flag banner is the natural way to celebrate being fluent in a new language after a few months of <u>Accelerated Language Learning</u>."
    },
    no: {
      life_area: "Akselerert Språklæring",
      object_code: "Spisepinner",
      object_explanation: "Spisepinnene symboliserer Livsområdet <u>Akselerert Språklæring</u> (å plukke opp små språkbiter i super hastighet).",
      animation_description: "1 + 1 som spisepinner",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Lucy Liu",
      person_explanation: "Skuespilleren snakker 6 språk. Jepp. Dermed vinner hun ambassdørtittelen for <u>Akselerert Språklæring</u> i Memolife-systemet.",
      action_code: "Å vifte med verdensflagg",
      action_explanation: "Å vifte med verdensflagg er en naturlig handling for å feire at du behersker et nytt språk etter noen måneder med <u>Akselerert Språklæring</u>."
    }
  }, {
    number: "12",
    en: {
      life_area: "Nature & Animals",
      object_code: "Whale",
      object_explanation: "The whale symbolizes the Life Area <u>Nature & Animals</u>.",
      animation_description: "1 as ocean surface + 2 as whale on top water",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Pamela Anderson",
      person_explanation: "The Baywatch TV actress represents the animal organisation PETA, so using her for the <u>Nature & Animals</u> category was a no-brainer.",
      action_code: "Petting (something)",
      action_explanation: "Now, there are worse things to do than visualizing a Pamela Anderson in her prime petting anything <u>Nature & Animals</u> related."
    },
    no: {
      life_area: "Natur & Dyr",
      object_code: "Hval",
      object_explanation: "Hvalen symboliserer Livsområdet <u>Natur & Dyr</u>. ",
      animation_description: "1 som vannflate + 2 som hval",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Pamela Anderson",
      person_explanation: "Baywatch TV-stjernen er også kjent som dyreverner og representant for PETA - og nå også som representant for <u>Natur & Dyr</u> hos oss.",
      action_code: "Å kose (noe)",
      action_explanation: "Det finnes verre ting enn å se for seg Pamela Anderson kose med <u>Natur & Dyr</u>."
    }
  }, {
    number: "13",
    en: {
      life_area: "Physics",
      object_code: "Bridge",
      object_explanation: "The bridge symbolizes the Life Area <u>Physics</u>.",
      animation_description: "1 as horizontal line + 3 sideways as bridge arches",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Stephen Hawking",
      person_explanation: "The famous physicist, also known for being paralyzed and sitting in a wheelchair, is one of the best scientists in <u>Physics</u> we have.",
      action_code: "Stumbling",
      action_explanation: "Ok, this action is mean given our <u>Physics</u> genius is in a wheelchair, but gravity is gravity - and luckily we know he's got a good sense of humor."
    },
    no: {
      life_area: "Fysikk",
      object_code: "Bro",
      object_explanation: "Broen symboliserer Livsområdet <u>Fysikk</u>.",
      animation_description: "1 som horisonal linje + 3 sidelengs som buer i en bro",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Stephen Hawking",
      person_explanation: "Den verdensberømte vitenskapsmannen i rullestol er kanskje den beste eksperten i <u>Fysikk</u> vi har. ",
      action_code: "Å snuble",
      action_explanation: "Ok, denne handlingen er jo litt slem med tanke på at vårt <u>Fysikk</u>-geni sitter i rullestol, men tyngdekraften gjelder ... og heldigvis har han humor."
    }
  }, {
    number: "14",
    en: {
      life_area: "Geography",
      object_code: "Sail ship",
      object_explanation: "The sail ship symbolizes the Life Area <u>Geography</u>.",
      animation_description: "1 as mast + 4 as sail",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Viking Leif Eriksson",
      person_explanation: "The true discoverer of America were the Vikings, so who better to use as a person for <u>Geography</u> than the viking Leif Eriksson?",
      action_code: "Rowing",
      action_explanation: "The viking ships were tailor-made for rowing in shallow rivers, enabling them to explore the <u>Geography</u> of even the most remote places."
    },
    no: {
      life_area: "Geografi",
      object_code: "Seilskip",
      object_explanation: "Seilskipet symboliserer Livsområdet <u>Geografi</u>.",
      animation_description: "1 som mast + 4 som seil",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Viking Leif Eriksson",
      person_explanation: "De virkelige oppdagerene av Amerika var jo vikingene, så hvem andre enn vikingen Leif Eriksson burde personifisere <u>Geografi</u>?",
      action_code: "Å ro",
      action_explanation: "Vikingskipene var skreddersydd for å ro i grunne elver - for å utforske <u>Geografi</u> i de fjerneste steder."
    }
  }, {
    number: "15",
    en: {
      life_area: "Music & Film",
      object_code: "Music note",
      object_explanation: "The music note symbolizes the Life Area <u>Music & Film</u>.",
      animation_description: "1 as note line + 5 as note",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jennifer Lopez",
      person_explanation: "Althoug perhaps not the best, the entertainer has had great success with her music & movies, making her a good person to represent <u>Music & Film</u>.",
      action_code: "Doing a pirouette",
      action_explanation: "She sings, she acts, she dances and in the Memolife system J-Lo does a pirouette, representing both <u>Music & Film</u>."
    },
    no: {
      life_area: "Musikk & Film",
      object_code: "Musikknote",
      object_explanation: "Musikknoten symboliserer Livsområdet <u>Musikk & Film.</u>",
      animation_description: "1 som notelinje + 5 som note",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jennifer Lopez",
      person_explanation: "J-Lo har hatt stor suksess innen både <u>Musikk & Film</u>.",
      action_code: "Å ta en piruett",
      action_explanation: "Hun synger, hun \"skuespillerer\", hun danser ... og i Memolife-systemet gjør hun en piruett for kategorien <u>Musikk & Film</u>."
    }
  }, {
    number: "16",
    en: {
      life_area: "Religion",
      object_code: "Eye",
      object_explanation: "The (third) eye symbolizes the Life Area <u>Religion</u>.",
      animation_description: "1 as eyebrow + 6 as eye",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Uma Thurman",
      person_explanation: "Known for being a buddhist, Uma Thurman embodies <u>Religion</u>, even though she does kill Bill in the end.",
      action_code: "Opening a third eye",
      action_explanation: "Ah, we don't know if she's found enlightenment yet, but opening a third eye will hopefully give ms. Thurman more insight into <u>Religion</u>."
    },
    no: {
      life_area: "Religion",
      object_code: "Øye",
      object_explanation: "Det tredje øyet symboliserer Livsområdet <u>Religion</u>.",
      animation_description: "1 som øyebryn + 6 som øye",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Uma Thurman",
      person_explanation: "Kjent som buddhist, kan Uma virkelig personifisere <u>Religion</u>, selv om hun til slutt dreper Bill.",
      action_code: "Å åpne et tredje øye",
      action_explanation: "Vi vet enda ikke om hun har funnet opplysning, men håper at det å åpne et tredje øye ihvertfall gir fru Thurman mer innsikt i <u>Religion</u>."
    }
  }, {
    number: "17",
    en: {
      life_area: "Art",
      object_code: "Ladder",
      object_explanation: "The ladder symbolizes the Life Area <u>Art</u> (the artist uses the ladder to create a sculpture).",
      animation_description: "1 as vertical ladder side + 7 as ladder steps",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "David ",
      person_explanation: "As the world's most famos sculpture by Michelangelo, \"David\" actually is <u>Art</u>.",
      action_code: "Chiseling",
      action_explanation: "David chiseling himself into life ... it's almost performance <u>Art</u>. Just like the mental art you'll be chiseling and visualizing in your mnemonics."
    },
    no: {
      life_area: "Kunst",
      object_code: "Stige",
      object_explanation: "Stigen symboliserer Livsområdet <u>Kunst</u> (kunstneren bruker stigen til å lage en skulptur).",
      animation_description: "1 som stigeside + 7 som stigesteg",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "David ",
      person_explanation: "Som verdens mest berømte skulptur (av Michelangelo), er \"David\" <u>Kunst</u> i seg selv.",
      action_code: "Å meisle",
      action_explanation: "David som meisler seg selv .... det er jo nesten performance-<u>Kunst</u>. Lik den mentale kunsten du kommer til å meisle med husketeknikkene."
    }
  }, {
    number: "18",
    en: {
      life_area: "History",
      object_code: "Sand clock",
      object_explanation: "The ancient sand clock symbolizes the Life Area <u>History</u>.",
      animation_description: "1 as vertical support structure + 8 as hour glass shape",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Achilles",
      person_explanation: "The epic Greek soldier, played by Brad Pitt in the movie Troy, personifies the hero from ancient <u>History</u>.",
      action_code: "Sword-fighting",
      action_explanation: "What would <u>History</u> have been without sword-fighting ... ? And what would memory techniques be without vivid and bloody sword fighting?"
    },
    no: {
      life_area: "Historie",
      object_code: "Timeglassklokke",
      object_explanation: "Den gamle timeglassklokken symboliserer Livsområdet <u>Historie</u>.",
      animation_description: "1 som vertikal støttestruktur + 8 som timeglassfigur",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Akilles",
      person_explanation: "Den episke greske soldaten Akilles, spilt av Brad Pitt i filmen \"Troja\", personifiserer <u>Historie</u>.",
      action_code: "Å sverd-fekte",
      action_explanation: "Hva ville <u>Historie</u> ha vært uten sverd-fekting? Og hva ville vel husketeknikker ha vært uten blodig sverd-fekting?"
    }
  }, {
    number: "19",
    en: {
      life_area: "Sport",
      object_code: "Bow",
      object_explanation: "The bow and arrow symbolize the Life Area <u>Sports</u>.",
      animation_description: "1 as string + 9 as bow",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Katniss Everdeen",
      person_explanation: "The main character uses a bow and arrow in the sci-fi series \"Hunger Games\", where a <u>Sport</u> to the death is the plot. ",
      action_code: "Shooting an arrow",
      action_explanation: "Shooting an arrow into her enemies is what Katniss does best in the movie series' twisted sense of <u>Sport</u>. "
    },
    no: {
      life_area: "Sport",
      object_code: "Bue",
      object_explanation: "Buen og pilen symboliserer Livsområdet <u>Sport</u>.",
      animation_description: "1 som sene + 9 som bue",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Katniss Everdeen",
      person_explanation: "Hovedpersonen i filmene Hunger Games bruker pil og bue hvor en <u>Sport</u> \"til døden\" er handlingen.",
      action_code: "Å skyte en pil",
      action_explanation: "Å skyte pil i hennes fiender var det Katniss gjorde best i den forvridde filmseriens oppfatning av <u>Sport</u>."
    }
  }, {
    number: "20",
    en: {
      life_area: "Astronomy",
      object_code: "UFO",
      object_explanation: "The UFO symbolizes the Life Area <u>Astronomy</u>.",
      animation_description: "2 as stairs + 0 horizontally as UFO on top",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Elon Musk",
      person_explanation: "Known for Paypal, Tesla and now for revolutionizing space travel with Space-X, who else can personify <u>Astronomy</u>?",
      action_code: "Hovering",
      action_explanation: "Anti-gravity force fields or pure illusions? The reported hovering UFOs are surely not making Elon any less interested in <u>Astronomy</u>."
    },
    no: {
      life_area: "Astronomi",
      object_code: "Ufo",
      object_explanation: "UFO'en symboliserer Livsområdet <u>Astronomi</u>.",
      animation_description: "2 trappetrinn + 0 horisontalt som UFO på toppen",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Elon Musk",
      person_explanation: "Kjent fra Paypal, Tesla og nå for å revolusjonere romindustrien med Space-X, er Elon Musk det selvfølgelige valget for <u>Astronomi</u>.",
      action_code: "Å sveve",
      action_explanation: "Anti-tyngdekraftfelt eller rene illusjoner? Rapportene om svevende UFO'er gjør ihvertfall neppe Elon mindre interessert i <u>Astronomi</u>."
    }
  }, {
    number: "21",
    en: {
      life_area: "Philosophy",
      object_code: "Thinker statue",
      object_explanation: "The thinker statue symbolizes the Life Area <u>Philosophy</u>.",
      animation_description: "2 as thinker statue + 1 as floor",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Aristotle",
      person_explanation: "The Greek philospher was to inspire all other scholars of <u>Philosophy</u> in one way or another.",
      action_code: "Thinking",
      action_explanation: "Thinking and visualizing cartoon thought bubbles into existence - what better ways to represent <u>Philosophy</u>?"
    },
    no: {
      life_area: "Filosofi",
      object_code: "Tenkerstatuen",
      object_explanation: "Tenkerstatuen symboliserer Livsområdet <u>Filosofi</u>.",
      animation_description: "2 som tenkerstatuen + 1 som gulv",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Aristoteles",
      person_explanation: "Den greske tenkeren har påvirket nær sagt alle kjente tenkere i <u>Filosofi</u> som har kommet etter ham.",
      action_code: "Å tenke",
      action_explanation: "Å tenke seg og visualisere tankebobler - finnes det noen bedre måte å representere <u>Filosofi</u> på?"
    }
  }, {
    number: "22",
    en: {
      life_area: "Psychology & Sociology",
      object_code: "Large mirror",
      object_explanation: "The mirror symbolizes the Life Area <u>Psychology</u>.",
      animation_description: "one 2 upside down + one normal 2 = old mirror with a beautiful ornament frame ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Sigmund Freud",
      person_explanation: "Known as the \"godfather\" of <u>Psychology</u>, Freud both invented psychoanalysis end experimented with different drugs.",
      action_code: "Crying",
      action_explanation: "Cry me a river! Yes, dealing with emotions is all <u>Psychology</u>, and although Sigmund looks very stiff in photos, we're sure he'd cry watching a chick flic."
    },
    no: {
      life_area: "Psykologi",
      object_code: "Stort Speil",
      object_explanation: "Det store speilet symboliserer Livsområdet <u>Psykologi</u>.",
      animation_description: "en 2 opp-ned + en 2 normal = gammelt speil med flotte ornamenter",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Sigmund Freud",
      person_explanation: "Kjent som gudfaren innen <u>Psykologi</u>, men også som oppfinneren av psykoterapi og en som eksperimenterte med medikamentene selv.",
      action_code: "Å gråte",
      action_explanation: "Cry me a river! Å forholde seg til følelser er nøkkelen i all <u>Psykologi</u>, og uansett om Sigmund så streng ut på bilder er vi sikre på at han ville ha grått om han hadde sett en \"jentefilm\"."
    }
  }, {
    number: "23",
    en: {
      life_area: "Anatomy",
      object_code: "Butt",
      object_explanation: "The butt symbolizes the Life Area <u>Anatomy</u>.",
      animation_description: "2 as butt  + 3 as fart bubbles",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jessica Alba",
      person_explanation: "The actress equally known for her fit body as for her acting skills is not a bad fit for the study of <u>Anatomy</u>.",
      action_code: "Farting",
      action_explanation: "Ok, waving a hand behind her butt to hide bad smell is not the most elegant action for ms. Alba, but it's a good link to <u>Anatomy</u> ... and pretty funny too!"
    },
    no: {
      life_area: "Anatomi",
      object_code: "Rumpe",
      object_explanation: "Rumpen symboliserer Livsområdet <u>Anatomi</u>.",
      animation_description: "2 som rumpe + 3 som prompeboble",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jessica Alba",
      person_explanation: "Skuespilleren som er like kjent for sin velformede kropp som for sine skuespillerevner er neppe noen dårlig modell for å studere <u>Anatomi</u>.",
      action_code: "Å prompe",
      action_explanation: "Ok, å vifte en hånd bak rumpa er ikke den mest elegante handlingen for fru Alba, men en bra link til <u>Anatomi</u> er det jo - og ganske morsomt også!"
    }
  }, {
    number: "24",
    en: {
      life_area: "Math",
      object_code: "Calculator",
      object_explanation: "The calculator symbolizes the Life Area <u>Math</u>.",
      animation_description: "2 square = 4 on a calculator",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Pythagoras",
      person_explanation: "One of the most famous scholars of <u>Math</u> through time.",
      action_code: "Measuring something",
      action_explanation: "There's no <u>Math</u> without measuring something, so visualize using the milimeter band to the milimeter."
    },
    no: {
      life_area: "Matematikk",
      object_code: "Kalkulator",
      object_explanation: "Kalkulatoren symboliserer Livsområdet <u>Matematikk</u>.",
      animation_description: "2 kvadrat  = 4 på en kalkulator",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Pythagoras",
      person_explanation: "Mannen som fant trekantens sjel - antageligvis verdens mest kjente ekspert innen <u>Matematikk</u>.",
      action_code: "Å måle noe",
      action_explanation: "Det finnes ikke <u>Matematikk</u> uten at vi måler noe, så visualiser det å måle noe med et milimeterbånd."
    }
  }, {
    number: "25",
    en: {
      life_area: "Chemistry",
      object_code: "Chemist glass",
      object_explanation: "The chemist glass symbolizes the Life Area <u>Chemistry</u>.",
      animation_description: "2 upside down as chemistry glass + 5 as chemistry snake symbol",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Marie Curie",
      person_explanation: "The discoverer of uranium, polon and radium represented <u>Chemistry</u> to her death (she died due to the radioactive side-effects).",
      action_code: "Mixing powder",
      action_explanation: "Stirring and mixing powder is a good <u>Chemistry</u> action and certainly a good link to Marie Curie."
    },
    no: {
      life_area: "Kjemi",
      object_code: "Kjemiglass",
      object_explanation: "Kjemiglasset symboliserer Livsområdet <u>Kjemi</u>.",
      animation_description: "2 opp-ned som kjemiglass + 5 som kjemisk slangesymbol",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Marie Curie",
      person_explanation: "Oppdageren av uranium, polon og radium representerte virkelig <u>Kjemi</u> helt til sin død (hun døde av radioaktiv strålig).",
      action_code: "Å mikse pulver",
      action_explanation: "Det å mikse pulver er en god handling for <u>Kjemi</u> og også en bra link til Marie Curie."
    }
  }, {
    number: "26",
    en: {
      life_area: "Vitamins & Minerals",
      object_code: "Apple",
      object_explanation: "The apple symbolizes the Life Area <u>Vitamins & Minerals</u>. ",
      animation_description: "2 as branch + 6 as apple",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jamie Oliver",
      person_explanation: "The Naked Chef\" TV host is also known for convincing Tony Blair to change school lunches to food with more <u>Vitamins & Minerals</u>.",
      action_code: "Biting",
      action_explanation: "Biting, like an animal craving its <u>Vitamins & Minerals</u>, is a vivid and good action. We can almost hear Jamie Oliver barking in the background."
    },
    no: {
      life_area: "Vitaminer & Mineraler",
      object_code: "Eple",
      object_explanation: "Eplet symboliserer Livsområdet <u>Vitaminer & Mineraler</u>.",
      animation_description: "2 som gren + 6 som eple",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jamie Oliver",
      person_explanation: "TV-kokken \"The Naked Chef\" er også kjent for å ha overtalt Tony Blair til å innføre mat med mer <u>Vitaminer & Mineraler</u> i britiske skoler.",
      action_code: "Å bite",
      action_explanation: "Å bite, som et dyr desperat etter <u>Vitaminer & Mineraler</u>, er en bra handling. Vi kan nesten høre Jamie knurre i bakgrunnen."
    }
  }, {
    number: "27",
    en: {
      life_area: "International Relations & Politics",
      object_code: "Podium",
      object_explanation: "The podium symbolizes the Life Area <u>International Relations & Politics</u>.",
      animation_description: "2 as man leaning forward + 7 as podium",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Martin Luther King",
      person_explanation: "Known for his political and non-violent human rights engagement, he's a natural choice for <u>International Relations & Politics</u>. Won the Nobel Peace Prize. Assassinated in 1968.",
      action_code: "Handshaking",
      action_explanation: "Super-fast handshaking is a hilarious action, and fast forwarding mr. King in the <u>International Relations & Politics</u> scene - even more so."
    },
    no: {
      life_area: "Internasjonale Relasjoner & Politikk",
      object_code: "Podium",
      object_explanation: "Podiumet symboliserer Livsområdet <u>Internasjonale Relasjoner & Politikk</u>.",
      animation_description: "2 som foroverlenende menneske + 7 som podium",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Martin Luther King",
      person_explanation: "Kjent for sitt urokkelige menneskerettighetsengasjement, var nobelprisvinneren den naturlige kandidat for <u>Internasjonale Relasjoner & Politikk</u>.",
      action_code: "Å håndhilse",
      action_explanation: "Å håndhilse i super-tempo er en rar handling, og hurtigfilm med Martin L. i Internasjonale <u>Relasjoner & Politikk</u> er enda rarere."
    }
  }, {
    number: "28",
    en: {
      life_area: "Biographies & Quotes",
      object_code: "Glasses",
      object_explanation: "The glasses symbolize the Life Area <u>Biographies & Quotes</u>.",
      animation_description: "2 as nose upside down + 8 as glasses",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Malala Yousafzai",
      person_explanation: "Known as the youngest Nobel prize winner and a great speaker, Malala rocks the <u>biographies & Quotes</u> category.",
      action_code: "Finger quoting ",
      action_explanation: "Constantly double-bending the index and middle finger on both hands would surely change Malala's speeches. A good link for <u>Biographies & Quotes</u>."
    },
    no: {
      life_area: "Biografier & Sitater",
      object_code: "Briller",
      object_explanation: "Brillene symboliserer Livsområdet <u>Biografier & Sitater</u>.",
      animation_description: "2 some nese opp-ned + 8 som briller",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Malala Yousafzai",
      person_explanation: "Som den yngste nobelprisvinneren gjennom tidene og en flott taler, rocker Malala virkelig kategorien <u>Biografier & Sitater</u>.",
      action_code: "Å sitere med fingrene",
      action_explanation: "Å hele tiden sitere med fingrene ville endret oppfatningen av Malalas taler. En god link til <u>Biografier & Sitater</u> er det også."
    }
  }, {
    number: "29",
    en: {
      life_area: "Literature & Theatre",
      object_code: "Bookshelf",
      object_explanation: "The bookshelf symbolizes the Life Area <u>Literature & Theatre</u>.",
      animation_description: "2 and 9 forming a bookshelf",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Shakespeare",
      person_explanation: "Known as one of the greatest writers in time, so not a bad ambassador for <u>Literature & Theatre</u>.",
      action_code: "Bowing",
      action_explanation: "Two beers or not to be - no matter what, bowing is the right action to do for Shakespeare's <u>Literature & Theatre</u> works."
    },
    no: {
      life_area: "Litteratur & Teater",
      object_code: "Bokhylle",
      object_explanation: "Bokhyllen symboliserer Livsområdet <u>Litteratur & Teater</u>.",
      animation_description: "2 og 9 som former en bokhylle",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Shakespeare",
      person_explanation: "Ingen over ... ingen ved siden - antageligvis den beste forfatteren gjennom tidene - og dermed også Memolife-systemets person for <u>Litteratur & Teater</u>.",
      action_code: "Å bukke",
      action_explanation: "Two beers or not to be - uansett, å bukke er den eneste riktige handlingen for Shakespeares verker i <u>Litteratur & Teater</u>."
    }
  }, {
    number: "30",
    en: {
      life_area: "Business & Law",
      object_code: "Coin",
      object_explanation: "The coin symbolizes the Life Area <u>Business & Law</u>.",
      animation_description: "number 3 inside 0 as a coin ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Richard Branson",
      person_explanation: "Known as one of the best business brains in time and founder of the Virgin empire of brands, he's a good ambassador for <u>Business & Law</u>.",
      action_code: "Flying",
      action_explanation: "Although he's no Peter Pan, Richard Branson is setting the standard for both flying as well as <u>Business & Law</u> practices."
    },
    no: {
      life_area: "Økonomi & Jus",
      object_code: "Mynt",
      object_explanation: "Mynten symboliserer Livsområdet <u>Økonomi & Jus</u>.",
      animation_description: "nummer 3 inne i en 0 som mynt",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Richard Branson",
      person_explanation: "En av de beste businesshjernene som har satt sine føtter på planeten ... Virgin-gründeren er det naturlige valget for <u>Økonomi & Jus</u>.",
      action_code: "Å fly",
      action_explanation: "Han er neppe noen Peter Pan, men Richard Branson har satt standarder innen både det å fly som i <u>Økonomi & Jus</u>."
    }
  }, {
    number: "31",
    en: {
      life_area: "Architecture",
      object_code: "Column",
      object_explanation: "The column symbolizes the Life Area <u>Architecture</u>.",
      animation_description: "3 as chapiter + 1 as a column ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Antoni Gaudí",
      person_explanation: "A Spanish architect, known as one of the greatest in <u>Architecture</u> throughout time, especially for his buildings in Barcelona.",
      action_code: "Stacking",
      action_explanation: "He's stacking bricks, but I'm building a cathedral\". No matter your perspective, stacking is the core of all of Gaudi's masterpieces of <u>Architecture</u>."
    },
    no: {
      life_area: "Arkitektur",
      object_code: "Søyle",
      object_explanation: "Søylen symboliserer Livsområdet <u>Arkitektur</u>.",
      animation_description: "3 som søylehode + 1 som søyle",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Antoni Gaudí",
      person_explanation: "Kanskje den mest spektakulære innen <u>Arkitektur</u> gjennom tidene, og selv i dag viderefører de bygningene hans - Antoni Gaudí - plassen er din.",
      action_code: "Å stable (noe)",
      action_explanation: "Han stabler murstein, men jeg bygger en katedral\". Uansett perspektivet, å stable er kjernen i alle Gaudis mesterverk i Arkitektur."
    }
  }, {
    number: "32",
    en: {
      life_area: "Culture & Etiquette",
      object_code: "Duck",
      object_explanation: "The ducks symbolizes the Life Area <u>Culture & Etiquette</u>.",
      animation_description: "ducks: 3 flying + 2 sitting",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Pocahontas",
      person_explanation: "Although known from Disney cartoons, she  actually served as a peace activist between colonists and Native Americans. Perfect ambassador for <u>Culture & Etiquette</u>.",
      action_code: "Curtseying",
      action_explanation: "Typical action for her in the Disney movie, and a good one for <u>Culture & Etiquette</u>."
    },
    no: {
      life_area: "Kultur & Etikette",
      object_code: "And",
      object_explanation: "Anden symboliserer Livsområdet <u>Kultur & Etikette</u>.",
      animation_description: "ender: 3 som flyr + 2 som sitter",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Pocahontas",
      person_explanation: "Selv om hun er kjent fra Disneys tegnefilmer, var hun faktisk megler mellom indianerne og nybyggerne - en riktig ambassadør for <u>Kultur & Etikette</u>.",
      action_code: "Å neie",
      action_explanation: "Pocahontas må ha ledd den første gangen hun neiet, men med tanke på hvor følsom hun var for <u>Kultur & Etikette</u> gjorde hun det helt sikkert innvendig."
    }
  }],
  mind: [{
    number: "33",
    en: {
      life_area: "Mental Hygiene",
      object_code: "Sponge",
      object_explanation: "The sponge symbolizes the Life Area <u>Mental Hygiene</u> (\"cleaning\" our brains daily).",
      animation_description: "3 flipped + 3 normal forming a sponge",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Arwen",
      person_explanation: "Liv Tyler, as the elf Arwen in the movie Lord of the Rings, represents the daily <u>Mental Hygiene</u> habits well.",
      action_code: "Shampooing",
      action_explanation: "All right, shampooing isn't perfect as a <u>Mental Hygiene</u> action, but give us a break here! No matter what, we're sure Arwen does it."
    },
    no: {
      life_area: "Mentalhygiene",
      object_code: "Svamp",
      object_explanation: "Svampen symboliserer Livsområdet <u>Mentalhygiene</u>.",
      animation_description: "3 snudd + 3 normal = danner en svamp",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Arwen",
      person_explanation: "Liv Tyler, som alven Arwen i Ringenes Herre, representerer sunn, daglig <u>Mentalhygiene</u> med stil.",
      action_code: "Å bruke shampoo",
      action_explanation: "Ok, å bruke shampoo er ikke den perfekte handlingen for <u>Mentalhygiene</u>, men la gå. Arwen gjør det uansett med stil."
    }
  }, {
    number: "34",
    en: {
      life_area: "Motivation & Happiness",
      object_code: "Heart",
      object_explanation: "The heart symbolizes the Life Area <u>Motivation & Happiness</u>.",
      animation_description: "3 sideways as huge heart inside 4 sail",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Dalai Lama",
      person_explanation: "Known for love, compassion and non-violence, the exiled spiritual leader of Tibet personifies <u>Motivation & Happiness</u>.",
      action_code: "Smiling",
      action_explanation: "Have you ever seen the Dalai Lama not smiling? Neither have we, so what better action to choose for <u>Motivation & Happiness</u>?"
    },
    no: {
      life_area: "Motivasjon & Lykke",
      object_code: "Hjerte",
      object_explanation: "Hjertet symboliserer Livsområdet <u>Motivasjon & Lykke</u>.",
      animation_description: "3 sidelengs som stort hjerte på et seil",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Dalai Lama",
      person_explanation: "Kjent for kjærlighet, medfølelse og ikke-vold, kaster eksil-Tibetaneren glans over kategorien <u>Motivasjon & Lykke</u>.",
      action_code: "Å smile",
      action_explanation: "Har du noen gang sett Dalai Lama ikke smile? Ikke vi heller, så det finnes neppe noen bedre handling for <u>Motivasjon & Lykke</u>."
    }
  }, {
    number: "35",
    en: {
      life_area: "Mind Focus & Discipline",
      object_code: "Old scale",
      object_explanation: "The old scale symbolizes the Life Area <u>Mind Focus & Discipline</u>.",
      animation_description: "3 as the two sides + 5 as the lifting hook",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Aung San Suu Kyi",
      person_explanation: "The Nobel Peace Prize winner lived in house arrest in Myanmar for decades, but still showed the <u>Mind Focus & Discipline</u> to persevere.",
      action_code: "Pressing palms together",
      action_explanation: "We should be clapping, but we're sure Aung San Suu Kyi simply pressed her palms calmly together with her unworldly <u>Mind Focus & Discipline</u>."
    },
    no: {
      life_area: "Tankefokus & Disiplin",
      object_code: "Gammel Vekt",
      object_explanation: "Den gamle vekten symboliserer Livsområdet <u>Tankefokus & Disiplin</u>.",
      animation_description: "3 som sidene + 5 som den løftende kroken",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Aung San Suu Kyi",
      person_explanation: "Nobelprisvinneren levde i årtier i husarrest, kanskje nettop på grunn av sin <u>Tankefokus & Disiplin</u>.",
      action_code: "Å presse håndflatene sammen",
      action_explanation: "Vi burde klappe, men vi er sikre på at Aung San Suu Kyi presset håndflatene rolig sammen med hennes utenomjordlige <u>Tankefokus & Disiplin</u>."
    }
  }, {
    number: "36",
    en: {
      life_area: "Habit Mastery",
      object_code: "Key",
      object_explanation: "The key symbolizes the Life Area <u>Habit Mastery</u> (the key unlocks the door to better habits).",
      animation_description: "3 as the head + 6 as the key tip",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Leonardo da Vinci",
      person_explanation: "He adapted <u>Habits</u> which turned him into a master of art, science, sports, music and more - perhaps the most versatile genius ever.",
      action_code: "Opening a small door",
      action_explanation: "From now on, opening a door can remind you of how <u>Habit Mastery</u> is the stepping stone to any successful life, just like Leonardo's."
    },
    no: {
      life_area: "Vaneendring",
      object_code: "Nøkkel",
      object_explanation: "Nøkkelen symboliserer Livsområdet <u>Vaneendring</u>.",
      animation_description: "3 som hode + 6 som nøkkeltupp",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Leonardo da Vinci",
      person_explanation: "Han tilegnet seg en <u>Vaneendring</u> som gjorde ham til en mester i kunst, vitenskap, idrett og mer - antageligvis verdens største geni gjennom tidene.",
      action_code: "Å åpne en liten dør",
      action_explanation: "Fra nå av vil det å åpne en dør minne deg på hvordan <u>Vaneendring</u> er nøkkelen til ethvert suksessfult liv, akkurat som Leonardos."
    }
  }, {
    number: "37",
    en: {
      life_area: "Visualization",
      object_code: "Eagle",
      object_explanation: "The giant eagle symbolizes the Life Area <u>Visualization</u> (the eagle visualizing itself catching a prey in the valley below).",
      animation_description: "3 as an eagle on top of 7 as a cliff",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Gandalf",
      person_explanation: "The wise magician in the Lord of the Rings and The Hobbit movies is known for his <u>Visualization</u> skills.",
      action_code: "Visualizing fireworks",
      action_explanation: "If you're going to use <u>Visualization</u>, like Gandalf, you might as well visualize fireworks and aim as high as you can get."
    },
    no: {
      life_area: "Visualisering",
      object_code: "Ørn",
      object_explanation: "Ørnen symboliserer Livsområdet <u>Visualisering</u> (ørnen visualiserer at den fanger et bytte i dalen nedenfor).",
      animation_description: "3 som ørn på toppen av 7 som klippe",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Gandalf",
      person_explanation: "Den vise magikeren fra Ringenes Herre-filmene er kjent for sine ferdigheter innen <u>Visualisering</u>.",
      action_code: "Å visualisere fyrverkeri",
      action_explanation: "Dersom du vil bruke <u>Visualisering</u>, slik som Gandalf, kan du like greit legge til litt fyrverkeri og dra det så langt du klarer."
    }
  }, {
    number: "38",
    en: {
      life_area: "Self-Growth Diary",
      object_code: "Calender",
      object_explanation: "The calender represents the Life Area <u>Self-Growth Diary</u>.",
      animation_description: "3 as horizontal spiral + 8 as calender date on paper",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Anne Frank",
      person_explanation: "Known for the diaries she wrote when hiding from the Nazis in Holland, she personifies the habit of a <u>Self-Growth Diary</u>.",
      action_code: "Writing diary",
      action_explanation: "Writing line by line of text, honoring yourself with a Self-Growth Diary is for many the best survival strategy. Unfortunately it was not enough for Anne Frank."
    },
    no: {
      life_area: "Mentaltreningsdagbok",
      object_code: "Kalender",
      object_explanation: "Kalenderen symboliserer Livsområdet <u>Hjernetreningsdagbok</u>.",
      animation_description: "3 som horisontal spiral + 8 som kalendertall på papir",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Anne Frank",
      person_explanation: "Kjent for dagbøkene hun skrev mens hun gjemte seg for Nazistene i Holland, personifiserer Anne Frank virkelig aktiviteten <u>Hjernetreningsdagbok</u>.",
      action_code: "Å skrive dagbok",
      action_explanation: "Å skrive linje for linje i en <u>Hjernetreningsdagbok</u> er en god overlevelsesstrategi, men dessverre var det ikke nok for Anne Frank."
    }
  }, {
    number: "39",
    en: {
      life_area: "Inner Dialogue",
      object_code: "Hand-held mirror",
      object_explanation: "The hand mirror symbolizes the Life Area <u>Inner Dialogue</u>.",
      animation_description: "3 as lips + 9 as hand mirror ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Robin Williams",
      person_explanation: "The comical genius is known for personifying an <u>Inner Dialogue</u> with self-comments in hilarious ways.",
      action_code: "Laughing",
      action_explanation: "Laughing should have been Robin Williams' middle name, the way he acted out an <u>Inner Dialogue</u> would make you laugh until you cried."
    },
    no: {
      life_area: "Indre Dialog",
      object_code: "Håndspeil",
      object_explanation: "Håndspeilet symboliserer Livsområdet <u>Indre Dialog</u>.",
      animation_description: "3 som lepper + 9 som håndspeil",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Robin Williams",
      person_explanation: "Komikeren var et geni i hvordan han med lynets hastighet fremstilte en <u>Indre Dialog</u> med de morsomme samtalene han hadde med seg selv.",
      action_code: "Å le",
      action_explanation: "Å le burde ha vært mellomnavnet til Robin Williams - måten han virkeliggjorde en <u>Indre Dialog</u> fikk lattermusklene til å krampe seg."
    }
  }, {
    number: "40",
    en: {
      life_area: "Goal Achievement",
      object_code: "Bull's eye target",
      object_explanation: "The target bull's eye symbolizes the Life Area <u>Goal Achievement</u>. ",
      animation_description: "4 as support + 0 as a goal/target ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Muhammad Ali",
      person_explanation: "Known as the most versatile and greatest boxer in history, Muhammad Ali is the perfect choice to personify <u>Goal Achievement</u>.",
      action_code: "Celebrating",
      action_explanation: "Celebrating and raising his arms in a victory pose is the way we love to remember Muhammad Ali's <u>Goal Achievement</u>."
    },
    no: {
      life_area: "Måloppnåelse",
      object_code: "Skyteskive",
      object_explanation: "Skyteskiven symboliserer Livsområdet <u>Måloppnåelse</u>.",
      animation_description: "4 som støtte + 0 som skyteskive",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Muhammad Ali",
      person_explanation: "Historiens beste bokser. Punktum. Urokkelig i sin tro og evne innen <u>Måloppnåelse</u>.",
      action_code: "Å feire",
      action_explanation: "Å feire med armene i opp i seiersposisjon er måten vi liker å huske Muhammad Ali's <u>Måloppnåelse</u> på."
    }
  }, {
    number: "41",
    en: {
      life_area: "Success Modeling",
      object_code: "Success curve",
      object_explanation: "The success curve diagram symbolizes the Life Area <u>Success Modeling</u>.",
      animation_description: "4 as the x/y axis + 1 as success curve ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Walt Disney",
      person_explanation: "The Disney Model\" is the name of a mental <u>Success Modeling</u> technique which unlocks the secret behaviors of successful individuals and companies.",
      action_code: "Clay-modelling",
      action_explanation: "Visualize making a clay model. You don't have to take your <u>Success Modeling</u> to \"Ghost\"/Demi-Patrick heights, but make try to make a nice clay model."
    },
    no: {
      life_area: "Suksessmodellering",
      object_code: "Suksesskurve",
      object_explanation: "Suksesskurven symboliserer Livsområdet <u>Suksessmodellering</u>.",
      animation_description: "4 som x/y aksene + 1 som suksesskurve",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Walt Disney",
      person_explanation: "Disney-modellen\" er et navn som brukes for teknikker innen <u>Suksessmodellering</u> av atferden til suksessfulle personer og selskaper.\\nLittlefoot",
      action_code: "Å forme leire",
      action_explanation: "Visualiser at du har <u>Suksess med å modellere</u> leire. Det er ikke nødvendig å dra den helt til \"Ghost\"/Demi-Patrick\"-høyder, men lag en fin liten leiremodell."
    }
  }, {
    number: "42",
    en: {
      life_area: "Neuro-Communication",
      object_code: "Motorcycle helmet",
      object_explanation: "The motor cycle helmet symbolizes the Life Area <u>Neuro-Communicaiton</u> (its central technique implies creating a safe reptile brain, and the helmet offers safety).",
      animation_description: "4 as the front + 2 as the back of the helmet",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Littlefoot",
      person_explanation: "Cute little reptile-dinosaur from Disney movie. Keeping a safe reptile brain is crucial in <u>Neuro-Communication</u>, so Littlefoot is a good ambassador.",
      action_code: "Relaxing",
      action_explanation: "Leaning backwards with hands behind the head. This makes our reptile brain feel safe, a crucial part of <u>Neuro-Communication</u>."
    },
    no: {
      life_area: "Nevro-Kommunikasjon",
      object_code: "Motorsykkelhjelm",
      object_explanation: "Motorsykkelhjelmen symboliserer Livsområdet <u>Nevro-Kommunikasjon</u> (den sentrale teknikken innebærer å skape en trygg reptilhjerne = hjelm).",
      animation_description: "4 som forsiden + 2 som baksiden av en hjelm",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Lillefot",
      person_explanation: "Søt liten reptil-dinosaur fra Disneys tegnefilm. Det å ha en trygg reptilhjerne er viktig for <u>Nevro-Kommunikasjon</u>, så Lillefot kler rollen godt.",
      action_code: "Å slappe av",
      action_explanation: "Å lene seg bakover med hendene bak hodet. Dette får reptilhjernen til å føle seg trygg, som er viktig i <u>Nevro-Kommunikasjon</u>."
    }
  }, {
    number: "43",
    en: {
      life_area: "Optimal State Generation",
      object_code: "Anchor",
      object_explanation: "The anchor symbolizes the Life Area <u>Optimal State Generation</u> (a central technique \"anchors\" the desired mental state through sensual triggers).",
      animation_description: "4 as rope + 3 as anchor",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "David Blaine",
      person_explanation: "The magician is famous for extreme mind-body experiments and <u>Optimal State Generation</u> such as his world record holding his breath under water.",
      action_code: "Finger-snapping",
      action_explanation: "Finger-snapping is a classic anchor and action used for <u>Optimal State Generation</u>. David Blaine and mentalists use it all the time."
    },
    no: {
      life_area: "Optimal Tilstandsoppnåelse",
      object_code: "Anker",
      object_explanation: "Ankeret symboliserer Livsområdet <u>Optimal Tilstandsoppnåelse</u> (en sentral teknikke for\"ankrer\" en optimal tilstand gjennom sanselige triggere.)",
      animation_description: "4 som rep + 3 som anker",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Mr. Fantastic",
      person_explanation: "Magikeren er kjent for sine ekstreme tanke- og kroppseksperimenter - han mestrer uten tvil en <u>Optimal Tilstandsoppnåelse</u>.",
      action_code: "Å knipse",
      action_explanation: "Å knipse med fingrene er et klassisk anker som brukes for <u>Optimal Tilstandsoppnåelse</u>. David Blaine og mentalister bruker det hele tiden."
    }
  }, {
    number: "44",
    en: {
      life_area: "Mastermind Group Learning",
      object_code: "Kite",
      object_explanation: "The kite symbolizes the Life Area <u>Mastermind Group Learning</u> (the kite offers the group the correct overview).",
      animation_description: "4 adjacent to a flipped 4",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Mr. Fantastic",
      person_explanation: "The Fantastic 4 and their <u>Mastermind Group Learning</u> are known for their superpowers after having been exposed to radiation.",
      action_code: "Arm-stretching",
      action_explanation: "Stretching his looong rubber arms straight ahead to include everyone in a <u>Mastermind Group Learning</u> is what Mr. Fantastic would do."
    },
    no: {
      life_area: "Mastermind Gruppelæring",
      object_code: "Drage",
      object_explanation: "Dragen symboliserer Livsområdet <u>Mastermind Gruppelæring</u> (dragen gir gruppen oversikten den trenger).",
      animation_description: "4 sidestilt med en snudd 4 = drage",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Mr. Fantastic",
      person_explanation: "De Fantastiske 4 er kjent for å ha individuelle superkrefter, men lederen deres - Mr. Fantastic - samler dem alle med <u>Mastermind Gruppelæring</u>.",
      action_code: "Å strekke armene fremover",
      action_explanation: "Å strekke sine laaaaange gummiarmer fremover for å inkludere alle i <u>Mastermind Læringsgruppen</u> er hva Mr. Fantastic ville ha gjort."
    }
  }, {
    number: "45",
    en: {
      life_area: "Personality Types",
      object_code: "Treasure chest",
      object_explanation: "The treasure chest symbolizes the Life Area <u>Personality Types</u> (the treasure chest keeps the skills, behaviors and dreams of a personality type together).",
      animation_description: "4 as treasure chest + 5 as lock",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Tom Hanks",
      person_explanation: "The actor is known for playing different <u>Personality Types</u>.",
      action_code: "Running",
      action_explanation: "Run Forrest, run! Some <u>Personality Types</u> run from their inner pain and some face it. Tom Hanks has done both in his movies."
    },
    no: {
      life_area: "Personlighetstyper",
      object_code: "Treasure Chest",
      object_explanation: "Skattekisten symboliserer Livsområdet <u>Personlighetstyper</u> (skattekisten holder evnene, atferden og drømmene til en personlighetstype sammen).",
      animation_description: "4 som skattekiste + 5 som lås",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Tom Hanks",
      person_explanation: "Skuespilleren er kjent for å spille forskjellige <u>Personlighetstyper</u> på en mesterlig måte.",
      action_code: "Å løpe",
      action_explanation: "Run Forrest, run! Noen <u>Personlighetstyper</u> løper fra sin indre smerte, mens andre konfronterer den. Tom Hanks gjør begge deler i filmene sine."
    }
  }, {
    number: "46",
    en: {
      life_area: "Values & Roles",
      object_code: "Thumbs up flag",
      object_explanation: "The thumbs up flag symbolizes the Life Area <u>Values & Roles</u> in life.",
      animation_description: "4 as flag + 6 as thumbs up inside the flag",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Bono",
      person_explanation: "The U2 vocalist is Known for his human rights and <u>Values & Roles</u> commitment.",
      action_code: "Showing both thumbs up",
      action_explanation: "Big ups! Even Bono's name means good in latin, so visualizing him showing both thumbs up for <u>Values & Roles</u> is the easiest thing to do."
    },
    no: {
      life_area: "Verdier & Roller",
      object_code: "Tommel-Opp-Flagg",
      object_explanation: "Tommelen opp symboliserer Livsområdet <u>Verdier & Roller</u>.",
      animation_description: "4 som flagg + 6 som tommel opp på flagget",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Bono",
      person_explanation: "Kjent for sitt menneskerettighetsengasjement, i tillegg til å være U2s vokalist, hvem andre enn Bono kan personifisere <u>Verdier & Roller</u> med glam?",
      action_code: "Å vise tomlene opp",
      action_explanation: "Big ups! Attopåtil navnet til Bono betyr jo \"bra\" på latin, så å visualisere ham rette tomlene opp for <u>Verdier & Roller</u> er no problemo."
    }
  }, {
    number: "47",
    en: {
      life_area: "Dating & Relationships",
      object_code: "Shovel",
      object_explanation: "The shovel symbolizes the Life Area <u>Dating & Relationship Skills</u> (the shovel represents digging for common \"gold\").",
      animation_description: "4 as shovel tip + 7 as shovel grip",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Will Smith",
      person_explanation: "Known as a \"dating doctor\" in the movie Hitch, it's easy to link him to <u>Dating & Relationships</u>.",
      action_code: "Blowing a kiss",
      action_explanation: "What else symbolizes <u>Dating & Relationships</u> as well as blowing a kiss?"
    },
    no: {
      life_area: "Dating & Forhold",
      object_code: "Spade",
      object_explanation: "Spaden symboliserer Livsområdet <u>Dating & Forhold</u>.",
      animation_description: "4 som spadetupp + 7 som spadegrep",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Will Smith",
      person_explanation: "Som datingspesialist i filmen \"Hitch\" med Eva Mendes, er Will Smith en bra person å assosiere med <u>Dating & Forhold</u>.",
      action_code: "Å gi slengkyss",
      action_explanation: "Hva annet kan symbolisere <u>Dating & Forhold</u> bedre enn å gi et slengkyss?"
    }
  }, {
    number: "48",
    en: {
      life_area: "Perception & Mind Models",
      object_code: "Binoculars",
      object_explanation: "The binoculars symbolize the Life Area <u>Perception & Mind Models</u> (different types of binoculars empower the mind with different perceptions of the world).",
      animation_description: "4 as support-stick + 8 as old binoculars",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Columbus",
      person_explanation: "The man who rediscovered America in 1492, had <u>Perception & Mind Models</u> which convinced him he had found India.",
      action_code: "Scouting",
      action_explanation: "Scouting with his palm above his eyes, Columbus must have questioned his <u>Perception & Mind Models</u> when he finally saw land (was it India?)."
    },
    no: {
      life_area: "Persepsjon & Tankemodeller",
      object_code: "Kikkert",
      object_explanation: "Kikkerten symboliserer Livsområdet <u>Persepsjon & Tankemodeller</u> (forskjellige kikkerttyper beriker tankene med ulike persepsjoner av verden).",
      animation_description: "4 som støttepinne + 8 som gammel kikkert",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Columbus",
      person_explanation: "Avhenging av hvilke <u>Persepsjon & Tankemodeller</u> du velger, enten oppdaget eller gjenoppdaget Columbus Amerika (han selv: er det virkelig India?).",
      action_code: "Å speide",
      action_explanation: "Å speide med håndflaten over øynene ... Columbus må ha stilt spørsmål til både <u>Persepsjon & Tankemodeller</u> da han så land."
    }
  }, {
    number: "49",
    en: {
      life_area: "Personal Wealth Management",
      object_code: "Gold bar",
      object_explanation: "The gold bar symbolizes the Life Area <u>Personal Wealth Management</u>.",
      animation_description: "4 as top + 9 as side of a gold bar",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Warren Buffett",
      person_explanation: "Known as a business genius and one of the richest people in the world, <u>Personal Wealth Management</u> is his middle name",
      action_code: "Champagne-toasting",
      action_explanation: "Toasting with champagne is a typical symbol of wealth, so what other action to choose for <u>Personal Wealth Management</u>?"
    },
    no: {
      life_area: "Personlig Formueforvaltning",
      object_code: "Gullbarre",
      object_explanation: "Gullbarren symboliserer Livsområdet <u>Personlig Formueforvaltning</u>.",
      animation_description: "4 som toppen + 9 som siden av en gullbarre",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Warren Buffet",
      person_explanation: "Som finansguru og en av verdens rikeste mennesker er Warren Buffet selvskreven person for <u>Personlig Formueforvaltning</u>.",
      action_code: "Å skåle",
      action_explanation: "Å skåle med champagneglass er en typisk velferdsassosiasjon, så hvorfor ikke bruke det som handling for <u>Personlig Formueforvaltning</u>? "
    }
  }, {
    number: "50",
    en: {
      life_area: "Friends & Networking",
      object_code: "Rainbow",
      object_explanation: "The rainbow symbolizes the Life Area <u>Friends & Networking</u> (the rainbow represents a happy friendship story).",
      animation_description: "5 as people holding hands + 0 as rainbow",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jennifer Aniston",
      person_explanation: "From the TV-series Friends, Jennifer personalizes <u>Friends & Networking</u>.",
      action_code: "High five",
      action_explanation: "Give me high five! It's the most natural thing to do when you're out with <u>Friends & Networking</u>."
    },
    no: {
      life_area: "Venner & Nettverking",
      object_code: "Regnbue",
      object_explanation: "Regnbuen symboliserer Livsområdet <u>Venner & Nettverking</u> (regnbuen står for en lykkelig vennskapshistorie).",
      animation_description: "5 som mennesker som holder hender + 0 som regnbue",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jennifer Aniston",
      person_explanation: "Som stjerne i TV-serien Friends er <u>Venner & Nettverking</u> + Jennifer Anniston = sant.",
      action_code: "Å gi high-five",
      action_explanation: "High five! Verdens mest naturlige handling, både for <u>Venner & Nettverking</u>."
    }
  }, {
    number: "51",
    en: {
      life_area: "Mind Improvement Systems",
      object_code: "Area 51 sign",
      object_explanation: "The Area 51 sign symbolizes the Life Area <u>Mind Improvement Systems</u> like NLP, CBT, etc. (it is rumored that the US army base Area 51 also uses advanced mind experiments).",
      animation_description: "Area 51 no trespassing sign",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Tony Robbins",
      person_explanation: "Known as the word's top coach and motivational speaker, using NLP and <u>Mind Improvement Systems</u> as his core toolset. Also known from movies such as \"Shallow Hal\" with Jack Black.",
      action_code: "Fire-walking",
      action_explanation: "Tony Robbins and other <u>Mind Improvement Systems</u> trainers are known to have their seminar participants walk across fire."
    },
    no: {
      life_area: "Mentale Utviklingssystemer",
      object_code: "Area 51 Skilt",
      object_explanation: "Skiltet \"Area 51\" symboliserer Livsområdet <u>Mentale Utviklingssystemer</u> som NLP, CBT, etc. (idet ryktes at militærbasen Area 51 også utfører avanserte tankeeksperimenter.)",
      animation_description: "Area 51 skilt",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Tony Robbins",
      person_explanation: "Verdens ledende coach og motivasjonstaler bruker forskjellige <u>Mentale Utviklingssystemer</u>, som bl. a. NLP, i sin mentale verktøykasse.",
      action_code: "Å gå på kull/ild",
      action_explanation: "Tony Robbins og andre trenere med <u>Mentale Utviklingssystemer</u> er kjente for å la sine deltagere gå på glødende kull. "
    }
  }],
  soul: [{
    number: "67",
    en: {
      life_area: "Meditation & Mindfulness",
      object_code: "Big stomach",
      object_explanation: "The big stomach symbolizes the Life Area <u>Meditation & Mindfulness</u>.",
      animation_description: "6 as big stomach breathing in + 7 as flat stomach breathing out",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Buddha",
      person_explanation: "Buddha personifies <u>Meditation & Mindfulness</u>. Enough said. ",
      action_code: "Finger-meditation",
      action_explanation: "Putting finger tips together is a typical association to <u>Meditation & Mindfulness</u>."
    },
    no: {
      life_area: "Meditasjon & Mindfulness",
      object_code: "Stor Mage",
      object_explanation: "Den store magen symboliserer Livsområdet <u>Meditasjon &  Mindfulness</u>.",
      animation_description: "6 som stor innpustende mage + 7 som flag utpustende mage",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Buddha",
      person_explanation: "Buddha personifiserer området <u>Meditasjon & Mindfulness</u>. Punktum.",
      action_code: "Å finger-meditere",
      action_explanation: "Å putte fingertuppene sammen er klassisk handling for <u>Mediasjon & Mindfulness</u>."
    }
  }, {
    number: "68",
    en: {
      life_area: "Self-Hypnosis",
      object_code: "Balls on string",
      object_explanation: "The balls on strings symbolize the Life Area <u>Self-Hypnosis</u> (the moving balls have a hypnotic sideways movement).",
      animation_description: "6 as ball on string + 8 as multiple balls",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Kaa",
      person_explanation: "Bull's eye! Or perhaps we should say snake's eye? The Jungle Book snake truly has <u>Self-Hypnosis</u> eyes.",
      action_code: "Hypnotizing",
      action_explanation: "Visualize the act of hypnosis or <u>Self-Hypnosis</u> as the eyes turn into spirals."
    },
    no: {
      life_area: "Selv-Hypnose",
      object_code: "Baller På Tråd",
      object_explanation: "Ballene på trådene symboliserer Livsområdet <u>Selv-Hypnose</u> (ballene har en hypnotisk effekt).",
      animation_description: "6 som ball på tråd + 8 som flere baller",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Slangen Kaa",
      person_explanation: "Jungelbokslangen Kaa kan på kommando endre øynene til spiraler for <u>Selv-Hypnose</u>. Hva mer trenger du?",
      action_code: "Å bli hypnotisert",
      action_explanation: "Forestill deg å bli hypnotisert med <u>Selv-Hypnose</u> så øynene går rundt og rundt i spiraler."
    }
  }, {
    number: "69",
    en: {
      life_area: "Sexual Intelligence",
      object_code: "Couple intertwined",
      object_explanation: "The couple in embrace symbolizes the Life Area <u>Sexual Intelligence</u>.",
      animation_description: "69 as sexual 69-position",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Don Juan",
      person_explanation: "Making ee-loooove ... Johnny Depp embodies <u>Sexual Intelligence</u> in his role as Don Juan de Marco in the romantic-comedy classic.",
      action_code: "Thrusting",
      action_explanation: "Whether thrusting - pushing the hips forwards - is an indication of <u>Sexual Intelligence</u> or not is unclear, but maybe Don Juan knows?"
    },
    no: {
      life_area: "Seksuell Intelligens",
      object_code: "Par Flettet Sammen",
      object_explanation: "Paret flettet sammen symboliserer Livsområdet <u>Seksuell Intelligens</u>.",
      animation_description: "69 som seksuell 69-posisjon",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Don Juan",
      person_explanation: "Making eeee-looooove .... Johnny Depp personifiserer <u>Seksuell Intelligens</u> i rollen som Don Juan i filmklassikeren.",
      action_code: "Å støte med hoftene",
      action_explanation: "Om det å støte med hoftene er et tegn på <u>Seksuell Intelligens</u> er usikkert - kanskje Don Juan vet?"
    }
  }, {
    number: "70",
    en: {
      life_area: "Stress Management",
      object_code: "Zen head band",
      object_explanation: "The zen headband symbolizes the Life Area <u>Stress Management</u> (wearing the zen headband reduces stress).",
      animation_description: "7 as zen head band + 0 as head",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Hulk Hogan",
      person_explanation: "The wrestler with anger management issues truly personifies someone in need of <u>Stress Management</u> techniques.",
      action_code: "Stretching neck",
      action_explanation: "Pulling the head downwards with one hand to the side. A simple <u>Stress Management</u> technique Hulk Hogan probably should use more often."
    },
    no: {
      life_area: "Stressmestring",
      object_code: "Zen Pannebånd",
      object_explanation: "Zen pannebåndet symboliserer Livsområdet <u>Stressmestring</u> (å bruke pannebåndet reduserer sress).",
      animation_description: "7 som zen pannebånd + 0 som hode",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Hulk Hogan",
      person_explanation: "Fribryteren med sinneproblemer kunne absolutt ha behov for litt <u>Stressmestring</u>.",
      action_code: "Å strekke nakken",
      action_explanation: "Å dra hodet ned til siden med en hånd er en enkel <u>Stressmestringsteknikk</u> Hulk Hogan burde bruke oftere."
    }
  }, {
    number: "71",
    en: {
      life_area: "Deeper Emotions",
      object_code: "Fire station pole",
      object_explanation: "The fire station pole symbolizes the Life Area <u>Deeper Emotions</u> (e.g. a bitterness cleaner is sent deeper down the pole & away from the deeper emotions)",
      animation_description: "7 as person sliding down a 1 pole ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Mother Teresa",
      person_explanation: "The Nobel Peace Prize winning missionary cared for poor and sick people in many countries - <u>Deeper Emotions</u> like forgiveness and gratitude could be her middle names.",
      action_code: "Having a halo",
      action_explanation: "Having a shining halo ... hmmmm, if anyone ever did it must have been Mother Theresa, with all the <u>Deeper Emotions</u> she demonstrated."
    },
    no: {
      life_area: "Dypere Følelser",
      object_code: "Brannstasjonsstang",
      object_explanation: "Brannstasjonsstangen symboliserer Livsområdet <u>Dypere Følelser</u> (når tilgivelse sklir ned kommer takknemlighet ovenfra).",
      animation_description: "7 som person som sklir ned + 1 som stang",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Mor Theresa",
      person_explanation: "Nobelprisvinneren kunne nærmest ha <u>Dypere Følesler</u> som tilgivelse og takknemlighet som mellomnavn etter sine år med å hjelpe andre i nød. ",
      action_code: "Å få en glorie",
      action_explanation: "Og så kommer glorien frem ... hmmmm, hvis noen en gang virkelig fikk en glorie var det nok Mor Theresa, med alle de <u>Dypere Følelser</u> hun viste."
    }
  }, {
    number: "72",
    en: {
      life_area: "Spiritual-Alternative Skills",
      object_code: "Yin yang symbol",
      object_explanation: "The yin-yang symbol represents the Life Area <u>Spiritual-Alternative Skills</u>.",
      animation_description: "7 as the outside + 2 as the inside of yin yang symbol",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Gwyneth Paltrow",
      person_explanation: "The actress is also known for her passion for all things <u>Spiritual-Alternative</u>.",
      action_code: "Incense-burning",
      action_explanation: "Visualize, smell and taste some incense burning, throw Gwyneth in the mix and wham ... your <u>Spiritual-Alternative Skills</u> are off the charts."
    },
    no: {
      life_area: "Alternative Ferdigheter",
      object_code: "Yin Yang Symbol",
      object_explanation: "Yin-yang symbolet symboliserer Livsområdet <u>Alternative Ferdigheter</u>.",
      animation_description: "7 som utsiden + 2 som innsiden av yin yang symbolet",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Gwyneth Paltrow",
      person_explanation: "Skuespillerinnen er kjent for sin interesse for det meste innen <u>Alternative Ferdigheter</u>.",
      action_code: "Å brenne røkelse",
      action_explanation: "Se for deg og lukt at røkelsen brenner - sleng litt Gwyneth inn i bildet og vips, så går dine <u>Alternative Ferdigheter</u> gjennom taket."
    }
  }, {
    number: "73",
    en: {
      life_area: "Neurofeedback & Brain Machines",
      object_code: "Antenna headphones",
      object_explanation: "The antenna headphones symbolize the Life Area Neurofeedback & Brain Machines (measurement of brain signals & brain stimulation through tech solutions).",
      animation_description: "7 as antenna + 3 as headphones",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Emmet Brown",
      person_explanation: "The crazy scientist in the \"Back to the Future\" movies can be seen with <u>Neurofeedback & Brain Machines</u> in several scenes.",
      action_code: "Attaching electrodes to the head",
      action_explanation: "There's no getting around attaching electrodes to the head if you want to use <u>Neurofeedback & Brain Machines</u>, just ask Doc. Doc ... are you ok?"
    },
    no: {
      life_area: "Nevrofeedback & Hjernemaskiner",
      object_code: "Antenne-Hodetelefoner",
      object_explanation: "Antenne-hodetelefonene symboliserer Livsområdet <u>Nevrofeedback & Hjernemaskiner</u> (måling av hjernesignaler & hjernestimulering via teknologiske løsninger).",
      animation_description: "7 som antenne + 3 som hodetelefoner",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Emmet Brown",
      person_explanation: "Den gale vitenskapsmannen fra Tilbake Til Fremtiden-filmene blir ofte sett med <u>Nevrofeedback & Hjernemaskiner</u>",
      action_code: "Å plassere elektroder på hodet",
      action_explanation: "Det er vanskelig å komme seg unna å feste elektroder på hodet dersom du vil bruke <u>Nevrofeedback & Hjernemaskiner</u> - bare spør Doc. Doc, er du ok?"
    }
  }, {
    number: "74",
    en: {
      life_area: "Mental Pain Relief",
      object_code: "Slippers",
      object_explanation: "The slippers symbolize the Life Area <u>Mental Pain Relief</u> (the slippers represent a mental on-switch to a relaxed pain-free feeling).",
      animation_description: "4 as foot inside + 7 as slippers laid down flat on the ground",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jesus",
      person_explanation: "For Christ's sake, the man could heal people with his mind, so who better to choose for <u>Mental Pain Relief</u> techniques (and becides, he wore slippers).",
      action_code: "Having a body aura ",
      action_explanation: "Having a colorful body aura ... all right, if anyone had a perfect one of these which helped with <u>Mental Pain Relief</u>, it must have been Jesus."
    },
    no: {
      life_area: "Mental Smertelindring",
      object_code: "Tøfler",
      object_explanation: "Tøflene symboliserer Livsområdet <u>Mental Smertelindring</u> (tøflene blir en mental bryter for en avslappet smertefri tilstand).",
      animation_description: "4 som fot inne i 7 som tøffel",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jesus",
      person_explanation: "For Guds skyld, mannen kunne visstnok helbrede folk med sine hender, som hvem bedre til å personifisere <u>Mental Smertelindring</u>?",
      action_code: "Å ha en kropps-aura",
      action_explanation: "Å ha en fargefull kropps-aura ... ok, hvis noen har hatt en perfekt slik en som hjalp med <u>Mental Smertelindring</u>, så må det ha vært Jesus."
    }
  }, {
    number: "75",
    en: {
      life_area: "Social Contribution",
      object_code: "Flash light",
      object_explanation: "The flash light symbolizes the Life Area <u>Social Contribution</u> (the flash light contributes with light).",
      animation_description: "7 as magic flash light + 5 as shadow",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Santa Claus",
      person_explanation: "Known to give people gifts from his sledge, <u>Social Contribution</u> is Santa's main purpose in life. ",
      action_code: "Hugging",
      action_explanation: "Who doesn't want a god hug from Santa? Come on, what better way of <u>Social Contribution</u> than giving back with a hug?"
    },
    no: {
      life_area: "Sosiale Bidrag",
      object_code: "Lommelykt",
      object_explanation: "Lommelykten symboliserer Livsområdet <u>Sosiale Bidrag</u> (lommelykten bidrar med lys).",
      animation_description: "7 som lommelykt + 5 som skygge",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Julenissen",
      person_explanation: "Nissen elsker jo <u>Sosiale Bidrag</u>, så denne var en no-brainer.",
      action_code: "Å gi klem",
      action_explanation: "Hvem vil vel ikke a en god klem av nissen? Hei, hvilken bedre måte for <u>Sosiale Bidrag</u> enn å gi en klem finnes det?"
    }
  }, {
    number: "76",
    en: {
      life_area: "Lucid Dreaming",
      object_code: "Bomb",
      object_explanation: "The bomb symbolizes the Life Area <u>Lucid Dreaming</u> (the Z represents sleep and the bomb represents a dream with a powerful message).",
      animation_description: "7 as Z inside a 6 as a bomb ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Leonardo di Caprio",
      person_explanation: "His character in the movie Inception controls people's dreams, just like you can do with your own dreams with <u>Lucid Dreaming</u> techniques.",
      action_code: "Opening head",
      action_explanation: "Visualize actually opening your head and - pow, kapoof - your brain is free and <u>Lucid Dreaming</u> can begin."
    },
    no: {
      life_area: "Lucid Dreaming",
      object_code: "Bombe",
      object_explanation: "Bomben symboliserer Livsområdet <u>Lucid Dreaming</u> (Z'en representerer søvn og bomben en drøm med en kraftig beskjed).",
      animation_description: "7 som Z inne i en 6 som bombe",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Leonardo di Caprio",
      person_explanation: "I karakteren hans i filmen Inception kan han manipulere folks drømmer, akkurat som du kan med teknikkene i <u>Lucid Dreaming</u>.",
      action_code: "Å åpne hodet",
      action_explanation: "Se for deg å åpne hodet - kabæmmm - hjernen er fri og du kan begynne med <u>Lucid Dreaming</u>."
    }
  }, {
    number: "77",
    en: {
      life_area: "Sleep Optimization & Power Napping",
      object_code: "a Z",
      object_explanation: "The Z symbolizes the Life Area <u>Sleep Optimization & Power Napping</u> (the Z is often used to illustrate sleep).",
      animation_description: "both 7 form a Z (flip the second 7 horizontally and vertically)",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Sleeping Beauty",
      person_explanation: "The Disney movie character slept for 100 years. Yup, she qualifies as our <u>Sleep Optimization & Power Napping</u> ambassador.",
      action_code: "Sleeping",
      action_explanation: "Simply visualize sleeping as lying down on a bed and when the comforter is in place the <u>Sleep Optimization & Power Napping</u> is a fact. "
    },
    no: {
      life_area: "Søvnoptimalisering & Power Napping",
      object_code: "En Z",
      object_explanation: "Z'en symboliserer Livsområdet <u>Søvnoptimalisering & Power Napping</u> (Z brukes ofte som tegn for søvn).",
      animation_description: "begge 7'erne danner en Z (flipp den andre horisontalt og vertikalt)",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Tornerose",
      person_explanation: "Disney-figuren sov i 100 år. Ok, hun kvalifiserer som personen for <u>Søvnoptimalisering & Power Napping</u> (verdens lengste høneblund).",
      action_code: "Å sove",
      action_explanation: "Se rett og slett for deg å sove på en seng og når dynen er på plass er også <u>Søvnoptimaliseringen & Power Nappingen</u> et faktum."
    }
  }, {
    number: "78",
    en: {
      life_area: "Soul Improvement Systems",
      object_code: "Elf crown",
      object_explanation: "The elf crown symbolizes the Life Area <u>Soul Improvement Systems</u> (elfs are known for being enlightened creatures).",
      animation_description: "7 as the crown + 8 as the \"infinity\" jewel",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Galadriel",
      person_explanation: "The elf queen Galadriel (Lord of The Rings) must have \"leveled up\" and be top ranking in all <u>Soul Improvement Systems</u>.",
      action_code: "Emitting light ray from heart",
      action_explanation: "What better way to represent <u>Soul Improvement Systems</u> than emitting a light ray from the heart? We're sure it's one of Galadriel's powers."
    },
    no: {
      life_area: "Dypere Utviklingssystemer",
      object_code: "Alvekrone",
      object_explanation: "Alvekronen symboliserer Livsområdet <u>Dypere Utviklingssystemer</u> (alver er kjent for å være opplyste vesener).",
      animation_description: "7 som kronen + 8 som \"uendelighetsjuvel\" ",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Galadriel",
      person_explanation: "Alvedronningen (Ringenes Herre) må ha nådd øverste sjelenivå for lengst ... så dronning av <u>Dypere Utviklingssystemer</u> - uten tvil.",
      action_code: "Å lyse fra hjertet",
      action_explanation: "Neppe noen kulere handling enn å la en lysstråle lyse fra hjertet for å representere <u>Dypere Utviklingssystemer</u>. Galadriel må ihvertfall ha denne ferdigheten!"
    }
  }],
  leisure: [{
    number: "89",
    en: {
      life_area: "Cooking",
      object_code: "Frying pan",
      object_explanation: "The frying pan symbolizes the Life Area <u>Cooking</u>.",
      animation_description: "8 as two eggs + 9 as frying pan",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Gordon Ramsay",
      person_explanation: "The famous cook from the Master Chef TV-show has inspired millions in their <u>Cooking</u> improvement.",
      action_code: "Opening a wine bottle",
      action_explanation: "Plain and simple - opening a wine bottle represents home <u>Cooking</u>."
    },
    no: {
      life_area: "Matlaging",
      object_code: "Stekepanne",
      object_explanation: "Stekepannen symboliserer Livsområdet <u>Matlaging</u>.",
      animation_description: "8 som to egg + 9 som stekepanne",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Gordon Ramsay",
      person_explanation: "Den berømte TV-kokken serverer <u>Matlaging</u> som en blanding av thriller, komedie og kunnskapsrett.",
      action_code: "Å åpne en vinflaske",
      action_explanation: "Enkelt og greit - å åpne en vinflaske representerer <u>Matlaging</u> - forvent kjeft fra Ramsey om vinen ikke matcher maten!"
    }
  }, {
    number: "90",
    en: {
      life_area: "Practical Life Hacking",
      object_code: "Pocket knife",
      object_explanation: "The Swiss pocket knife symbolizes the Life Area <u>Practical Life Hacking</u>.",
      animation_description: "9 inside 0 as closed Swiss pocket knife",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "MacGyver",
      person_explanation: "The main character from the TV-series uses <u>Practical Life Hacking</u> solutions to escape from the life-threatening situations he usually finds himself in.",
      action_code: "Unfolding a pocket knife",
      action_explanation: "Unfolding a Swiss pocket knife can truly unleash your <u>Practical Life Hacking</u> potential. But the king will forever be MacGyver."
    },
    no: {
      life_area: "Praktisk Life Hacking",
      object_code: "Lommekniv",
      object_explanation: "Lommekniven symboliserer Livsområdet <u>Praktisk Life Hacking</u>.",
      animation_description: "9 inne i 0 som lukket sveitsisk lommekniv",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "MacGyver",
      person_explanation: "Når det gjelder <u>Praktisk Life Hacking</u> står MacGyver ene og alene på tronen - han finner alltid en løsning fra livstruende situasjoner.",
      action_code: "Å åpne en lommekniv",
      action_explanation: "Å åpne en sveitsisk lommekniv kan virkeliggjøre ditt potensial innen <u>Praktisk Life Hacking</u>, men kongen vil for alltid være MacGyver!"
    }
  }, {
    number: "91",
    en: {
      life_area: "Self-Defence",
      object_code: "Knife",
      object_explanation: "The knife symbolizes the Life <u>Area Self-Defence</u>.",
      animation_description: "9 as ergonomic knife handle + 1 as knife blade ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Bruce Lee",
      person_explanation: "Be like water my friend! Bruce Lee has inspired a whole world of martial artists and <u>Self-Defence</u> specialists.",
      action_code: "Martial arts high kicking",
      action_explanation: "Ooooaaaaah ... a spinning heel kick to the head! All right, probably not the best <u>Self-Defence</u> technique, but still pretty cool. Bruce was the man!"
    },
    no: {
      life_area: "Selvforsvar",
      object_code: "Kniv",
      object_explanation: "Kniven symboliserer Livsområdet <u>Selvforsvar</u>.",
      animation_description: "9 som ergonomisk knivhåndtak + 1 som knivblad",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Bruce Lee",
      person_explanation: "Be like water my friend\". Bruce Lee har inspirert generasjoner med eksperter og entusiaster innen <u>Selvforsvar</u> og kampsport.",
      action_code: "Å sparke høyt kampsportspark",
      action_explanation: "Paff ... et spinning hælspark til hodet! Ok, kanskje ikke den beste teknikken i <u>Selvforsvar</u>, men kult endog. Bruce var sjef!"
    }
  }, {
    number: "92",
    en: {
      life_area: "Knowledge Travels",
      object_code: "Airpline",
      object_explanation: "The airplane symbolizes the Life Area <u>Knowledge Travels</u> (with Memolife's apps, you can learn a country's history, geography, culture/etiquette or a new language while flying).",
      animation_description: "9 as horizontal plane body + 2 flipped as plane tail",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Marco Polo",
      person_explanation: "Marco Polo's travels were indeed <u>Knowledge Travels</u>, enriching him with skills, knowledge and wealth.",
      action_code: "Opening a suitcase",
      action_explanation: "Visualize opening a suitcase and which possible gifts from your <u>Knowledge Travels</u> it could contain. Perhaps a new language or a country history?"
    },
    no: {
      life_area: "Kunnskapsreiser",
      object_code: "Fly",
      object_explanation: "Flyet symboliserer Livsområdet <u>Kunnskapsreiser</u> (med Memolife's apper kan du lynraskt lære et lands historie, geografi, kultur/etikette eller språk mens du flyr).",
      animation_description: "9 som horisontal flykropp + 2 som flyhale",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Marco Polo",
      person_explanation: "Marco Polo's reiser var <u>Kunnskapsreiser</u>, beriket med ny kunnskap, nye ferdigheter og ny velferd.",
      action_code: "Å åpne en koffert",
      action_explanation: "Visualiser at du åpner en koffert med mulige gaver fra dine <u>Kunnskapsreiser</u> - kanskje et nytt språk, et lands historie eller geografi?"
    }
  }, {
    number: "93",
    en: {
      life_area: "Appreciating Nature",
      object_code: "Grapes",
      object_explanation: "The grapes symbolize the Life Area <u>Nature Appreciation</u>.",
      animation_description: "9 upside down + 3 sideways as grapes",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Neytiri",
      person_explanation: "Zoe Saldana as the beautiful alien Na'vi character living in harmony with nature in the movie Avatar. Yup, <u>Appreciating Nature</u> is all her!",
      action_code: "Growing a tail",
      action_explanation: "Growing a tail is a cute action, and what else can symbolize <u>Appreciating Nature</u> better than \"going back to our roots\"?"
    },
    no: {
      life_area: "Verdsettelse Av Naturen",
      object_code: "Druer",
      object_explanation: "Druene symboliserer Livsområdet <u>Verdsettelse Av Naturen</u>.",
      animation_description: "9 oppned + 3 sidelengs som druer",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Neytiri",
      person_explanation: "Zoe Saldana som den vakre utenomjordiske Na'vi-kvinnen Neytiri. Hvem personifiserer vel <u>Verdsettelse Av Naturen</u> bedre?",
      action_code: "Å få en hale til å vokse",
      action_explanation: "Å få en hale til å vokse er en søt handling, og hva ellers kunne symbolisere <u>Verdsettelse Av Naturen</u> bedre enn å \"gå tilbake til våre røtter\"?"
    }
  }, {
    number: "94",
    en: {
      life_area: "Sports Mastery",
      object_code: "Football field",
      object_explanation: "The athletics field symbolizes the Life Area <u>Sports Mastery</u>.",
      animation_description: "9 as center + 4 as boundaries of football field",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Cristiano Ronaldo",
      person_explanation: "The world's best football player (sorry guys, soccer just doesn't have the same ring to it) personifies <u>Sports Mastery</u> with style.",
      action_code: "Kicking a football",
      action_explanation: "Kicking a football can be done by anyone, but doing it to perfection over and over again is a <u>Sports Mastery</u> where Ronaldo triumphs."
    },
    no: {
      life_area: "Sportsmestring",
      object_code: "Fotballbanespill",
      object_explanation: "Fotballbanespillet symboliserer Livsområdet <u>Sportsmestring</u>.",
      animation_description: "9 i senter + 4 som rammene for fotballbanespill",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Cristiano Ronaldo",
      person_explanation: "Verdens beste fotballspiller personifiserer <u>Sportsmestring</u> med seriøsitet og idrettsglede.",
      action_code: "Å sparke en fotball",
      action_explanation: "Å sparke en fotball kan hvem som helst gjøre, men å gjøre det til perfeksjon som Ronaldo er sjelden <u>Sportsmestring</u>."
    }
  }, {
    number: "95",
    en: {
      life_area: "Eco-Ethical Lifestyle & Shopping",
      object_code: "Grass dollar sign",
      object_explanation: "The grass dollar sign symbolizes the Life Area <u>Eco-Ethical Lifestyle & Shopping</u>.",
      animation_description: "9 through 5 = dollar $",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Angelina Jolie",
      person_explanation: "Known as a human rights and eco-activist, and seeing we're in lack of an adoption prize, the <u>Eco-Ethical Lifestyle & Shopping</u> award goes to her.",
      action_code: "Carrying a hemp bag",
      action_explanation: "Carrying a hemp bag ... a pretty suitable action for our <u>Eco-Ethical Lifestyle & Shopping</u> queen."
    },
    no: {
      life_area: "Øko-Etisk Livsstil & Shopping",
      object_code: "Gressdollartegn",
      object_explanation: "Gressdollartegnet symboliserer Livsområdet <u>Øko-Etisk Livsstil & Shopping</u>.",
      animation_description: "9 gjennom 5 = dollar $",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Angelina Jolie",
      person_explanation: "Som menneskerettighets- og økoforkjemper, og med tanke på at vi ikke har en adopsjonspremie, går <u>Øko-Etisk Livsstil & Shopping</u> til Angelina.",
      action_code: "Å bære en hamp-veske",
      action_explanation: "Å bære en hamp-veske ... en passende handling for vår dronning innen <u>Øko-Etisk Livsstil & Shopping</u>."
    }
  }, {
    number: "96",
    en: {
      life_area: "Music, Film & Photo Mastery",
      object_code: "Treble clef",
      object_explanation: "The treble clef symbolizes the Life Area of <u>Music, Film & Photo Mastery</u>.",
      animation_description: "9 and 6 on top of each other as treble clef",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Prince",
      person_explanation: "As a musical genius and creative artist, choosing Prince as the representative for <u>Music, Film & Photo Mastery</u> was pretty easy.",
      action_code: "Playing the guitar",
      action_explanation: "With a funky purple guitar around the neck anyone will unleash their <u>Music, Film & Photo Mastery</u> moves."
    },
    no: {
      life_area: "Musikk, Film & Foto",
      object_code: "G-Nøkkel",
      object_explanation: "G-nøkkelen symboliserer Livsområdet <u>Musikk-, Film- og Foto</u>.",
      animation_description: "9 og 6 på toppen av hverandre = g-nøkkel",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Prince",
      person_explanation: "Som musikkgeniet og kunstneren han er vinnes <u>Musikk-, Film- og Foto</u> lett av Prince.",
      action_code: "Å spille gitar",
      action_explanation: "Med en funky lilla gitar rundt nakken kan hvem som helst slippe løs sin indre helt innen <u>Musikk-, Film- og Foto</u>."
    }
  }, {
    number: "97",
    en: {
      life_area: "Drawing & Sketching",
      object_code: "Pencil",
      object_explanation: "The pencil symbolizes the Life Area <u>Drawing & Sketching</u>.",
      animation_description: "9 reversed joined with 7 forming a pencil",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Donald Duck",
      person_explanation: "The most famous cartoon character of all time - who else could personify <u>Drawing & Sketching</u>?",
      action_code: "Drawing",
      action_explanation: "Visualizing drawing lines and shading is fine, but Donald Duck <u>Drawing & Sketching</u> himself? Hmmmm ... sure, why not?"
    },
    no: {
      life_area: "Tegning, Form & Farge",
      object_code: "Blyant",
      object_explanation: "Blyanten symboliserer Livsområdet <u>Tegning, Form & Farge</u>.",
      animation_description: "9 reversert, slått sammen med 7 = danner en blyant",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Donald Duck",
      person_explanation: "Verdens mest berømte tegneseriefigur - hvem ellers kan personifisere <u>Tegning, Form & Farge</u>.",
      action_code: "Å tegne",
      action_explanation: "Å se for seg å tegne linjer og skyggelegge er greit nok, men å se for seg Donald som lager en <u>Tegning, Form & Farge</u> av seg selv? Hmmm ... hvorfor ikke?"
    }
  }, {
    number: "98",
    en: {
      life_area: "Survival & Life Rescue Skills",
      object_code: "Knot",
      object_explanation: "The knot symbolizes the Life Area <u>Survival & Rescue</u> skills.",
      animation_description: "9 + 8 as a rope knot",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Bear Grylls",
      person_explanation: "A British adventurer, writer and television presenter, widely known for his television series Man vs. Wild dealing with <u>Survival & Life Rescue</u> Skills.",
      action_code: "Water rescuing",
      action_explanation: "Swimming sideways with one arm pulling who/what you're rescuing - a core <u>Survival & Life Rescue</u> skill."
    },
    no: {
      life_area: "Overlevelse & Livredning",
      object_code: "Knute",
      object_explanation: "Knuten symboliserer Livsområdet <u>Overlevelse & Livredning</u>.",
      animation_description: "9 + 8 som knute på et rep",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Bear Grylls",
      person_explanation: "Den britiske eventyreren og TV-personen er kjent for programmet Man VS Wild, og lager bra interesse rundt <u>Overlevelse & Livredning</u>.",
      action_code: "Å livrednings-svømme",
      action_explanation: "Å svømme sidelengs med en arm og dra med seg den man redder med den andre - kjerneteknikk innen <u>Overlevelse & Livredning</u>."
    }
  }, {
    number: "99",
    en: {
      life_area: "Dancing",
      object_code: "Bell bottom pants",
      object_explanation: "The bell bottom pants symbolize the Life Area of <u>Dancing</u>.",
      animation_description: "9 + 9 as bell bottom pants",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "John Travolta",
      person_explanation: "Travolta shows off his <u>Dancing</u> moves in Saturday Night Fever and Pulp Fiction. Make your choice.",
      action_code: "Dancing",
      action_explanation: "Visualize the disco <u>Dancing</u> signature moves with one hand up and another down to the side."
    },
    no: {
      life_area: "Dansing",
      object_code: "Hippiebukser",
      object_explanation: "Hippiebuksene symboliserer Livsområdet <u>Dansing</u>.",
      animation_description: "9 + 9 som hippiebukser",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "John Travolta",
      person_explanation: "Travolta shower med <u>Dansing</u> i både Saturday Night Fever og Pulp Fiction. Valget er ditt.",
      action_code: "Å danse",
      action_explanation: "Forestill deg <u>Dansing</u> med diskoens signaturbevegelser - en hånd opp og en annen ned og til siden."
    }
  }],
  career: [{
    number: "79",
    en: {
      life_area: "Personal Branding",
      object_code: "Tm (trademark)",
      object_explanation: "The TM symbolizes the Life Area <u>Personal Branding</u>.",
      animation_description: "7 as a T + 9 sideways as an M = Tm",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Marilyn Monroe",
      person_explanation: "All right, Marilyn Monroe pretty much invented <u>Personal Branding</u> with her trademark dress-move.",
      action_code: "Air-lifting dress from below",
      action_explanation: "Visualize a person wearing a dress (man or woman) and air from below lifting it up - like the <u>Personal Branding</u> trademark of Marilyn Monroe."
    },
    no: {
      life_area: "Personlig Branding",
      object_code: "Tm (Varemerke)",
      object_explanation: "TM symboliserer Livsområdet <u>Personlig Branding</u>.",
      animation_description: "7 som T + 9 sidelenges som M = TM",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Marilyn Monroe",
      person_explanation: "Ok, Marilyn Monroe fant vel mer eller mindre opp <u>Personlig Branding</u> med sine typiske poseringer og antrekk.",
      action_code: "Å få kjolen vind-løftet",
      action_explanation: "Se for deg en person med kjole og at den blir løftet av vinden - som varemerket i Marilyn Monroes <u>Personlige Branding</u>."
    }
  }, {
    number: "80",
    en: {
      life_area: "Time Management & Efficiency",
      object_code: "Table",
      object_explanation: "The table symbolizes the Life Area <u>Time Management & Efficiency</u> (the table reperesents efficient meetings).",
      animation_description: "8 as two chairs + 0 as a table",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Donald Trump",
      person_explanation: "The Donald\" seems to have a no-compromise-approach to <u>Time Management & Efficiency techniques</u>.",
      action_code: "Firing someone",
      action_explanation: "Pointing the arm with finger outwards. \"You're fired\" was part of Donald Trump's <u>Time Management & Efficiency</u> phrases on his reality show."
    },
    no: {
      life_area: "Time Management & Effektivitet",
      object_code: "Bord",
      object_explanation: "Bordet symboliserer Livsområdet <u>Time Management & Effektivitet</u> (bordet spiller på effektive møter).",
      animation_description: "8 som to stoler + 0 som bord i møterom",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Donald Trump",
      person_explanation: "The Donald\" virker å ha en null-kompriss-holdning til <u>Time Management & Effektivitet</u>.",
      action_code: "Å gi noen sparken",
      action_explanation: "Å strekke armen med pekende finger til siden. \"You're fired\" var Donald Trumps gjenganger i <u>Time Management & Effektivet</u> i reality-showet hans."
    }
  }, {
    number: "81",
    en: {
      life_area: "Teamwork & People Skills",
      object_code: "Necklace",
      object_explanation: "The necklace symbolizes the Life Area </u>Teamwork & People Skills</u> (the necklace represents a magic people touch).",
      animation_description: "8 as pearls + 1 as necklace connecting them",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Oprah Winfrey",
      person_explanation: "Her outstanding <u>Teamwork & People Skills</u> have helped the American talk show queen build her massive business empire.",
      action_code: "To sit down",
      action_explanation: "Visualize how a person sits down next to you and already things are seeming friendly - a small part of Opra's <u>Teamwork & People Skills</u>."
    },
    no: {
      life_area: "Samarbeid & Sosiale Evner",
      object_code: "Halskjede",
      object_explanation: "Halskjedet symboliserer Livsområdet <u>Samarbeid & Sosiale Evner</u> (halskjedet representerer en magisk mellommenneskelig touch).",
      animation_description: "8 som perler + 1 som halskjedet som forbinder dem",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Oprah Winfrey",
      person_explanation: "Hennes eksepsjonelle <u>Samarbeid & Sosiale Evner</u> er det som har gjort Oprah til den mediedronningen hun er i dag.",
      action_code: "Å sette seg ned",
      action_explanation: "Se for deg at en person setter seg ned ved siden av deg ... og vips ble det jo litt mer vennlig, som er viktig i <u>Samarbeid & Sosiale Evner</u>."
    }
  }, {
    number: "82",
    en: {
      life_area: "Storytelling & Presentation Techniques",
      object_code: "Projector",
      object_explanation: "The projector symbolizes the Life Area <u>Storytelling & Presentation Techniques</u>.",
      animation_description: "8 as two projector zoom features + 2 as the projector height support",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Morgan Freeman",
      person_explanation: "Besides acting, he is known for his brilliant <u>Storytelling & Presentation Techniques</u>.",
      action_code: "Using something as a microphone",
      action_explanation: "Like a child imitating a rock star with a fake microphone. Good ol' Freeman prefers a real one, but still rocks the <u>Storytelling & Presentation techniques</u>."
    },
    no: {
      life_area: "Historiefortelling & Presentasjonsteknikk",
      object_code: "Prosjektor",
      object_explanation: "Prosjektoren symboliserer Livsområdet <u>Historiefortelling & Presentasjonsteknikker</u>.",
      animation_description: "8 som prosjektor zoom + 2 som prosjektor høydestøtte",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Morgan Freeman",
      person_explanation: "Ved siden av å være skuespiller er <u>Historiefortelling & Presentasjonsteknikker</u> det gode gamle Freeman er kjent for.",
      action_code: "Å bruke noe som mikrofon",
      action_explanation: "Som et barn som leker rockestjerne! Gamle Freeman foretrekker en ekte en, men han rocker allikevel <u>Historiefortelling & Presentasjonsteknikker</u>."
    }
  }, {
    number: "83",
    en: {
      life_area: "Neuro-Sales & Negotiation",
      object_code: "Mannequin model",
      object_explanation: "The naked mannequin model symbolizes the Life Area <u>Neuro-Sales & Negotiation</u> skills (nudity sells).",
      animation_description: "8 as mannequin model + 3 as breasts ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jerry Maguire",
      person_explanation: "Tom Cruise's character Jerry Maguire personifies <u>Neuro-Sales & Negotiation</u> techniques, following his values and adapting to his clients.",
      action_code: "Showing money bills",
      action_explanation: "Show me the money!\" is a hilarious movie scene in Jerry Magire, although pretty far from any ideal <u>Neuro-Sales & Negotiation</u> techniques."
    },
    no: {
      life_area: "Nevro-Salg & Forhandlinger",
      object_code: "Mannequinmodell",
      object_explanation: "Den nakne mannequinmodellen symboliserer Livsområdet <u>Nevro-Salg & Forhandlinger</u> (nakenhet selger).",
      animation_description: "8 som mannequinmodell + 3 som bryster",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jerry Maguire",
      person_explanation: "Tom Cruise som Jerry Maguire personifiserer <u>Nevro-Salg & Forhandlinger</u>, idet han følger sine verdier og tilpasser seg kundene.",
      action_code: "Å vise pengesedler",
      action_explanation: "Show me the money!\" er den morsomste scenen fra Jerry Magire, selv om det er langt fra ideelt for <u>Nevro-Salg & Forhandlinger</u>.\\nÅ slå karate-slag"
    }
  }, {
    number: "84",
    en: {
      life_area: "Coaching & Mentoring",
      object_code: "Present",
      object_explanation: "The present symbolizes the Life Area <u>Coaching & Mentoring</u> skills (the present contains valuable skills and insights from mentor to student).",
      animation_description: "8 as ribbon on top of present + 4 continuing around the present",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Mr. Miyagi",
      person_explanation: "Wax on, wax off! Jackie Chan plays Mr. Miyagi in the Karate Kid remake, earning him the <u>Coaching & Mentoring</u> award in the Memolife system.",
      action_code: "Karate-punching",
      action_explanation: "Open hand karate-punching. Jackie Chan should have enough <u>Coaching & Mentoring</u> skills to get the job done."
    },
    no: {
      life_area: "Coaching & Mentorskap",
      object_code: "Presang",
      object_explanation: "Presangen symboliserer Livsområdet <u>Coaching & Mentorskap</u> (presangen inneholder verdifull ferdighetsoverføring fra mentor til student).",
      animation_description: "8 som sløyfe på presang + 4 som sløyfe rundt presangen",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Mr. Miyagi",
      person_explanation: "Wax on, wax off! Jackie Chan spiller Mr. Miyagi i den nye versjonen av Karate Kid og vinner rollen for <u>Coaching & Mentorskap</u> i Memolife-systemet.",
      action_code: "Å slå karate-slag",
      action_explanation: "Åpent håndkantslag. Jackie Chan burde ha nok erfaring innen <u>Coaching & Mentorskap</u> for å få jobben gjort."
    }
  }, {
    number: "85",
    en: {
      life_area: "It-Skills & It-Security",
      object_code: "Laptop",
      object_explanation: "The laptop symbolizes the Life Area <u>IT-security & IT-skills</u>.",
      animation_description: "8 as a wide laptop screen and keyboard + 5 as a lock",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Mark Zuckerberg",
      person_explanation: "As the founder of Facebook, <u>IT-Skills & IT-Security</u> are his bread and butter.",
      action_code: "Using a mouse",
      action_explanation: "Using a mouse. Click, click, scroll, pan ... all right, a pretty boring action, but as long as it works, it works. Just like <u>IT-Skills & IT-Security</u>."
    },
    no: {
      life_area: "IT-Ferdigheter & IT-Sikkerhet",
      object_code: "Laptop",
      object_explanation: "Laptopen symboliserer Livsområdet <u>IT-Ferdigheter & IT-Sikkerhet</u>.",
      animation_description: "8 som bred laptop + 5 som lås",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Mark Zuckerberg",
      person_explanation: "Som grunnlegger av Facebook er <u>IT-Sikkerhet & IT-Ferdigheter</u> alfa omega for Zuckerberg.",
      action_code: "Å bruke mus",
      action_explanation: "Å bruke en mus. Klikk, klikk, scroll, flytt ... ok, en ganske kjedelig handling, men så lenge den funker, funker den. Akkurat som for <u>IT-Ferdigheter & IT-Sikkerhet</u>."
    }
  }, {
    number: "86",
    en: {
      life_area: "Management & Project Management",
      object_code: "Puzzle piece",
      object_explanation: "The puzzle piece symbolizes the Life Area <u>Management & Project Management</u> skills (the manager places the right pieces together).",
      animation_description: "8 + 6 as puzzle pieces",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Sun Tzu",
      person_explanation: "The famous Chinese general's classic masterpiece \"The Art of War\" is still considered a \"must-read\" in <u>Management & Project Management</u>.",
      action_code: "Opening a map roll",
      action_explanation: "Visualize the opening of a map roll with Sun Tzu's master plan - any <u>Management & Project Management</u> needs one."
    },
    no: {
      life_area: "Ledelse & Prosjektledelse",
      object_code: "Puslebiter",
      object_explanation: "Puslebitene symboliserer Livsområdet <u>Ledelse & Prosjektledelse</u> (lederen plasserer de riktige bitene sammen).",
      animation_description: "8 + 6 som puslebiter",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Sun Tzu",
      person_explanation: "Den kinesiske generalens mesterverk \"The Art of War\" er fremdeles som pensum å regne for alle innen <u>Ledelse & Prosjektledelse</u>.",
      action_code: "Å åpne en kartrull",
      action_explanation: "Visualiser å åpne en kartrull med Sun Tzus mesterplan - enhver <u>Ledelse & Prosjektledelse</u> trenger en."
    }
  }, {
    number: "87",
    en: {
      life_area: "Critical-Analytical Thinking & Decision Making",
      object_code: "Chess board",
      object_explanation: "The chess board symbolizes the Life Area <u>Critical-Analytical Thinking & Decision Making</u>.",
      animation_description: "8 + 7 as a crossword",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Magnus Carlsen",
      person_explanation: "The Norwegian world chess champion was a prodigy and no stranger to <u>Critical-Analytical Thinking & Decision Making</u>.",
      action_code: "Playing chess",
      action_explanation: "Moving a chess piece involves both <u>Critical-Analytical Thinking & Decision Making</u>, skills the chess guru and fashion model should have enough of."
    },
    no: {
      life_area: "Kritisk-Analytisk Tenkning & Beslutningsevne",
      object_code: "Sjakkbrett",
      object_explanation: "Sjakkbrettet symboliserer Livsområdet <u>Kritisk-Analytisk Tenkning & Beslutningsevne</u>.",
      animation_description: "8 + 7 som kryssord",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Magnus Carlsen",
      person_explanation: "Verdensmesteren i sjakk har ganske sikkert <u>Kritisk-Analytisk Tenkning & Beslutningsevne</u> som mantra.",
      action_code: "Å spille sjakk",
      action_explanation: "Å flytte en sjakkbrikke involverer både <u>Kritisk-Analytisk Tenkning & Beslutningsevne</u>. Ferdigheter sjakk-guruen og motemodellen burde ha nok av."
    }
  }, {
    number: "88",
    en: {
      life_area: "Innovation & Creative Problem Solving",
      object_code: "Light bulb",
      object_explanation: "The light bulb symbolizes the Life Area <u>Innovation & Creative Problem Solving</u>. ",
      animation_description: "8 as light bulb + 8 as its shadow ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Steve Jobs",
      person_explanation: "The late co-founder and charismatic CEO of Apple was widely known for <u>Innovation & Creative Problem Solving</u>.",
      action_code: "Pulling something out of a hat",
      action_explanation: "It's a kind of magic! <u>Innovation & Creative Problem Solving</u> does seem like a hat trick sometimes, and Steve Jobs seemed to pull it off time and again."
    },
    no: {
      life_area: "Innovasjon & Kreativ Problemløsning",
      object_code: "Lyspære",
      object_explanation: "Lyspæren symboliserer Livsområdet <u>Innovasjon & Kreativ Problemløsning</u>.",
      animation_description: "8 som lyspære + 8 som skygge",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Steve Jobs",
      person_explanation: "Ok, dersom noen fortjener å bli titulert som personen for <u>Innovasjon & Kreativ Problemløsning</u> i Memolife-systemet er det \"Lord Jobs\".",
      action_code: "Å dra noe ut av en hatt",
      action_explanation: "Ren magi! <u>Innovasjon & Kreativ Problemløsning</u> kan virker som tryllekunst noen ganger, ihvertfall slik Steve Jobs fikk det til gang etter gang."
    }
  }],
  body: [{
    number: "52",
    en: {
      life_area: "Versatile Fitness",
      object_code: "Pull-up bar",
      object_explanation: "The calisthenics pull-up bar symbolizes the Life Area <u>Versatile Fitness</u>.",
      animation_description: "5 as body + 2 as pull-up bar",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jason Statham",
      person_explanation: "The actor combines <u>Versatile Fitness</u> with martial arts and stuntman training to stay fit.",
      action_code: "Doing pull-ups",
      action_explanation: "Doing pull-ups the calisthenics ways represents <u>Versatile Fitness</u> just as much as Jason Statham."
    },
    no: {
      life_area: "Allsidig Trening",
      object_code: "Opptrekksstang",
      object_explanation: "Opptrekksstangen symboliserer Livsområdet <u>Allsidig Trening</u>.",
      animation_description: "5 som kropp + 2 som opptrekksstang",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jason Statham",
      person_explanation: "Skuespilleren kombinerer <u>Allsidig Trening</u> med kampsport og stuntmanfilming for å holde seg i form.",
      action_code: "Å ta opptrekk",
      action_explanation: "Å ta opptrekk med forskjellige calisthenicsøvelser representerer <u>Allsidig Trening</u> like mye som Statham selv."
    }
  }, {
    number: "53",
    en: {
      life_area: "Brain-Body Training",
      object_code: "Sit-ups doll with tablet",
      object_explanation: "The sit-ups with a tablet symbolizes the Life Area <u>Brain-Body Training</u> (involves time-saving exercises which train your brain and body at the same time).",
      animation_description: "5 as sit-up model + 3 as hands holding a phone/tablet ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Neo",
      person_explanation: "Keanu Reeves, as Neo in the Matrix movie, is simply put a <u>Brain-Body Training</u> badass.",
      action_code: "Doing brain-body sit-ups",
      action_explanation: "Doing sit-ups with a phone/tablet in the hands."
    },
    no: {
      life_area: "Brain-Body Trening",
      object_code: "Situpsdukke med mobil",
      object_explanation: "Situpsdukken med nettbrettet symboliserer Livsområdet <u>Hjerne-Kropp-Trening</u> (involverer tidsbesparende øvelser som trener kropp og hjerne samtidig).",
      animation_description: "5 som situpsdukke + 3 som hender med mobil/nettbrett",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Neo",
      person_explanation: "Keanu Reeves, som Neo i Matrix-filmene, er rett og slett <u>Hjerne-Kropp-Trening</u> personifisert. Nye mentale ferdigheter lastet opp mens han slåss!",
      action_code: "Å ta hjerne-kropp situps",
      action_explanation: "Å ta sit-ups med mobil/nettbrett i hånd lar deg gjøre <u>Hjerne-Kropp-Trening</u> på en morsom og effektiv måte."
    }
  }, {
    number: "54",
    en: {
      life_area: "Body Language",
      object_code: "Crossed arms statue",
      object_explanation: "The crossed arms statue symbolizes the Life Area <u>Body Language</u>.",
      animation_description: "5 as upper-body statue + 4 as crossed arms",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Charlie Chaplin",
      person_explanation: "Actor-comedian known for his funny <u>Body Language</u> in the silent movies.",
      action_code: "Crossing arms",
      action_explanation: "Crossing arms is a stereotypical <u>Body Language</u> signal of someone being defensive. It's easy to imagine Charlie Chaplin doing this well."
    },
    no: {
      life_area: "Kroppsspråk",
      object_code: "Statue Med Kryssede Armer",
      object_explanation: "Statuen med kryssede armer symboliserer Livsområdet <u>Kroppsspråk</u>.",
      animation_description: "5 som overkroppsstatue + 4 som kryssede armer",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Charlie Chaplin",
      person_explanation: "Skuespiller-komikeren er mest av alt kjent for sitt <u>Kroppsspråk</u>, da de fleste av filmene hans kom før lyd ble lagt til.",
      action_code: "Å krysse armene",
      action_explanation: "Å krysse armene er stereotypisk <u>Kroppsspråk</u> for å være defensiv. Det er enkelt å se for seg Chaplin gjøre dette hysterisk morsomt."
    }
  }, {
    number: "55",
    en: {
      life_area: "Micro-Facial Expressions",
      object_code: "Smiling devil mask",
      object_explanation: "The smiling devil mask symbolizes the Life Area <u>Micro-Facial Expressions</u>.",
      animation_description: "flip the left 5 and merge with the right 5 = smiling devil mask ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Jim Carrey",
      person_explanation: "Comedian and Actor known for his many funny faces, impressions and <u>Micro-Facial Expressions</u>. ",
      action_code: "Grimacing",
      action_explanation: "Known for his great impersonations, <u>Micro-Facial Expressions</u> and grimacing is Jim Carey's bread and butter."
    },
    no: {
      life_area: "Ansiktsuttrykk",
      object_code: "Smilende Djevelmaske",
      object_explanation: "Den smilende djevelmasken symboliserer Livsområdet <u>Ansiktsuttrykk</u>,",
      animation_description: "snu en 5 og slå sammen med den andre 5 = smilende djevelsmaske",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Jim Carrey",
      person_explanation: "Komikeren og skuespilleren er kjent for sine mange etterligninger og <u>Ansiktsuttrykk</u>.",
      action_code: "Å lage grimase",
      action_explanation: "Med sine gode og morsomme etterligninger av kjendiser ble <u>Ansiktsuttrykk</u> til Jim Carey's varemerke."
    }
  }, {
    number: "56",
    en: {
      life_area: "Sensual Intelligence",
      object_code: "Ear",
      object_explanation: "The ear symbolizes the Life Area <u>Sensual Intellingence</u> (involves the training of all the body's senses).",
      animation_description: "5 as face boundary + 6 as ear ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Wolfgang Amadeus Mozart",
      person_explanation: "Perhaps the greatest musical genius the world has seen, Mozart is a good fit for auditive and <u>Sensual Intelligence</u> (training of senses).",
      action_code: "Listening",
      action_explanation: "Putting a palm behind the ear to listen better actually works. A small trick to increase your <u>Sensual Intelligence</u>."
    },
    no: {
      life_area: "Sensuell Intelligens",
      object_code: "Øre",
      object_explanation: "Øret symboliserer Livsområdet <u>Sensuell Intelligens</u> (innebærer trening av alle sansene).",
      animation_description: "5 som ansiktsomriss + 6 som øre",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Wolfgang Amadeus Mozart",
      person_explanation: "Kanskje verdens største musikalske geni - Mozart personifiserer <u>Sensuell Intelligens</u> (trening av sansene).",
      action_code: "Å lytte",
      action_explanation: "Å plassere hånden bak øret får deg faktisk til å lytte bedre; et enkelt triks for å forbedre din <u>Sensuelle Intelligens</u> (trening av sansene)."
    }
  }, {
    number: "57",
    en: {
      life_area: "Sports Psychology",
      object_code: "Diving tower",
      object_explanation: "The diving tower symbolizes the Life Area <u>Sports Psychology</u> (doing diving tricks from a 10m height requires a good sports psychology).",
      animation_description: "5 on top of 7 as diving tower",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Alex Ferguson",
      person_explanation: "The legendary Manchester United football coach used <u>Sports Psychology</u> to unleash the potential in his players.",
      action_code: "Whistling",
      action_explanation: "Whistling is a good feedback tool in <u>Sports Psychology</u>, although Ferguson apparently also used a much harder approach (hairdryer, anyone?)."
    },
    no: {
      life_area: "Sportspsykologi",
      object_code: "Stupetårn",
      object_explanation: "Stupetårnet symboliserer Livsområdet <u>Sportspsykologi</u> (å gjøre triks fro 10m høyde forutsetter en god sportspsykologi).",
      animation_description: "5 på toppen av 7 som stupetårn",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Alex Ferguson",
      person_explanation: "Den legendariske Manchester United treneren brukte en spesiell <u>Sportspsykologi</u> for å få frem potensialet i sine spillere.",
      action_code: "Å plystre",
      action_explanation: "Å plystre er et bra feedback-verktøy i <u>Sportspsykologi</u>, selv om Ferguson også var kjent for hardere grep (hårføner, anyone?)."
    }
  }, {
    number: "58",
    en: {
      life_area: "Body & Brain Food",
      object_code: "Oyster",
      object_explanation: "The oyster symbolizes the Life Area <u>Body & Brain Food</u>.",
      animation_description: "5 as pearls inside 8 as oyster",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Novak Djokovic",
      person_explanation: "As one of the world's best tennis players, Djokovic is also known for his holistic, gluten-free and low-carb <u>Body & Brain Food</u>.",
      action_code: "Strike with tennis racket",
      action_explanation: "All right, we know ... serving in tennis doesn't have much to do with <u>Body & Brain Food</u>, but Djokovic sure does."
    },
    no: {
      life_area: "Kropps- & Hjernemat",
      object_code: "Østers",
      object_explanation: "Østers symboliserer Livsområdet <u>Mat for Kropp & Hode</u>.",
      animation_description: "5 som perler inne i 8 østers",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Novak Djokovic",
      person_explanation: "Som en av verens beste tennisspillere er Djokovic også kjent for sin holistiske, glutenfrie og lavkarbo <u>Mat for Kropp & Hode</u>.",
      action_code: "Å slå tenisslag",
      action_explanation: "Ok, vi vet .... å slå et tennisslag har ikke såååå mye med <u>Mat for Kropp & Hode</u> å gjøre, men det har Djokovic."
    }
  }, {
    number: "59",
    en: {
      life_area: "Biofeedback & Electrotherapy",
      object_code: "Antenna",
      object_explanation: "The antenna symbolizes the Life Areas <u>Biofeedback & Electrotherapy</u> (measuring body signals & stimulating the body through tech solutions)",
      animation_description: "5 as person walking + 9 as antenna attached to back of body",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Robocop",
      person_explanation: "The movie character is half man, half robot, probably taking <u>Biofeedback & Electrotherapy</u> a step too far ...",
      action_code: "Attaching electrodes to the body",
      action_explanation: "If you want to use <u>Biofeedback & Electrothereapy</u>, hey, there's really no way around attaching electrodes to the body."
    },
    no: {
      life_area: "Biofeedback & Elektroterapi",
      object_code: "Antenne",
      object_explanation: "Antennen symboliserer Livsområdet <u>Biofeedback & Elektroterapi</u> (å måle kroppsignaler & stimulere kroppen vha teknologiske løsninger).",
      animation_description: "5 som gående person+ 9 som antenne på ryggen",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Robocop",
      person_explanation: "Filmfiguren, halvt mann og halvt robot, tok antageligvis <u>Biofeedback & Elektroterapi</u> et hakk for langt ...",
      action_code: "Å plassere elektroder på kroppen",
      action_explanation: "Vil du bruke <u>Biofeedback & Elektroterapi</u>, så er det ikke mange veier utenom å plassere elektroder på kroppen."
    }
  }, {
    number: "60",
    en: {
      life_area: "Yoga & Pilates",
      object_code: "Yoga mat",
      object_explanation: "The yoga mat symbolizes the Life Area <u>Yoga & Pilates</u>.",
      animation_description: "6 and 0 as two sides of a half rolled up yoga mat",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Madonna",
      person_explanation: "Known for her passion for <u>Yoga & Pilates</u>, as well as for her singing and acting ... all right Madonna - this spot is yours!",
      action_code: "Bending backwards",
      action_explanation: "Bending slowly backwards until the hands touch the ground ... it's such a <u>Yoga & Pilates</u> thing to do that we're sure Madonna posted it on Instagram."
    },
    no: {
      life_area: "Yoga & Pilates",
      object_code: "Yogamatte",
      object_explanation: "Yogamatten symboliserer Livsområdet <u>Yoga & Pilates</u>.",
      animation_description: "6 og 0 som to sider av en halvt rullet sammen yogamatte",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Madonna",
      person_explanation: "Kjent for sin lidenskap for <u>Yoga & Pilates</u>, så vel som for musikk ... ok Madonna, denne kategorien er din!",
      action_code: "Å bøye seg bakover",
      action_explanation: "Å bøye seg sakte bakover til hendene rører bakken ... det er en såpass typisk <u>Yoga & Pilates</u> handling at vi er sikre på at Madonna posted det på Instagram."
    }
  }, {
    number: "61",
    en: {
      life_area: "Body Focus & Posture",
      object_code: "Tin soldier",
      object_explanation: "The tin soldier symbolizes the Life Area <u>Body Focus & Posture</u>. ",
      animation_description: "6 as bad posture to 1 as good posture ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Napoleon",
      person_explanation: "Napoleon was known for using <u>Body Focus & Posture</u> awareness to compensate for his low height.",
      action_code: "Straightening up",
      action_explanation: "Attteeeentioooon! There you go - pushing your shoulders back and chest forwards improves your <u>Body Focus & Posture</u> - Napoleon would be proud."
    },
    no: {
      life_area: "Kroppsfokus & Holdningstrening",
      object_code: "Tinnsoldat",
      object_explanation: "Tinnsoldaten symboliserer Livsområdet <u>Kroppsfokus & Holdningstrening</u>.",
      animation_description: "6 som tinnsoldat med god holdning + 1 som gevær",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Napoleon",
      person_explanation: "Napoleon var kjent for å bruke <u>Kroppsfokus & Holdningstrening</u> for å kompensere for sin lave høyde.",
      action_code: "Å rette seg opp",
      action_explanation: "Oooopppstilliiing! Der ja, å rette seg opp med skuldrene bak og brystet frem er bra <u>Kroppsfokus & Holdningstrening</u> - Napoleon ville vært stolt."
    }
  }, {
    number: "62",
    en: {
      life_area: "Natural & Alternative Treatments",
      object_code: "Tea cup",
      object_explanation: "The tea cup symbolizes the Life Area <u>Natural & Alternative Treatments</u> (the tea cup can contain herbs and natural remedies).",
      animation_description: "6 and 2 as the left and right side of a beautiful tea cup",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Ghandi",
      person_explanation: "Ghandi, the peace loving activist for the Indian liberation movement, was also a strong supporter of <u>Natural & Alternative Treatments</u>.",
      action_code: "Dripping healing oil ",
      action_explanation: "Dripping healing oil onto the body seems to be the holy grail of the <u>Natural & Alternative Treatments</u>."
    },
    no: {
      life_area: "Naturlig & Alternativ Behandling",
      object_code: "Tekopp",
      object_explanation: "Tekoppen symboliserer Livsområdet <u>Naturlig & Alternativ Behandlling</u> (tekoppen kan f. eks. inneholde urter).",
      animation_description: "6 og 2 som venstre og høyre side av en flott tekopp",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Ghandi",
      person_explanation: "Ghandi, den fredselskende aktivisten for den indiske frigjøringsbevegelsen, var også en stor tilhenger av <u>Naturlig & Alternativ Behandling</u>.",
      action_code: "Å dryppe helbredende olje",
      action_explanation: "Å dryppe helbredende olje på kroppen virker å være den hellige gral innen <u>Naturlig & Alternativ Behandling</u>."
    }
  }, {
    number: "63",
    en: {
      life_area: "Small Body Movements",
      object_code: "Snail",
      object_explanation: "The snail symbolizes the Life Area <u>Small Body Movements</u> (the small movements being the cure against the health danger of sitting still for hours a day).",
      animation_description: "6 as the a snail shell + 3 as the snail",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Beyonce",
      person_explanation: "The hip-shaking entertainer can easily be associated with the importance of <u>Healthy Body Movements</u> in everyday life.",
      action_code: "Shaking hips",
      action_explanation: "We're talking vibratingly fast hip shaking! Real fast <u>Small Body Movements</u>. We're sure Beyonce does it in her sleep."
    },
    no: {
      life_area: "Sunne Kroppsbevegelser",
      object_code: "Snegle",
      object_explanation: "Sneglen symboliserer Livsområdet <u>Små Kroppsbevegelser</u> (korte kroppsøvelser motvirker helsefaren ved å sitte stille mange timer om dagen).",
      animation_description: "6 som et snegleskjell + 3 som snegle",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Beyonce",
      person_explanation: "Den hoftevrikkende artisten rocker helsefremmende <u>Små Kroppsbevegelser</u> som få andre.",
      action_code: "Å vrikke på hoftene",
      action_explanation: "Vi snakker vibrerende hoftevrikking. Super-hurtige <u>Små Kroppsbevegelser</u>. Beyonce gjør det sikkert i søvne."
    }
  }, {
    number: "64",
    en: {
      life_area: "Coordination Play",
      object_code: "Yo-yo",
      object_explanation: "The yo-yo symbolizes the Life Area <u>Coordination Play</u>.",
      animation_description: "6 as yo-yo + 4 as the thread ",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Ellen DeGeneres",
      person_explanation: "The entertainer is known for her crazy TV stunts and playful attitude - a pretty good match for <u>Coordination Play</u>.",
      action_code: "Yo-yo-ing",
      action_explanation: "Yo-yo-ing ... as in doing up and down <u>Coordination Play</u> with a yo-yo. Ellen, knock yourself out!"
    },
    no: {
      life_area: "Koordinasjonslek",
      object_code: "Yo-Yo",
      object_explanation: "Yo-yoen symboliserer Livsområdet <u>Koordinasjonslek</u>.",
      animation_description: "6 som yo-yo + 4 som tråd",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Ellen DeGeneres",
      person_explanation: "Entertaineren er kjent for sine smågale TV-stunts og kan lett bli assosiert med <u>Koordinasjonslek</u>. Ellen, uten tvil.",
      action_code: "Å bruke yo-yo",
      action_explanation: "Yo-yoing ... som i opp og ned <u>Koordinasjonslek</u> med en yo-yo. Ellen, ta jorda rundt!"
    }
  }, {
    number: "65",
    en: {
      life_area: "Massage & Physical Therapy",
      object_code: "Hand statue",
      object_explanation: "The hand statue symbolizes the Life Area <u>Massage & Physical Therapy</u>.",
      animation_description: "5 as fingers + 6 as support frame",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "James Bond",
      person_explanation: "007 is typically seen either giving or receiving <u>Massage & Physical Therapy</u>.",
      action_code: "Massaging ",
      action_explanation: "Visualize using all 10 fingers to really squeeze something, and imagine a sigh of relief when the <u>Massage & Physical Therapy</u> effects kick in."
    },
    no: {
      life_area: "Massasje & Fysikalsk Behandling",
      object_code: "Håndstatue",
      object_explanation: "Håndstatuen symboliserer Livsområdet <u>Massasje & Fysikalsk Behandling</u>.",
      animation_description: "5 som fingre + 6 som støtte",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "James Bond",
      person_explanation: "007 kan typisk bli sett mens han gir eller mottar <u>Massasje & Fysikalsk Behandling</u>.",
      action_code: "Å massere",
      action_explanation: "Se for deg å bruke alle 10 fingrene til å kna noe, og forestill deg et lettelsens sukk når <u>Massasje & Fysikalsk Behandling</u> får effekt."
    }
  }, {
    number: "66",
    en: {
      life_area: "Lifelong Health & Self-Monitoring",
      object_code: "Sperm cell",
      object_explanation: "The sperm cells symbolize the Life Area <u>Lifelong Health & Self-Monitoring</u>.",
      animation_description: "6 on top of 6 as sperm cell",
      learnification: "Now, link the OBJECT with the Life Area, PERSON and ACTION",
      person_code: "Dr. House",
      person_explanation: "The character House in the hospital TV-series is a good fit for <u>Lifelong Health & Self-Monitoring</u>.",
      action_code: "Using a stethoscope",
      action_explanation: "Imagine pressing a stethoscope against an object and hearing a pulse! A clear sign that <u>Lifelong Health & Self-Monitoring</u> techniques work ..."
    },
    no: {
      life_area: "Livslang Helse & Selvmåling",
      object_code: "Sædcelle",
      object_explanation: "Sædcellen symboliserer Livsområdet <u>Livslang Helse & Selvmåling</u>.",
      animation_description: "6 på toppen av 6 som sædcelle",
      learnification: "Knytt nå OBJEKTET sammen med Livsområdet, PERSONEN og HANDLINGEN",
      person_code: "Dr. House",
      person_explanation: "Legen \"House\" i TV-serien med samme navn passer bra for sin kunnskap om <u>Livslang Helse & Selvmåling</u>.",
      action_code: "Å bruke stethoskop",
      action_explanation: "Se for deg å bruke stethoskopet mot et objekt og at du hører en puls! Et klart tegn på at <u>Livslang Helse & Selvmåling</u> funker!"
    }
  }]
}

window.doublesysLocation = {
'en': {
"brain" : ['Moon', 'Sun'],
"knowledge" : ['Pyramid', 'Parthenon'],
"mind": ['Ballon', 'Bus'],
"body": ['Aeroplane', 'Car'],
"soul": ['Aifell tower', 'Statue of liberty'],
"career":['Chinese wall', 'Berlin wall'],
"leisure": ['Colosseum', 'Skew tower of Piza']
},
'no': {
"brain" : ['Saturn', 'Sol'],
"knowledge" : ['Pyramide', 'Akropolis'],
"mind": ['Luftballong', 'Buss'],
"body": ['Fly', 'Bil'],
"soul": ['Eiffeltårnet', 'Frihetsgudinnen'],
"career":['Den kinesiske mur', 'Berlinmuren'],
"leisure": ['Colosseum', 'Det skjeve tårnet i Piza']
}
}
