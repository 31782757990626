function initTestTime(){
	currentMode = 'test';
	showPanel('paneltest'+currentGame);
	timeSet.sort(function(){ return Math.random()-0.5; });
	index = -1;
	showLives(3);
	if(gameLevel < 2) $('.num-hint').text('YYYY');
	else if(gameLevel < 5) $('.num-hint').text('MM.YYYY');
	else if(gameLevel < 8) $('.num-hint').text('YYYY-YYYY');
	else $('.num-hint').text('DD.MM.YYYY-DD.MM.YYYY');
	$('.num-hint').show();
	nextTimeTest();
}

function initGameTime(){
	currentMode = 'learn';
  // We have already set gameLevel when start newGame.
  // DO NOT overwrite it here.
	///// gameLevel = getGameLevel(currentGame);
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1];
	pageSize = totalItems;
	totalPages = 1;
	star2 = levelSettings[5];
	star3 = levelSettings[6];

	index = 0;
	itemTime = levelSettings[2];
	pageTime = 0;
	pageTimes = [];
	gameTime = itemTime * totalItems;
	page = -1;
	learnRemainTime = 0;
	penaltyTime =0;
	hideButton();

	$('.time-container').empty();
	$('.time-count-container').empty();

	if(missionParams) {
    itemTime = 1000000000;
    $('.pagination').addClass('hide'); 
  } else { $('.pagination').removeClass('hide'); }

	setTimeout(function(){
		getTimeSet();
		nextTime();
		setTimeout(updateClockLearn2,2000);
	},2300);

};

function verifyTime(e) {
	var ans = $('.time-test .num-answer').text();
	if(e) ans = '' + $(e.currentTarget).data('text');

	if(ans.replace(/[\.\s-]/g,'') == timeSet[index].time.replace(/[\.\s-]/g,'')) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function(){
			$('.time-test').removeClass('show');
			$('.tick').removeClass('animate');
			timeSet[index].step = 'done';
			nextTimeTest();
			$('.num-hint').show();
		}, 500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function(){
			$('.tick-red').removeClass('animate');
			$('.num-hint').show();
		}, 500);
		checkGameOver();
	}
	$('.num-answer').html('');
};

function skipTime(step){
	if(index<timeSet.length) timeSet[index].step = step;
	checkGameOver();
	if(lives) {
		nextTimeTest();
	}
}

function getTimeSet(){
	timeSet = [];
	if(gameLevel < 3) {
		getTime('individual', totalItems);
	} else {
		getTime('period', totalItems);
	}
	var types = [];
	for(var i=0; i< 0.75 * timeSet.length; i++) types.push(1);
	for(var i= parseInt(0.75 * timeSet.length); i<timeSet.length; i++) types.push(0);
	types.sort(function(){ return Math.random()-0.5; });
	for(var i=0; i<timeSet.length; i++) timeSet[i].type = types[i];
}

function randomYear(start){
	if(!start)
		return zeroPad(getRandomInt(3000), 4);
	else
		return zeroPad(start + getRandomInt(100), 4);
}

function randomMonth(){
	return zeroPad(getRandomInt(12), 2);
}

function randomDay(){
	return zeroPad(getRandomInt(30), 2);
}

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function getRandomInt(max) {
    return Math.ceil(Math.random() * max);
}

function nextTime(button) {
	if(button && $(button).hasClass('inactive')) return;
	page += 1;
	if(page >= totalItems) {
		startTesting();
	} else {
		learnRemainTime +=  pageTime>0?pageTime:0;
		pageTimes.push(pageTime >= 0 ? pageTime0 - pageTime : pageTime0);

		$('.time-content').addClass('animate');
		setTimeout(function(){
			$('.time-container').html(TemplateEngine($('#tpl-time-travel').html(),{ timeItem : timeSet[page] }));
			setTimeout(function(){ $('.time-content').addClass('show'); }, 100);
		}, 1000);
		showPages();
	}
}

function nextTimeTest() {
	index += 1;
	for(; index<totalItems; index++){
		if(timeSet[index].step != 'done') break;
	}
	if(index >= totalItems) {
		if(timeSet.filter(function(item){ return item.step != 'done'; }).length == 0) {
			showResult(totalItems, totalItems);
		} else {
			index = -1;
			nextTimeTest();
		}
		return;
	}
	$('.test-order').text(''+(index+1)+'/'+totalItems);
	$('.time-text').html(timeSet[index].text[lang]);

	if(timeSet[index].type) {
		$('#paneltesttime .time-test').addClass('show').removeClass('hide');
		$('#paneltesttime .time-mc').addClass('hide').removeClass('show');
	} else {
		nextTimeTestMC();
	}
}

function nextTimeTestMC() {
	$('#paneltesttime .time-test').removeClass('show').addClass('hide');
	$('#paneltesttime .time-mc').removeClass('hide').addClass('show');
	$('#paneltesttime .time-mc .face-answers').html('');

	var options= [timeSet[index].time];
	for(var i=1; i<timeSet.length && i<4; i++) {
		options.push(timeSet[(i+index)%timeSet.length].time);
	}
	if(gameLevel < 2)
	for(var i=options.length; i<4; i++) {
		options.push(randomYear());
	}
	options.sort(function(){ return Math.random()-0.5; });

	for(var i=0; i<options.length; i++){
		$('#paneltesttime .time-mc .face-answers').append('<div class="ans key-'+(65+i)+'" data-type="pro" data-text="'+options[i]+'" onclick="verifyTime(event);"><span class="no"></span> ' +options[i]+'</div>');
	}
}


function getTime(type, totalItems){
	currentTimeType = type;
	for(var i=0; i<totalItems; i++){
		var timeItem = randomItem(times[type]);
		if(gameLevel < 2)
			timeItem.time = randomYear();
		else if(gameLevel < 5)
			timeItem.time = randomMonth() + '.' + randomYear();
		else if(gameLevel < 8) {
			var start = randomYear();
			timeItem.time = start + ' - '+ randomYear(parseInt(start));
		} else {
			var start = randomYear();
			timeItem.time = randomDay()+ '.' + randomMonth() + '.' + start + ' - ' +randomDay()+ '.' + randomMonth() + '.' + randomYear(parseInt(start));
		}
		timeSet.push(timeItem);
	}
}
