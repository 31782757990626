function initTestFace(){
	currentMode = 'test';
      
	showPanel('paneltest'+currentGame);
	faceSet.sort(function(){ return Math.random()-0.5; });
	index = -1;
	showLives(3);
	nextFaceTest();
}

function initGameFace(){
	currentMode = 'learn';
	pageSize = 8;
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1];
	totalPages = Math.ceil(totalItems/pageSize);
	masterLevel = levelSettings[6];
	star2 = levelSettings[7];
	star3 = levelSettings[8];

	itemTime = levelSettings[2];
	page = -1;
	learnRemainTime = 0;
	penaltyTime =0;
	pageTime = 0;
	pageTimes = [];
	hideButton();
	$('.face-frame .face-grid').html('');
  
  if(missionParams) {
    itemTime = 1000000000;
    $('.pagination').addClass('hide'); 
  } else { $('.pagination').removeClass('hide'); }
  
	setTimeout(function(){
		nextFacePage();
		updateClockLearn();
	}, 3000);

}

function verifyFirstname(e) {
	if($('.face-answers .active')[0].innerHTML.toLowerCase() + $('.face-answers .active')[1].innerHTML.toLowerCase() == getFirstname(faceSet[index].name).toLowerCase() || $('.face-answers .active')[1].innerHTML.toLowerCase() + $('.face-answers .active')[0].innerHTML.toLowerCase() == getFirstname(faceSet[index].name).toLowerCase()) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function() {
			if(levelSettings[4]) {
				showLastNameSplits();
			} else {
				faceSet[index].step = 'done';
				nextFaceTest();
			}
			$('.tick').removeClass('animate');
		}, 500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function() {
			$('.tick-red').removeClass('animate');
			showFirstNameSplits();
			gameTime -= gameTime> 5 ? 5.0: gameTime;
			checkGameOver();
		}, 500);
	}
}

function verifyLastname(e) {
	if($('.face-answers .active')[0].innerHTML + $('.face-answers .active')[1].innerHTML == getLastname(faceSet[index].name).toLowerCase() || $('.face-answers .active')[1].innerHTML + $('.face-answers .active')[0].innerHTML == getLastname(faceSet[index].name).toLowerCase()) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function() {
			if(levelSettings[5]) {
				faceProfessionTest();
			} else {
				faceSet[index].step = 'done';
				nextFaceTest();
			}
			$('.tick').removeClass('animate');
		},500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function() {
			$('.tick-red').removeClass('animate');
			gameTime -= gameTime> 5 ? 5.0: gameTime;
			showLastNameSplits();
			checkGameOver();
		},500);
	}
}

function verifyProfession(e, text) {
	if((e && $(e.currentTarget).data('text') == faceSet[index].pro) || (text && text == faceSet[index].pro)) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function() {
			if(levelSettings[6]) {
				faceHobbyTest();
			} else {
				faceSet[index].step = 'done';
				nextFaceTest();
			}
			$('.tick').removeClass('animate');
		},500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function() {
			$('.tick-red').removeClass('animate');
			gameTime -= gameTime> 5 ? 5.0: gameTime;
			checkGameOver();
			showProfessions();
		},500);
	}
}

function verifyHobby(e, text) {
	if((e && $(e.currentTarget).data('text') == faceSet[index].hobby) || (text && text == faceSet[index].hobby)) {
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		faceSet[index].step = 'done';
		setTimeout(function() {
			nextFaceTest();
			$('.tick').removeClass('animate');
		}, 500);
	} else {
		$('.tick-red').addClass('animate').show();
		playSoundAction('music-wrong');
		setTimeout(function() {
			gameTime -= gameTime> 5 ? 5.0: gameTime;
			showHobbies();
			checkGameOver();
			$('.tick-red').removeClass('animate');
		}, 500);
	}
}

function skipFace(step){
	if(index<faceSet.length) faceSet[index].step = step;
	checkGameOver();
	if(lives) {
		nextFaceTest();
	}
}

function nextFacePage(button) {
	if(button && $(button).hasClass('inactive')) return;

	var el = $('.face-frame .face-grid li:visible');
	if(isMobile && el.next().length) {
		el.addClass('hide');
		el.next().removeClass('hide');
		return;
	}

	page += 1;
	if(page >= totalPages) {
		startTesting();
	} else {
  	learnRemainTime += pageTime>0?pageTime:0;
  	pageTimes.push(pageTime >= 0 ? pageTime0 - pageTime : pageTime0);
		playSoundAction('music-superm-action');

		$('.face-frame .face-grid').html('').css('opacity',1);
		for(var i= page*pageSize; i<page*pageSize+pageSize && i < totalItems; i++){
			$('.face-frame .face-grid').append(
				'<li class="preview'+(i%pageSize)+'">'+
				  '<figure>'+
					'<div><img src="faces/'+faceSet[i].img+'.png"></div>'+
					'<figcaption>'+
					  '<h3>'+faceSet[i].name+'</h3>'+
					  (levelSettings[5]? '<h4>'+faceSet[i].pro+'</h4>' : '')+
					  (levelSettings[6]? '<p>'+faceSet[i].hobby+'</p>': '')+
					'</figcaption>'+
				  '</figure>'+
				'</li>');
		}
		if(isMobile) {
			$('.face-frame .face-grid li').addClass('hide');
			$('.face-frame .face-grid li').addClass('preview');
			$('.face-frame .face-grid .preview0').removeClass('hide');
		} else setTimeout( function() { $('.face-frame .face-grid li').addClass('preview'); }, 500);
		showPages();
	}
}

function getFaceSet(totalItems){
	console.log("getFaceSet()", totalItems);
	faceSet = [];
  var homeCountry = gamestate.country || 'Norway';
	if(gameLevel < 5) {
		getFace(null, homeCountry, totalItems);
	} else if(gameLevel < 8) {
		getFace(null, homeCountry, parseInt(totalItems/2));
		getFace(null, 'Western', parseInt(totalItems/2));
	} else {
		getFace(null, homeCountry, parseInt(totalItems * 0.33));
		getFace(null, 'Western', parseInt(totalItems * 0.33));
		getFace(null, 'Asian', parseInt(totalItems * 0.16));
		getFace(null, 'African', totalItems - parseInt(totalItems * 0.82));
	}
  window.gamestate_utils.set({'facesU': facesU});

}

function getFace(region, country, total){
	console.log("getFace()", region, country, total);
	var img = '';
	var name='';
	var natives = country=='USA'? total : parseInt(0.9 * total);
	var foreigners = total - natives;
	var firstnameFList;
	var firstnameMList;
	var surnameList;
  
  if(missionParams){
    natives = total;
    foreigners = 0;
		firstnameMList = window.gamestate.data.learned_names.male || firstnameM[country];
		firstnameFList = window.gamestate.data.learned_names.female || firstnameF[country];
		surnameList = window.gamestate.data.learned_names.family || surnames[country];
  } else if(firstnameF[country]) {
		firstnameFList = firstnameF[country];
		firstnameMList = firstnameM[country];
		surnameList = surnames[country];
	} else if(region){
		var country2 = randomItem(regionsNames[region]);
		firstnameFList = firstnameF[country2];
		firstnameMList = firstnameM[country2];
		surnameList = surnames[country2];
	}

	for(var i=0; i<natives; i++){
		if(races[country]) {
			img = getImg(country, i, natives);
      if(missionParams) {
        var firstname = (img[2] == 'F' ? firstnameFList[i] : firstnameMList[i]).split('/');
        var lastname = (levelSettings[4]? randomItem(surnameList):'').split('/');
        name = randomItem(firstname) + ' ' + randomItem(lastname);
      }else{
        var firstname = (img[2] == 'F' ? randomItem(firstnameFList) :  randomItem(firstnameMList)).split('/');
        var lastname = (levelSettings[4]? randomItem(surnameList):'').split('/');
        name = randomItem(firstname) + ' ' + randomItem(lastname);
      }
		} else {
			img = getImg('USA', i , natives);
			if(missionParams) {
				var firstname = (img[2] == 'F' ? firstnameFList[i] :  firstnameMList[i]).split('/');
				var lastname = (levelSettings[4]? randomItem(surnames['USA']):'').split('/');
				name = randomItem(firstname) + ' ' + randomItem(lastname);
			} else {
				var firstname = (img[2] == 'F' ? randomItem(firstnameF['USA']):  randomItem(firstnameM['USA'])).split('/');
				var lastname = (levelSettings[4]? randomItem(surnames['USA']):'').split('/');
				name = randomItem(firstname) + ' ' + randomItem(lastname);
			}
		}
		faceSet.push({img: img, name: name, pro: randomItem(professions)[langs[lang]], hobby: randomItem(hobbies)[langs[lang]] });    
	}

	for(var i=0; i< foreigners; i++){
		if(races[country]) {
			img = getImg(null, i , foreigners);
      var firstname = (img[2] == 'F' ? randomItem(firstnameFList) :  randomItem(firstnameMList)).split('/');
      var lastname = (levelSettings[4]? randomItem(surnameList):'').split('/');
			name = randomItem(firstname) + ' ' + randomItem(lastname);
		} else {
			img = getImg('USA', i , foreigners);
      var firstname = (img[2] == 'F' ? randomItem(firstnameF['USA']) :  randomItem(firstnameM['USA'])).split('/');
      var lastname = (levelSettings[4]? randomItem(surnames['USA']):'').split('/');
			name = randomItem(firstname) + ' ' + randomItem(lastname);
		}
		faceSet.push({img: img, name: name, pro: randomItem(professions)[langs[lang]], hobby: randomItem(hobbies)[langs[lang]]});
	}
}

function getFaceRegion(region, total){
	if(region == 'AmeriaN-Latin') {
		getFace(region, 'USA', parseInt(0.6 * total));
		getFaceRegion('Latin', total - parseInt(0.6*total));
	} else if(region == 'Asia-Africa') {
		getFaceRegion('Asia', parseInt(0.6*total));
		getFaceRegion('Africa', total- parseInt(0.6*total));
	} else if(region == 'Asia') {
		getFaceRegion('AsiaSE',parseInt(0.33 * total));
		getFaceRegion('India',parseInt(0.33 * total));
		getFaceRegion('China',total - 2* parseInt(0.33 * total));
	} else if(regions[region]) {
		getFace(region, randomItem(regions[region]), total);
	}
}

function getImg(country, index, total){
	var race = '', percent = index / total;
	if(!country || country == 'USA') {
		if(percent < 0.7) race = 'W';
		else if(percent < 0.85) race = 'L';
		else if(percent < 0.95) race = 'A';
		else if(percent < 0.975) race = 'C';
		else race = 'I';
	} else {
		if(percent < 0.9) race = races[country];
		else race = randomItem(['W','L','A','C','R','I','S']);
	}
	return randomItemU(facesU[race], faces[race]);
}

function getFirstname(name) {
	var space = name.indexOf(' ');
	return name.substr(0, space>0?space : name.length);
}

function getLastname(name) {
	return name.substr(name.indexOf(' ')+1);
}

function nextFaceTest() {
	index += 1;
	for(; index<totalItems; index++){
		if(faceSet[index].step != 'done') break;
	}
	if(index >= totalItems) {
		if(faceSet.filter(function(item){ return item.step != 'done'; }).length == 0) {
			showResult(totalItems, totalItems);
		} else {
			index = -1;
			nextFaceTest();
		}
		return;
	}
	$('.test-order').text(''+(index+1)+'/'+totalItems);
	$('.user-face').html(
	  '<div class="img-holder">'+
		'<img src="faces/'+faceSet[index].img+'.png">'+
	  '</div>'+
		'<figcaption>'+
		  '<h3><span class="fig-cap fig-firstname">'+getFirstname(faceSet[index].name)+'</span><span class="fig-cap fig-lastname"> '+getLastname(faceSet[index].name)+'</span></h3>'+
		  '<h4 class="fig-cap fig-pro">'+faceSet[index].pro+'</h4>'+
		  '<p class="fig-cap fig-hobby">'+faceSet[index].hobby+'</p>'+
		'</figcaption>'+
	  '<div class="test-progress" style="width: 100%;"></div>'
	);
	if(!faceSet[index].step) {
		showFirstNameSplits();
	} else if(faceSet[index].step == 'lastname') {
		showLastNameSplits();
	} else if(faceSet[index].step == 'pro') {
		faceProfessionTest();
	} else if(faceSet[index].step == 'hobby') {
		faceHobbyTest();
	}
}

function showLastNameSplits(){
	$('#paneltestface .test-card').addClass('hide');
	$('#paneltestface .test-lastname').removeClass('hide');
	$('#paneltestface .fig-cap').hide();
	$('#paneltestface .fig-firstname').show();
	$('.face-answers').html('');
	var answer = faceSet[index].name, lastname = getLastname(answer), options= [lastname.substr(0,lastname.length/2), lastname.substr(lastname.length/2)];
	for(var i=1; i<= faceSet.length; i++) {

		answer = faceSet[(index+i)%faceSet.length].name;

		lastname = getLastname(answer);
		pushUnique(options, lastname.substr(0,lastname.length/2));
		pushUnique(options, lastname.substr(lastname.length/2));
		if(options.length>=12) break;
	}
	if(options.length<12) {
	  var _index = parseInt(Math.random()*surnames['USA'].length);
	  for(var i=1; i<= surnames['USA'].length; i++) {
		  lastname = (surnames['USA'][(_index+i)%surnames['USA'].length]).split('/');
      lastname = randomItem(lastname);
		  pushUnique(options, lastname.substr(0,lastname.length/2));
		  pushUnique(options, lastname.substr(lastname.length/2));
		  if(options.length>=12) break;
	  }
	}
	options.sort(function() { return Math.random()- 0.5; });
	for(var i=0; i<options.length; i++) {
		$('.face-answers').append('<div class="box" onclick="$(this).toggleClass(\'active\');if($(\'.face-answers .active\').length >= 2) verifyLastname(event);">'+options[i].toLowerCase()+'</div>');
	}
}

function showFirstNameSplits(){
	$('#paneltestface .test-card').addClass('hide');
	$('#paneltestface .test-name').removeClass('hide');
	$('#paneltestface .fig-cap').hide();
	$('.face-answers').html('');
	var answer = faceSet[index].name, firstname = getFirstname(answer), options= [firstname.substr(0,firstname.length/2), firstname.substr(firstname.length/2)];
	for(var i=1; i<= faceSet.length; i++) {

		answer = faceSet[(index+i)%faceSet.length].name;

		firstname = getFirstname(answer);
		pushUnique(options, firstname.substr(0,firstname.length/2));
		pushUnique(options, firstname.substr(firstname.length/2));
		if(options.length>=12) break;
	}
	if(options.length<12) {
	  var _index = parseInt(Math.random()*firstnameF['USA'].length);
	  for(var i=1; i<= firstnameF['USA'].length; i++) {
		  firstname = (firstnameF['USA'][(_index+i)%firstnameF['USA'].length]).split('/');
      firstname = randomItem(firstname);
		  pushUnique(options, firstname.substr(0,firstname.length/2));
		  pushUnique(options, firstname.substr(firstname.length/2));
		  if(options.length>=12) break;
	  }
	}
	options.sort(function() { return Math.random()- 0.5; });
	for(var i=0; i<options.length; i++) {
		$('.face-answers').append('<div class="box" onclick="$(this).toggleClass(\'active\');if($(\'.face-answers .active\').length >= 2) verifyFirstname(event);">'+options[i].toLowerCase()+'</div>');
	}
}

function showProfessions(){
	$('.face-answers').html('');
	var answer = faceSet[index].pro, options= [answer];
	for(var i=0; i< professions.length; i++) {
		pushUnique(options, professions[(index+i)%professions.length][langs[lang]]);
		if(options.length >= 4) break;
	}
	options.sort(function() { return Math.random()- 0.5; });
	var letters = ['A','B','C','D'];
	for(var i=0; i<options.length; i++) {
		$('.face-answers').append('<div class="ans key-'+(65+i)+'" data-type="pro" data-text="'+options[i]+'" onclick="verifyProfession(event);"><span class="no">'+letters[i]+'.</span> ' +options[i]+'</div>');
	}
}

function faceProfessionTest() {
	$('#paneltestface .test-card').addClass('hide');
	$('#paneltestface .test-profession').removeClass('hide');
	$('#paneltestface .fig-cap').hide();
	$('.fig-firstname, .fig-lastname').show();
	showProfessions();
}

function showHobbies(){
	$('.face-answers').html('');
	var answer = faceSet[index].hobby, options= [answer];
	for(var i=0; i< hobbies.length; i++) {
		pushUnique(options, hobbies[(index+i)%hobbies.length][langs[lang]]);
		if(options.length >= 4) break;
	}
	options.sort(function() { return Math.random()- 0.5; });
	var letters = ['A','B','C','D'];
	for(var i=0; i<options.length; i++) {
		$('.face-answers').append('<div class="ans key-'+(65+i)+'" data-type="hobby" data-text="'+options[i]+'" onclick="verifyHobby(event);"><span class="no">'+letters[i]+'.</span> ' +options[i]+'</div>');
	}
}

function faceHobbyTest() {
	$('#paneltestface .test-card').addClass('hide');
	$('#paneltestface .test-hobby').removeClass('hide');
	$('#paneltestface .fig-cap').hide();
	$('.fig-firstname, .fig-lastname, .fig-pro').show();
	showHobbies();
}

function showFaces(page) {
	if(page < 0) {
		page = 0;
	} else if(page >= Math.ceil(levelMap[currentGame][getGameLevel(currentGame)][0]/8)) {
		page = Math.ceil(levelMap[currentGame][getGameLevel(currentGame)][0]/8);
	} else {
		var offset = 0;
		for(var i=0; i<page; i++) {
			offset += $('.face-frame .page-'+i).height();
		}
		$('.face-frame').css({
            '-webkit-transform':'translateY(-'+offset+'px)',
            'transform':'translateY(-'+offset+'px)'
          });
		  $('.face-frame .face-grid').css('opacity',0.2);
		  $('.face-frame .page-'+page).css('opacity',1);
	}
}
