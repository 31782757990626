function random_tree(totalItems, maxLevel, doubleWords) {
	var title = randomItemU(wordsU.abstracts, words.abstracts);
	window.root = { title: title, answer: title, layout: "map", ideas: {}, id: 1, level:0, color: randomItem(rootColors) };
	window.nodes = [root];
	window.mainNodes = [];

	var categories = [];
	for(var k in words) categories.push(k);
	for(var i=2; i<=totalItems; i++) {
		var cat = randomItem(categories);
		title = randomItemU(wordsU[cat], words[cat]);
		if(i<=(levelSettings[11]+1)) insertNode(i, title, 1);
		else insertNode(i, title, maxLevel);
	}
		
	for(var i=0; i<levelSettings[5]; i++) {
		var node = randomItem(nodes);
		node.img = randomItem(nodeImages);
		if(!node.color) console.log('color');
		node.color = node.color.replace('gradient','pale');
	}

	var textNodes = nodes.filter(function(item){ return !item.img && item.level; });
	textNodes.sort(function(){ return Math.random()-0.5; });
	for(var i=0; i<levelSettings[6]; i++) {
		var node = textNodes[i];
		if(node.level > 0) {
			node.answer1 = randomItemU(wordsU[cat], words[cat]);
			node.title = node.answer + ', ' + node.answer1;
		}
	}
	
	return root;
}

function insertNode(i, title, maxLevel){
	var parents = [];

	var node = { title: title, answer: title, layout: "map", ideas: {}, id: i, level:0};
	for(var i=0; i<nodes.length; i++) {
		if(nodes[i].level < (maxLevel-1)) {
			if(levelSettings[11]) {
				if(Object.keys(nodes[i].ideas).length < 2) parents.push(nodes[i]);
			} else if(Object.keys(nodes[i].ideas).length < Math.log(totalItems)) {
				parents.push(nodes[i]);
			}
		}
	}
	if(!parents.length) parents.push(root);
	var randParent = parseInt(Math.random()*parents.length);
	if(parents[randParent].level == 0) {
		mainNodes.push(node);
		if(levelSettings[11]) {
			node.id = mainNodes.length <= levelSettings[11]/2 ? node.id : -node.id;
		} else {
			node.id = Math.random() > 0.5 || totalItems<10 ? node.id : -node.id;
		}
		node.color = randomItem(mapColors);
	} else { //if(parents[randParent].level == 1) {
		node.color = parents[randParent].color.replace('gradient','pale');
	}
	parents[randParent].ideas[node.id] = node;
	node.parent = parents[randParent].id;
	node.level = parents[randParent].level + 1;
	nodes.push(node);
}

function startLearnMap(){
	mapModel.selectNode(1);
	mapModel.toggleCollapse();
	showNode(root.id, 0);
	var order = 1;
	for(var i=0; i< nodes.length; i++) {
		if(nodes[i].img) {
			mapModel.setIcon('drag and drop', 'images/'+nodes[i].img, 16, 16, 'left', nodes[i].id);
		}
	}
	for(var i=0; i<mainNodes.length; i++) {
		if(mainNodes[i].id > 0) {
			mapModel.selectNode(mainNodes[i].id);
			mapModel.toggleCollapse();
			showNode(mainNodes[i].id, order, mainNodes[i].answer);
			order += 1;
		}
	}
	var remaining = mainNodes.length - order + 1;
	for(var i=0; i<mainNodes.length; i++) {
		if(mainNodes[i].id < 0) {
			mapModel.selectNode(mainNodes[i].id);
			mapModel.toggleCollapse();
			showNode(mainNodes[i].id, order + remaining - 1, mainNodes[i].answer);
			remaining -= 1;
		}
	}
}

function showNode(id, order, answer){
	if(currentMode == 'learn') {
		setTimeout(function(){
			if(currentMode == 'learn') {
				if(nodes[Math.abs(id)-1].getAttr('collapsed')) {
					mapModel.selectNode(id);
					mapModel.toggleCollapse();
				}
				if(order >= mainNodes.length){
				  $('#panelmindmap .next-test').removeClass('inactive');
					var bounding = getBoundary();
					var currentCenter = {x: $('.map-container').width()/2, y: $('.map-container').height() / 2};
					$('.map-inner-container').children().animate({'left' : currentCenter.x-(bounding.left+bounding.right)/2}, 500);
				}
			}
		}, 500+order*levelSettings[9]*1000);
	}
}

function getBoundary(){
	var left = 100000, right = 0;
	for(var i=0; i< nodes.length; i++) {
		left = Math.min($('#node_'+nodes[i].id).offset().left, left);
		right = Math.max($('#node_'+nodes[i].id).offset().left+$('#node_'+nodes[i].id).outerWidth(), right);
	}
	return {left : left, right : right};
}