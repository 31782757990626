window.gamestate = {
  access_token: "",

  user_settings: {},

  app: 'genius',

  timestamp: 0,

  // namecode structure: { "code" : "ah men", "name" : "Ahmad", "type" : "male" }

  /* Stuff you put in the data object will get persisted across sessions */
  data: {
    done_mission_steps: [],
    current_mission: "doublememory",
    level: {},
    journeys: {},
    firsttime: true,
    missionStarted: {},
    levelUpMap: {},
    starMap: {}
  }
};

var gamestate_utils = {
  save: function() {
    if (gamestate_utils.test_localStorage()) {
      window.gamestate.access_token = window.accessToken;
      localStorage.setItem("gamestate", JSON.stringify(window.gamestate));
    }
  },

  set: function(data) {
    var update = {
      data: data,
      timestamp: (new Date()).toISOString(),
      app: 'genius'
    };

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        window.gamestate.data[key] = data[key];
      }
    }
    window.gamestate.timestamp = update.timestamp;

    $.ajax({
      type: 'POST',
      url: 'https://nanny.memolife.com/state/',
      //url: 'http://localhost:5000/state/',
      data: JSON.stringify(update),
      contentType: 'application/json',
      success: function(response) {},
      complete: gamestate_utils.save,
    });


  },

  fetch_and_merge: function(callback) {
    // take the what we got from the server and insert the data on the client:
    //
    initGameStateFromLocal();
    console.log("done init gamestate from local");
    console.log(window.gamestate.timestamp);
    $.ajax({
      type: 'GET',
      url:  'https://nanny.memolife.com/state/genius?t='+window.gamestate.timestamp,
      //url: 'http://localhost:5000/state/genius?t='+window.gamestate.timestamp,
      cache: false,

      success: function(response) {
        $.extend(true, window.gamestate, response);
        window.gamestate.data.firsttime = false;
      },

      error: function(response) {
      },

      complete: function() {
        migrate_gamestate();
        window.gamestate_utils.save();
        if (callback !== undefined) {
          callback();
        }
      }
    });

    // for development purposes (look at gamestate_test.js)
    //
    // .error(function() {
    //   updated_gamestate = $.extend(true, {}, gamestate_mock, gamestate);
    // });
  },

  test_localStorage: function() {
    // this is meant to look stupid (for old IE's):
    //
    try {
      localStorage.setItem("dummy", "string");
      localStorage.removeItem("dummy");
      return true;
    } catch(e) {
      return false;
    }
  }
}

window.gamestate_utils = gamestate_utils;
//window.gamestate = gamestate;

var initGameStateFromLocal = function(){
  if (gamestate_utils.test_localStorage() && localStorage.getItem("gamestate")) {
    var saved_gamestate = JSON.parse(localStorage.getItem("gamestate"));
    var saved_user = jwt_decode(saved_gamestate.access_token);
    if (saved_user.username === window.user.username) {
    //if (saved_gamestate.access_token === window.accessToken) {
      console.log("Saved gamestate for this access token found, loading", saved_gamestate.timestamp);
      window.gamestate = saved_gamestate;
    }
  }
}

function migrate_gamestate() {
  console.log("Starting gamestate migration");
  /* Migrations to gamestate object happens here */

  var migration = {};

  if (typeof(window.gamestate.data.level) === 'undefined') {
    migration.level = {};
  }
  if (typeof(window.gamestate.data.journeys) === 'undefined') {
    migration.journeys = {};
  }

  if (typeof(window.gamestate.data.current_mission) === 'undefined') {
    migration.current_mission = "doublememory";
  }

  if (typeof(window.gamestate.data.done_mission_steps) === 'undefined') {
    migration.done_mission_steps = [];
  } else {
    var done_steps = window.gamestate.data.done_mission_steps || [];
    if (done_steps.length > 0) {
      if (done_steps[0].indexOf(":") === -1) {
        // migrate to new step name scheme
        var steps = [];
        for (var i = 0; i < missions.length; i++) {
          var mission = missions[i];
          if (!window.gamestate.data.missionStarted[mission.id]) {
            continue;
          }
          for (var j = 0; j < mission.steps.length; j++) {
            var step_name = mission.steps[j];
            var idx = done_steps.indexOf(step_name);
            if (idx > -1) {
              var item = done_steps[idx];
              steps.push("" + mission.id + ":" + item);
            } else {
              /* Make sure we don't set a step as done if its in the middle */
              break;
            }
          }
        }
        migration.done_mission_steps = steps;
      }
    }
  }

  if (typeof(window.gamestate.data.firsttime) === 'undefined') {
    migration.firsttime = true;
  }
  
  if (typeof(window.gamestate.data.missionStarted) === 'undefined') {
    migration.missionStarted = {};
    $('.sidebar .missions .no').addClass('hide');
  }

  if (typeof(window.gamestate.data.mission_badge) === 'undefined') {
    migration.mission_badge = {};
  }

  /* Remove default journeys from gamestate if they exist */
  if (typeof(window.gamestate.data.journeys) !== 'undefined') {
    if (window.gamestate.data.journeys.length >= window.journeys[lang].length) {
      for (var i = window.journeys[lang].length - 1; i >= 0; i--) {
        if (window.journeys[lang][i].name === window.gamestate.data.journeys[i].name) {
          window.gamestate.data.journeys.splice(i, 1);
        }
        migration.journeys = window.gamestate.data.journeys;
      };      
    }
  }

  if (typeof(window.gamestate.data.namesys) === 'undefined') {
    var namesysdata = {};
    for (region in regionsNames) {
      if (regionsNames.hasOwnProperty(region)) {
        namesysdata[region] = {'countries': {}}
        for (var i=0; i < regionsNames[region].length; i++) {
          var country_name = regionsNames[region][i];
          var country_data = {
            'firstnameF': [],
            'firstnameM': [],
            'surnames': []
          };

          for (j=0; j < firstnameF[country_name].length; j++) {
            country_data.firstnameF.push({
              'name': firstnameF[country_name][j],
              'mnemonic': systemNameFCode[country_name] && systemNameFCode[country_name][j] || ""
            });
          }

          for (j=0; j < firstnameM[country_name].length; j++) {
            country_data.firstnameM.push({
              'name': firstnameM[country_name][j],
              'mnemonic': systemNameMCode[country_name] && systemNameMCode[country_name][j] || ""
            });
          }

          for (j=0; j < surnames[country_name].length; j++) {
            country_data.surnames.push({
              'name': surnames[country_name][j],
              'mnemonic': systemSurnameCode[country_name] && systemSurnameCode[country_name][j] || ""
            });
          }
          namesysdata[region].countries[country_name] = window.gamestate.data.namesys && window.gamestate.data.namesys.regions && window.gamestate.data.namesys.regions[region] && window.gamestate.data.namesys.regions[region].countries[country_name] ? window.gamestate.data.namesys.regions[region].countries[country_name] : country_data;
        }
      }
    }
    migration.namesys = {'regions': namesysdata};
  }

  if (typeof(window.gamestate.data.learned_names) === 'undefined') {
    migration.learned_names = {};
  }

  if (window.gamestate.data.languagesU instanceof Array) {
    migration.languagesU = {'Klingon': [], 'Nadsat': [], 'Elvish': [], 'Na’vi': []};
  }

  if (window.gamestate.data.starMap === undefined || (JSON.stringify(window.gamestate.data.starMap) === JSON.stringify({}))) {
    window.gamestate.data.starMap = {
      'superm':  [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'superm0': [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'bankv':   [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'library': [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'meeting': [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'face':    [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'mindmap': [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'vocab':   [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]],
      'time':    [[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]]
    };
  }

  console.log("migrating", migration);
  window.gamestate_utils.set(migration);


  /* End of migrations */
  
  window.starMap = window.gamestate.data.starMap;
  
  window.facesU = window.gamestate.data.facesU || {A:[],L:[],C:[],I:[],W:[],R:[],S:[]};
  window.languagesU = window.gamestate.data.languagesU || {'Klingon': [], 'Nadsat': [], 'Elvish': [], 'Na’vi': []};

  window.current_mission = {
    'mission': getMission(window.gamestate.data.current_mission),
    'remaining_steps': remaining_steps(getMission(window.gamestate.data.current_mission))
  };
  
  $('.sound-control, .d-sounds').addClass((window.gamestate.data.mute ? 'off' : 'on'));

}
