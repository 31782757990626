window.systems = ['brain', 'knowledge', 'mind', 'body', 'soul', 'career', 'leisure']

var systemIndex = 0,
    system = systems[systemIndex],
    systemCodeIndex = 0,

    systemJourneys = [{
      name: 'A home',
      items: [{
        img: 'Bed',
        text:''
      },{
        img: 'Bedroom Floor',
        text:''
      },{
        img: 'Toilet',
        text:''
      },{
        img:'Shower' ,
        text:''
      },{
        img: 'Hallway',
        text:''
      },{
        img: 'Kitchen',
        text:''
      },{
        img: 'Living Room',
        text:''
      },{
        img: 'Dining Table',
        text:''
      },{
        img: 'Exit door',
        text:''
      },{
        img: 'Driveway',
        text:''
      }]
    }, {
      name: 'Ny central Park',
      items: [{
        img: 'CentralPark Zoo',
        text:''
      },{
        img: 'Chess house',
        text:''
      },{
        img: 'Carousel',
        text:''
      },{
        img:'Strawberry fields',
        text:''
      },{
        img: 'Lincoln Center for Performing Arts',
        text:''
      },{
        img: 'Cherry Hill',
        text:''
      },{
        img: 'Central park lake',
        text:''
      },{
        img: 'Bow Bridge',
        text:''
      },{
        img: 'Bethesda Terrace',
        text:''
      },{
        img: 'Alice in Wodnerland',
        text:''
      }]
    }, {
      name: 'West East World Wonders',
      items: [{
        img: 'Holywood hills',
        text:''
      },{
        img: 'Niagara Falls',
        text:''
      },{
        img:'Statue of libery',
        text:''
      },{
        img: 'Big Ben',
        text:''
      },{
        img: 'Eiffel tower',
        text:''
      },{
        img: 'Brandburger Tor',
        text:''
      },{
        img: 'Kremlin',
        text:''
      },{
        img: 'Great Wall of China',
        text:''
      },{
        img: 'Forbidden City',
        text:''
      },{
        img: 'Mountain Fuji',
        text:''
      }]
    }],

    systemJourneyIndex = 0,
    systemJourneyScroll = 0,
    systemJourneyStep = 0,
    imgStep = 0,

    systemCountry = '',
    practiceNameCodes = [],
    practiceNameCodeIndex = 0,
    systemNamePage = 0,
    systemNamePart = 'male',

    systemSingleCodes=[],

    systemNumberShapeCodes = {'en':[
      [0,'wheel'],
      [1,'forefinger'],
      [2,'swan'],
      [3,'breasts'],
      [4,'bell'],
      [5,'hook'],
      [6,'paper clip'],
      [7,'cliff'],
      [8,'snowman'],
      [9,'sunflower'],
      [10,'knife and plate'],
    ],
    'no':[
      [0,'hjul'],
      [1,'pekefinger'],
      [2,'svane'],
      [3,'bryster'],
      [4,'bjelle'],
      [5,'krok'],
      [6,'binders'],
      [7,'klippe'],
      [8,'snømann'],
      [9,'solsikke'],
      [10,'kniv og tallerken'],
    ]},

    systemAlphabetCodes={
      'en':[
        ['A-1', 'alligator'],
        ['B-2', 'bear'],
        ['C-3', 'chihuaua'],
        ['D-4', 'dolphin'],
        ['E-5', 'eskimo dog (huskey)'],
        ['F-6', 'fish'],
        ['G-7', 'giraffe'],
        ['H-8', 'horse'],
        ['I-9', 'iguana'],
        ['J-10', 'jaguar'],
        ['K-11', 'koala bear'],
        ['L-12', 'lama'],
        ['M-13', 'mouse'],
        ['N-14', 'neanderthal'],
        ['O-15', 'otter'],
        ['P-16', 'panther'],
        ['Q-17', 'quail'],
        ['R-18', 'reindeer'],
        ['S-19', 'sea horse'],
        ['T-20', 'tiger'],
        ['U-21', 'uakari monkey'],
        ['V-22', 'vampyr'],
        ['W-23', 'wallaby'],
        ['X-24', 'xtra big hamster'],
        ['Y-25', 'yak'],
        ['Z-26', 'zebra']
      ],
    'no': [
        ['A-1', 'alligator'],
        ['B-2', 'bjørn'],
        ['C-3', 'chihuaua'],
        ['D-4', 'delfin'],
        ['E-5', 'eskimohund (husky)'],
        ['F-6', 'fisk'],
        ['G-7', 'giraff'],
        ['H-8', 'hest'],
        ['I-9', 'iguana'],
        ['J-10', 'jaguar'],
        ['K-11', 'koala-bjørn'],
        ['L-12', 'lama'],
        ['M-13', 'mus'],
        ['N-14', 'neandertaler'],
        ['O-15', 'oter'],
        ['P-16', 'panter'],
        ['Q-17', 'quail'],
        ['R-18', 'reinsdyr'],
        ['S-19', 'sjøhest'],
        ['T-20', 'tiger'],
        ['U-21', 'uakari ape'],
        ['V-22', 'vampyr'],
        ['W-23', 'wallaby'],
        ['X-24', 'xtra stor hamster'],
        ['Y-25', 'yak-okse'],
        ['Z-26', 'zebra'],
        ['Æ-27', 'ærfugl'],
        ['Ø-28', 'øyenstikker'],
        ['Å-29', 'ål']
      ]
    },

    systemRhymeCodes={
      'en': [
        [1,'sun'],
        [2,'shoe'],
        [3,'tree'],
        [4,'door'],
        [5,'dive (scuba)'],
        [6,'sticks (drum)'],
        [7,'heaven'],
        [8,'bait (fishing)'],
        [9,'wine'],
        [10,'hen'],
        [11,'seven-eleven'],
        [12,'shelf'],
        [13,'tight jeans'],
        [14,'farting'],
        [15,'50 Cent (rapper) '],
        [16,'sunscreen'],
        [17,'silver screen (a white or silvered surface where pictures can be projected for viewing)'],
        [18,'racing'],
        [19,'fine green (golf course)'],
        [20,'penny']
      ],
    'no': [
        [1,'ben'],
        [2,'do'],
        [3,'tre'],
        [4,'slire'],
        [5,'hjem'],
        [6,'heks'],
        [7,'tyv'],
        [8,'rotte'],
        [9,'ski'],
        [10,'politi'],
        [11,'elven'],
        [12,'troll'],
        [13,'hetten'],
        [14,'skjorten'],
        [15,'skremten (spøkelse)'],
        [16,'heisen'],
        [17,'nøtten'],
        [18,'hatten'],
        [19,'dritten'],
        [20,'flue']
    ]
  }
;
