window.journeys = {
  'en': [{
    "name": "A home",
    "img": "Driveway.png",
    "items":[
      {"img": "Bed.png", "text": "Bed"},
      {"img": "Bedroom-Floor.png", "text": "Bedroom floor"},
      {"img": "Hallway.png", "text": "Hallway"},
      {"img": "Toilet.png", "text": "Toalett"},
      {"img": "Shower.png", "text": "Shower"},
      {"img": "Living-Room.png", "text": "Living room"},
      {"img": "Dining-Table.png", "text": "Dining table"},
      {"img": "Kitchen.png", "text": "Kitchen"},
      {"img": "Exit-door.png", "text": "Exit"},
      {"img": "Driveway.png", "text": "Driveway"},
    ]
  }, {
    "name": "NY central Park",
    "img": "Central-park-lake.png",
    "items":[
      {"img": "Strawberry-fields.png", "text": "Strawberry fields"},
      {"img": "Lincoln-Center-for-Performing-Arts.png", "text": "Lincoln center for performing arts"},
      {"img": "Chess-house.png", "text": "Chess house"},
      {"img": "Cherry-Hill.png", "text": "Cherry Hill"},
      {"img": "Central-Park-Zoo.png", "text": "Central Park Zoo"},
      {"img": "Central-park-lake.png", "text": "Central Park Lake"},
      {"img": "Carousel.png", "text": "Carousel"},
      {"img": "Bow-Bridge.png", "text": "Bow Bridge"},
      {"img": "Bethesda-Terrace.png", "text": "Bethesda Terrace"},
      {"img": "Alice-in-Wonderland.png", "text": "Alice in Wonderland"}
    ]
  }, {
    "name": "West East World Wonders",
    "img": "Eiffel-tower.png",
    "items":[
      {"img": "Holywood-hills.png", "text": "Hollywood Hills"},
      {"img": "Niagara-Falls.png", "text": "Niagara Falls"},
      {"img": "Statue-of-libery.png", "text": "Statue of Liberty"},
      {"img": "Big-Ben.png", "text": "Big Ben"},
      {"img": "Eiffel-tower.png", "text": "Eiffel Tower"},
      {"img": "Brandburger-Tor.png", "text": "Brandenburger Tor"},
      {"img": "Kremlin.png", "text": "Kremlin"},
      {"img": "Great-Wall-of-China.png", "text": "Great Wall of China"},
      {"img": "Forbidden-City.png", "text": "Forbidden City"},
      {"img": "Mountain-Fuji.png", "text": "Mount Fuji"},    
    ]
  }],
  'no': [{
    "name": "Et hjem",
    "img": "Driveway.png",
    "items":[
      {"img": "Bed.png", "text": "Seng"},
      {"img": "Bedroom-Floor.png", "text": "Soveromsgulv"},
      {"img": "Hallway.png", "text": "Gang"},
      {"img": "Toilet.png", "text": "Toalett"},
      {"img": "Shower.png", "text": "Dusj"},
      {"img": "Living-Room.png", "text": "Stue"},
      {"img": "Dining-Table.png", "text": "Stuebord"},
      {"img": "Kitchen.png", "text": "Kjøkken"},
      {"img": "Exit-door.png", "text": "Husdør"},
      {"img": "Driveway.png", "text": "Innkjørsel"},
    ]
  }, {
    "name": "NY central Park",
    "img": "Central-park-lake.png",
    "items":[
      {"img": "Strawberry-fields.png", "text": "Strawberry fields"},
      {"img": "Lincoln-Center-for-Performing-Arts.png", "text": "Lincoln center for performing arts"},
      {"img": "Chess-house.png", "text": "Chess house"},
      {"img": "Cherry-Hill.png", "text": "Cherry Hill"},
      {"img": "Central-Park-Zoo.png", "text": "Central Park Zoo"},
      {"img": "Central-park-lake.png", "text": "Central Park Lake"},
      {"img": "Carousel.png", "text": "Carousel"},
      {"img": "Bow-Bridge.png", "text": "Bow Bridge"},
      {"img": "Bethesda-Terrace.png", "text": "Bethesda Terrace"},
      {"img": "Alice-in-Wonderland.png", "text": "Alice in Wonderland"}
    ]
  }, {
    "name": "Vest-øst symboler",
    "img": "Eiffel-tower.png",
    "items":[
      {"img": "Holywood-hills.png", "text": "Hollywood Hills"},
      {"img": "Niagara-Falls.png", "text": "Niagara Falls"},
      {"img": "Statue-of-libery.png", "text": "Statue of Liberty"},
      {"img": "Big-Ben.png", "text": "Big Ben"},
      {"img": "Eiffel-tower.png", "text": "Eiffeltårnet"},
      {"img": "Brandburger-Tor.png", "text": "Brandenburger Tor"},
      {"img": "Kremlin.png", "text": "Kremlin"},
      {"img": "Great-Wall-of-China.png", "text": "Den kinesiske mur"},
      {"img": "Forbidden-City.png", "text": "Den forbudte by"},
      {"img": "Mountain-Fuji.png", "text": "Mount Fuji"},    
    ]
  }]
}