function num(d) {
	var el = $('.num-answer:visible');
	
	if(el.text().length > 36) {
		el.addClass('long-digits');
	} else {
		el.removeClass('long-digits');
	}
	
	var activeItem = el.find('.active');

	if(d === 'del') {
		if(activeItem.length) {
			activeItem.remove();
		} else {
			var lastgroup = el.find('.group-12').last();
			if(!lastgroup.children().length) {
				lastgroup.remove();
			} else {
				lastgroup.children().last().remove();
			}
		}
	} else {
		if(activeItem.length) {
			activeItem.removeClass('active').html(d);
		}
		else {
			if(el.text().length % 12 === 0) {
				el.append('<div class="group-12"></div>');
			}
			el.find('.group-12').last().append('<a onclick="activateDigit(this)">'+d+'</a>');


			/* Time game specific code */
			if(currentGame === 'time'){
				var len = timeSet[index].time.replace(/[\.\s-]/g,'').length;
				if(el.text().length == len) {
					setTimeout(verifyTime, 500);
				}
				else if(el.text().length < len) {
					if(gameLevel < 2) {
						/* empty */
					} else if(gameLevel < 5) {
						if(el.text().length % 8 === 2) {
							el.find('.group-12').last().children().last().addClass('margin-date');
						}
					} else if(gameLevel < 8) {
						if(el.text().length % 4 === 0) {
							el.find('.group-12').last().children().last().addClass('margin-year');
						}
					} else {
						if(el.text().length % 8 === 2 || el.text().length % 8 === 4) {
							el.find('.group-12').last().children().last().addClass('margin-date');
						}
						if(el.text().length % 8 === 0) {
							el.find('.group-12').last().children().last().addClass('margin-year');
						}
					}
				}
			}
			/* End time game specific code */
		}		
	}
	if($('.num-answer').text().length > 0) {
		$('.num-hint').hide();
	} else {
		$('.num-hint').show();
	}
	
	$('.num-answer.long-digits').animate({scrollTop: $('.num-answer.long-digits').height()});
}

function activateDigit(el){
	if($(el).hasClass('active')) {
		$(el).toggleClass('active');
	} else {
		$(el).parent().find('.active').removeClass('active');
		$(el).addClass('active');
	}
}

function activateDigitLeft(){
	var active = $('.num-answer:visible').find('.active');
	if(active.length) {
		active.removeClass('active');
		active.prev().addClass('active');
	} else {
		$('.num-answer:visible').find(':last').addClass('active');
	}
}

function activateDigitRight(){
	var active = $('.num-answer:visible').find('.active');
	if(active.length) {
		active.removeClass('active');
		active.next().addClass('active');
	} else {
		$('.num-answer:visible').find(':last').addClass('active');
	}
}
