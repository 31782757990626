$.ajaxSetup({
  beforeSend: function(xhr) {
    if (window.accessToken) { // NOTE: Safari 9 has a weird bug that is triggered when accesToken is empty
      xhr.setRequestHeader('authorization', 'Bearer ' +  window.accessToken);
    } else {
      window.location.href = sso_url;
    }
  },
  statusCode: {
    401: function() {
        test_localStorage() && localStorage.removeItem('token');
        window.location.href = sso_url;
    }
  }
});
