var getQueryParam = function(e){var a,n,r;return a=e.replace(/[\[]/,"\\[").replace(/[\]]/,"\\]"),n=new RegExp("[\\?&]"+a+"=([^&#]*)"),r=n.exec(location.search),null===r?"":decodeURIComponent(r[1].replace(/\+/g," "))}
var login_next_url = encodeURI(window.location.protocol + "//" + window.location.hostname);
var sso_url = "https://app.memolife.com/#login?next="+login_next_url;

function test_localStorage() {
  // this is meant to look stupid (for old IE's):
  //
  try {
    localStorage.setItem("dummy", "string");
    localStorage.removeItem("dummy");
    return true;
  } catch(e) {
    return false;
  }
}

/* check for access token from the GET request or localstorage */
window.accessToken = getQueryParam('access_token');
if (window.accessToken === "") {
  if (test_localStorage() && localStorage.getItem("token")) {
    window.accessToken = localStorage.getItem('token');
    console.log("Loaded access token from localstorage", window.accessToken);
  }
}

if (window.accessToken === undefined) {
  window.location.href = sso_url;
}

function validateAccessToken(callback) {
  "use strict";
  /* Validate access token and go to login screen if we fail */
  $.ajax({
    url: 'https://mbouncer.herokuapp.com/validate',
    type: 'POST',
    data: {'token': window.accessToken},
    dataType: 'json',
    success: function(response) {
      window.accessToken = response.token;
      if (window.accessToken.length > 0) {
        window.user = jwt_decode(window.accessToken);
      }
      saveToken();
    },
    complete: function() {
      if (callback !== undefined) {
        callback();
      }
    }
  });
}

function saveToken() {
  /* save token */
  console.log("saving access token");
  test_localStorage() && localStorage.setItem('token', window.accessToken);
}
