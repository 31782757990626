// ---- TODO after testing the functions of basic missions above
// Become a memogenius 1-8 (master mission)
// 1. Add journey (done)
// 2. Double Digit (1-10) (brain category)
// 3. 40 digits Bank vault (2 wheel complexity) numbers only 00-09

// 1. Double Digit (10-20) (Knowledge) - on the drag and drop include random from (1-10)* (backlog)
// 2. 40 digits Shopping cart (2 wheel complexity) numbers only 00-09

var missions = [{
  "id": "doublememory",
  "name": {
    'en': "Double your memory",
    'no': "Doble din hukommelse"
  },
  "description": {
    'en': "Take the memory test to see where you stand. Thereafter, get ready to unlock your hidden memory powers with your first memory system.",
    'no': "Ta husketesten og se hvor du står. Deretter er det bare å gjøre seg klar til å slippe løs dine skjulte hukommelsesevner med ditt første huskesystem."
  },
  "benefits": {
    "no": {
      "title": "Dette vil du mestre",
      "list": [
                "Super-hurtig husketeknikk",
                "Hvordan huske lister",
                "Hvordan huske tall",
                "Hvordan visualisere og huske"
      ]
    },
    "en": {
      "title": "This is what you'll master",
      "list": [
                "Super-fast memory technique",
                "How to remember lists",
                "How to remember numbers",
                "How to visualize and remember"
      ]
    }
  },
  "badge": "mission-badge1.png",
  "banner": "mission-banner1.jpg",
  "dependencies": [],
  "steps": [
    "superm0",
    "practice_singlesys",
    "superm0_0"
  ]
}, {
  "id": "basic",
  "name": {
    'en': "Getting the memory basics",
    'no': "Forstå hukommelsens ABC"
  },
  "description": {
    'en': "Get insight into different memory systems and how they use the whole brain and daydreaming to give you super-memory powers.",
    'no': "Få innblikk i forskjellige typer huskesystemer og hvordan de bruker hele hjernen og dagdrømming til å gi deg super-hukommelse."
  },
  "benefits": {
    "no": {
      "title": "Dette vil du leke deg med",
      "list": [
                "Husketeknikker bruker hele hjernen",
                "De 3 hovedtypene av husketeknikker",
                "Kunsten å dagdrømme",
                "Hvordan du kan visualisere og huske"
      ]
    },
    "en": {
      "title": "This is what you'll play with",
      "list": [
                "Memory systems use your whole brain",
                "The 3 main types of memory systems",
                "The art of daydreaming",
                "How to visualize and remember"
      ]
    }
  },
  "badge": "mission-badge2.png",
  "banner": "mission-banner2.jpg",
  "dependencies": ['doublememory'],
  "steps": [
    "intro_article_basics",
    "article_art_of_daydreaming",
    "sound_visualization1"
  ]
}, {
  "id": "multiplymemory",
  "name": {
    'en': "Multiply your memory",
    'no': "Mangedoble hukommelsen din"
  },
  "description": {
    'en': "Learn how to easily triple or quadruple your new memory powers with a simple trick. Prepare to be cloned!",
    'no': "Lær hvordan du enkelt kan tredoble eller firedoble dine nye huskeferdigheter med et enkelt triks. Gjør deg klar for kloning!"
  },
  "benefits": {
    "no": {
      "title": "Dette vil du leke deg med",
      "list": [
                "Hvordan du mangedobler hukommelsen din",
                "Klonesystemet",
                "En liste med 30 handleprodukter"
      ]
    },
    "en": {
      "title": "This is what you'll play with",
      "list": [
                "How to multiply your memory skills",
                "The clone system",
                "A list with 30 shopping items"
      ]
    }
  },
  "dependencies": ['doublememory'],
  "badge": "mission-badge3.png",
  "banner": "mission-banner3.jpg",
  "steps": [
    "practice_singlesys",
    'article_clones',
    "practice_superm_0_30_items"
  ]
}, {
  "id": "visualization",
  "name": {
    'en': "Visualization Master",
    'no': "Visualiseringsmester"
  },
  "description": {
    'en': "How good you become at memorizing depends on how good you become at visualizing. Play and visualize with exaggerations, movements, dimentions, suprises and much more.",
    'no': "Hvor dyktig du blir til å huske avhenger av hvor dyktig du blir til å visualisere. Lek deg og visualiser med overdrivelser, bevegelser, dimensjoner, overraskelser og mye mer. "
  },
  "benefits": {
    "no": {
      "title": "Slik vil du visualisere for å huske bedre",
      "list": [
                "Visualiser i forskjellige dimensjoner",
                "Visualiser og overdriv",
                "Visualiser og ha det gøy",
                "Visualiser ting som skiller seg ut"
      ]
    },
    "en": {
      "title": "This is how you'll visualize to remember",
      "list": [
                "Visualize in different dimensions",
                "Visualize and exaggerate",
                "Visualize and have fun",
                "Visualize things that stand out"
      ]
    }
  },
  "dependencies": ['basic', 'multiplymemory'],
  "badge": "mission-badge4.png",
  "banner": "mission-banner4.jpg",
  "steps": [
    "sound_vizualization2",
    "sound_visualization3"
  ]
}, {
  "id": "rockmedium",
  "name": {
    'en': "Rock Medium-sized numbers",
    'no': "Lek deg gjennom mellomstore tall"
  },
  "description": {
    'en': "Learn how to remember medium-sized to long numbers with the memory systems you already know - in combination with the journey system.",
    'no': "Lær hvordan du enkelt og hurtig kan huske mellomstore tall ved å bruke huskesystemene du allerede kan i kombinasjon med reisesystemet."
  },
  "benefits": {
    "no": {
      "title": "Dette vil du leke deg med",
      "list": [
                "Hvordan bruke omgivelsene for å huske",
                "Hvordan huske tall og bursdager",
                "Hvordan huske pin-koder og passord",
                "Hvordan knekke en 20-sifret bankhvelv-kode"
      ]
    },
    "en": {
      "title": "This is what you'll play with ",
      "list": [
                "How to use your surroundings to remember",
                "How to remember numbers and birthdays",
                "How to remember pin-codes and passwords",
                "How to break a 20-digit bankvault code"
      ]
    }
  },
  "dependencies": ['basic', 'multiplymemory'],
  "badge": "mission-badge5.png",
  "banner": "mission-banner5.jpg",
  "steps": [
    "article_journeysys",
    "show_technique_journeysys",
    "practice_singlesys",
    "practice_bankv_20_items"
  ]
}, {
  "id": "kickstart",
  "name": {
    'en': "Kickstart your name memory",
    'no': "Kickstart evnen til å huske navn"
  },
  "description": {
    'en': "What was the name of those people I was just presented to ...? Forgetting names has become so normal - almost like a brain-bug we take for granted - but luckily it's much easier to learn than you'd think. In this mission you'll already learn how to quickly remember the names of 10 new people you meet.",
    'no': "Hva var det disse menneskene het igjen, de jeg akkurat hilste på ... ? Det å ikke huske navn er noe mange tar for gitt, men heldigvis er det mye enklere enn man skulle tro å gjøre noe med. Dette oppdraget lar deg hurtig huske navn på 10 nye mennesker du hilser på."
  },
   "benefits": {
    "no": {
      "title": "Dette vil du leke deg med",
      "list": [
                "Hvordan du husker navn",
                "En intro til Navne-systemet",
                "Huske 10 navn i spillet Navnemester"
      ]
    },
    "en": {
      "title": "This is what you'll play with",
      "list": [
                "How to remember names",
                "An intro to the name system",
                "Remembering 10 names in the SocialStar game"
      ]
    }
  },
 "dependencies": ['basic', 'multiplymemory'],
  "badge": "mission-badge6.png",
  "banner": "mission-banner6.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_female",
    "show_technique_namesys_10_male",
    "newgame_face_10_female_10_male"
  ]
}, {
  "id": "nailabstract",
  "name": {
    'en': "Fun With Abstract Words",
    'no': "Moro med abstrakte ord"
  },
  "description": {
    'en': "Abstract words are a bit similar to names, facts and vocabularies - our brains simply don't know what to do with them unless we link them to something we already know. And that's just what we're going to do. We're going to have fun while bending, twisting and changing abstract words until they become familiar friends.",
    'no': "Abstrakte ord ligner litt på navn, definisjoner og gloser - hjernen vår vet rett og slett ikke hva den skal gjøre med dem inntil vi kobler dem opp med noe vi kjenner. Og det er nettopp det vi skal gjøre. Vi kommer til å ha det moro mens vi bøyer, vrir og endrer på abstrakte ord inntil de blir som gode venner."
  },
  "benefits": {
    "no": {
      "title": "Dette vil du leke deg med",
      "list": [
                "Hvordan huske abstrakte ord",
                "Hvordan huske med rim",
                "Det å huske 15 Brainstorming-ideer"
      ]
    },
    "en": {
      "title": "This is what you'll play with ",
      "list": [
                "How to remember abstract words",
                "How to remember using rhymes",
                "Remembering 15 Brainstorming ideas"
      ]
    }
  },
  "dependencies": ['visualization', 'rockmedium', 'kickstart'],
  "badge": "mission-badge7.png",
  "banner": "mission-banner7.jpg",
  "steps": [
    "article_symbolization",
    'article_rhyme',
    "show_technique_rhymesys",
    "newgame_library_0"
  ]
}, {
  "id": "vocabularyguru",
  "name": {
    'en': "Become a vocabulary guru",
    'no': "Bli en gloseguru"
  },
  "description": {
    'en': "Mastering the right vocabularies is the bread and butter of making yourself understood in a foreign language. Luckily it's super-fast to learn new vocabularies with the memory techniques in MemoGenius. In fact, the Memolife members who use the language game MemoLanguage often learn up to 1000 new vocabularies in a week and can become functionally fluent in a language within a couple of months.",
    'no': " Det å mestre de riktige glosene er ABC for å gjøre seg forstått på et fremmed språk. Heldigvis er det lynraskt å lære seg nye gloser med de riktige teknikkene. Faktisk, så lærer Memolife-medlemmene som bruker språkspillet MemoLanguage ofte opp til 1000 gloser på en uke og kan bli funksjonelt flytende i et språk på noen måneder."
  },
  "benefits": {
    "no": {
      "title": "Dette vil du leke deg med",
      "list": [
                "Kunsten å lære et språk",
                "Hvordan lære gloser lynraskt",
                "Memorisere 10 ord på et rart språk i lyntempo"
      ]
    },
    "en": {
      "title": "This is what you'll play with ",
      "list": [
                "The art of learning a language",
                "How to speed-learn vocabularies",
                "Speed-learning 10 words in strange language",
      ]
    }
  },
  "dependencies": ['visualization', 'rockmedium', 'kickstart'],
  "badge": "mission-badge8.png",
  "banner": "mission-banner8.jpg",
  "steps": [
    'article_language_similiarity',
    "newgame_vocab_level_0_totalitems_10"
  ]
}, {
  "id": "alphabetmemory",
  "name": {
    'en': "Unlock Your Alphabet Memory Powers",
    'no': "Slipp løs alfabetets huskekrefter"
  },
  'description': {
    'en': "Once you get your letters straight you can use the alphabet as a series of powerful memory systems. All you have to do is learn the number sequence of the different letters and then link the letters up with an image for your alphabet topic. In a snap you have a series of new memory systems which trump both the Single and Rhyme systems.",
    'no': "Så lenge du klarer å sjonglere bokstavene i riktig rekkefølge kan du bruke alfabetet som forskjellige huskesystemer. Alt som skal til er å koble bokstavene til et bilde som passer med temaet for alfabetsystemet du vil lage, og vips, så har du et sterkt huskesystem som trumfer både Singel- og Rimsystemet."
  },
  "benefits": {
    "no": {
      "title": "Dette vil du leke deg med",
      "list": [
                "Lær tallrekkefølgen i alfabetet",
                "Hvordan huske med Alfabet-systemet",
                "Det er glede i oppfriskning med Klone-systemet"
      ]
    },
    "en": {
      "title": "This is what you'll play with ",
      "list": [
                "Learning the number sequence of the alphabet",
                "How to remember with an alphabet system",
                "There's joy in repetition with the clone system"
      ]
    }
  },
  'dependencies': ['visualization', 'rockmedium', 'kickstart'],
  "badge": "mission-badge9.png",
  "banner": "mission-banner9.jpg",
  'steps': [
    'article_clones',
    'article_rhyme',
    'newgame_shopping_alphabet',
    'article_alphabet',
    'newgame_alphabet'
  ]

}]

var master_sub_missions = [{
  "id": "memogenius1",
  "name": {
    'en': "Brain 00-09",
    'no': "Hjernen 00-09"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-00-09.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    "article_memolife_system",
    "article_journeysys",
    "show_technique_journeysys",
    'show_technique_doublesys',
    //"show_sys_double_practice_0_9",
    "newgame_bankv_2_40_0_9"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius2",
  "name": {
    'en': "Knowledge A 10-20",
    'no': "Kunnskap A 10-20"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-10-20.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    'article_memolife_system',
    "show_sys_double_practice_10_20_0_9_3",
    "show_technique_rhymesys",
    "newgame_superm_0_40"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius3",
  "name": {
    'en': "Knowledge B 21-32",
    'no': "Kunnskap B 21-32"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-21-32.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    "article_memolife_system",
    "article_symbolization",
    'article_history',
    "show_sys_double_practice_21_32_0_20_3",
    "newgame_time_0_10_21_32"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius4",
  "name": {
    'en': "Mind A 33-42",
    'no': "Hode A 33-42"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-33-42.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    'article_memolife_system',
    'article_journeysys',
    "show_technique_journeysys",
    "show_sys_double_practice_33_42_0_32_3",
    "newgame_bankv_2_40_33_42"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius5",
  "name": {
    'en': "Mind B 43-51",
    'no': "Hode B 43-51"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-43-51.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    'article_memolife_system',
    'article_journeysys',
    "show_technique_journeysys",
    "show_sys_double_practice_43_51_0_52_3",
    "newgame_bankv_2_43_51"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius6",
  "name": {
    'en': "Body 52-66",
    'no': "Kroppen 52-66"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-52-66.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    'article_memolife_system',
    'article_symbolization',
    "show_sys_double_practice_52_66_0_51_3",
    "newgame_library_1_15_52_66"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius7",
  "name": {
    'en': 'Soul 67-78',
    'no': 'Sjelen 67-78'
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-67-78.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    "article_memolife_system",
    'article_symbolization',
    'article_history',
    "show_sys_double_practice_67_78_0_66_3",
    "newgame_time_1_10_67_78"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius8",
  "name": {
    'en': "Career 79-88",
    'no': "Karriere 79-88"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-79-88.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    'article_memolife_system',
    'article_journeysys',
    'article_history',
    "show_technique_journeysys",
    "show_sys_double_practice_79_88_0_78_3",
    "newgame_bankv_1_40_79_88"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius9",
  "name": {
    'en': "Leisure 89-99",
    'no': "Fritid 89-99"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-89-99.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    'article_memolife_system',
    'article_symbolization',
    'article_history',
    "show_sys_double_practice_89_99_0_88_3",
    "newgame_time_1_10_89_99"
  ],
  "master_mission": "memogenius"
}, {
  "id": "memogenius10",
  "name": {
    'en': "All categories",
    'no': "Alle kategorier"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['nailabstract', 'vocabularyguru'],
  "badge": "Genius-00-99.png",
  "banner": "mission-banner10.jpg",
  "steps": [
    'article_memolife_system',
    "show_technique_journeysys_repeat_3",
    "show_sys_double_practice_0_0_0_99_50",
    "newgame_bankv_1_50_0_99"
  ],
  "master_mission": "memogenius"
}, {
  "id": "social_star1",
  "name": {
    'en': "Social star 1",
    'no': "Navnemester 1"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-1-10.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male",
    "show_technique_namesys_10_female",
    "show_technique_namesys_10_surname",
    "newgame_face_2_10_1_10"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star2",
  "name": {
    'en': "Social star 2",
    'no': "Navnemester 2"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-11-20.png",
  "banner": "mission-banner11.jpg",  
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_1",
    "show_technique_namesys_10_female_page_1",
    "show_technique_namesys_10_surname_page_1",
    "newgame_face_2_10_11_20"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star3",
  "name": {
    'en': "Social star 3",
    'no': "Navnemester 3"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-21-30.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_2",
    "show_technique_namesys_10_female_page_2",
    "show_technique_namesys_10_surname_page_2",
    "newgame_face_2_10_21_30"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star4",
  "name": {
    'en': "Social star 4",
    'no': "Navnemester 4"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-31-40.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_3",
    "show_technique_namesys_10_female_page_3",
    "show_technique_namesys_10_surname_page_3",
    "newgame_face_2_10_31_40"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star5",
  "name": {
    'en': "Social star 5",
    'no': "Navnemester 5"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-41-50.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_4",
    "show_technique_namesys_10_female_page_4",
    "show_technique_namesys_10_surname_page_4",
    "newgame_face_2_10_41_50"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star6",
  "name": {
    'en': "Social star 6",
    'no': "Navnemester 6"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-51-60.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_5",
    "show_technique_namesys_10_female_page_5",
    "show_technique_namesys_10_surname_page_5",
    "newgame_face_2_10_51_60"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star7",
  "name": {
    'en': "Social star 7",
    'no': "Navnemester 7"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-61-70.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_6",
    "show_technique_namesys_10_female_page_6",
    "show_technique_namesys_10_surname_page_6",
    "newgame_face_2_10_61_70"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star8",
  "name": {
    'en': "Social star 8",
    'no': "Navnemester 8"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-71-80.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_7",
    "show_technique_namesys_10_female_page_7",
    "show_technique_namesys_10_surname_page_7",
    "newgame_face_2_10_71_80"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star9",
  "name": {
    'en': "Social star 9",
    'no': "Navnemester 9"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-81-90.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_8",
    "show_technique_namesys_10_female_page_8",
    "show_technique_namesys_10_surname_page_8",
    "newgame_face_2_10_81_90"
  ],
  "master_mission": "social_star"
}, {
  "id": "social_star10",
  "name": {
    'en': "Social star 10",
    'no': "Navnemester 10"
  },
  "description": {
    'en': "",
    'no': ""
  },
  "dependencies": ['memogenius'],
  "badge": "Names-91-100.png",
  "banner": "mission-banner11.jpg",
  "steps": [
    "intro_article_name_memory",
    "show_technique_namesys_10_male_page_9",
    "show_technique_namesys_10_female_page_9",
    "show_technique_namesys_10_surname_page_9",
    "newgame_face_2_10_91_100"
  ],
  "master_mission": "social_star"
}, {
  "id": "everydaymemboosters",
  "name": {
    'en': "Everyday memory boosters",
    'no': "Everyday memory boosters"
  },
  "description": {
    'en': "",
    'no': ""
  },
  // "dependencies": ['expandyourmemoryuniverse'],
  "dependencies": [],
  "steps": [
    "article_facts_trivia",
    "article_memory_reminder",
    "article_directions_parking",
    "article_presentations",
    "article_memory_sports"
  ]
}
// {
//   "id": "expandyourmemoryuniverse1",
//   "name": {
//     'en': "Nail Your Project Meetings",
//     'no': "Briljer i prosjektmøtene"
//   },
//   "description": {
//       "en": "What if you could remember everything you wanted to from your project meetings - the tasks, the project managers and deadlines - without taking a single note? Wouldn't this improve your interaction with the other meeting participants and help you get your points across? Well, in this mission we'll look at how.",
//       "no": "Hva hvis du kunne huske alt fra jobbmøtene - oppgavene, prosjektlederne og deadlinesene - uten å ta et eneste notat? Ville ikke dette forbedre kjemien og effektiviteten med de andre møtedeltagerne? Vel, i dette oppdraget tar vi en titt på hvordan du kan gå frem."
//     },
//   "dependencies": ['aceyourcurriculum', 'nailyourbusinessmeetings'],
//   "steps": [
//     "article_detailed_meeting",
//     "newgame_meeting_4_1"
//   ],
//   "master_mission": "expandyourmemoryuniverse"
// }, {
//   "id": "expandyourmemoryuniverse2",
//   "name": {
//     'en': "Master complex lists",
//     'no': "Mestre komplekse lister"
//   },
//   "description": {
//     'en': "",
//     'no': ""
//   },
//   "dependencies": ['aceyourcurriculum', 'nailyourbusinessmeetings'],
//   "steps": [
//     "article_shopping",
//     "newgame_shopping_20_quantities_prices"
//   ],
//   "master_mission": "expandyourmemoryuniverse"
// }, {
//   "id": "expandyourmemoryuniverse3",
//   "name": {
//     'en': "Detailed curriculum",
//     'no': "Detaljert pensum"
//   },
//   "description": {
//     'en': "",
//     'no': ""
//   },
//   "dependencies": ['aceyourcurriculum', 'nailyourbusinessmeetings'],
//   "steps": [
//     "article_curriculum",
//     "newgame_curriculum_4_15"
//   ],
//   "master_mission": "expandyourmemoryuniverse"
// }, {
//   "id": "expandyourmemoryuniverse4",
//   "name": {
//     'en': "Get personal",
//     'no': "Bli personlig"
//   },
//   "description": {
//     'en': "",
//     'no': ""
//   },
//   "dependencies": ['aceyourcurriculum', 'nailyourbusinessmeetings'],
//   "steps": [
//     "intro_article_name_memory",
//     "article_personal_memory"
//     // can't find this one
//     // "newgame_face_4_1"
//   ],
//   "master_mission": "expandyourmemoryuniverse"
// }
                          ];

var other_missions = [{
  "id": "aceyourcurriculum",
  "name": {
    'en': "Ace your curriculum",
    'no': "Pensum på halve tiden"
  },
  "badge": "mission-badge12.png",
  "banner": "mission-banner12.jpg",
  "description": {
    "en": "What if you could easily finish your curriculum in half the time, or even faster? Well, with these techniques and a little practice, you can. And, with a little help from your friends you could finish even faster. Let's have a look!",
    "no": "Hva hvis du enkelt kunne bli ferdig med pensum på halvparten av tiden eller enda fortere? Vel, med disse teknikkene og litt øving kan du det. Og, med litt hjelp fra noen venner kan du bli ferdig enda fortere. La oss ta en titt på hvordan!"
  },
  "dependencies": ['social_star'],
  "steps": [
    "article_linking",
    "article_symbolization",
    "newgame_curriculum_2_15"
  ]
}, {
  "id": "nailyourbusinessmeetings",
  "name": {
    'en': "Nail your business meetings",
    'no': "Få med alt i jobbmøtene"
  },
  "badge": "mission-badge13.png",
  "banner": "mission-banner13.jpg",
  "description": {
    "en": "What if you could remember everything you wanted to from your business meetings without taking a single note? Wouldn't this improve your interaction with the other meeting participants and help you get your points across? Well, in this mission we'll look at how.",
    "no": "Hva hvis du kunne huske alt fra jobbmøtene dine uten å ta et eneste notat? Hvordan ville vel ikke dette forbedre kjemien og effektiviteten med de andre møtedeltagerne? Vel, i dette oppdraget tar vi en titt på hvordan du kan gå frem."
  },
  "dependencies": ['social_star'],
  "steps": [
    "article_business_meetings",
    "article_rhyme",
    "newgame_meeting_2_3"
  ]
}]

var master_missions = [{
  'id': 'memogenius',
  'dependencies': ['alphabetmemory'],
  'name': {
    'en': "Become A True MemoGenius",
    'no': "Bli et ekte memogeni"
  },
  "badge": "mission-badge10.png",
  "banner": "mission-banner10.jpg",
  'description': {
    'en': 'Learn the Memolife Double System and unlock your true memogenius. This master mission will teach you the memory system which combines 100 different brain improvement and life areas with funny memory codes. At the same time you\'ll have fun being your own movie director, combining memory codes for people, actions and objects into different mental "movie scenes".',
    'no': 'Lær Memolife Dobbelsystemet og bli et ekte memogeni. Med dette mastersystemet vil du lære et huskesystem som kombinerer 100 forskjellige hjernetrings- og livsområder med morsomme huskekoder. Samtidig vil du ha det moro som filmregissør idet du kombinerer huskekodene for personer, handlinger og objekter i forskjellige mentale "filmscener".'
  },
  'tip': {
    'en': "NOTE: never use objects from the Double System as locations to memorize any permanent information.",
    'no': "OBS: bruk aldri objektene fra Dobbeltsystemet som steder for å lagre permanent informasjon."
  }
}, {
  'id': 'social_star',
  'dependencies': ['alphabetmemory'],
  'name': {
    'en': "Become A Social Star",
    'no': "Bli en navnemester"
  },
  "badge": "mission-badge11.png",
  "banner": "mission-banner11.jpg",
  'description': {
    'en': "What was the name of those people I was just presented to ...? Forgetting names has become so normal - almost like a brain-bug we take for granted - but luckily it's much easier to learn than you'd think. In this mission you'll learn how to remember the names of all the people you meet",
    'no': "Hva var det disse menneskene het igjen, de jeg akkurat hilste på ... ? Det å ikke huske navn er noe mange tar for gitt, men heldigvis er det mye enklere enn man skulle tro å gjøre noe med. Dette oppdraget lar deg hurtig huske navnene på alle nye mennesker du treffer"
  }
}
  //                      , {
  // 'id': 'expandyourmemoryuniverse',
  // "badge": "mission-badge0.png",
  // "banner": "mission-banner0.jpg",
  // 'name': {
  //   'en': "Expand Your Memory Universe",
  //   'no': "Utvid ditt hukommelsesunivers"
  // },
  // 'description': {
  //   'en': "Now it's time to raise the stakes and add complexity to some of the memory challenges you've already solved. It's time to remember more and faster.",
  //   'no': "Nå er det på tide å løfte nivået og legge til mer kompleksitet til huskeutfordringene du allerede har løst. Det er på tide å både huske mer og fortere."
  // }
  //                      }
                      ];

var mission, missionParams, missionSystem;


// TODO: delete this... it's only for testing.
//
if (typeof global === 'undefined') {
  global = {};
}

global.missions = missions;
