function verifySinglesys(e, ui) {
	var target = $(e.target),
		dragItem = ui.draggable;
	itemId = dragItem.data('id');
	if(target.data('id') == itemId) {
		playSoundAction('music-correct');
		target.removeClass('wrong').addClass('correct');
		target.find('.vertical').text(target.data('text'));
		target.find('img').attr('src', '/images/'+target.data('id')+'.jpg');
		dragItem.remove();
		if($('#'+currentGame+'  .shopping-items li').length == 0 && systemCodeIndex< (systemSingleCodes.length-1)) {
			showNextSysSinglePractice();
		} else if($('#'+currentGame+' .shopping-items li').length == 0 && systemCodeIndex >= (systemSingleCodes.length-1)){
			showResult(1,1);
		}
	} else {
		playSoundAction('music-wrong');
		target.addClass('wrong');
		dragItem.addClass('wrong');
		dragItem.find('span').text(5);
		dragEnd(dragItem);
	}
}

function showSysSinglePractice(system, index){
	if(system) {
		currentGame = system;
		systemCodeIndex = index -1;
	}

	showPracticeNotice('a');
	$('#'+currentGame+ ' .single-practice').empty();
	$('#'+currentGame+ ' .single-test').hide();
	showPanel(currentGame);

	if(/singlesys/.test(currentGame)) systemSingleCodes = systemNumberShapeCodes[lang];
	else if(/alphabetsys/.test(currentGame)) systemSingleCodes = systemAlphabetCodes[lang];
	else if(/rhymesys/.test(currentGame)) systemSingleCodes = systemRhymeCodes[lang];

	setTimeout(showNextSysSinglePractice,3000);

  translate();
}

function showNextSysSinglePractice(){
	systemCodeIndex += 1;
	if(/singlesys/.test(currentGame) && systemCodeIndex >= systemSingleCodes.length){
		if(window.gamestate.data.firsttime) newGame('superm0');
		else { showNextSysSingleTest(); }
		return;
	}

	var systemCode = systemSingleCodes[systemCodeIndex];
  if(/singlesys/.test(currentGame)) {
    if(systemCodeIndex == 0 || systemCodeIndex == 3 || systemCodeIndex == 7){
      $('.single-practice').html('<div class="system-learn single-learn">'+TemplateEngine($('#tpl-'+currentGame+'-practice').html()+'</div>', {systemCode: systemCode}));
    }else {
      $('.single-practice .single-learn').html(TemplateEngine($('#tpl-'+currentGame+'-practice').html(), {systemCode: systemCode}));
    }
  } else {
    if(systemCodeIndex % 3 == 0)
      $('.single-practice').html('<div class="system-learn single-learn">'+TemplateEngine($('#tpl-'+currentGame+'-practice').html()+'</div>', {systemCode: systemCode}));
    else
      $('.single-practice .single-learn').html(TemplateEngine($('#tpl-'+currentGame+'-practice').html(), {systemCode: systemCode}));
  }
	$('#'+currentGame+' .single-test').hide();
	$('#'+currentGame+' .single-practice').show();

	setTimeout(function(){
    if(/singlesys/.test(currentGame) && systemCodeIndex == 0) showLearnificationSinglesys('step1');
		$('.single-practice .box, .single-practice .top h1 ,.single-practice .top p').addClass('preview');
	},500);

	translate();
}

function showNextSysSingleTest(){
	$('#'+currentGame+' .drop-places').html('');
	$('#'+currentGame+' .shopping-items').html('');
	var systemCode, options = [];
  if(/singlesys/.test(currentGame)) {
    var start= 0;
    if(systemCodeIndex == 2) {start =0; }
    if(systemCodeIndex == 6) {start =3;}
    if(systemCodeIndex == 10){start= 7;}

    for(var i=start; i<=systemCodeIndex; i++) {
      systemCode = systemSingleCodes[i];
      $('#'+currentGame+' .drop-places').append(
          '<li class="">'+
            '<div class="system-box">'+
            '<div class="close"></div>'+
            '<div class="header">'+
              systemCode[0]+
            '</div>'+
            '<div class="body">'+
              '<div class="item" data-id="'+currentGame+systemCode[0]+'" data-text="'+systemCode[1]+'">'+
              '<div class="img-holder pull-left">'+
                '<span class="number">'+systemCode[0]+'</span>'+
                '<img>'+
              '</div>'+
              '<div class="text">'+
                '<div class="vertical">'+
                '</div>'+
              '</div>'+
              '</div>'+
            '</div>'+
            '</div>'+
          '</li>'
      );
      options.push(currentGame+systemCode[0]);
    }
  } else {
    for(var i=Math.floor(systemCodeIndex / 3) * 3; i< Math.floor(systemCodeIndex / 3) * 3 + 3 && i < systemSingleCodes.length; i++) {
      systemCode = systemSingleCodes[i];
      $('#'+currentGame+' .drop-places').append(
          '<li class="">'+
            '<div class="system-box">'+
            '<div class="close"></div>'+
            '<div class="header">'+
              systemCode[0]+
            '</div>'+
            '<div class="body">'+
              '<div class="item" data-id="'+currentGame+systemCode[0]+'" data-text="'+systemCode[1]+'">'+
              '<div class="img-holder pull-left">'+
                '<span class="number">'+systemCode[0]+'</span>'+
                '<img>'+
              '</div>'+
              '<div class="text">'+
                '<div class="vertical">'+
                '</div>'+
              '</div>'+
              '</div>'+
            '</div>'+
            '</div>'+
          '</li>'
      );
      options.push(currentGame+systemCode[0]);
    }
  }
	options.sort(function(){ return Math.random() - 0.5; });
	for(var i=0; i<options.length; i++) {
		$('#'+currentGame+' .shopping-items').append(
			'<li class="drag option-'+options[i]+'" data-id="'+options[i]+'"><span></span><img src="/images/'+options[i]+'.jpg"></li>'
		);
	}
	$('#'+currentGame+' .drop-places .item').droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
	$('#'+currentGame+' .shopping-items .drag').draggable({ cancel: ".wrong" });

}

function showNextSysSinglePracticeOrTest(){
  if(/singlesys/.test(currentGame)) {
    if(systemCodeIndex == 2 || systemCodeIndex == 6 ||  systemCodeIndex == 10){
      $('#'+currentGame+' .drop-places').empty();
      $('#'+currentGame+' .shopping-items').empty();
      $('#'+currentGame+' .single-practice').toggle();
      showPracticeNotice('b');
      gameEnd = false;
      gameTime = 0;
      setTimeout(function(){
        showNextSysSingleTest();
        $('#'+currentGame+' .single-test').show();
        updateClockUp();
      }, 3000);
    } else {
      showNextSysSinglePractice();
    }
  } else {
    if((systemCodeIndex % 3 == 2) || systemCodeIndex == (systemSingleCodes.length-1)){
      $('#'+currentGame+' .drop-places').empty();
      $('#'+currentGame+' .shopping-items').empty();
      $('#'+currentGame+' .single-practice').toggle();
      showPracticeNotice('b');
      gameEnd = false;
      gameTime = 0;
      setTimeout(function(){
        showNextSysSingleTest();
        $('#'+currentGame+' .single-test').show();
        updateClockUp();
      }, 3000);
    } else {
      showNextSysSinglePractice();
    }
  }
}
