// This is taken from github.com/svexican/web-extras/rivets_config
;
(function () {
  var shout, slice = [].slice;
  rivets.configure({
    templateDelimiters: ["{{", "}}"]
  });

  /*rivets.formatters.mailto = function(t) {
    return "mailto:" + t;
  };*/

  /*rivets.formatters.pdate = function(t) {
    return new Date(t).prettyHTML();
  };*/

  /*rivets.formatters.capitalise = function(t) {
    return t.capitalise();
  };*/

  /*
  rivets.formatters.format = function() {
    var t, e;
    return e = arguments[0],
    t = 2 <= arguments.length ? slice.call(arguments, 1) : [],
    t.join(" ").format(Array(e.toString()));
  };*/

  /*
  rivets.formatters.upcase = function(t) {
    return t.toUpperCase();
  };*/

  /*
  rivets.formatters.translate = function(t) {
    return dictionary[t];
  };*/

  shout = function(t, e) {
    throw console.log(' ---- ERROR: The value "' + e + '" of the following object is not defined OR "' + e + '" has not been called/bound by rivets ---- '),
    console.log(t),
    "TypeError: Cannot read property '_rv' of undefined"
  };

  rivets.adapters["."].get = function(t, e) {
    return "undefined" == typeof t[e] && shout(t, e),
    t[e]
  };

  rivets.adapters[":"] = {
    observe: function(t, e, r) {
      return rivets.adapters["."].observe(t, e, r);
    },
    unobserve: function() {},
    get: function(t, e) {
      return t[e];
    },
    set: function() {}
  };

  rivets.adapters["="] = {
    observe: function() {},
    unobserve: function() {},
    get: function(t, e) {
      return "undefined" == typeof t[e] && shout(t, e),
      t === e
    },
    set: function() {}
  };

  /*
  rivets.binders["init-*"] = function(t, e) {
    var r;
    return r = this.args[0].split("-"),
    void 0 === e[r[0]] ? e[r[0]] = r[1].toBoolean() : void 0
  };

  rivets.binders["match-*"] = function(t, e) {
    return t.style.display = e === this.args[0] ? "" : "none"
  };*/

}());
