function drop(e, ui){
	if(e.stopPropagation) e.stopPropagation();
	if(e.preventDefault) e.preventDefault();
	
	var target = $(e.target);
	target.removeClass('over');
	if (target.hasClass('speaker')) {
		if(verify[currentGame]($(e.toElement).getData('id'), target.data('id'))) {
			target.addClass('correct');
		} else {
			target.addClass('wrong');
		}
		setTimeout(function(){ $('.speaker').removeClass('correct wrong'); }, 600);
	} else {
		verify[currentGame](e, ui);
	}
}

function dragEnd(e) {
	if(e) {
		setTimeout(function() {
			e.css('left','').css('right','').css('top','').removeClass('moving');
		}, 500);
	}
}

function dragMove(e){
	$(e.currentTarget).css('transition','all 0').addClass('moving');
}

