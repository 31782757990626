function showIntroLearn(forced){
	if(forced) showLevelIntro(currentGame);
	$('.intro-card').removeClass('bounceInUp').addClass('bounceOutDown');
	$('.learn-card').removeClass('bounceInUp');
	setTimeout(function(){
		$('.intro-card').addClass('hide');
		$('.learn-card').removeClass('hide bounceOutDown').addClass('bounceInUp');
	},100);
}

function showIntroPlay(){
	$('.learn-card').removeClass('bounceInUp').addClass('bounceOutDown');
	$('.intro-card').removeClass('bounceInUp');
	setTimeout(function(){
		$('.learn-card').addClass('hide');
		$('.intro-card').removeClass('hide bounceOutDown').addClass('bounceInUp');
	},100);
}

function showSublevels(i){
	if(getMasterLevel(currentGame)<i) return;
	$('.top-level').addClass('bounceOutRight').removeClass('bounceInRight');
	$('.intro-backlink').show();
	$('.intro-mainlevels').hide();
	$('.intro-learn-level').hide();
	$('.intro-learn-level'+i).show();
	setTimeout(function(){
		$('.top-level').addClass('hide');
		$('.sub-level-'+i).removeClass('hide bounceOutRight').addClass('bounceInRight');
	},500);
}

function showMainlevels(el){
	$('.sub-level').addClass('bounceOutRight').removeClass('bounceInRight');
	$('.intro-backlink').hide();
	$('.intro-mainlevels').show();
	$('.intro-learn-level').hide();
	$('.intro-learn-level0').show();
	setTimeout(function(){
		$('.sub-level').addClass('hide');
		$('.top-level').removeClass('hide bounceOutRight').addClass('bounceInRight');
	},500);
}

