function initTestBankvault(){
	currentMode = 'test';
	$('.num-answer').text('');
	showPanel('paneltest'+currentGame);
	$('#paneltest'+currentGame).addClass('start');
  
  if(missionParams) { 
    gameTime = 1000000;
    $('.game-timer').addClass('hide'); 
  } else { $('.game-timer').removeClass('hide'); }
  
	showLives(3);
}

function initGameBankvault(){
  // a hack until we clean this up
  // var missionParams = missionParams || { };

	currentMode = 'learn';
	number = '';
  // We have already set gameLevel when start newGame.
  // DO NOT overwrite it here.
	///// gameLevel = getGameLevel(currentGame);
	levelSettings = levelMap[currentGame][gameLevel];
	totalItems = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1];
	pageSize = totalItems; //1 + levelSettings[4] + levelSettings[5];
	totalPages = 1; //Math.ceil(totalItems/pageSize);
	star2 = levelSettings[9];
	star3 = levelSettings[10];
	if(gameLevel < 2) $('#panelbankv .game-button').hide(); else $('#panelbankv .game-button').show();

	if(!missionParams || !missionParams.max) {
		while(number.length < totalItems) {
			number = number + parseInt(Math.random()*10);
		}
	} else {
		while(number.length < totalItems) {
      var set = parseInt(Math.random()*(missionParams.max - missionParams.min) + missionParams.min + 1);
			number = number + (set < 10 ? '0': '') + set;
		}
	}

	if(!initSlots) {
		$('.slot1').jSlots({
			number:2,
			spinner : '.vault.tiny',
			onEnd: vaulttiny1,
			time :500,
		});
		$('.slot2').jSlots({
			number:2,
			spinner : '.vault.tiny',
			onEnd: vaulttiny2,
			time :500,
		});
		initSlots = true;
		$($('.jSlots-wrapper')[0]).append('<ul class="slot slot001"><li>00</li></ul>');
		$($('.jSlots-wrapper')[1]).append('<ul class="slot slot002"><li>00</li></ul>');

	}
	$('.slot').hide();
	if(levelSettings[5]<4) $('.slot001').show();
	else if(levelSettings[5]>=4) $('.slot001, .slot002').show()

	$('.vault-holder').addClass('hide-arrow');
	$('.vault .done').removeClass('done');
	$('.vault-input-holder').removeClass('correct');

	$('.vault .arrow').css({'-webkit-transform':'rotate(0deg)','transform':'rotate(0deg)'});
	if(gameLevel < 2) $('.vault.small').hide(); else $('.vault.small').show();
	if(gameLevel < 5) $('.vault.tiny').hide(); else $('.vault.tiny').show();
	$('#paneltest'+currentGame).removeClass('start');

  
  if(missionParams) {
    $('.pagination').addClass('hide'); 
  } else { $('.pagination').removeClass('hide'); }
  
	index = 0;
	itemTime = levelSettings[2];
	learnRemainTime = 0;
	penaltyTime =0;
	pageTime = 0;
	pageTimes = [];
	gameTime = itemTime * totalItems;
	page = -1;
	ring1 = '0';
	hideButton();
	setTimeout(function(){
		$('.vault-holder').removeClass('hide-arrow');
		rotate(number);
		updateClockLearn2();
	},3000);
}

function verifyBankvault(){
	$('#panel'+currentGame).removeClass('play');
	if($('.num-answer:visible a').text() == number) {
		$('.vault-input-holder').addClass('correct');
		$('.tick').addClass('animate').show();
		playSoundAction('music-correct');
		setTimeout(function(){
			$('.tick').removeClass('animate');
			showResult(number.length, number.length);
		}, 1000);
	} else {
		$('.vault-input-holder').addClass('wrong');
		setTimeout(function(){
			$('.vault-input-holder').removeClass('wrong');
		}, 1000);
		lives = 0;
		checkGameOver();
	}
}

function rotate(number){
	page += 1;
	showPages();
	if(page >= Math.ceil(totalItems/(1 + levelSettings[4] + levelSettings[5]))) {
		$('.vault-answer').text(number);
    learnRemainTime = gameTime;
		setTimeout(function() { startTesting(); }, 1000);
		return;
	}
	digit = number[index];
	if(digit == ring1) digit = 10+parseInt(ring1);
	ring1 = digit;
	index += 1;
	var x = 360.0 / 10.0 * digit;
	$('.vault .done').removeClass('done');
	$('.next-ring').addClass('inactive');
	$('.vault1').css({'-webkit-transform':'rotate('+x+'deg)','transform':'rotate('+x+'deg)'});
	setTimeout(function(){
		$('.vault1').addClass('done');
		if(!levelSettings[4]) $('.next-ring').removeClass('inactive');
	}, 500);
	if(levelSettings[4]) {
		digit = number[index];
		if(digit == ring2) digit = 10 + parseInt(ring2);
		ring2 = digit;
		index += 1;
		setTimeout(function() {
			var x = 360.0 / 10.0 * digit;
			$('.vault2').css({'-webkit-transform':'rotate('+x+'deg)','transform':'rotate('+x+'deg)'});
			setTimeout(function(){
				$('.vault2').addClass('done');
				$('.next-ring').removeClass('inactive');
			}, 500);
		}, levelSettings[6]* 1000);
	}
	if(levelSettings[5]) {
		index += levelSettings[5];
		setTimeout(function(){
			$('.slot001, .slot002').hide();
			if(levelSettings[5]<4) $('.slot1').fadeIn();
			else if(levelSettings[5]>=4) $('.slot1, .slot2').fadeIn();

			$('.vault.tiny').click();
		}, levelSettings[7]*1000);
	}
}

function vaulttiny1(data) {
	if(levelSettings[5] == 2) {
		number = number.substr(0, index - 2) + data[0] % 10 + data[1] % 10 + number.substr(index);
	} else if(levelSettings[5] == 4) {
		number = number.substr(0, index - 4) + data[0] % 10 + data[1] % 10 + number.substr(index-2);
	}
	$('.vault.tiny').addClass('done');
}

function vaulttiny2(data) {
	if(levelSettings[5] == 4) {
		number = number.substr(0, index - 2) + data[0] % 10 + data[1] % 10 + number.substr(index);
	}
}
