var MEETINGQUESTION_PAGESIZE = 3;

var MEETINGANSWER_PAGESIZE = isMobile? 2 : 4;

function initTestMeeting(){
	totalItems = meetingAnswers.length;
	currentMode = 'test';
	testState = '';
	$('.meeting-messages').empty().append('<ul class="meeting-chat-holder meeting-questions" style="z-index:auto"></ul>'+
	(meetingAnswers.length > 3 ? '<div class="up" onclick="showPageItemsPrev($(\'#paneltestmeeting .meeting-questions\'), MEETINGQUESTION_PAGESIZE)"></div>'+
	'<div class="down" onclick="showPageItemsNext($(\'#paneltestmeeting .meeting-questions\'), MEETINGQUESTION_PAGESIZE)"></div>' : '')+
	'<div class="group-answer"></div>');
	for(var i=0; i<meetingAnswers.length; i++) {
		meetingAnswers[i].id = i;
		var faceId = meetingAnswers[i].speaker.id;
		var avatars = '';
		for(var j=0; j<meetingSpeakers.length;j++){
			avatars  += '<div class="img-holder '+(meetingSpeakers[j].img ==meetingAnswers[i].speaker.img?'speaker':'')+'" onclick="verifyMeetingSpeaker(event)">'+
				'<img src="faces/'+meetingSpeakers[j].img+'.png">'+
			  '</div>'
		}
		$('.meeting-messages .meeting-questions').append(
			'<li class="hide drop-li" onclick="verifyMeeting(event)" data-id="'+meetingAnswers[i].id+'"  data-time="'+meetingAnswers[i].time +'." >'+
			'<div class="avatar correct-avatar correct-avatar-'+meetingAnswers[i].id+' hide0"><div class="img-holder"><img  src="faces/'+meetingAnswers[i].speaker.img+'.png"/></div></div>'+
			'<div class="avatar avatar-list" data-name="'+meetingAnswers[i].speaker.name+'"><span></span>'+avatars + '</div>'+
			'<div class="time-options hide0" data-time="'+meetingAnswers[i].time+'">'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">1</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">2</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">3</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">4</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">5</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">6</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">7</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">8</span>'+
				'<span onclick="verifyMeetingTime(event)" class="time-option">9</span>'+
			'</div>'+
			'<div class="messages drop"><span class="number">'+(i+1)+'</span>'+
			  '<p>&nbsp;</p>'+
			'</div>'+
		  '</li>'
		);
	}
	showPageItems($('#paneltest'+currentGame+' .meeting-questions'), MEETINGQUESTION_PAGESIZE);
	var firstAnswer = parseInt(Math.random()*MEETINGQUESTION_PAGESIZE),
	options = [];
	for(var i=0; i<meetingAnswers.length; i++) if(i != firstAnswer) options.push(i);
	options.sort(function(){ return Math.random() - 0.5; });
	options.unshift(firstAnswer);
	for(var i=0; i<options.length; i++) {
		var ix = options[i];
		var text = (meetingAnswers[ix].trans?meetingAnswers[ix].trans+'___':'___, ')+meetingAnswers[ix].msg+(meetingAnswers[ix].time?',___':'');
		$('.meeting-messages .group-answer').append(
			'<div onclick="activate(this)" class="answer drag hide answer-'+meetingAnswers[ix].id+'" data-id="'+meetingAnswers[ix].id+'">'+
				'<p>'+text+'</p>'+
			'<span></span></div>'
		);
	}
	showPageItems($('#paneltest'+currentGame+' .group-answer'), MEETINGANSWER_PAGESIZE);
	$(".meeting-messages .answer").draggable({ cancel: ".wrong" }).css('transition', 'all 0');
	$(".meeting-questions li").droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
	$(".meeting-messages").droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
	$(".meeting-questions").droppable({drop: drop, hoverClass: 'over', greedy: true, tolerance: 'pointer'});
	showLives(3);
	showPanel('paneltest'+currentGame);
}

function initGameMeeting(){
	currentMode = 'learn';
  // We have already set gameLevel when start newGame.
  // DO NOT overwrite it here.
	///// gameLevel = getGameLevel(currentGame);
	levelSettings = levelMap[currentGame][gameLevel];
	totalNeeds = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1];
	totalSpeakers = levelSettings[6];
	star2 = levelSettings[7];
	star3 = levelSettings[8];

	faceSet = [];
	getFace('AmeriaN', 'USA', totalSpeakers);
  
	meetingSpeakers = faceSet;
	for(var i=0; i<totalSpeakers;i++){
		meetingSpeakers[i].id = i+1;
		//meetingSpeakers[i].title = 'Project officer';
	}

	if(gameLevel < 2) {
		initElevatorMeeting();
	} else if(gameLevel < 5) {
		initSaleMeeting();
	} else if(gameLevel < 8) {
		initQAMeeting();
	} else {
		initProjectMeeting();
	}

	totalItems = meetingMsgs.length; //meetingAnswers.length;
	pageSize = totalItems;
	totalPages = 1;
	itemTime = levelSettings[2];
	learnRemainTime = 0;
	penaltyTime =0;
	pageTime = 0;
	pageTimes = [];
	gameTime = itemTime * totalItems;

	if(missionParams) {
    itemTime = 1000000000;
    $('.pagination').addClass('hide'); 
  } else { $('.pagination').removeClass('hide'); }

  $('.meeting-start-grid').html('');
	for(var i=0; i<faceSet.length; i++){
		$('.meeting-start-grid').append(
				'<li class="preview'+(i+1)+'">'+
				  '<figure>'+
					'<div><img src="faces/'+faceSet[i].img+'.png" alt="img04"></div>'+
					'<figcaption>'+
					  '<h3>'+faceSet[i].name+'</h3>'+
					  //'<h4>'+faceSet[i].title+'</h4>'+
					'</figcaption>'+
				  '</figure>'+
				'</li>');
	}
	$('#panelmeetingpeople .slide1').show();
	$('#panelmeetingpeople .slide2').hide();
	showPanel('panelmeetingpeople');
	$('#panelmeetingpeople .meeting-start-holder').show();
	setTimeout(function(){
		$('#panelmeetingpeople').find('.preview1, .preview2, .preview3, .preview4, .preview5, .preview6, .preview7, .preview8, .preview9').addClass('preview');
	}, 500);
}

function showMeetingMessage(){
	$('#panelmeeting .meeting-game').hide();
	showPanel('panelmeeting');
	showMasterNotice();
	setTimeout(function(){
		$('#panelmeeting .meeting-game').show();
		$('.chat-holder').html('');
		$('.chat-holder').css({
			  '-webkit-transform':'translateY('+1000+'px)',
			  'transform':'translateY('+1000+'px)'
		});
		page = -1;
		learnRemainTime = 0;

		nextMeetingMessage();
		showPages();
		setTimeout(updateClockLearn2, 1000);
	},4000);
}

function initElevatorMeeting(){
	meetingSpeakersU = []; disPointsU = [];
	disTopic = randomItem(disElevatorTopics);
	var speaker1 = randomItemU(meetingSpeakersU, meetingSpeakers);
	meetingMsgs = [{speaker: speaker1, msg: disElevatorIntros[disTopic['en']][lang], type: 'intro'}];
	meetingAnswers = [];
	for(var needCount =0; needCount<totalNeeds; needCount++) {
		var point = randomItemU(disPointsU, disElevatorPoints[disTopic['en']][lang]),
		speaker = randomItemU(meetingSpeakersU, meetingSpeakers);
		meetingMsgs.push({speaker: speaker, msg : point});
		meetingAnswers.push({speaker: speaker, msg : point});
	}
	$('.meeting-people-title').html(getMSG('game_meeting_memory_01').replace('[game]', disTopic[lang]));
}

function initSaleMeeting(){
	meetingSpeakersU = []; disNeedsU = [];
	var totalComments = levelSettings[4], speaker1 = randomItemU(meetingSpeakersU, meetingSpeakers);
	disProduct = randomItem(disSaleProducts['en']);
	meetingMsgs = [{speaker: speaker1, msg: randomItem(disSaleIntros[lang]), type: 'intro'}, {speaker: speaker0, msg: randomItem(disSellerIntros[lang]), type: 'intro'}, {speaker: speaker1, msg: randomItem(disTrans1[lang]), type: 'intro'}];
	meetingAnswers = [];
	for(var needCount =0; needCount<totalNeeds; needCount++) {
		var need = randomItemU(disNeedsU, disNeeds[disProduct][lang], disNeedComments[disProduct][lang], disAnswerNeeds[disProduct][lang]),
		speaker = randomItemU(meetingSpeakersU, meetingSpeakers);
		meetingMsgs.push({speaker: speaker, msg : randomItem(disNeedIntros[lang]) + ' ' + need[0]});
		meetingAnswers.push({speaker: speaker, msg : need[2], trans: randomItem(disAnswerTrans[lang])});
		if(needCount < totalComments) {
			speaker1 = randomItemU(meetingSpeakersU, meetingSpeakers);
			meetingMsgs.push({speaker: speaker1, msg : need[1]});
			meetingAnswers.push({speaker: speaker1, msg : randomItem(disAnswerTrans[lang]) + ' ' + need[1], trans: randomItem(disAnswerTrans[lang])});
		}
	}
	$('.meeting-people-title').html(getMSG('msg-game_meeting_memory_02').replace('[game]', disProduct));
}

function initQAMeeting(){
	meetingSpeakersU = [];
	disProduct = randomItem(disQAAreas);
	meetingMsgs = [{speaker: speaker0, msg: randomItem(disQAIntros[lang]), type: 'intro'}, {speaker: speaker0, msg: randomItem(disQAQues1[lang]), type: 'intro'}];
	meetingAnswers = [];
	for(var needCount =0; needCount<totalNeeds; needCount++) {
		var topic = randomItemU(disQAQuestionsU[disProduct], disQAQuestions[disProduct][lang], disQAAnswers[disProduct][lang],[]);
		var speaker = randomItemU(meetingSpeakersU, meetingSpeakers);
		meetingMsgs.push({speaker: speaker, msg : topic[0]});
		if(needCount<(totalNeeds-1)) {
			meetingMsgs.push({speaker: speaker0, msg : randomItem(disQAAfterQuestionComments[lang]) + ' ' +randomItem(disQAPreQuestionComments[lang]), type:'intro'});
		}
		meetingAnswers.push({speaker: speaker, msg : topic[1][0].toLowerCase()+topic[1].substr(1,topic[1].length)});
	}
	$('.meeting-people-title').html(getMSG('msg-game_meeting_memory_03').replace('[game]', disProduct));
}

function initProjectMeeting(){
	meetingSpeakersU = [];
	disProduct = randomItem(disProjects);
	meetingMsgs = [{speaker: speaker0, msg: randomItem(disProjectIntros[lang]), type: 'intro'}];
	meetingAnswers = [];
	for(var needCount =0; needCount<totalNeeds; needCount++) {
		var task = randomItemU(disTasksU[disProduct], disTasks[disProduct][lang]);
		meetingMsgs.push({speaker: speaker0, msg : (needCount ==0 ? randomItem(disTaskIntros1[lang]) : randomItem(disTaskComments[lang])+ ' ' + randomItem(disTaskIntros[lang])) + ' ' + task+ '. ' +randomItem(disWhoQuestions[lang]), type:'intro'});
		var speaker = randomItemU(meetingSpeakersU, meetingSpeakers);
		var time = parseInt(Math.random()*9)+1;
		meetingMsgs.push({speaker: speaker, msg : randomItem(disTaskAccepts[lang])});
		meetingMsgs.push({speaker: speaker0, msg : randomItem(disTimeQuestions[lang]), type:'intro'});
		meetingMsgs.push({speaker: speaker, msg : time + ' ' + disProjectUnits[disProduct][lang]});
		meetingAnswers.push({speaker: speaker, msg : task, time: time+ ' ' + disProjectUnits[disProduct][lang]});
	}
	$('.meeting-people-title').html(getMSG('msg-game_meeting_memory_04').replace('[game]', disProduct));
}

function verifyMeeting(e, ui) {
	var target, dragItem;
	if(e.type == 'drop') {
		target = $(e.target);
		dragItem = ui.draggable;
		itemId = dragItem.data('id');
		//dragItem.css('transition', 'all 0');
		//setTimeout(function(){ dragItem.css('transition', 'all 400ms'); },500);
	} else {
		target = $(e.currentTarget);
		dragItem = $('#paneltest'+currentGame+' .dock-items .drag.active');
		itemId = dragItem.data('id');
	}
	if(itemId == null || target==null || target.data('id') == null) {
		dragEnd(dragItem);
	} else if(itemId == target.data('id')){
		playSoundAction('music-correct');
		target.addClass('correct')
		target.find('.messages').css('border','2px solid lightgreen');
		var textbox = target.find('.messages');
		textbox.html('<p>'+(meetingAnswers[itemId].trans?meetingAnswers[itemId].trans:'')+' <span>Choose the right person</span>'+(meetingAnswers[itemId].trans?'':', ')+meetingAnswers[itemId].msg+'</p>');
		textbox.css({
			'-webkit-transform':'translateX('+(itemId%2?'-':'')+(isMobile? 160: $('.avatar-list').outerWidth())+'px)',
			'transform':'translateX('+(itemId%2?'-':'')+(isMobile? 160: $('.avatar-list').outerWidth())+'px)'
		  });
		dragItem.remove();
		showPageItems($('#paneltest'+currentGame+' .group-answer'), MEETINGANSWER_PAGESIZE);
		testState = 'more';
	} else {
		playSoundAction('music-wrong');
		target.addClass('wrong');
		target.find('.messages').css('border','2px solid red');;
		dragItem.addClass('wrong');
		dragItem.find('span').text(5);
		dragEnd(dragItem);
		checkGameOver();
	}
}

function verifyMeetingSpeaker(e) {
	if(e.stopPropagation) e.stopPropagation();
	if(e.preventDefault) e.preventDefault();
	if($(e.currentTarget).parent().hasClass('wrong')) return;
	itemId = $(e.currentTarget).parent().parent().data('id');
	if($(e.currentTarget).hasClass('speaker')) {
		playSoundAction('music-correct');
		$(e.currentTarget).addClass('correct');
		$(e.currentTarget).parent().hide();
		var textbox = $(e.currentTarget).parent().parent().find('.messages');
		textbox.find('span').text($(e.currentTarget).parent().data('name'));
		$('.correct-avatar-'+itemId).show();
		if(levelSettings[5]) {
		  $(e.currentTarget).parent().parent().find('.time-options').show();
		  $(e.currentTarget).parent().parent().find('.avatar-list').hide();
			textbox.css({
				'-webkit-transform':'translateX('+(itemId%2?'':'-')+'220px)',
				'transform':'translateX('+(itemId%2?'':'-')+'220px)'
			  });
		} else {
			textbox.css({
				'-webkit-transform':'translateX(0)',
				'transform':'translateX(0)'
			  });
			meetingAnswers[itemId].correct = true;
			if(meetingAnswers.filter(function(item){ return item.correct; }).length == totalNeeds) {
				showResult(meetingAnswers.length, meetingAnswers.length);
			}
			testState = 'done';
		}
	} else {
		playSoundAction('music-wrong');
		$(e.currentTarget).parent().addClass('wrong');
		$(e.currentTarget).parent().find('span').text(5);
		$(e.currentTarget).addClass('wrong');
		checkGameOver();
	}
	return false;
}

function verifyMeetingTime(e) {
	if(e.stopPropagation) e.stopPropagation();
	if(e.preventDefault) e.preventDefault();
	var time = $(e.currentTarget).parent().parent().data('time');
	if($(e.currentTarget).text() == $(e.currentTarget).parent().data('time')[0]) {
		playSoundAction('music-correct');
		$(e.currentTarget).addClass('correct');
		var textbox = $(e.currentTarget).parent().parent().find('.messages');
		textbox.text(textbox.text()+', '+time);
		textbox.css({
			'-webkit-transform':'translateX(0)',
			'transform':'translateX(0)'
		  });
		meetingAnswers[itemId].correct = true;
		if(meetingAnswers.filter(function(item){ return item.correct; }).length == totalNeeds) {
			showResult(meetingAnswers.length, meetingAnswers.length);
		}
		testState = 'done';
	} else {
		playSoundAction('music-wrong');
		$(e.currentTarget).addClass('wrong');
		checkGameOver();
	}
	return false;
}

function nextTestMeeting(_index) {
	index = _index;
	var options = [];
	for(var i=0; i<4; i++) {
		options.push(meetingMsgs[index+i]);
	}
	options.push(meetingMsgs[index+3+parseInt(Math.random()*(meetingMsgs.length-index-4))]);
	options.push(meetingMsgs[index+3+parseInt(Math.random()*(meetingMsgs.length-index-4))]);
	options.sort(function(){ return Math.random()-0.5; });
	$('.meeting-messages').html('');
	for(var i=0; i<6; i++) {
		$('.meeting-messages').append(
			'<div class="answer drag">'+
				options[i].msg+
			'</div>'
		);
	}
}

function nextMeetingMessage(button) {
	playSoundAction('music-meeting-action');
	if($(button).hasClass('inactive')) return;
	page += 1;
	if(page >= totalItems) {
		startTesting();
	} else {
  	learnRemainTime += pageTime>0?pageTime:0;
		$('.chat-holder').append(
			'<li>'+
				'<div class="avatar">'+
				  '<div class="img-holder">'+
					'<img src="faces/'+meetingMsgs[page].speaker.img+'.png">'+
				  '</div>'+
				  '<b class="name">'+meetingMsgs[page].speaker.name+'</b>'+

				  //'<div class="title">'+meetingMsgs[page].speaker.title+'</div>'+
				'</div>'+
				'<div class="messages '+(meetingMsgs[page].type=='intro'?'lightblue':'')+'">'+
				  '<p>'+meetingMsgs[page].msg+'</p>'+
				'</div>'+
			  '</li>'
		);

		var chatHeight =  $('.meeting-game .game-button')[0].offsetTop - 100 - $('.chat-holder').height();
		$('.chat-holder').css({
			  '-webkit-transform':'translateY('+chatHeight+'px)',
			  'transform':'translateY('+chatHeight+'px)'
		});
		$('.chat-holder li:last-child').addClass('animate');

		for(var j = 1; j <= (page+1); j++){
			$('.chat-holder li:nth-child('+j+') .messages').css({
				'opacity':j/(page+1)
			});
		}
		showPages();
	}
}
