Array.prototype.count = function() { return this.length }

Array.prototype.group_by = function(t){var r,n,i,e;for(e=new Object,n=0,i=this.length;i>n;n++){if(r=this[n],"undefined"==typeof r[t]) continue;e[r[t]]instanceof Array?e[r[t]].push(r):e[r[t]]=[r]}return e}

window.current_mission = {
  'mission': getMission(window.gamestate.data.current_mission),
  'remaining_steps': remaining_steps(getMission(window.gamestate.data.current_mission))
};

rivets.formatters['check_done'] = function(ignoreme, mission, step_index) {
  var step = mission.steps[step_index];
  return is_mission_step_done(mission.id, step);
}

rivets.formatters['step_is_done'] = function(step, mission_name) {
  return is_mission_step_done(mission_name, step);
}


function is_mission_step_done(mission_name, step_name) {
  "use strict";
  return (window.gamestate.data.done_mission_steps.indexOf(""+mission_name + ":" + step_name) > -1);
}

function check_mission_done(mission) {
  var steps = [];
  if (mission.sub_missions !== undefined) {
    for (var i=0; i<mission.sub_missions.length; i++) {
      steps = steps.concat(mission.sub_missions[i].steps);
    }
  } else {
    steps = mission.steps;
  }
  for(var i =0; i<steps.length; i++) {
    if(is_mission_step_done(mission.id, steps[i]) === false){
      return false;
    }
  }
  return true;  
}

rivets.formatters['show_start_button'] = function(ignoreme, mission) {
  return !is_mission_started(mission) && !check_mission_done(mission);
}

rivets.formatters['check_mission_done'] = function(ignoreme, mission) {
  return is_mission_started(mission) && check_mission_done(mission);
}

function is_mission_started(mission) {
  return window.gamestate.data.missionStarted[mission.id] === true;
}

rivets.formatters['check_mission_started'] = function(ignoreme, mission) {
  return is_mission_started(mission);
}

rivets.formatters['check_mission_length'] = function(ignoreme, mission, step) {
  return mission.steps.length >= step;
}

rivets.formatters['is_locked'] = function(ignoreme, mission_id) {
  if (typeof(mission_id) === 'object' && mission_id.sub_missions !== undefined) {
      mission_id = mission_id.sub_missions[0].id;
    }
  return mutils.is_locked(mission_id);
}

rivets.formatters['stringFormat'] = function(target) {
  for(var i = 1; i < arguments.length; i++) {
    var offset = target.indexOf('%s')
    if(offset === -1)
      break;
    target = target.slice(0, offset) + arguments[i] + target.slice(offset + 2)
  }
  return target
}

rivets.formatters['oneindex'] = function(idx) {
  return idx+1;
}

 function mission_completed(mission) {
  for(var i =0; i<mission.steps.length; i++) {
    if(is_mission_step_done(mission.id, mission.steps[i]) === false ){
      return false;
    }
  }
  return true;
}

rivets.formatters['mission_completed'] = mission_completed;

function rivetsToggleSteps(e,v) {
  $(e.target).closest('.footer').toggleClass('active');
  $(e.target).closest('.footer').prev().toggleClass('open');
}

function rivetsToggleDescription(e,v) {
  $(e.target).siblings('.description').toggle();
}

function rivetsLoadMission(e,v) {
  loadMission(v.m.id || v.m.self.id);
}

function getMission(id) {
  var m = missions.findById(id) || master_sub_missions.findById(id);
  return m;
}


function current_mission_step_name() {
  if (typeof(window.current_mission.mission) !== 'undefined') {
    if (typeof(window.current_mission.step) !== 'undefined') {
      return window.current_mission.mission.steps[window.current_mission.step];
    }
  }
  return "";
}

function finished_mission_step(step_name) {
  var mission_name = window.current_mission.mission.id;
  console.log("finished_mission_step() for "+mission_name+" with args", step_name);
  if (!is_mission_step_done(mission_name, step_name)) {
    console.log("setting step as done:", step_name)

    var upd = {'done_mission_steps': window.gamestate.data.done_mission_steps};
    upd.done_mission_steps.push(""+mission_name+":"+step_name);
    window.gamestate_utils.set(upd);

    window.current_mission.remaining_steps = remaining_steps(window.current_mission.mission);
    
    /* Make sure sidebar counter is decreased, rivets bindings are messed up somehow */
    $('.sidebar span.no, .mobile-header .missions .no').text(window.current_mission.remaining_steps);
  }
}

function loadMission(id) {
  if (window.globalhack_challengeHook && window.globalhack_challengeHook()) {
    //showPanel('missions-overview-panel');
    return;
  }

  if (id === undefined) {
    id = window.current_mission.mission.master_mission || window.current_mission.mission.id;
  }
  
  showPanel('mission-panel');
  activate_mission_in_menu();

  var current_mission = getMission(id);
  
  var tmp_missions = [];

  var completed_total = 0;

  var is_master_mission = false;

  // we'll handle everything as an array (as a pseudo-master-mission) so
  // we can use the same template for master and normal missions:
  //
  if (current_mission) {
    tmp_missions.push(current_mission);
  } else {
    current_mission = master_missions.filter(function(m) { return m.id === id })[0]
    is_master_mission = true;
    tmp_missions = master_sub_missions.filter(function(m) {
      return m['master_mission'] === id
    });
  }

  var current_mission_steps = [];

  var total_steps = [];
  var total_completed_steps = 0;

  tmp_missions.forEach(function(m) {
    m['completed_steps'] = 0;
    m['full_steps'] = [];
    m['badge']  = m.badge || current_mission['badge']
    m['banner'] = m.banner || current_mission['banner']

    m.steps.forEach(function(s) {
      // make a copy of the step object
      var step = jQuery.extend(true, {}, mission_steps[s]);
      
      // change the description if we find a override
      var override = mission_steps_descriptions[''+id+'::'+s];
      if ( override !== undefined) {
        step.name = override.name;
        step.description = override.description;
      }

      total_steps.push(step);
      m['full_steps'].push(step);

      if (is_mission_step_done(id, s)) {
        m['completed_steps']  += 1;
        total_completed_steps += 1;
      }
    });
  });


  var completed_percent = total_completed_steps * 100 / total_steps.length;

  /* TODO: perhaps use rivets components instead of reloading the DOM */

  $('#mission-panel').html($('#mission-panel-template').html());


  rivets.bind($('#mission-panel'), {
    mission:       current_mission,
    missions:      tmp_missions,
    is_master_mission: is_master_mission,
    access_token:  window.accessToken,
    total_completed_steps: total_completed_steps,
    completed_percent: completed_percent,
    
    toggleSteps: rivetsToggleSteps,
    toggleDescription: rivetsToggleDescription,
    startMissionStep: rivetsStartMissionStep
  });

  /* Open first mission */
  $('#mission-panel .box:not(.completed)').first().find('.footer').click();
  /* Open first mission step */
  $('#mission-panel .box:not(.completed)').first().find('.body ul li:not(.done)').first().find('p.clickme').click();

  /* Lock starting of mission steps not in order */
  $('#mission-panel .box ul li:not(.done)').first().nextAll().find('.button').remove();

  if (current_mission.benefits !== undefined) {
    $('.mission-individual .benefits-title').html(current_mission.benefits[lang].title);
    for (i = 0; i < current_mission.benefits[lang].list.length; i++) {
      var li = $('<li>' + current_mission.benefits[lang].list[i] + '</li>');
      $('.mission-individual ul.benefits-list').append(li);
    }
  }
  /*

        if (step.benefits !== undefined) {
        step.benefits.title = step.benefits[lang].title;
        step.benefits.list = step.benefits[lang].list;
      }
  */
  translate();
}

function startupMissions() {
  /* GET: /mission/<mission name>/<step> */
  console.log("starting missions");
  var path = window.location.pathname.split("/");
  var mission_name = window.gamestate.data.current_mission || path[2];
  var current_step_index = +path[3];

  startMissionStep(mission_name, current_step_index);
}

function startMissionStep(id, step_index) {
  var mission = getMission(id);
  console.log("setting current mission to "+id);
  window.gamestate.data.missionStarted[id] = true;
  window.gamestate_utils.set({'missionStarted': window.gamestate.data.missionStarted, 'current_mission': id});

  /* Lets load us a mission boys! */
  window.current_mission['mission'] = mission;
  window.current_mission['step'] = step_index;
  window.current_mission['remaining_steps'] = remaining_steps(mission);

  var step_name = mission.steps[step_index]
  var step = mission_steps[step_name]

  var fn = eval(step.load)
  missionParams = step.load_args;
  missionParams.push(step.params || {});
  fn.apply(null, missionParams);

  bind_sidebar();
}

function rivetsStartMissionStep(e, v) {
  var mission_name = v.m.id;
  var step_index = v.index;
  startMissionStep(mission_name, step_index);
}

function activate_mission_in_menu() {
  $('.game-menu.middle .button.active').removeClass('active');
  $('.game-menu.middle .button.mission-overview-menu-item').addClass('active');
}

function loadMissionsOverview() {

  /* Reload template */
  $('#missions-overview-panel').html($('#missions-overview-panel-tmpl').html());
  

  activate_mission_in_menu();
  var standalone_missions = missions.filter(function(e) {
    if (!e['master_mission']) {
      e.depth = mutils.depth(e.id);

      return e;
    }
  });

  var tmp_master_missions = []

  var mm_grouped = master_sub_missions.group_by('master_mission');

  for (var mm in mm_grouped) {
    tmp_master_missions.push({
      self: master_missions.filter(function(mmm) {
        return mm_grouped[mm][0]['master_mission'] === mmm.id ;
      })[0],
      sub_missions: mm_grouped[mm]
    });
  }

  // TODO: cleanup this

  var missions_0 = standalone_missions.filter(function(m) {
    if (m.id === "doublememory") return m });

  var missions_1 = standalone_missions.filter(function(m) {
    if (m.id === "basic" || m.id === "multiplymemory") return m });

  var missions_2 = standalone_missions.filter(function(m) {
    if (m.id === "visualization" || m.id === "rockmedium" || m.id === "kickstart") return m });

  var missions_3 = standalone_missions.filter(function(m) {
    if (m.id === "nailabstract" || m.id === "vocabularyguru" || m.id === "alphabetmemory") return m });

  rivets.bind($('#missions-overview-panel'), {
    missions_0:    missions_0,
    missions_1:    missions_1,
    missions_2:    missions_2,
    missions_3:    missions_3,
    other_missions:    other_missions,
    master_missions:   tmp_master_missions,
    loadMission: rivetsLoadMission
  });

    /* add lock icons */
  $('#mission-board .row').each(function() {$(this).find('.mission-container.lock').closest('.row').addClass('lock')})

}


rivets.formatters.mission_step_name = function(step) {
  console.log("Called mission step:", step);
  return mission_steps[step].name;
}

function sub_missions_filter(e) {
  if (window.current_mission.mission.master_mission &&
      e.master_mission === window.current_mission.mission.master_mission &&
      window.current_mission.mission.id !== e.id) {
    return e;
  }
}

function sub_missions() {
  return missions.filter(sub_missions_filter);
}

function remaining_steps(mission) {
  console.log("remaining_steps()");
  if (mission === undefined) {
    return 0;
  }
  if(gamestate.data.missionStarted && !gamestate.data.missionStarted[mission.id]) return 0;
  
  var steps = mission.steps;
  var r_steps = steps.length;
  for (var i = 0; i < steps.length; i++) {
    if (is_mission_step_done(mission.id, steps[i])) {
      r_steps--;
    }
  }
  console.log("remaining steps", r_steps);
  return r_steps;
}

function showMissionOverview() {
  $('.sidebar-content.mission').removeClass('open');
  showPanel('missions-overview-panel');
}

var actions = {
  showMissionOverview: function() {
    $('.sidebar-content.mission').removeClass('open');
    showPanel('missions-overview-panel');
  },
  startupMissions: function(e, v) {
    $('.sidebar-content.mission').removeClass('open');

    var mm = v.data.current_mission.mission['master_mission']

    if (mm)
      loadMission(mm);
    else
      loadMission(v.data.current_mission.mission.id);
  }
}

function build_full_steps() {
  if (window.current_mission.mission && window.current_mission.mission.steps !== undefined) {
    return window.current_mission.mission.steps.map(function(s) {
      var tmp_s = mission_steps[s]
      tmp_s['done'] = is_mission_step_done(window.current_mission.mission.id, s);
      return tmp_s
    });    
  }
  return [];
}

function next_missions() {
  var next = [];
  for (var i = 0; i < missions.length; i++) {
    if (missions[i].id === window.current_mission.mission.id) {
      for (var j = i+1; j < missions.length; j++) {
        next.push(missions[j])
      }
      break;
    }
  }
  var subs = sub_missions();
  for (var i = 0; i < subs.length; i++) {
    var index = next.indexOf(subs[i]);
    if (index > -1) {
      next.splice(index, 1);
    }
  }
  // Return the next 3 missions
  return next.slice(0, 3);
}

function showLockedGameLevels(){  
  var locks = 0;
  for(var g=0; g<gameNames.length; g++) {
    for(var i= userBelt <= 0 ? 1 : beltMap[userBelt][g]+1; i<= beltMap[userBelt+1][g]; i++) {
      if(!starMap[gameNames[g]][i-1] || !starMap[gameNames[g]][i-1].length || starMap[gameNames[g]][i-1][0] < 1) {
        locks += 1;
      }
    }
  }
  return $('.locked_levels').text(locks).show();
}

function bind_sidebar() {
  var full_steps = build_full_steps();
  console.log(full_steps[0].done);

  $('#sidebar').html($('#sidebar-tmpl').html());
  $('#sidebar-content').html($('#sidebar-content-tmpl').html());
  rivets.bind($('.sidebar, .sidebar-content'), {
    data: {
      'current_mission': window.current_mission,
      'sub_missions': sub_missions,
      'full_steps': full_steps,
      'next_missions': next_missions,
      'locked_levels': window.locked_levels
    },
    'actions': actions
  });  
  $('.sidebar span.no, .mobile-header .missions .no').text(window.current_mission.remaining_steps);
}

function async(fn, callback) {
    setTimeout(function() {
        fn();
    }, 0);
}

function cache_dependencies() {
  for (var i=0; i < missions.length; i++) {
    var m = missions[i];
    mutils.acc_dependencies(m.id);
  }
  for (var i=0; i < master_missions.length; i++) {
    var m = master_missions[i];
    mutils.acc_dependencies(m.id);
  }
  for (var i=0; i < other_missions.length; i++) {
    var m = other_missions[i];
    mutils.acc_dependencies(m.id);
  }
}

async(cache_dependencies);

$(document).ready(function() {
  bind_sidebar();
})
