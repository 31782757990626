var mission_steps = {
  'superm0': {
    'name': {
      'en': "Test your memory",
      'no': "Test hukommelsen din"
    },
    'description': {
      'en': "Take this super-fast memory test to see how much you're able remember without a memory system.",
      'no': "Ta denne lyntesten og se hvor mye du klarer å huske uten huskesystem."
    },
    'load': 'newGame',
    'load_args': ['superm0', 0, {}]
  },
  'practice_singlesys': {
    'name': {
      'en': "Ace your first memory system",
      'no': "Lek deg gjennom ditt første huskesystem"
    },
    'description': {
      'en': "Did you ever play with a flash light and your hands to create shadow-shapes on the wall? Well, that's what we're going to do now. Simply imagine what the numbers 0 to 9 look like and afterwards we'll use these shapes to double your memory.",
      'no': "Har du noen gang lekt med lommelykt og hendene dine for å lage skyggedyr på veggen? Det er nettopp det vi skal gjøre nå. Du trenger kun å se for deg hva tallene 0 til 9 ser ut som, og etterpå skal vi bruke disse formene for å doble hukommelsen din."
    },
    'load': 'showSysPractice',
    'load_args': ['singlesys', 0, {}]

  },
  'superm0_0': {
    'name': {
      'en': "Take a new memory test",
      'no': "Ta en ny husketest"
    },
    'description': {
      'en': "Now, let's use the memory system with the number shapes from 0 to 9 as mental memory containers to to remember the products from the shopping list.",
      'no': "Bruk nå huskesystemet med tallformene fra 0 til 9 som mentale lagringsplasser for å huske produktene på handlelisten."
    },
    'load': 'newGame',
    'load_args': ['superm0', 1, {}]
  },
  'intro_article_basics': {
    'name': {
      'en': "Read and learn the basics",
      'no': "Les og lær det grunnleggende"
    },
    'description': {
      'en': "Understand the memory and visualization principles.",
      'no': "Forstå de grunnleggende hukommelses- og visualiseringsprinsippene."
    },
    'load': 'showArticle',
    'load_args': ['basics', {}]
  },
  'sound_visualization1': {
    'name': {
      'en': "Listen, visualize and remember (turn sound on)",
      'no': "Lytt, visualiser og husk (skru på lyd)"
    },
    'description': {
      'en': "Listen to this mp3 file and let yourself be guided into the world where strange, strong and funny memory visualizations enable you to remember better.",
      'no': "Lytt til mp3-filen og bli guidet inn i en verden hvor rare, sterke og morsomme visualiseringer hjelper deg huske bedre."
    },
    'load': 'soundFile',
    'load_args': ['basics', 0, {}]
  },
  'sound_vizualization2': {
    'name': {
      'en': "Listen, visualize and remember - part A",
      'no': "Lytt, visualiser og husk - del A"
    },
    'description': {
      'en': "Listen to this mp3 file and let yourself be guided into the world where strange, strong and funny memory visualizations enable you to remember better.",
      'no': "Lytt til mp3-filen og bli guidet inn i en verden hvor rare, sterke og morsomme visualiseringer hjelper deg huske bedre."
    },
    'load': 'soundFile',
    'load_args': ['visualization1', 0, {}]
  },
  'sound_visualization3': {
    'name': {
      'en': "Listen, visualize and remember - part B",
      'no': "Lytt, visualiser og husk - del B"
    },
    'description': {
      'en': "Listen to this mp3 file and let yourself be guided into the world where strange, strong and funny memory visualizations enable you to remember better.",
      'no': "Lytt til mp3-filen og bli guidet inn i en verden hvor rare, sterke og morsomme visualiseringer hjelper deg huske bedre."
    },
    'load': 'soundFile',
    'load_args': ['visualization2', 0, {}]
  },
  'article_art_of_daydreaming': {
    'name': {
      'en': "The art of daydreaming",
      'no': "Kunsten å dagdrømme"
    },
    'description': {
      'en': "Super-memory is not at all about memorizing, but rather about visualizing in fun, relaxing and organized daydreams.",
      'no': "Super-hukommelse dreier seg ikke om å memorisere, men snarere om å visualisere i morsomme, avslappete og organiserte dagdrømmer."
    },
    'load': 'showArticle',
    'load_args': ['visualization-intro', 0, {}]
  },
  'intro_vizualization_article': {
    'name': {
      'en': "Visualize and reach your goals",
      'no': "Visualiser og nå målene dine"
    },
    'description': {
      'en': "Read this how-to article for visualization and goal achivement.",
      'no': "Les denne slik-gjør-du-det artikkelen om visualisering og måloppnåelse."
    },
    'load': 'showArticle',
    'load_args': ['visualization-intro',0, {}]
  },
  'show_vizualization_y': {
    'name': 'Visualization',
    'load': 'showVisualization',
    'load_args': ['y',0,{}]
  },
  'article_clones': {
    'name': {
      'en': "The clones are coming",
      'no': "Klonene kommer"
    },
    'description': {
      'en': 'Clones are handy for "lazy learning" and help you multiply your memory without much effort. The Clone System adds unique elements to a memory system in order to duplicate its memory codes. This way, you can use the same memory codes with a different appearance - almost like the same person, but with different clothes.',
      'no': 'Kloner er praktiske for "lat læring" og hjelper deg mangedoble hukommelsen din uten særlig innsats. Klonesystemet tilfører unike elementer til et huskesystem for å duplisere huskekodene. På den måten kan du bruke de samme huskekodene med et annet utseende - nesten som den samme personen, men med forskjellige klær og antrekk.'
    },
    'load': 'showArticle',
    'load_args': ['clone',0,{}]
  },
  'intro_multiple_memory_article': {
    'name': {
      'en': "Repeat the Memolife Single System",
      'no': "Repeter Memolife Singelsystemet"
    },
    'description': {
      'en': "Repeat and practise the memory codes of the Memolife Single System. It's import you know them well, so that you can learn to multiply them in the next mission step.",
      'no': "Repeter og øv deg på huskekodene i Memolife Singelsystemet. Det er viktig at du mestrer disse, slik at du kan mangedoble dem i det neste steget."
    },
    'load': 'showArticle',
    'load_args': ['x',0,{}]
  },
  'practice_superm_0_30_items': {
    'name': {
      'en': "Memorize a shopping list with 30 products",
      'no': "Husk en handleliste med 30 produkter"
    },
    'description': {
      'en': "Combine the Memolife Single and Clone systems to memorize 30 products of a shopping list.",
      'no': "Kombinere Memolife Singel- og Klonesystemet for å memorisere 30 produkter fra en handleliste."
    },
    'load': 'newGame',
    'load_args': ['superm', 0, {totalItems: 30}]
  },
  'intro_article_medium_numbers':{
    'name': {
      'en': ""
    },
    'load': 'showArticle',
    'load_args': ['x',0,{}]
  },
  'practice_bankv_20_items': {
    'name': {
      'en': "Combine the systems to memorize 20 digits",
      'no': "Kombiner systemene og husk 20 sifre"
    },
    'description': {
      'en': "<p>Combine the Journey System with the Memolife Single System to ace a practice session of the Bank Vault game.</p>"+
            "<p>You'll be presented with a number series of 2 digits at the time, so simply visualize two memory codes from the Memolife Single System together and place them in your first journey step. For the next two digits, combine another two codes from the Memolife Single System and place them in the next journey step, and so on.</p>"+
            "<p>Afterwards, just go through your journey from beginning to the end to recall the correct number sequence. Later you'll use the Memolife Double System for this, which is twice as fast, but for now this is a good start.</p>",
      'no': "<p>Kombiner Reisesystemet med Memolife Singelsystemet for å rocke en øvelsesøkt med Bankhvelvet.</p>"+
            "<p>Du kommer til å få en tallserie med 2 sifre av gangen, så visualiser disse med huskekodene fra Memolife Singelsystemet og plasser dem i det første steget i Reisesystemet du akkurat har lært. For de neste 2 sifrene, kombiner de neste 2 huskekodene fra Memolife Singlesystemet og plasser dem i det neste reisesteget, osv.</p>"+
            "<p>For å huske alle sifrene i riktig rekkefølge etterpå trenger du kun å gå gjennom reisesystemet og gjenta hva du har visualisert i hvert steg. Senere kommer du til å bruke Memolife Dobbelsystemet (dobbelt så hurtig) for dette, men for nå er dette en god start.</p>",
    },
    'load': 'newGame',
    'load_args': ['bankv', 0, {totalItems: 20}]
  },
  'intro_article_name_memory': {
    'name': {
      'en': "Why we don't remember names",
      'no': "Hvorfor vi ikke husker navn"
    },
    'description': {
      'en': "Read and understand what makes our brains almost incapable of remembering names - and what you can easily do to compensate for this.",
      'no': "Les og forstå hva som gjør at hjernen vår praktisk talt å ikke klarer å huske navn - og hva som skal til for å endre det."
    },
    'load': 'showArticle',
    'load_args': ['names-and-faces',0,{}]
  },
  'show_technique_namesys_10_male': {
    'name': {
      'en': "Learn your first memory codes for male names",
      'no': "Lær dine første huskekoder for guttenavn"
    },
    'description': {
      'en': "Explore the name memory system and learn your 10 first memory codes for male names.",
      'no': "Utforsk navnesystemet og lær de første 10 huskekodene for guttenavn."
    },
    'load': 'showSysPractice',
    'load_args': ['namesys', null, {male: 10, country: 'Norway', page: 0}]
  },
  'show_technique_namesys_10_female': {
    'name': {
      'en': "Learn your first memory codes for female names",
      'no': "Lær dine første huskekoder for jentenavn"
    },
    'description': {
      'en': "Explore the name memory system and learn your first 10 memory codes for female names.",
      'no': "Utforsk navnesystemet og lær de første 10 huskekodene for jentenavn."
    },
    'load': 'showSysPractice',
    'load_args': ['namesys', null, {female: 10, country: 'Norway', page: 0}]
  },
  'show_technique_namesys_10_surname': {
    'name': {
      'en': "Learn your first memory codes for surnames",
      'no': "Lær dine første huskekoder for etternavn"
    },
    'description': {
      'en': "Explore the name memory system and learn your 10 first memory codes for surnames.",
      'no': "Utforsk navnesystemet og lær de første 10 huskekodene for etternavn."
    },
    'load': 'showSysPractice',
    'load_args': ['namesys', null, {surname: 10, country: 'Norway', page: 0}]
  },
  'newgame_face_10_female_10_male': {
    'name': {
      'en': "Your first steps to becoming a Social Star",
      'no': "Dine første steg til å bli en navnemester"
    },
    'description': {
      'en': "You're about to meet a group of people who have the same names as the memory codes you've just learnt. In order to remember their names, all you have to do is use these memory codes and associate them with the dominant facial features - just like you read in the article from step one. Have you got what it takes to remember all the 10 names?",
      'no': "Du er i ferd med å møte en gruppe mennesker som har de samme navnene som huskekodene du akkurat har lært. Alt du må gjøre for å huske navnene deres er å bruke disse huskekodene og assosiere dem med de mest dominerende ansiktstrekkene - akkurat slik du leste om i artikkelen fra steg en. Har du det som skal til for å huske navnene på alle 10 personene?"
    },
    'load': 'newGame',
     'load_args': ['face', 0],
     'params': { totalItems: 10, min: 11, max: 20}
  },
  'show_technique_rhymesys': {
    'name': {
      'en': "The Rhyme System - memorizing with rhymes",
      'no': "Rimsystemet - husk med rim"
    },
    'description': {
      'en': "In contrast to the Memolife Single System, where the memory codes have the shape of the numbers, the Rhyme System has memory codes that rhyme with the numbers. Oh, and the Rhyme System also has 20 memory codes, so it's twice as powerful. Learn and practise them well, so that you can use the system in step 3.",
      'no': "I motsetning til Memolife Singelsystemet, hvor huskekodene er ord med fasonger som ser ut som tallene, har Rimsystemet huskekoder som rimer på tallene. I tillegg har Rimsystemet 20 huskekoder, så det er dobbelt så kraftfullt. Lær kodene godt, slik at du kan bruke dem i steg 3."
    },
    'load': 'showSysPractice',
    'load_args': ['rhymesys', 0, {}]
  },
  'newgame_library_0': {
    'name': {
      'en': "Abstract brainstorming-memory",
      'no': "Repeter Memolife Singelsystemet"
    },
    'description': {
      'en': "Your task is to remember 15 abstract words in the correct sequence, using the Symbolization and Rhyme Systems. Are you up for the challenge?",
      'no': "Oppgaven din er å huske 15 abstrakte ord i riktig rekkefølge, ved å bruke Symbolisering- og Rimsystemene. Er du klar for utfordringen?"
    },
    'load': 'newGame',
    'load_args': ['library', 0],
    'params': {totalItems: 15},
  },
  'intro_vocab_guru': {
    'name': 'Introduction',
    'load': 'showArticle',
    'load_args': ['x',0,{}],
    'description': {
      "en": "",
      "no": ""
    }
  },
  'article_journeysys': {
    'name': {
      'en': "Creating journeys from your everyday surroundings",
      'no': "Slik lager du mentale reiser fra omgivelsene dine"
    },
    'description': {
      'en': "Read and understand the beauty of the Journey System - often called Roman Rooms or Memory Palaces - and how you can visualize new memories in locations you already know.",
      'no': "Les artikkelen og forstå det genialt enkle med Reisesystemer - ofte kalt Romerske Rom eller Huskepalasser - og hvordan du enkelt kan visualisere og lagre nye minner i steder du allerede kjenner godt."
    },
    'load': 'showArticle',
    'load_args': ['journeysys',0,{}]
  },
  'article_language_similiarity': {
    'name': {
      'en': "Hey, that reminds me of ... find the similarity",
      'no': "Hei, det minner meg om ... finn likheten"
    },
    'description': {
      'en': "The most efficient method for speed-learning vocabularies is the Similarity System. All you have to do is ask yourself what the word looks like (how it is written) or what it sounds like in your own language. Then you use that as a memory code in a funny memory fantasy which links the code up with the meaning in your language. All right, this sounds more complicated than it really is, so let's have a look at some examples in this article right away.",
      'no': "Den beste metoden for å lære gloser lynraskt er Likhetssystemet. Alt du må gjøre er å spørre deg selv hva ordet ser ut som (hvordan det skrives) eller hva det høres ut som på ditt eget språk. Deretter bruker du dette som en huskekode og lager en eller annen rar huskefantasi som knytter koden opp mot betydningen på språket ditt. Ok, dette høres mer komplisert ut enn det det egentlig er, så la oss ta en titt på noen eksempler i denne artikkelen med en gang."
    },
    'load': 'showArticle',
    'load_args': ['language-similiarity',0,{}]
  },
  'newgame_vocab_level_0_totalitems_10':{
    'name': {
      'en': "Your first steps towards becoming a vocabulary guru",
      'no': "Dine første steg på ferden til bli en glosemester"
    },
    'description': {
      'en': "All right, now it's time to jump right into it and practise how to become a true vocabulary guru. In this practice session we'll start with 10 words from the Clockwork Orange movie language Nadsat, but later on in the game you'll also learn Elvish, Klingon and Navi words.",
      'no': "Ok, nå er det på tide å hoppe rett i det og øve seg på å bli en glosemester. I denne økten starter vi med 10 ord fra Clockwork Orange filmens språk Nadsat, men i spillet Gloseguru kommer du senere også til å øve deg på alvespråket, klingon og navi."
    },
    'load': 'newGame',
    'load_args': ['vocab', 0],
    'params': {totalItems: 10},
  },
  'article_memolife_system': {
    'name': {
      'en': "Explore The Memolife Double System",
      'no': "Utforsk Memolife Dobbelsystemet"
    },
    'description': {
      'en': "Learn the principles of the Memolife Double system - explore the examples and how you can use the system to improve your brain and get a better everyday life.",
      'no': "Lær prinsippene i Memolife Dobbelsystemet - utforsk eksemplene og hvordan du kan bruke systemet til å få en bedre hjerne og bedre hverdag."
    },
    'load': 'showArticle',
    'load_args': ['Memolifesys-Double',0,{}]
  },
  'intro_memogenius2': {
    'name': 'Introduction',
    'load': 'showArticle',
    'load_args': ['x',0,{}],
    'description': {
      "en": "",
      "no": ""
    }
  },
  'show_technique_journeysys': {
    'name': {
      'en': "Create A Journey System",
      'no': "Lag et Reisesystem"
    },
    'description': {
      'en': "Choose a series of locations or a journey that you've experienced - and organize them into a new Journey System with 10 steps.",
      'no': "Velg en rekke med forskjellige steder eller en reise som du har vært på - og organiser dem i et nytt Reisesystem med 10 steg."
    },
    'load': 'showSysPractice',
    'load_args': ['journeysys',0,{}]
  },
  'show_technique_journeysys_repeat_3': {
    'name': {
      'en': "Expand Your Memory With Mental Journeys",
      'no': "Utvid hukommelsen med mentale reiser"
    },
    'description': {
      'en': "Going down memory lane is often a good thing, so feel free to repeat 3 of the Journey Systems you've already created. You'll need them in a moment when you'll memorize the longest number you've done so far.",
      'no': "Å reise tilbake i tid med noen gode minner er ofte en god opplevelse, så ta deg gjerne tid til å repetere 3 av Reisesystemene på nytt. Du kommer til å trenge dem snart når du får som oppgave å memorisere det lengste tallet du har gjort så langt."
    },
    'load': 'showSysPractice',
    'load_args': ['journeysys',0,{}]
  },
  'show_sys_double_practice_0_9': {
    'name': {
      'en': "Rock Long Numbers",
      'no': "Lek deg gjennom store tall"
    },
    'description': {
      'en': "<p>Combine the Memolife Double System and the Journey System to remember a 40 digit number. All you need to do is take the Person for the first two digits, the Action from the next two digits and the Object from the final two digits and place them in a journey step.</p>"+
            "<p>Thereafter, simply repeat the process until you're done with all the digits. You'll be fine with a journey system with 6-7 journey steps, depending on how much you want to visualize in your last journey step.</p>",
      'no': "<p>Kombiner Memolife Dobbelsystemet og Reisesystemet for å memorisere et tall på 40 sifre. Alt du trenger å gjøre er å bruke Personen for de to første sifrene, Handlingen for de neste to sifrene og Objektet fra de siste to sifrene og plassere dem i et reisesteg.</p>"+
            "<p>Etterpå er det bare å gjenta prosessen til du er ferdig med alle sifrene. Du kommer til å klare deg helt fint med et reisesystem som inneholder 6-7 reisesteg, avhengig av hvor mye du vil visualisere på det siste reisesteget.</p>"
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:0, max:9 },
  },
  'newgame_bankv_2_40_0_9': {
    'name': {
      'en': "Rock Long Numbers",
      'no': "Lek deg gjennom store tall"
    },
    'description': {
      'en': "<p>Combine the Memolife Double System and the Journey System to remember a 40 digit number. All you need to do is take the Person for the first two digits, the Action from the next two digits and the Object from the final two digits and place them in a journey step.</p>"+
            "<p>Thereafter, simply repeat the process until you're done with all the digits. You'll be fine with a journey system with 6-7 journey steps, depending on how much you want to visualize in your last journey step.</p>",
      'no': "<p>Kombiner Memolife Dobbelsystemet og Reisesystemet for å memorisere et tall på 40 sifre. Alt du trenger å gjøre er å bruke Personen for de to første sifrene, Handlingen for de neste to sifrene og Objektet fra de siste to sifrene og plassere dem i et reisesteg.</p>"+
            "<p>Etterpå, bare gjenta prosessen til du er ferdig med alle sifrene. Du kommer til å klare deg helt fint med et reisesystem som inneholder 6-7 reisesteg, avhengig av hvor mye du vil visualisere på det siste reisesteget.</p>"
    },
    'load': 'newGame',
    'load_args': ['bankv', 2],
    'params': {totalItems: 40, min:0, max:9}
  },
  'show_sys_double_practice_10_20_0_9_3': {
    'name': {
      'en': "Learn the Knowledge category in the Memolife Double System",
      'no': "Lær Kunnskaps-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Knowledge category contains memory codes for people, actions and objects that are all related to the Life Areas about knowledge and accelerated learning. Shortly you may see Elon Musk doing a pirouette with chop sticks (201511) in one place or Jennifer Lopez waving a magic wand at a UFO (151020) in another.",
      'no': 'Kategorien "Kunnskap" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med livsområdene for kunnskap og akselerert læring å gjøre. Ganske snart vil du forestille deg Elon Musk ta en piruett med spisepinner (201511) på et sted eller Jennifer Lopez vifte med en tryllestav mot en UFO (151020) på et annet.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:10, max:20, min2:0, max2:9, randomItems:3},
  },
  'newgame_superm_0_40': {
    'name': {
      'en': "Ace Medium-Sized Lists (40 words)",
      'no': "Husk mellomstore lister (40 ord) lynraskt"
    },
    'description': {
      'en': "Use the objects from the Double System to memorize the shopping list items 1-20 and the Rhyme System to memorize the remaining 21-40 products in the shopping list.",
      'no': "Bruk objektene fra Dobbelsystemet til å memorisere ordene 1-20 og Rimsystemet for 21-40 for å memorisere alle produktene fra handlisten."
    },
    'load': 'newGame',
    'load_args': ['superm', 0],
    'params': {totalItems: 40},
  },
  'intro_memogenius3':{
    'name': 'Introduction',
    'load': 'showArticle',
    'load_args': ['x',0,{}],
    'description': {
      "en": "",
      "no": ""
    }
  },
  'intro_memogenius3_2': {
    'name': 'Introduction',
    'load': 'showArticle',
    'load_args': ['x',0,{}],
    'description': {
      "en": "",
      "no": ""
    }
  },
  'show_sys_double_practice_21_32_0_20_3':{
    'name': {
      'en': "Learn the Knowledge category in the Memolife Double System",
      'no': "Lær Kunnskaps-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Knowledge category contains memory codes for people, actions and objects that are all related to the Life Areas about knowledge and accelerated learning. Shortly you may see Richard Branson farting on bricks (302331) in one of your journey locations or Pocahontas shaking hands with a thought bubble (322721) in another.",
      'no': 'Kategorien "Kunnskap" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med Livsområdene om kunnskap og akselerert læring å gjøre. Ganske snart kan du forestille deg Richard Branson som promper på murstein (302331) i et av reisestegene dine eller at Pocahontas håndhilser på en tankeboble (322721) på et annet sted.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:21, max:32, min2:0, max2:20, randomItems:3}
  },
  'newgame_time_0_10_21_32':{
    'name': {
      'en': "Test your history memory",
      'no': "Test din historiehukommelse"
    },
    'description': {
      'en': "Here we go. Remember 10 imaginary historic events using the Symbolization System (or Similarity System) in combination with the Memolife Double System. Start by finding the main symbol/object/location to represent the historic event and thereafter visualize the year (yyyy) by using the Double System person for the first two digits (yy) and the action as the last two digits (yy) to the left of the historic symbol.",
      'no': "Da kjører vi i vei. Memoriser 10 tullehistoriske hendelser ved hjelp av Symbolisering- (eller Likhets-) og Memolife Dobbelsystemet. Begynn med å finne hovedsymbolet/objektet/stedet som kan representere den historiske hendelsen og visualiser deretter årstallet (åååå) til venstre for symbolet ved å bruke Personen for de to første sifrene (åå) og Handlingen for de to siste sifrene (åå)."
    },
    'load': 'newGame',
    'load_args': ['time', 0],
    'params': { totalItems: 10, min: 21, max: 32 }
  },
  'show_sys_double_practice_33_42_0_32_3': {
    'name': {
      'en': "Learn the Mind category in the Memolife Double System",
      'no': "Lær Hode-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Mind category contains memory codes for people, actions and objects that are related to the Life Areas about personal development. Shortly you may see Muhammad Ali smiling at fireworks (403437) in one of your journey locations or Robin Williams washing his hair with a key (393336) in another.",
      'no': 'Kategorien "Hodet" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med livsområdene for personlig utvikling å gjøre. Ganske snart kan du se Muhammad Ali smile av fyrverkeri (403437) på et av dine reisesteder og Robin Williams vaske håret sitt med en nøkkel (393336) på et annet sted.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:33, max:42, min2:0, max2:32, randomItems:3}
  },
  'newgame_bankv_2_40_33_42': {
    'name': {
      'en': "Rock Long Numbers",
      'no': "Lek deg gjennom store tall"
    },
    'description': {
      'en': "<p>Combine the Memolife Double System and the Journey System to remember a 40 digit number. All you need to do is take the Person for the first two digits, the Action from the next two digits and the Object from the final two digits and place them in a journey step.</p>"+
            "<p>Thereafter, simply repeat the process until you're done with all the digits. You'll be fine with a journey system with 6-7 journey steps, depending on how much you want to visualize in your last journey step.</p>",
      'no': "<p>Kombiner Memolife Dobbelsystemet og Reisesystemet for å memorisere et tall på 40 sifre. Alt du trenger å gjøre er å bruke Personen for de to første sifrene, Handlingen for de neste to sifrene og Objektet fra de siste to sifrene og plassere dem i et reisesteg.</p>"+
            "<p>Etterpå, bare gjenta prosessen til du er ferdig med alle sifrene. Du kommer til å klare deg helt fint med et reisesystem som inneholder 6-7 reisesteg, avhengig av hvor mye du vil visualisere på det siste reisesteget.</p>"
    },
    'load': 'newGame',
    'load_args': ['bankv', 2],
    'params': { totalItems: 40, min: 33, max: 42 }
  },
  'show_sys_double_practice_43_51_0_52_3': {
    'name': {
      'en': "Learn the Mind category in the Memolife Double System",
      'no': "Lær Hode-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Mind category contains memory codes for people, actions and objects that are related to the Life Areas about personal development. Shortly you'll see Will Smith running towards huge binoculars (474548) in one of your journey locations or Jennifer Aniston firewalking towards a treasure chest (505145) in another.",
      'no': 'Kategorien "Hodet" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med livsområdene for personlig utvikling å gjøre. Ganske snart vil du se Will Smith løpe mot en stor kikkert (474548) på et av dine reisesteder og Jennifer Aniston gå på glødende kull mot en skattekiste (505145) på et annet sted.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:43, max:51, min2:0, max2:42, randomItems:3}
  },
  'newgame_bankv_2_43_51': {
    'name': {
      'en': "Rock Long Numbers",
      'no': "Lek deg gjennom store tall"
    },
    'description': {
      'en': "<p>Combine the Memolife Double System and the Journey System to remember a 40 digit number. All you need to do is take the Person for the first two digits, the Action from the next two digits and the Object from the final two digits and place them in a journey step.</p>"+
            "<p>Thereafter, simply repeat the process until you're done with all the digits. You'll be fine with a journey system with 6-7 journey steps, depending on how much you want to visualize in your last journey step.</p>",
      'no': "<p>Kombiner Memolife Dobbelsystemet og Reisesystemet for å memorisere et tall på 40 sifre. Alt du trenger å gjøre er å bruke Personen for de to første sifrene, Handlingen for de neste to sifrene og Objektet fra de siste to sifrene og plassere dem i et reisesteg.</p>"+
            "<p>Etterpå, bare gjenta prosessen til du er ferdig med alle sifrene. Du kommer til å klare deg helt fint med et reisesystem som inneholder 6-7 reisesteg, avhengig av hvor mye du vil visualisere på det siste reisesteget.</p>"
    },
    'load': 'newGame',
    'load_args': ['bankv', 2],
    'params': { totalItems: 40, min: 43, max: 51 }
  },
  'show_sys_double_practice_52_66_0_51_3': {
    'name': {
      'en': "Learn the Body category in the Memolife Double System",
      'no': "Lær Kropp-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Body category contains memory codes for people, actions and objects that are related to the Life Areas about the body and healthy living. Shortly you'll see Jason Statham doing a backflip on an oyster (526058) in one of your journey locations or Charlie Chaplin doing pull-ups from a tea-cup (545262) in another.",
      'no': 'Kategorien "Kroppen" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med livsområdene for fysisk trening og helsevennlig livsstil å gjøre. Ganske snart vil du se Jason Statham ta flikk-flakk på en østers (526058) på et av dine reisesteder og Charlie Chaplin ta opptrekk fra en tekopp (545262) på et annet sted.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:52, max:66, min2:0, max2:51, randomItems:3}
  },
  'newgame_library_1_15_52_66': {
    'name': {
      'en': "Seduce a list of abstract words into your memory",
      'no': "Forfør en liste med abstrakte ord i hukommelsen"
    },
    'description': {
      'en': "Use the objects of the Memolife Double System to remember a sequence of abstract words and use the Symbolization System to identify the words themselves.",
      'no': "Bruk objektene fra Memolife Dobbelsystemet for å memorisere en rekkefølge av abstrakte ord og bruk Symboliseringssystemet for å identifisere ordene."
    },
    'load': 'newGame',
    'load_args': ['library', 1],
    'params': { totalItems: 15, min: 52, max: 66 }
  },
  'intro_memogenius_7': {
    'name': 'Introduction',
    'load': 'showArticle',
    'load_args': ['x',0,{}],
    'description': {
      "en": "",
      "no": ""
    }
  },
  'show_sys_double_practice_67_78_0_66_3': {
    'name': {
      'en': "Learn the Soul category in the Memolife Double System",
      'no': "Lær Sjel-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Knowledge category contains memory codes for people, actions and objects that are all related to the Life Areas about deeper self improvement. Shortly you may see Mother Theresa hugging some slippers (717574) in one of your journey locations or Jesus stretching his neck with a halo (747071) in another.",
      'no': 'Kategorien "Sjelen" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med Livsområdene for dypere selvutvikling å gjøre. Ganske snart vil du forestille deg Mor Theresa som klemmer noen tøfler (717574) i et av reisestegene dine eller at Jesus strekker ut nakken sin med en glorie (747071) på et annet sted.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:67, max:78, min2:0, max2:66, randomItems:3}
  },
  'newgame_time_1_10_67_78': {
    'name': {
      'en': "Test your history memory",
      'no': "Test din historiehukommelse"
    },
    'description': {
      'en': "Remember 10 imaginary historic events using the Symbolization System (or Similarity System) in combination with the Memolife Double System. Start by finding the main symbol/object/location to represent the historic event and thereafter visualize the year (yyyy) by using the Double System person for the first two digits (yy) and the action as the last two digits (yy) to the left of the historic symbol.",
      'no': "Memoriser 10 tullehistoriske hendelser ved hjelp av Symbolisering- (eller Likhets-) og Memolife Dobbelsystemet. Begynn med å finne hovedsymbolet/objektet/stedet som kan representere den historiske hendelsen og visualiser deretter årstallet (åååå) til venstre for symbolet ved å bruke Personen for de to første sifrene (åå) og Handlingen for de to siste sifrene (åå)."
    },
    'load': 'newGame',
    'load_args': ['time', 1],
    'params': { totalItems: 10, min: 67, max: 78 }
  },
  'show_sys_double_practice_79_88_0_78_3': {
    'name': {
      'en': "Learn the Career category in the Memolife Double System",
      'no': "Lær Karriere-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Career category contains memory codes for people, actions and objects that are related to the Life Areas about career and professional development. Shortly you can see Steve Jobs opening a map roll on top of a chess board (888687) in one of your journey locations or Oprah Winfrey showing money to a light bulb (818388) in another.",
      'no': 'Kategorien "Karriere" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med livsområdet karriereutvikling å gjøre. Ganske snart vil du se Steve Jobs åpne en kartrull på et sjakkbrett (888687) på et av dine reisesteder og Oprah Winfrey vise penger til en lyspære (818388) på et annet sted.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:79, max:88, min2:0, max2:78, randomItems:3}
  },
  'newgame_bankv_1_40_79_88': {
    'name': {
      'en': "Rock Long Numbers",
      'no': "Lek deg gjennom store tall"
    },
    'description': {
      'en': "<p>Combine the Memolife Double System and the Journey System to remember a 40 digit number. All you need to do is take the Person for the first two digits, the Action from the next two digits and the Object from the final two digits and place them in a journey step.</p>"+
            "<p>Thereafter, simply repeat the process until you're done with all the digits. You'll be fine with a journey system with 6-7 journey steps, depending on how much you want to visualize in your last journey step.</p>",
      'no': '<p>Kombiner Memolife Dobbelsystemet og Reisesystemet for å memorisere et tall på 40 sifre. Alt du trenger å gjøre er å bruke Personen for de to første sifrene, Handlingen for de neste to sifrene og Objektet fra de siste to sifrene og plassere dem i et reisesteg.</p>'+
            '<p>Etterpå, bare gjenta prosessen til du er ferdig med alle sifrene. Du kommer til å klare deg helt fint med et reisesystem som inneholder 6-7 reisesteg, avhengig av hvor mye du vil visualisere på det siste reisesteget.</p>'
    },
    'load': 'newGame',
    'load_args': ['bankv', 1],
    'params': { totalItems: 40, min: 79, max: 88 }
  },
  'show_sys_double_practice_89_99_0_88_3': {
    'name': {
      'en': "Learn the Leisure category in the Memolife Double System",
      'no': "Lær Fritids-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': 'The Leisure category contains memory codes for people, actions and objects that are all related to the Life Areas about having an awesome leisure time. Shortly you can see Angelina Jolie playing the guitar on a rope knot (959698) in one of your journey locations or Gordon Ramsey "martial-arts-kicking" grapes (899193) in another.',
      'no': 'Kategorien "Fritid" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med Livsområdene for en lykkelig og meningsfull fritid å gjøre. Ganske snart vil du forestille deg Angelina Jolie som spiller gitar på en knute (959698) i et av reisestegene dine eller at Gordon Ramsey karate-sparker druer (899193) på et annet sted.'
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:89, max:99, min2:0, max2:88, randomItems:3}
  },
  'newgame_time_1_10_89_99':{
    'name': {
      'en': "Test your history memory",
      'no': "Test din historiehukommelse"
    },
    'description': {
      'en': 'Remember 10 imaginary historic events using the Symbolization System (or Similarity System) in combination with the Memolife Double System. Start by finding the main symbol/object/location to represent the historic event and thereafter visualize the year (yyyy) by using the Double System person for the first two digits (yy) and the action as the last two digits (yy) to the left of the historic symbol.',
      'no': 'Memoriser 10 tullehistoriske hendelser ved hjelp av Symbolisering- (eller Likhets-) og Memolife Dobbelsystemet. Begynn med å finne hovedsymbolet/objektet/stedet som kan representere den historiske hendelsen og visualiser deretter årstallet (åååå) til venstre for symbolet ved å bruke Personen for de to første sifrene (åå) og Handlingen for de to siste sifrene (åå).'
    },
    'load': 'newGame',
    'load_args': ['time', 1],
    'params': { totalItems: 10, min: 89, max: 99 }
  },
  'show_sys_double_practice_0_0_0_99_50': {
    'name': {
      'en': "Slot machine",
      'no': "Slot-maskinen"
    },
    'description': {
      'en': "All right, time to repeat a bunch of memory codes from all the categories in the Memolife Double System. You'll need to know them well for the final memory challenge in this mission.",
      'no': "Ok, nå er det dags å repetere en rekke med huskekoder fra alle kategoriene i Memolife Dobbelsystemet. Det er viktig at de sitter når du hopper på den siste huskeutfordringen i dette oppdraget."
    },
    'load': 'showSysDoublePractice',
    'load_args': [],
    'params': { min:0, max:0, min2:0, max2:99, randomItems:50}
  },
  'newgame_bankv_1_50_0_99':{
    'name': {
      'en': "Memorize the longest number you've done so far",
      'no': "Memoriser det lengste tallet du har gjort så langt"
    },
    'description': {
      'en': "Have you got it in you to memorize a number with 50 digits? Simply combine a couple of journey systems and create your mental movie scenes out of the Persons, Actions and Objects for each of the double digits - and you'll be done before you know it!",
      'no': "Har du det som skal til for å memorisere et tall med 50 sifre? Alt du trenger å gjøre er å bruke noen Reisesystemer du har laget og lage dine mentale filmscener med Personer, Handlinger og Objekter for hvert av de dobbeltsifrete deltallene - så kommer du til å være ferdig før du vet ordet av det."
    },
    'load': 'newGame',
    'load_args': ['bankv', 1],
    'params': { totalItems: 50, min: 0, max: 99 }
  },
  'newgame_face_2_10_1_10': {
    'name': {
      "en": 'Social Embarrassment or a Social Star',
      "no": "Sosial angst eller sosial lykke"
    },
    'description': {
      "en": "You're about to meet a group of people who have the same names as the memory codes for the surnames you've just learnt and the first names you've just repeated. In order to remember their names, all you have to do is to use these memory codes and associate them with the dominant facial features - just like you read in the article from step one. Have you got what it takes to remember all the 10 names?",
      "no": "Du er i ferd med å møte en gruppe mennesker som har de samme navnene som huskekodene du akkurat har lært. Alt du må gjøre for å huske navnene deres er å bruke disse huskekodene og assosiere dem med de mest dominerende ansiktstrekkene - akkurat slik du leste om i artikkelen fra steg en. Har du det som skal til for å huske navnene på alle 10 personene?"
    },
    'load': 'newGame',
    'load_args': ['face', 2],
    'params': { totalItems: 10, min: 1, max: 10}
  },
  'newgame_face_2_10_11_20': {
    'name': {
       'en': "Social Embarrassment or a Social Star",
       'no': "Sosial angst eller sosial lykke"
     },
     'description': {
       'en': "You're about to meet a group of people who have the same names as the memory codes for the first names and surnames you've just learnt. In order to remember their names, all you have to do is to use these memory codes and associate them with the dominant facial features - just like you read in the article from step one. Have you got what it takes to remember all the 10 names?",
       'no': "Du er i ferd med å møte en gruppe mennesker som har de samme navnene som huskekodene du akkurat har lært. Alt du må gjøre for å huske navnene deres er å bruke disse huskekodene og assosiere dem med de mest dominerende ansiktstrekkene - akkurat slik du leste om i artikkelen fra steg en. Har du det som skal til for å huske navnene på alle 10 personene?"
     },
     'load': 'newGame',
     'load_args': ['face', 0],
     'params': { totalItems: 10, min: 11, max: 20}
   },
  'newgame_face_2_10_21_30': {
    'name': {
       'en': "Learn more memory codes for names",
       'no': "Lær flere huskekoder for navn"
     },
     'description': {
       'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
       'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
     },
     'load': 'newGame',
     'load_args': ['face', 2],
     'params': { totalItems: 10, min: 21, max: 30}
   },
  'newgame_face_2_10_31_40': {
    'name': {
       'en': "Learn more memory codes for names",
       'no': "Lær flere huskekoder for navn"
    },
    'description': {
       'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
       'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
     },
     'load': 'newGame',
     'load_args': ['face', 2],
     'params': { totalItems: 10, min: 31, max: 40}
  },
  'newgame_face_2_10_41_50': {
    'name': {
      'en': "Learn more memory codes for names",
      'no': "Lær flere huskekoder for navn"
    },
    'description': {
      'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
      'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
    },
       'load': 'newGame',
       'load_args': ['face', 2],
       'params': { totalItems: 10, min: 41, max: 50}
  },
  'newgame_face_2_10_51_60': {
    'name': {
         'en': "Learn more memory codes for names",
         'no': "Lær flere huskekoder for navn"
       },
    'description': {
         'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
         'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
       },
       'load': 'newGame',
       'load_args': ['face', 2],
       'params': { totalItems: 10, min: 51, max: 60}
  },
 'newgame_face_2_10_61_70': {
    'name': {
         'en': "Learn more memory codes for names",
         'no': "Lær flere huskekoder for navn"
       },
    'description': {
         'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
         'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
       },
       'load': 'newGame',
       'load_args': ['face', 2],
       'params': { totalItems: 10, min: 61, max: 70}
  },
  'newgame_face_2_10_71_80': {
    'name': {
         'en': "Learn more memory codes for names",
         'no': "Lær flere huskekoder for navn"
       },
    'description': {
         'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
         'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
       },
       'load': 'newGame',
       'load_args': ['face', 2],
       'params': { totalItems: 10, min: 71, max: 80}
  },
 'newgame_face_2_10_81_90': {
    'name': {
         'en': "Learn more memory codes for names",
         'no': "Lær flere huskekoder for navn"
       },
     'description': {
         'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
         'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
       },
       'load': 'newGame',
       'load_args': ['face', 2],
       'params': { totalItems: 10, min: 81, max: 90}
  },
  'newgame_face_2_10_91_100': {
    'name': {
         'en': "Learn more memory codes for names",
         'no': "Lær flere huskekoder for navn"
       },
    'description': {
         'en': "Dive into a series of 10 male first names, 10 female first names and 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
         'no': "La oss dykke ned i en serie med 10 nye guttenavn, 10 nye jentenavn og 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom."
       },
       'load': 'newGame',
       'load_args': ['face', 2],
       'params': { totalItems: 10, min: 91, max: 100}
  },

  'article_facts_trivia': {
    'name': 'Facts & Definitions',
    'load': 'showArticle',
    'load_args': ['facts_trivia',0,{}]
  },
  'article_memory_reminder': {
    'name': 'Idea boxes & Memory reminders',
    'load': 'showArticle',
    'load_args': ['idea_boxes_and_memory_reminders',0,{}]
  },
  'article_directions_parking': {
    'name': 'Directions & parking',
    'load': 'showArticle',
    'load_args': ['directions_parking',0,{}]
  },
  'article_presentations': {
    'name': 'Presentations',
    'load': 'showArticle',
    'load_args': ['presentations',0,{}]
  },
  'article_memory_sports': {
    'name': 'Memory sports',
    'load': 'showArticle',
    'load_args': ['memory_sports',0,{}]
  },
  'article_linking': {
      'name': {
        'en': "How to tattoo concepts into your memory",
        'no': "Tatover konsepter inn i hukommelsen din"
      },
      'description': {
        'en': "A curriculum often consists of concepts and abstract logical relationships. These things can be tough - and almost impossible to remember without a lot of work. The answer is once again in the Symbolization System, so feel free to refresh your memory of this once more.",
        'no': "Pensum og fagområder består ofte av konsepter og abstrakte logiske sammenhenger. Dette kan være vanskelig - nesten umulig - å huske uten mye arbeid. Svaret ligger nok en gang i Symbolisesringssystemet, så frisk gjerne opp hukommelsen din med en titt på artikkelen nok en gang."
      },
      'load': 'showArticle',
      'load_args': ['linking',0,{}]
    },
  'article_history': {
    'name': {
      'en': "Learn Any Historic Events Super-Fast",
      'no': "Lær historiske hendelser lynraskt"
    },
    'description': {
      'en': "Learn how to use the Symbolization System (or Similarity System) in combination with the Memolife Double System to learn any historic event super-fast.",
      'no': "Lær hvordan du kan bruke Symboliseringssystemet (eller Likhetssystemet) i kombinasjon med Memolife Dobbeltsystemet for å lære historiske hendelser super-raskt."
    },
    'load': 'showArticle',
    'load_args': ['history',0,{}]
  },
  'article_symbolization': {
      'name': {
        'en': "Symbolization - the art of 'breaking' words",
        'no': "Symbolisering - kunsten å 'knuse' ord"
      },
      'description': {
        'en': "A heart for love, a dove for peace and dreadlocks for reggae music. Symbols are used unconsciously all the time. This article will make you understand how to replace abstract words with symbols or other elements which represent abstract words in a fun and exciting way.",
        'no': 'Et hjerte for kjærlighet, en due for fred og dreadlocks for reggae-musikk. Symboler brukes ubevisst hele tiden. Denne artikkelen lar deg forstå hvordan du kan erstatte abstrakte ord med symboler og elementer som representerer abstrakte ord på en morsom og spennende måte.'
      },
      'load': 'showArticle',
      'load_args': ['nail-abstract-words-brainstorming',0,{}]
    },
  'newgame_curriculum_2_15': {
    'name': {
          'en': "A curriculum part in 1-2-3",
          'no': "En del av pensum på 1-2-3"
      },
    'description': {
          'en': "Let's get busy and dive into the details. All you need to do to remember your curriculum in the shape of memomaps is combine the right MEMOry system with your mindMAP. Start by choosing your mental image for the central memomap topic and thereafter link this up with the main branches from one o' clock to twelve o' clock. Thereafter, simply use each main branch as a base and create your link stories in chains with each new branch node linked up to the next one. Let's see if you have what it takes!",
          'no': "Ok, la oss hoppe inn i detaljene med en gang. Alt du trenger å gjøre for å huske pensum i form av memomaps er å kombinere de riktige MEMOsystemene med ditt mindMAP. Begynne med å velge hvilket mentale bilde du vil bruke for midten av memomappet og link dette deretter opp i en huskefantasi som kombinerer hovedgrenene med hverandre fra klokken ett til klokken tolv. Deretter kan du bruke hovedgrenene som start på nye linkfantasier for de underliggende grenene. La oss se om du har det som skal til!"
      },
          'load': 'newGame',
          'load_args': ['mindmap', 2],
          'params': {'totalItems': 15}
    },
  'article_business_meetings': {
       'name': {
          'en': "How to remember texts",
          'no': "Hvordan huske tekster"
        },
        'description': {
          'en': "Ultimately, a text is nothing but a series of keywords. Let's look at how to remember them.",
          'no': "Til syvende og sist er en tekst ikke noe annet enn en serie med nøkkelord. La oss ta en titt på hvordan du kan huske dem."
        },
        'load': 'showArticle',
        'load_args': ['business_meetings',0,{}],
      },
  'article_rhyme': {
         'name': {
          'en': "The Rhyme System - memorizing with rhymes",
          'no': "Rimsystemet - husk med rim"
        },
        'description': {
          'en': "In contrast to the Memolife Single System, where the memory codes have the shape of the numbers, the Rhyme System has memory codes that rhyme with the numbers. Oh, and the Rhyme System also has 20 memory codes, so it's twice as powerful. Learn and practise them well, so that you can use the system in step 3.",
          'no': "I motsetning til Memolife Singelsystemet, hvor huskekodene er ord med fasonger som ser ut som tallene, har Rimsystemet huskekoder som rimer på tallene. I tillegg har Rimsystemet 20 huskekoder, så det er dobbelt så kraftfullt. Lær kodene godt, slik at du kan bruke dem i steg 3."
        },
        'load': 'showArticle',
        'load_args': ['rhymesys',0,{}]
      },
  'newgame_meeting_2_3': {
     'name': {
          'en': "Memorize all the sales meeting points",
          'no': "Husk alle punktene fra salgsmøtet"
        },
    'description': {
          'en': "It's no secret that the better you can connect with people, the more likely you'll close the sale. And, remembering who said what and all that was said in a sales meeting definitely won't hurt."+
                 "Simply use a memory system, like the Rhyme or Journey System, and attach the person and the keywords you want to remember to the different memory codes in the right sequence. As described in the text article, the challenge is which keywords to focus on and how to remember them with the Symbolization or Similarity Systems. With a little practice this will come naturally. Are you ready to nail your business meeting?",
          'no': "Det er ingen hemmelighet at jo bedre kjemi du får med mennesker desto høyere sannsynlighet blir det for at du lander salget. Og, det å huske nøyaktig hvem som sa hva og hva som ble sagt er ihvertfall ingen ulempe."+
                'Bruk et huskesystem, som Rimsystemet eller Reisesystemet, og legg til personen og nøkkelordene du vil huske, så er du i mål. Som nevnt i "tekst"-artikkelen, så er utfordringen hvilke nøkkelord du bør fokusere på og hvordan du kan huske dem med Symboliserings- eller Likhetssystemene. Med litt øving, så kommer dette helt naturlig. Er du klar for å få med deg alt fra jobbmøtet?'
        },
        'load': 'newGame',
        'load_args': ['meeting', 4,{}]
      },
  'show_technique_doublesys': {
    'name': {
      'en': "Learn the Brain category in the Memolife Double System",
      'no': "Lær Hjernen-kategorien i Memolife Dobbelsystemet"
    },
    'description': {
      'en': "The Brain category contains memory codes for people, actions and objects that are related to the Life Areas about the brain and brain training. Shortly you may see Bill Gates sticking his tongue onto to a brain (040500) in one location or Albert Einstein playing golf on an old telephone (000903) in another.",
      'no': 'Kategorien "Hjernen" inneholder huskekoder for mennesker, handlinger og objekter som alle har noe med livsområdene om hjernen og akselerert læring å gjøre. Ganske snart vil du se Bill Gates slikke en hjerne (040500) på et sted og Albert Einstein spille golf med en gammel telefon (000903) på et annet sted.'
    },
    'load': 'showSysPractice',
    'load_args': ['doublesys',0,{}]
  },
  'article_detailed_meeting': {
   'name': {
        'en': "How to remember texts",
        'no': "Hvordan huske tekster"
      },
    'description': {
        'en': "There's joy in repetition, at least for your brain, so feel free to refresh how to remember texts with this article.",
        'no': "Hjernen elsker å repetere, så at deg gjerne tid til en kjapp repetisjon for hvordan du kan huske tekster."
      },
      'load': 'showArticle',
      'load_args': ['detailed_meeting',0,{}]
    },
    'newgame_meeting_4_1': {
   'name': {
        'en': "You'll do fine with a rhyme",
        'no': "Du blir fin med rim"
      },
    'description': {
        'en': "The rhyme system gives you 20 codes you can use as a base for your most important meeting points. If there are things you want to remember permanently, however, then creating a Roman Room, a Journey or a Memomap is the way to go. Feel free to repeat the system, before using it in the final mission step.",
        'no': "Rimsystemet gir deg 20 huskekoder du kan bruke som base for å knytte de viktigste møtepunktene til. Dersom det er ting du vil huske for alltid, vil vel og merke et Romersk Rom, en Reise eller et Memomap være bedre egnet. Frisk derfor gjerne opp systemet før du bruker det i det siste steget i oppdraget."
      },
      'load': 'newGame',
      'load_args': ['meeting', 8, {}]
    },
  'article_shopping': {
    'name': 'Learn',
    'load': 'showArticle',
    'load_args': ['shopping',0,{}],
    'description': {
      'en': "",
      'no': ""
    }
  },
  'newgame_shopping_l1': {
    'name': {
      'en': "A Quick Sidestep - Memorize A New List!",
      'no': "Et lite sidesteg - husk en ny liste!"
    },
    'description': {
      'en': "All right, sometimes we need to break things up a bit, so now it's time to memorize a list of up to 30 items. Use the Rhyme system for the first 20 and thereafter use the Clone and Rhyme systems together for the next numbers.",
      'no': "Ok, noen ganger er det viktig å ta et steg til siden for å bevege seg videre, så nå er det på tide å memorisere en liste med 30 ting. Bruk Rimsystemet for de første 20 og bruk deretter Klone- og Rimsystemet sammen for de siste numrene."
    },
    'load': 'newGame',
    'load_args': ['superm', 8],
    'params': {'totalItems': 20}
  },
  'newgame_shopping_alphabet': {
    'name': {
      'en': "A Quick Sidestep - Memorize A New List!",
      'no': "Et lite sidesteg - husk en ny liste!"
    },
    'description': {
      'en': "All right, sometimes we need to break things up a bit, so now it's time to memorize a list of up to 30 items. Use the Rhyme system for the first 20 and thereafter use the Clone and Rhyme systems together for the next numbers.",
      'no': "Ok, noen ganger er det viktig å ta et steg til siden for å bevege seg videre, så nå er det på tide å memorisere en liste med 30 ting. Bruk Rimsystemet for de første 20 og bruk deretter Klone- og Rimsystemet sammen for de siste numrene."
    },
    'load': 'newGame',
    'load_args': ['superm', {}],
    'params': {'totalItems': 0, 'source': 'alphabet'}
  },
  'article_alphabet': {
    'name': {
      'en': "ABC ... Use The Alphabet And See",
      'no': "ABC ... bruk alfabetet og le"
    },
    'description': {
      'en': "Here we go - check out the alphabet article and understand how you can use it for different memory benefits.",
      'no': "Hopp i det og sjekk ut artikkelen som beskriver hvordan du kan bruke forskjellige alfabetsystemer for forskjellige huskefordeler."
    },
    'load': "showArticle",
    'load_args': ['alphabetsys',0,{}]
  },
  'newgame_alphabet': {
    'name': {
      'en': "A Trip To The Zoo - Ace Your First Alphabet System",
      'no': "En tur til dyrehagen - lær ditt første Alfabetsystem"
    },
    'description': {
      'en': "<p>Ok, now it's time to actually practise your first Alphabet system. The theme for the system is animals, so all you have to do is link every letter to an animal. But, as you now might have noticed, you already learnt this system in the memory test in step 3 ...</p>"+
            "<p>The purpose of that test was to give you a head start and some tips for how you can remember the letter sequence of the alphabet (e.g.. G=7, A=1, D=4, etc.). So now, simply repeat your memory associations with the Rhyme and Clone systems and you already know them.</p>",
      'no': "<p>Ok, nå er det på tide å faktisk lære ditt første Alfabetsystem. Temaet for systemet er dyr, så alt du må gjøre er å knytte hver bokstav til et dyr. Men, som du muligens har lagt merke til, så lærte du faktisk allerede indirekte dette systemet i husketesten i steg 3 ...</p>"+
            "<p>Formålet med testen var å gi deg en tyvstart og noen tips for hvordan du kan huske hvordan bokstavene passer med tallrekkefølgen (eks. G=7, A=1, D=4, osv). Bare repeter huskefantasiene dine med Rim- og Klonesystemet, så vil du nå huske hvilke bokstaver og tall som hører sammen.</p>"
    },
    'load': "showSysPractice",
    'load_args': ['alphabetsys',0, {}]
  },
  'newgame_shopping_20_quantities_prices': {
    'name': {},
    'description': {},
    'load': 'newGame',
    'load_args': ['shopping', 8],
    'params': {'totalItems': 20}
  },
  'article_curriculum': {
    'name': {},
    'description': {},
    'load': 'showArticle',
    'load_args': ['curriculum',0,{}]
  },
  'newgame_curriculum_4_15': {
    'name': {},
    'description': {},
    'load_args': ['mindmap', 8],
    'params': {'totalItems': 15}
  },
  'article_personal_memory': {
    'name': {},
    'description': {},
    'load': 'showArticle',
    'load_args': ['personal_memory',0,{}],
  },
  'newgame_social_star_4_1': {
    'name': {},
    'description': {},
    'load': 'newGame',
    'load_args': ['face', 8,{}]
  }
}

/* Generate namesys steps for social star missions */
for (var i=1; i<10;i++) {
  var names = ['male', 'female', 'surname'];
  for (var j=0; j < names.length; j++) {
    var margs = {'country': 'Norway'}
    margs[names[j]] = 10;
    margs['page'] = i;
    var step_name = 'show_technique_namesys_10_'+names[j]+"_page_"+i;
    mission_steps[step_name] = {
      'load': 'showSysPractice',
      'load_args': ['namesys', null, margs]
    }

    if (names[j] === 'male') {
      mission_steps[step_name]['name'] = {
        'en': "Learn more memory codes for male names",
        'no': "Lær flere huskekoder for guttenavn"        
      }
      mission_steps[step_name]['description'] = {
        'en': "Dive into a series of 10 male first names. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
        'no': "La oss dykke ned i en serie med 10 nye guttenavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom. "
      }
    }

    if (names[j] === 'female') {
      mission_steps[step_name]['name'] = {
        'en': "Learn more memory codes for female names",
        'no': "Lær flere huskekoder for jentenavn"        
      }
      mission_steps[step_name]['description'] = {
        'en': "Dive into a series of 10 female first names. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
        'no': "La oss dykke ned i en serie med 10 nye jentenavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom. "
      }
    }

    if (names[j] === 'surname') {
      mission_steps[step_name]['name'] = {
        'en': "Learn more memory codes for surnames",
        'no': "Lær flere huskekoder for etternavn"        
      }
      mission_steps[step_name]['description'] = {
        'en': "Dive into a series of 10 surnames. Based on the Similarity and Symbolization Systems, it's easy to remember the name codes, so you'll be done in no time.",
        'no': "La oss dykke ned i en serie med 10 nye etternavn. Huskekodene er basert på Likhets- og Symboliseringssystemene, så det er både enkelt å lære seg og hurtig å komme gjennom. "
      }
    }
  }
}

/*  
    If you need to override the description for a step inside a specific mission
    you can do that here.
*/
var mission_steps_descriptions = {
'multiplymemory::practice_singlesys': {
    'name': {
      'en': "Repeat the Memolife Single System",
      'no': "Repeter Memolife Singelsystemet"
    },
    'description': {
      'en': "Repeat the Memolife Single System and how the numbers from 0 to 9 look like specific shapes.",
      'no': "Repeter Memolife Singelsystemet og hvordan tallene fra 0 til 9 ligner på forskjellige objekter."      
    }
  },
  'rockmedium::practice_singlesys': {
    'name': {
      'en': "Repeat the Memolife Single System",
      'no': "Repeter Memolife Singelsystemet"
    },
    'description': {
      'en': "Repeat the Memolife Single System and how the numbers from 0 to 9 look like specific shapes.",
      'no': "Repeter Memolife Singelsystemet og hvordan tallene fra 0 til 9 ligner på forskjellige objekter."      
    }
  },

  'alphabetmemory::article_clones': {
    'name': {
      'en': "Multiply your memory with the Clone system",
      'no': "Mangedoble hukommelsen med Klonesystemet"
    },
    'description': {
      'en': "You'll soon learn the Alphabet system with the help of the Clone and Rhyme systems, so feel free to have a look at this article once more.",
      'no': "Du kommer snart til å lære Alfabetsystemet med hjelp av Klone- og Rimsystemet, så ta deg gjerne tid til å ta en ny titt på artikkelen for Klonesystemet."
    }
  },

  'alphabetmemory::article_rhyme': {
    'name': {
      'en': "You'll Be Fine With A Rhyme",
      'no': "Du blir fin med rim"
    },
    'description': {
      'en': "The brain loves repetitions, so why don't your repeat the memory codes for the rhyme systems once more?",
      'no': "Hjernen elsker repetisjoner, så ta deg gjerne tid til et øyeblikk med huskepoesi og repeter Rimsystemet."
    }
  },
  'social_star1::intro_article_name_memory': {
    'name': {
      'en': "Why we don't remember names",
      'no': "Hvorfor vi ikke husker navn"
    },
    'description': {
      'en': "Re-read the article which explains what makes our brains almost incapable of remembering names - and what you can easily do to compensate for this.",
      'no': "Repeter artikkelen og forstå hva som gjør at hjernen vår praktisk talt å ikke klarer å huske navn - og hva som skal til for å endre det."
    },    
  }

}
