function updateClock() {
	if(currentMode != 'test') return;
	$('.game-timer').text(gameTime < 0 ? '0.0' : gameTime.toFixed(1)).show();
	if(gameTime >= 0 && !gameEnd) {
		if(gameTime<= 5 && gameTime > 4.9) playSoundAction('music-penalty', 5000);
		setTimeout(updateClock, 100);
		if(currentGame == 'superm' || currentGame == 'superm0'){
			$('.shopping-test .drag span').each(function(){
				var time = parseFloat($(this).text());
				if(time >0) {
					$(this).text((time - 0.1).toFixed(1));
				} else {
					$(this).text('');
					$(this).parent().removeClass('wrong');
				}
			});
		} else if(currentGame == 'meeting'){
			$('.meeting-messages .drag span').each(function(){
				var time = parseFloat($(this).text());
				if(time >0) {
					$(this).text((time - 0.1).toFixed(1));
				} else {
					$(this).text('');
					$(this).parent().removeClass('wrong');
				}
			});
			$('.meeting-questions .avatar span').each(function(){
				var time = parseFloat($(this).text());
				if(time >0) {
					$(this).text((time - 0.1).toFixed(1));
				} else {
					$(this).text('');
					$(this).parent().removeClass('wrong');
				}
			});
		} else if(currentGame == 'mindmap'){
			$('#panelmindmap .drop-area .item span').each(function(){
				var time = parseFloat($(this).text());
				if(time >0) {
					$(this).text((time - 0.1).toFixed(1));
				} else {
					$(this).text('');
					$(this).parent().removeClass('wrong');
				}
			});
			var _i = parseInt(Math.floor(gameTime) / levelSettings[10]);
			if(gameTime >= _i*levelSettings[10] && (gameTime - _i*levelSettings[10]) <= 0.1) {
				nextAnswer();
			}
			moveAnswers();
		}
		gameTime -= 0.1;
	} else if(gameTime < 0 && !gameEnd) {
		if(currentGame == 'namesys') {
			showTechniqueNameAnswer();
		} else {
			checkGameOver();
		}
	}
}

function updateClockUp() {	
	if(gameTime < 300 && !gameEnd) {
		$('.game-timer').text(gameTime.toFixed(1)).show();
		setTimeout(updateClockUp, 100);
		gameTime += 0.1;
		$('.shopping-items .drag span').each(function(){
			var time = parseFloat($(this).text());
			if(time >0) {
				$(this).text((time - 0.1).toFixed(1));
			} else {
				$(this).text('');
				$(this).parent().removeClass('wrong');
			}
		});
	} else {
		if(currentGame == 'namesys') {
			showTechniqueNameAnswer();
		}
	}
}

function updateClockLearn() {  
	if(currentMode != 'learn') return;
	$('.pagination, .radial-timer').show();
	if(pageTime >= 0 || page < (totalPages-1)) {
		$('.pagination .number').text(pageTime.toFixed(1));
		pageTime -= 0.1;
		setTimeout(updateClockLearn, 100);
	}
	if(pageTime < 0 && page < totalPages){
		if(/superm/.test(currentGame)) {
			nextSupermarketPage();
		} else if(/library/.test(currentGame)) {
			nextLibraryPage();
		} else if(/face/.test(currentGame)) {
			nextFacePage();
		} else if(/bankv/.test(currentGame)) {
			$('.vault-answer').text(number);
			rotate(number);
		} else {
			startTesting();
		}
	}	
}

function updateClockLearn2() {
	if(currentMode != 'learn') return;
	$('.radial-timer').show();
	if(currentGame == 'bankv') {
		if(pageTime <= 0 && page < Math.ceil(totalItems/(1 + levelSettings[4] + levelSettings[5]))){
			$('.vault-answer').text(number);
			rotate(number);
		}
	} else if(/time|vocab|meeting/.test(currentGame) && pageTime <= 0 && page < totalItems){
		if(currentGame == 'vocab') {
			nextVocab();
		}else if(currentGame == 'time') {
			nextTime();
		}else if(currentGame == 'meeting') {
			nextMeetingMessage();
		}
	}
	
	if(gameTime >= 0 && currentMode == 'learn') {
		$('.pagination .number').text(gameTime.toFixed(1));
		gameTime -= 0.1;
		pageTime -= 0.1;
		setTimeout(updateClockLearn2, 100);
	} else {
		$('.pagination .number').text(0);
		if(!/bankv/.test(currentGame)) startTesting();
	}
}

