// TODO: (fabian) clean this whole script up. It's definitenly up to
// human standards and it's quite "wet"
//

var mutils = {
  cached_acc: {},
  
  acc_dependencies: function(id) {
    if (mutils.cached_acc[id] !== undefined) {
      return mutils.cached_acc[id];
    }
    var mission = missions.findById(id) || other_missions.findById(id) || master_missions.findById(id);

    if (typeof mission === 'undefined') {
        throw "Mission with id " + id + " was not found."
    }

    // get the mission's dependencies
    //
    var deps = mission.dependencies || [];

    // copy those so that we don't get into trouble on the next step:
    //
    var acc  = deps.slice(0);

    // for each dependency get those dependencies and append it to an
    // accumulator
    //
    deps.forEach(function(id) {
      var m = missions.findById(id) || other_missions.findById(id) || master_missions.findById(id);

      if (typeof m.dependencies === 'undefined')
        throw Error("Check dependencies for " + id + "!!!");

      acc.push(m.dependencies)
    });

    // you know you are done when you hit dependencies === []
    //
    result = [].concat.apply([], acc).unique();

    mutils.cached_acc[id] = result;

    return result;
  },

  depth: function(id) {
    return mutils.depth_helper(id, 0);
  },

  // this function is only to be used by the depth function above
  //
  depth_helper: function(id, level) {
    var m = missions.findById(id) || master_missions.findById(id);

    if (typeof m === 'undefined') throw Error("No mission with ID " + id + "!");

    if (m.dependencies) {
      if (m.dependencies.length === 0) {
        return level;

      } else {

        var arr = m.dependencies.map(function(d) {
          return mutils.depth_helper(d, level + 1)
        });

        return Math.max.apply(null, arr);
      }
    } else {
      return level;
    }
  },

  is_root: function(id) {
    return missions.findById(id)['dependencies'].length === 0;
  },

  is_finished_cache: {},

  is_finished: function(id) {
    // check for all steps if finished
    // TODO: if it's a master missions, check for sub missions' finished steps
    //

    var m = missions.findById(id);

    if (typeof m === 'undefined') {
      // search for the master mission
      //
      var mm = master_missions.findById(id);

      if (typeof mm === 'undefined') {
        throw Error("Master mission with id " + id + " was not found.");
      }

      mm['dependencies'] = missions.filter(function(e) {
        return e['master_mission'] === id;
      });

      return;
    }


    for (var i = 0; i < m.steps.length; i++) {
      if (!is_mission_step_done(m.id, m.steps[i])) { return false; }
    }

    return true;
  },

  is_locked: function(id) {
    if (typeof id !== 'string')
      console.log("_id_ should be a string. got:", id);

    var acc_deps = mutils.acc_dependencies(id);

    if (acc_deps == null || acc_deps.length === 0) return false;

    for (var i=0; i < acc_deps.length; i++)
      if (!mutils.is_finished(acc_deps[i])) {
        return true;
      }

    return false;
  }
}

if (typeof window !== 'undefined') { window.mutils = mutils };
if (typeof global !== 'undefined') { global.mutils = mutils };

// some utilities... the work.
//
Array.prototype.findById = function (i) {
  return this.filter(function (e) {
    return e['id'] === i;
  })[0];
}

Array.prototype.unique=function(){var t,r,n,i,e;for(n=new Object,t=r=0,i=this.length;i>=0?i>r:r>i;t=i>=0?++r:--r)n[this[t]]=this[t];return function(){var r;r=[];for(t in n)e=n[t],r.push(e);return r}()}
