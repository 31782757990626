var systemRegion;
var systemCountry;
var systemNamePage;

function setupNamesysMissionMode() {
	/* Lets pre-select Norway */
	var country = missionParams.country;
	$('#namesys .content-wrap').empty();
	var ul = $('<ul></ul>');
	ul.append(
		'<li class="clickme" onclick="showNameCountry(\'' + country + '\')">'+
			'<div class="img-holder">'+
				'<img src="/images/Flags/' + country + '.png">'+
			'</div>'+
			'<div class="text">'+
				'<div class="vertical">'+
					country+
				'</div>'+
			'</div>'+
		'</li>'
	);
	$('#namesys .content-wrap').append(ul);
	showNameCountry(country, missionParams.page || 0);

  if (missionParams.male !== undefined) {
      showNameTab('male');
  }
  if (missionParams.female !== undefined) {
      showNameTab('female');
  }
  if (missionParams.surname !== undefined) {
      showNameTab('family');
  }

	/* Set up mission class */
	$('#namesys').addClass('mission-mode');
}

function sysnameNameContinue() {
	if (missionParams !== undefined) {
		loadMission();
	} else {
		showSysNamePractice();
	}
}

function showSysNamePractice(){  
	$('#namesys').removeClass('mission-mode');

	showPanel('namesys');
	//for(region in window.gamestate.data.namesys.regions) {
		//if($('.region-' + region).html() != '') continue;
		//for(country in window.gamestate.data.namesys.regions[region].countries) {
      /*
			$('.region-' + region).append(
			  '<li onclick="showNameCountry(\'\')">'+
				'<div class="img-holder">'+
				  '<img src="/images/Flags/.png">'+
				'</div>'+
				'<div class="text">'+
				  '<div class="vertical"></div>'+
				'</div>'+
			  '</li>'
			);
      */
		//}
	//}
  
	$('.system-journey .country').show().addClass('animate');
  
  if(!gamestate.data.country) {
      showLearnificationNamesys('step0');
      return;
  }

	if (missionParams !== undefined) {
		setupNamesysMissionMode();
	}
}

function setCountry(countryCode){
  gamestate.data.country = countryCode;
  gamestate_utils.set('country',countryCode);
  showNameCountry(countryCode);
}

function countryData(country_name) {
  var country;
  for (region in window.gamestate.data.namesys.regions) {
    for (c in window.gamestate.data.namesys.regions[region].countries) {
      if (c === country_name) {
        var country = window.gamestate.data.namesys.regions[region].countries[c];
        return {'country': country, 'region': region}
      }
    }
  }  
}

function showNameCountry(country_name, page){
	country_data = countryData(country_name);
  var country = country_data.country;
  systemRegion = country_data.region;

  $('#namesys .content-intro').hide();
	
  if(isMobile) {
    $('.system-journey .country').hide().removeClass('animate');    
  }
	
  systemNamePage = page || 0;
	systemCountry = country_name;

	$('.namesys-country').attr('src', '/images/Flags/'+country_name+'.png');
	$('.namesys-countryname').text(country_name);

  ['male', 'female', 'family'].forEach(function(item, index) {
    var name_list;
    if (item === "male") {
      name_list = country.firstnameM;
      window.gamestate.data.learned_names.male = [];
      for(var i=systemNamePage*10; i < name_list.length && i < systemNamePage * 10 + 10; i++) {
        window.gamestate.data.learned_names.male.push(name_list[i].name);
      }
    }
    if (item === "female") {
      name_list = country.firstnameF;
      window.gamestate.data.learned_names.female = [];
      for(var i=systemNamePage*10; i < name_list.length && i < systemNamePage * 10 + 10; i++) {
        window.gamestate.data.learned_names.female.push(name_list[i].name);
      }
    }
    if (item === "family") {
      name_list = country.surnames;
      window.gamestate.data.learned_names.family = [];
      for(var i=systemNamePage*10; i < name_list.length && i < systemNamePage * 10 + 10; i++) {
        window.gamestate.data.learned_names.family.push(name_list[i].name);
      }
    }

    gamestate_utils.save();
    
    $('.'+item+'-name-country').html('<div class="item"><div class="names msg-names">Names</div><div class="codes msg-memorycodes">Codes</div></div>');
    for(var i=systemNamePage*10; i < name_list.length && i < systemNamePage * 10 + 10; i++) {
      $('.'+item+'-name-country').append(
        '<div class="item"><div class="mark">'+(i+1)+'</div>'+
        '<div class="names">'+
          name_list[i].name+
        '</div>'+
        '<div class="codes">'+

          '<input type="text" class="input-'+item+'name-'+i+'" value="'+name_list[i].mnemonic+'" onkeyup="saveOnEnter(event)" placeholder="'+getMSG('namesys-placehoder')+'">'+
        '</div>'+
        '<div class="edit"></div>'+
        '<div class="save hide" data-id="'+i+'" data-type="'+item+'" data-name="'+name_list[i].name+'" onclick="updateNameCode(this)"></div>'+
        '</div>'
        );
    }


  });


	translate();

  /* For mobile country list */
	$('#namesys .system-journey .col-right').css('z-index', 2);
}

function namesys_paginate(direction) {

  /*
  systemNamePart; // male, female, family
  systemCountry; // France, Norway, etc
  systemNamePage; // 1, 20, etc
  */

  var country = countryData(systemCountry).country;
  if (systemNamePart === "male") name_list = country.firstnameM;
  if (systemNamePart === "female") name_list = country.firstnameF;
  if (systemNamePart === "family") name_list = country.surnames;

  var max = Math.ceil(name_list.length / 10) -1;
  var min = 0;

  if (direction === 'next') {
    systemNamePage++;;
  }
  if (direction === 'prev') {
    systemNamePage--;
  }

  if (systemNamePage > max) {
    systemNamePage = max;
  }

  if (systemNamePage < min) {
    systemNamePage = min;
  }

  showNameCountry(systemCountry, systemNamePage)

}

function showRegions() {
  $('.system-journey .col-left.country').show();
	$('#namesys .system-journey .col-right').css('z-index', 'auto');
}

function showNameTab(category) {
	$('.name-practice .tab-holder .tab').removeClass('active');
	$('.name-practice .box-holder').addClass('hide');
	$('.'+category+'-name-tab').addClass('active');
	$('.'+category+'-name-country').removeClass('hide');
	systemNamePart = category;

  $('.header .male-female-name').html(getMSG('namesys-'+category+'-names'));

  $('.namesys-mission-next').not('.'+category).show();
}

function showTechniqueNameTest() {
	showPanel('namesystest');
	practiceNameCodes = [];
  var pageStart = systemNamePage*10;
	if(systemNamePart == 'male'){
		for(var i=pageStart; i<firstnameM[systemCountry].length && i<(pageStart + 10); i++) {
			practiceNameCodes.push(window.gamestate.data.namesys.regions[systemRegion].countries[systemCountry].firstnameM[i])
		}
	} else if(systemNamePart == 'female') {
		for(var i=pageStart; i<firstnameF[systemCountry].length && i< (pageStart + 10); i++) {
			practiceNameCodes.push(window.gamestate.data.namesys.regions[systemRegion].countries[systemCountry].firstnameF[i])
		}
	} else {
		for(var i=pageStart; i<surnames[systemCountry].length && i< (pageStart+10); i++) {
			practiceNameCodes.push(window.gamestate.data.namesys.regions[systemRegion].countries[systemCountry].surnames[i])
		}
	}
	practiceNameCodeIndex = 0;
	showNextSysNameTest();
	$('.name-test .spr-text').hide();
	$('.name-test .msg-spr1, .name-test .msg-spr5').show();

	if (missionParams !== undefined) {
    var upd = {'learned_names': window.gamestate.data.learned_names};
    upd[systemNamePart] = practiceNameCodes;
    window.gamestate_utils.set(upd);
	}
}

function showNextSysNameTest(){
	if(practiceNameCodeIndex >= practiceNameCodes.length) {
		$('#namesystest .opaque').addClass('show');
		var correct = practiceNameCodes.filter(function(item){ return item.correct; }).length;
		$('.spr-text').hide();
		if(correct >= practiceNameCodes.length) $('.spr-text.msg-spr2').show();
		else if(correct >= 0.7 * practiceNameCodes.length) $('.spr-text.msg-spr1').show();
		else $('.spr-text.msg-spr3').show();
		$('.msg-spr4').html($('.msg-spr4').html().replace('[x]',correct).replace('[y]',practiceNameCodes.length));

    if (missionParams !== undefined) {
      finished_mission_step(current_mission_step_name());
    }

	} else {
		$('#namesystest .opaque').removeClass('show');
		var namecode = practiceNameCodes[practiceNameCodeIndex];
		$('.name-test-name').text(namecode.name);
		$('.name-test-code').text(namecode.mnemonic || getMSG('system_name_27'));
		if (namecode.mnemonic === '') {
			$('.practice-button .btn-next').show();
		} else {
			$('.practice-button .btn-next').hide();
		}
		$('.name-test .question').removeClass('hide');
		$('.name-test .answer').addClass('hide');
		$('.name-test .btn-answer').show();
		$('.name-test .no, .name-test .yes, .name-test .btn-next').hide();
		$('.msg-system_name_21').html(getMSG('system_name_21').replace('[x]', namecode.name));
		gameTime = 0;
		gameEnd = false;
		updateClockUp();

		practiceNameCodeIndex += 1;
	}
	showHintYesNo();
}

function showTechniqueNameAnswer(){
	gameEnd = true;
	var namecode = practiceNameCodes[practiceNameCodeIndex-1];
	
  if(namecode.mnemonic)
		$('.name-test .no, .name-test .yes').show();
	else
		$('.name-test .btn-next').show();
	
  $('.name-test .btn-answer').hide();
	$('.name-test .question').addClass('hide');
	$('.name-test .answer').removeClass('hide');
	$('.msg-sp2').html(getMSG('sp2').replace('[x]',practiceNameCodes[practiceNameCodeIndex-1].name));
	
  showHintYesNo();
}

function showNextSysNameYes(){
	practiceNameCodes[practiceNameCodeIndex-1].correct = true;
	$('.tick').addClass('animate').show();
	playSoundAction('music-correct');
	setTimeout(function(){
		$('.tick').removeClass('animate');
		showNextSysNameTest();
	}, 500);
}

function showNextSysNameNo(){
	practiceNameCodes[practiceNameCodeIndex-1].correct = false;
	setTimeout(function(){
		showNextSysNameTest();
	}, 500);
}

function updateNameCode(e){
	var id = $(e).data('id');
	var name = $(e).data('name');
	var type = $(e).data('type');

  var upd = {'namesys': window.gamestate.data.namesys}
	if(type=='male') {
		code = $('.input-malename-'+id).val();
		upd.namesys.regions[systemRegion].countries[systemCountry].firstnameM[id].mnemonic = code;
	} else if(type=='female') {
		code = $('.input-femalename-'+id).val();
		upd.namesys.regions[systemRegion].countries[systemCountry].firstnameF[id].mnemonic = code;
	} else if(type=='surname') {
		code = $('.input-surame-'+id).val();
		upd.namesys.regions[systemRegion].countries[systemCountry].surnames[id].mnemonic = code;
	}
	$(e).addClass('hide');
	window.gamestate_utils.set(upd);
}

function showRegionCountries(e){
	if($(e).hasClass('open')) {
		$(e).removeClass('open');
	} else {
		$(e).parent().find('.content').removeClass('open');
		$(e).addClass('open');
		if(!isMobile) $(e).find('ul').css('max-height', 265);
	}
}

function nameScroll(page){
  systemNamePage = page;
  showNameCountry(systemCountry, systemNamePage);
}

function showLearnificationNamesys(step){
  $('#namesys .content-learn').addClass('hide');
  if(step == 'step0') { 
    $('#namesys .content-country-intro').removeClass('hide');
  } else if(step == 'step1') { 
    $('#namesys .content-learn-1').removeClass('hide');
    setTimeout(function(){ $('.content-learn-1 .preview1').addClass('preview'); }, 500);
    setTimeout(function(){ $('.content-learn-1 .preview2').addClass('preview'); }, 1000);
  } else if(step == 'step2') {
    $('#namesys .content-learn-2').removeClass('hide');
    setTimeout(function(){ $('.content-learn-2 .preview1').addClass('preview'); }, 500);
    setTimeout(function(){ $('.content-learn-2 .preview2').addClass('preview'); }, 1000);
  }
}

function hideLearnificationNamesys(){
  $('#namesys .content-learn').addClass('hide');
}
