var wordsU = {verbs: [], nouns1: [], adjectives:[], names:[], abstracts:[]}, words = {verbs: ['believe','bend','bite','bleed','borrow','break','breathe','build','calculate','cancel','carve','charm','clean','close','comb','complain','convince','cough','count','crawl','cut','dance','die','dive','draw','dress up','drink','drive','eat','fall','fart','feel','fit','flirt','fly','focus','freeze','hide','hug','hunt','hurry','hurt','hypnotize','ignore','improve','jump','kill','kiss','leave','lift','listen','look','massage','open','organize','play','point','pull','punch','purchase','push','read','refuse','relax','rise','run','scare','scratch','scream','sell','shake','shoot','shuffle','sign','sing','sit','sleep','smell','smile','smoke','sow','spit','squeeze','stand','stare','step','stretch','study','swim','taste','think','threaten','translate','travel','type','undress','walk','work','worry','yawn'],
nouns1: ['bank','beach','bed','book','boxing','car','casino','chair','chess','clouds','coffee','competition','crime rate','customer','deadline','door','enemies','eyes','face','field','fish','football','fork','friends','gangster','glasses','goal','graph','hair','hairstyle','hammer','happiness','heart','hill','hospital','hurricane','jaw','king','knife','legs','lightning','lips','mafia','make-up','military','money','mountain','nail','nurse','parachute','PC','phone','photo','plan','plane','police','pollution','princess','prison','rain','river','road','sale','salsa','shadow','shoes','six-pack','sky','skyscraper','slide','spreadsheet','stars','strategy','suit','sun','supporter','swimming pool','table','tablet','target','terrorist','tower','war','warlord','watch','water','waterfall','wave','wind','surfboard','snow','sun','baby','wheel','kayak','axe','finger','biceps','shark','delfin','dress'],
adjectives:['fast','high','low','important','up','down','over','better','worse','warm','cold','raining','wet','dark','bright','smooth','touchy','inside','awesome','sweet','sexy','dangerous','empty','full','slow','afraid','brave','inelligent','stupid','boring','exciting','sweet','faithful','funny'],
names:['Elvis','Bono','Donald Duck','Mickey Mouse','Robert','Nicholas','Benny','Anne','Lydia','Abraham Lincoln','Madame Curie','Nicola Tesla','Albert Einstein','Montessori','Elon Musk','Leonard Cohen','Alisha Keys','Lauryn Hill','Superman','Ironman','Oslo','Singapore','Berlin','Manhattan','Barcelona','Paris','Cape Town','James Bond','Paul','Samuel','Jenny','Isabelle','Veronica','Linda','Christopher','Africa','Niagara','Indiana Jones','Luke Skywalker','Lara Croft','Anderson Silva','Bruce Lee','GSP','Pink Floyd','Prince','Helmut Kohl','Spitzbergen','Tokyo','Samsung','Apple'],
abstracts:['accident','adventure','aggressive','alone','anger','anxiety','artistic','bad','beauty','bend','big','bizarre','Blindly','bloated','brave','breathtaking','bright','brilliant','brutal','bubbling','calm','chaotic','charity','cheap','cheerful','childhood','clever','close','cloudy','coldness','comfort','comical','crazy','crime','cruel','customer','dangerous','death','defeat','democracy','desolate','desperate','dictatorship','disappointment','divine','dizzy','dramatic','duty','education','ego','elastic','elegant','embarrassing','ending','energy','enormos','envy','even','evil','expensive','failure','faith','fast','fear','feedback','foggy','freedom','friendship','frightening','generous','glamorous','gloomy','goodness','grow','happiness','happy','hard','hatred','haunted','hazy','hilarious','homeless','honest','honour','hot','hour','humility','humongous','humour','hungry','hurt','idea','illuminated','imagination','industry','inflation','innocent','Inquisitive','insanity','intelligence','invisible','inviting','joy','juicy','justice','knowledge','law','lazy','life','lift','loss','loud','love','low','luck','luxurious','memory','move','movement','music','naughty','needy','Nervous','open','pain','pale','peace','perfect','pleasure','Polite','polluted','Poorly','poverty','power','precocious','pull','push','Quickly','radical','rebellious','receive','refreshment','relaxation','restoration','rich','romance','sadness','safe','satisfaction','sensitive','shaking','shallow','Sharp','shock','shut','silence','slavery','sleep','Slowly','small','smell','sober','soft','sparkle','speed','spicy','sport','stable','strange','strength','strict','surprise','sweet','thrill','tightly','tiredness','together','tough','unstable','victory','visionary','watery','wealth','Weekly','wet','wildly','wisdom','wooly','awkward','belief','clarity','content','encourage','enlightened','enthusiasm','fantastic','grief','mortal','wonderful']
};

var rootColors=[
'map-pale-white','map-pale-blue','map-pale-darkblue','map-pale-green','map-pale-darkgreen','map-pale-purple','map-pale-darkpurple','map-pale-red','map-pale-yellow','map-pale-orange'
];
var mapColors=[
'map-pale-white','map-pale-blue','map-pale-darkblue','map-pale-green','map-pale-darkgreen','map-pale-purple','map-pale-darkpurple','map-pale-red','map-pale-yellow','map-pale-orange','map-gradient-white','map-gradient-blue','map-gradient-darkblue','map-gradient-green','map-gradient-darkgreen','map-gradient-purple','map-gradient-darkpurple','map-gradient-red','map-gradient-yellow','map-gradient-orange'
];
var nodeImagesU=[], nodeImages = [
'curriculum1.ico',
'curriculum10.ico',
'curriculum11.ico',
'curriculum12.ico',
'curriculum13.ico',
'curriculum14.ico',
'curriculum15.ico',
'curriculum16.ico',
'curriculum17.ico',
'curriculum18.ico',
'curriculum19.ico',
'curriculum2.ico',
'curriculum20.ico',
'curriculum21.ico',
'curriculum22.ico',
'curriculum23.ico',
'curriculum24.ico',
'curriculum25.ico',
'curriculum26.ico',
'curriculum27.ico',
'curriculum28.ico',
'curriculum29.ico',
'curriculum3.ico',
'curriculum30.ico',
'curriculum31.ico',
'curriculum32.ico',
'curriculum33.ico',
'curriculum4.ico',
'curriculum5.ico',
'curriculum6.ico',
'curriculum7.ico',
'curriculum8.ico',
'curriculum9.ico'
];