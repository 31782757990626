function randomItem(arr, arr2, arr3) {
	if(arr === disTrans1) {
		return randomItemU(disTrans1U, arr, arr2, arr3);
	} else if(arr === disNeedTrans) {
		return randomItemU(disNeedTrans1U, arr, arr2, arr3);
	} else if(arr === disNeedIntros) {
		return randomItemU(disNeedIntrosU, arr, arr2, arr3);
	} else if(arr === disAnswerTrans) {
		return randomItemU(disAnswerTransU, arr, arr2, arr3);
	} else if(arr === disTaskIntros) {
		return randomItemU(disTaskIntrosU, arr, arr2, arr3);
	} else if(arr === disWhoQuestions) {
		return randomItemU(disWhoQuestionsU, arr, arr2, arr3);
	} else if(arr === disTaskAccepts) {
		return randomItemU(disTaskAcceptsU, arr, arr2, arr3);
	} else if(arr === disTimeQuestions) {
		return randomItemU(disTimeQuestionsU, arr, arr2, arr3);
	} else if(arr === disTaskComments) {
		return randomItemU(disTaskCommentsU, arr, arr2, arr3);
	} else if(arr === disTasks) {
		return randomItemU(disTasksU, arr, arr2, arr3);
	} else if(arr === disQAQuestions) {
		return randomItemU(disQAQuestionsU, arr, arr2, arr3);
	} else if(arr === disQAPreQuestionComments) {
		return randomItemU(disQAPreQuestionCommentsU, arr, arr2, arr3);
	} else if(arr === disQAAfterQuestionComments) {
		return randomItemU(disQAAfterQuestionCommentsU, arr, arr2, arr3);
	} else if(arr === nodeImages) {
		return randomItemU(nodeImagesU, arr, arr2, arr3);
	}
	if(!arr || !arr.length)
		console.log('empty array');
	var i = parseInt(Math.random() * arr.length);
	if(!arr2) {
		return arr[i];
	} else {
		return [arr[i], arr2[i], arr3[i]];
	}
}

//non-duplicate sequence
function randomItemU(arrU, arr, arr2, arr3) {

  // Initialize the array of unique indexes
	if(arrU.length === 0) {
		for(var j=0; j<arr.length; j++) {
			arrU.push(j);
		}
    console.log("arrU initialized", arrU);
  }
  
  // Get a random i in the current index array and remove i from this array so we don't get duplicate i in recursive calls.
	var ix = parseInt(Math.random() * arrU.length);
	var i = arrU[ix];
	arrU.splice(ix, 1);
  
  // If the img of item i is the same as the img from the last item, we shall call this function again to get a different item.
	if(arr[i].img && arr[i].img === lastItemU) {
		return randomItemU(arrU, arr, arr2, arr3);
	} else if(arr[i].img) {
		lastItemU = arr[i].img;
	}

	if(!arr2) {
		return arr[i];
	} else {
		return [arr[i], arr2[i], arr3[i]];
	}
}


function pushUnique(arr, item) {
	for(var i=0; i<arr.length; i++) {
		if(arr[i] === item) return;
	}
	arr.push(item);
}
