/*global currentGame, $, faceSet, levelMap, missionParams, showPanel, console, initGame, gameLevel, TemplateEngine */
"use strict";


var preloaded = {
  'superm': false,
  'superm0': false,
  'bankv': false,
  'library': false,
  'meeting': false,
  'face': false,
  'mindmap': false,
  'time': false
};

var loadingItems = {
  'superm':  ['super-market-wood.png', 'super-market-bag.png', 'bg-super-market-play.jpg', 'bg-super-market-intro.jpg'],
  'superm0': ['super-market-wood.png', 'super-market-bag.png', 'bg-super-market-play.jpg', 'bg-super-market-intro.jpg'],
  'bankv':   ['bank-vault-big-outer-shadow.png', 'bank-vault-big-outer.png', 'bank-vault-big.png', 'bank-vault-small.png', 'bank-vault-tiny.png', 'bg-bank-vault-intro.jpg'],
  'library': ['bg-brain-storm-play-bottom.jpg', 'bg-brain-storm-intro.jpg', 'bg-brain-storm-play.jpg'],
  'meeting': ['bg-meeting-play-bottom.jpg', 'bg-meeting-play.jpg', 'bg-meeting-intro.jpg'],
  'face':    ['bg-name-face-play.jpg', 'bg-name-face-intro.jpg'],
  'mindmap': ['library-background.jpg', 'bg-library-play.jpg', 'bg-library-intro.jpg'],
  'time':    ['bg-time-travel-play.jpg']
};

function getLoadingItems(game) {
  return loadingItems[game] || [];
}


function items_to_load(game) {
  return $('audio[id^=music-' + game + ']').length + getLoadingItems(game).length;
}

function showLevelAnimate(game) {
  $('#levelanimatepanel').html(TemplateEngine($('#tpl-level-animate').html(), {}));
  $('#levelanimatepanel .row.game-' + game).show();
  $('#levelanimatepanel .count-knob').knob();
  showPanel('levelanimatepanel');
}

function showPracticeNotice(part) {
  gameEnd = true;
  if (part === 'a') {
    $('.game-notice-sysa').addClass('animate').show();
  } else {
    $('.game-notice-sysb').addClass('animate').show();
  }
  setTimeout(function () { $('.game-notice-sysb, .game-notice-sysa').removeClass('animate'); }, 3000);
}

function showMasterNotice(part) {
  var _master = getMasterLevel(currentGame, gameLevel);
  if (/b/.test(part)) {
    $('.game-noticeb-master' + _master).addClass('animate');
  } else {
    $('.game-notice-master' + _master).addClass('animate');
  }
  setTimeout(function () { $('.game-notice').removeClass('animate'); }, 3000);
}


function showLoading() {
  showLevelAnimate(currentGame);
  var totalItems = 0;

  if (!preloaded[currentGame]) {
    preloaded[currentGame] = true;
    console.log("Loading", currentGame);

    /* Load sounds */
    console.log("Sounds...");
    var audio_elems = $('audio[id=music-' + currentGame + ']');
    audio_elems.bind('canplaythrough', function(e) {
      console.log("Audio element is ready", e.target);
    });
    audio_elems.each(function(i, audio) {
      console.log("Loading audio element", audio, "current ready state", audio.readyState);
      if (audio.readyState < 4) {
        audio.load();
        console.log("Called load for", audio);
      }
    });

    /* Load images in loadingItems */
    for (var i = 0; i<getLoadingItems(currentGame).length; i++){
      $('#loadingpanel .loading-items').append($('<img>').attr('src', '/images/'+getLoadingItems(currentGame[i])));
    }
    /* Load images in faceSet */
    if(currentGame === 'face') {
      levelSettings = levelMap[currentGame][gameLevel];
      totalItems = (missionParams && missionParams.totalItems) ? missionParams.totalItems : levelSettings[1];
      console.log("Loading face set");
      getFaceSet(totalItems);
      for (var i = 0; i<faceSet.length; i++){
        $('#loadingpanel .loading-items').append($('<img>').attr('src', '/faces/' + faceSet[i].img+'.png'));
      }
      preloaded[currentGame] = false; /* Make sure face game always updates */
    }
  }

  var close_timeout = 4000 + totalItems * 80;

  /* Start the knob progress animation */
  knobProgress(close_timeout/100, 0);

  /* Start the countdown clock for closing the progress screen */
  setTimeout(function(){
    $('.count-down.animate').addClass('up');
    //Set the delay time based on page loading progress time
    setTimeout(function(){
      var gym = window.gamestate.data.gameMode === 'gym' ? 'gym': '';
      if (!gameHasGymMode(currentGame)) {
        gym = '';
      }
      showPanel('panel' + currentGame + gym);
      if(!/meeting/.test(currentGame)) {
        if(/mindmap/.test(currentGame)) {
          setTimeout(showMasterNotice,3000);
        } else {
          showMasterNotice();
        }
      }
      $('#panel'+currentGame).addClass('play');
      initGame[currentGame+gym]();
    }, 1000);
  }, close_timeout);
}

function gameHasGymMode(game) {
  return (initGame[game + "gym"] !== undefined);
}

function knobProgress(timeout, percent) {
  setTimeout(function() {
    if (percent <= 100) {
      $('#levelanimatepanel .count-knob').val(percent).trigger('change');
      percent++;
      knobProgress(timeout, percent);
    }
  }, timeout);
}