function showSysPractice(technique, level, params){
  if(technique) {
    currentGame = technique;    
  }
  missionParams = params;
  systemCodeIndex = -1;
  if (/singlesys|doublesys/.test(currentGame)) {
    if (technique === 'singlesys') {
      showSysSinglePractice('singlesys',0);
    } else {
      showDoubleSysCategories();
    }
  }
  else if(/journeysys/.test(currentGame)) showSysJourneyPractice();
  else if(/namesys/.test(currentGame)) showSysNamePractice();
  else if(/alphabetsys|rhymesys/.test(currentGame)) showSysSinglePractice();

  translate();
}

function saveOnEnter(e){
  var parent = $(e.currentTarget).parent().parent();
  parent.parent().find('.active').removeClass('active');
  parent.addClass('active');
  parent.find('.edit').addClass('hide');
  parent.find('.save').removeClass('hide');
  var charCode = (e.which) ? e.which : e.keyCode;
  if(charCode == 13) {
    if($(e.currentTarget).parent().find('.save').length) $(e.currentTarget).parent().find('.save').click();
    else $(e.currentTarget).parent().parent().find('.save').click();
  }
}

function showLearnificationSinglesys(step){
  hideLearnificationSinglesys();
  if(step == 'step1') $('.singlesys-tour1').removeClass('hide').addClass('animate');
  else if(step == 'step2') $('.singlesys-tour2').removeClass('hide').addClass('animate');
  else if(step == 'step3') {
    $('.singlesys-tour3').removeClass('hide').addClass('show');
    $('.system-tour > div').hide();
    $('.system-tour .slide1').show();
  }
  
  setTimeout(function(){ 
    $('.singlesys-tour1').css('top',$('.single-learn').offset().top);
  },1000); 
  $('.singlesys-tour2').css('top',$('.single-learn .footer').offset().top - $('.singlesys-tour2').height()-30);

}

function hideLearnificationSinglesys(){
 $('.singlesys-tour1, .singlesys-tour2, .singlesys-tour3').addClass('hide').removeClass('animate');
}
